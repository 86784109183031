import React, { Component } from 'react';

class Note extends Component {
    render() {
        const {
            factura,
            extra_amount_initial = 0,
        } = this.props;
        console.log("PROPS IN NOTE: ", this.props);

        return (
            <div className="d-flex p-3 flex-column justify-content-center align-items-center w-100 fac">
                <span className="fac-title mb-3">
                    Recibo por anticipo
                </span>

                <table className="table table-payments-list">
                    <thead>
                        <tr>
                            <th>Código venta</th>
                            <th>Fecha y hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{factura.dataBilling.correlative}</td>
                            <td>{factura.dataBilling.fecha}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table table-payments-list">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{factura.dataBilling && factura.dataBilling.patient || factura.dataBilling.name}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table table-sm table-preview">
                    <thead>
                        <tr className="fac-title-detail">
                            <th className="text-left"><span>  PRODUCTO</span></th>
                            <th className="text-right"><span>TOTAL</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {factura.services.map((product, i) =>
                            <React.Fragment key={i}>
                                <tr className="fac-row-detail">
                                    <td className="space-none"><span>{product.quantity}&nbsp;&nbsp;</span><span>{product.service_name}</span>
                                        {product.discount > 0 && <em className="fac-row-discount">descuento</em>}
                                    </td>
                                    <td className="text-right space-none"><span>Q {product.subtotal}</span>
                                        {product.discount > 0 && <em className="fac-row-discount">Q {product.discount}</em>}
                                    </td>
                                </tr>
                                {product.coupons.map((coupon, j) =>
                                    <tr className="fac-row-detail" key={j}>
                                        <td className="space-none"><em className="fac-row-discount">Descuento cupón {coupon.code}</em></td>
                                        <td className="text-right space-none"><em className="fac-row-discount">Q {coupon.amount_label}</em>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )}
                        <tr>
                            <td colSpan="2"><hr /></td>
                        </tr>
                        {this.props.descuento > 0 &&
                            <tr className="fac-row-descuento">
                                <td><span>Total de decuento</span></td>
                                <td className="text-right"><span>Q {(factura.calculate.descuento).toFixed(2)}</span></td>
                            </tr>
                        }
                        {extra_amount_initial &&
                            <tr className="fac-row-total">
                                <td><span>SUB TOTAL</span></td>
                                <td className="text-right"><span>Q {(factura.calculate.total).toFixed(2)}</span></td>
                            </tr>
                        }
                        <tr className="fac-row-total">
                            <td><span>TOTAL
                                {extra_amount_initial && ' (IVA INCLUIDO)'}
                            </span></td>
                            <td className="text-right"><span>Q {parseFloat(factura.calculate.total) + parseFloat(extra_amount_initial)}</span></td>
                        </tr>
                    </tbody>
                </table>

                {this.props.history_payments.length ?
                    <table className="table table-payments-list">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Tipo</th>
                                <th>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.history_payments.map((payment, i) =>
                                <tr className="fac-row-detail" key={i}>
                                    <td className="space-none">
                                        {payment.made}
                                    </td>
                                    <td className="space-none">
                                        {payment.payment_type === 1 && "Efectivo"}
                                        {payment.payment_type === 2 && "Tarjeta"}
                                        {payment.payment_type === 3 && "Cuotas"}
                                        {payment.payment_type === 4 && "Depósito"}
                                    </td>
                                    <td className="text-right space-none"><span>
                                        Q.
                                        {!!(payment.payment_type === 3 && "Cuotas") ?
                                            parseFloat(payment.payment) + parseFloat(payment.extra_amount) :
                                            payment.payment
                                        }
                                    </span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    : null
                }
                <span className="fac-footer">ESTE DOCUMENTO NO ES UNA FACTURA</span>
            </div>
        );
    }
}

export default Note;
