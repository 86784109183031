import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/treatments';

import Treatments from './Treatments';
import { resetStore } from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.treatments,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Treatments);
