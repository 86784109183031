import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/surgeryFee';

import FeePaid from './FeePaid';
import {resetStore} from "../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.fee,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(FeePaid);
