import React, { Component } from 'react';

class Format extends Component {
    render(){
        const {
            factura,
            payments,
        } = this.props;

        const fuente= {
            color: "black !important",
            fontWeight: "bold",
            fontSize: 12
        }

        console.log("FACTURA: ", factura);
        const total_entrada = factura ? parseFloat(parseFloat(factura.total).toFixed(2)) : 0;
        const total_pagado = factura ? parseFloat(parseFloat(factura.paid).toFixed(2)) : 0;
        const total_retencion = factura ? parseFloat(parseFloat(factura.retention_amount).toFixed(2)) : 0;
        const descuento = factura ? parseFloat(parseFloat(factura.discount).toFixed(2)) : 0;

        const saldo_pendiente = parseFloat((total_entrada - total_pagado - total_retencion - descuento).toFixed(2));

        return(
            <div className="d-flex p-3 flex-column justify-content-center align-items-center w-100 fac">
                {!!factura.bill_number &&
                <div className='mt-2 mb-2'>
                    <span

                        style={{color:"black", fontSize: 13}}
                    >
                        No factura: {factura.bill_number}
                    </span>
                </div>
                }
                <table className="table table-sm table-preview">
                    <thead>
                        <tr className="fac-title-detail">
                            <th className="text-left"><span>PRODUCTO</span></th>
                            <th className="text-right"><span>TOTAL</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {factura.details.map((product, i) =>
                            <React.Fragment key={i}>
                            <tr className="fac-row-detail">
                                <td className="space-none">
                                    <span>{product.entry_quantity}&nbsp;&nbsp;</span>
                                    <span>{product.service.name}</span>
                                    &nbsp;&nbsp;
                                    <span className='font-weight-bold'>[{product.service.barcode}]</span>
                                </td>
                                <td className="text-right space-none">
                                    {product.consignment ?
                                        <span>(Consignación)</span>
                                        :
                                        <span>Q {product.total}</span>
                                    }
                                    {product.discount > 0 && <em className="fac-row-discount">Q {product.discount}</em>}
                                </td>
                            </tr>
                            </React.Fragment>
                        )}

                        {!!factura.entrymovex_movement && factura.entrymovex_movement.map((product, i) =>
                            <React.Fragment key={i}>
                            <tr className="fac-row-detail">
                                <td className="space-none">
                                    <span>1&nbsp;&nbsp;</span>
                                    <span>{product.spending_type.name}</span>
                                    &nbsp;&nbsp;
                                    <span className='font-weight-bold'></span>
                                </td>
                                {/*
                                <td className="text-right space-none">
                                    {product.consignment ?
                                        <span>(Consignación)</span>
                                        :
                                        <span>Q {product.total}</span>
                                    }
                                    {product.discount > 0 && <em className="fac-row-discount">Q {product.discount}</em>}
                                </td>
                                */}
                            </tr>
                            </React.Fragment>
                        )}

                        <tr className="fac-row-detail">
                            <td colSpan={2}><hr className="fac-division-line" /></td>
                        </tr>

                        <tr className="fac-row-total">
                            <td><span>TOTAL</span></td>
                            <td className="text-right"><span>Q {(parseFloat(factura.total)).toFixed(2)}</span></td>
                        </tr>
                        {total_retencion > 0 &&
                            <tr className="fac-row-total">
                                <td><span>Pagado con retención</span></td>
                                <td className="text-right"><span>Q {total_retencion}</span></td>
                            </tr>
                        }
                    </tbody>
                </table>

                {payments && payments.length ?
                    <React.Fragment>

                    <div className="d-flex flex-row justify-content-start w-100">
                        <span style={fuente}>Historial de pagos:</span>
                    </div>
                    {payments.map((payment, i) =>
                        <React.Fragment key={i}>
                            <div className="d-flex flex-row justify-content-between w-100">
                                <span style={fuente}>{payment.payment_type}</span>
                                <span style={fuente}>{payment.creado}</span>
                                <span style={fuente}>Q{payment.amount}</span>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="d-flex flex-row justify-content-between w-100">
                        <span style={fuente}>Saldo pendiente</span>
                        <span style={fuente}>Q {saldo_pendiente}</span>
                    </div>
                    </React.Fragment>
                    :
                    null
                }

                <span className="fac-footer">Fecha: {factura.fecha_fac}</span>
                <span className="fac-footer">
                    {!!factura.purchase ? factura.purchase.provider.name : !!factura.provider ? factura.provider.name : ''}
                </span>
                <span className="fac-footer">
                    {!!factura.purchase ? factura.purchase.provider.direction : !!factura.provider ? factura.provider.direction: ''}
                </span>
            </div>
        );
    }
}

export default Format;
