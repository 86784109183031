import { connect } from 'react-redux';
import ExpenseCrear from './ExpensesCrear';
import { actions } from '../../../redux/modules/expenses';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    const me = state.login.me;
    return {
        ...state.expense,
        me,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(ExpenseCrear);
