import Table from '../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { showNestedFields } from '../../Utils/MyOptions';
import './diagnosticQueue';
import MyModal from '../../Utils/myModal';
import Form from './CancelForm';
import ToolbarSearchSelect from '../../Utils/Toolbar/ToolbarSearchSelect';
import moment from "moment";

class DiagnosticQueue extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount = () =>{
        this.props.getColaDiagnostico();
        this.consultaContinua();
    }

    consultaContinua = () => {
        this.timer = setInterval(() => this.props.getColaDiagnostico(), 30000);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.props.resetStore();
    }

    actions = (cell, row) => {
        return(
                <div className='d-flex flex-row justify-content-center'>

                {row.state === 0 &&
                        <button
                            className='btn btn-sm btn-primary small-button'
                            onClick={()=>this.props.iniciarDiagnostico(cell)}
                        >Iniciar</button>
                }

                {row.state === 1 &&
                    <React.Fragment>
                        <button
                            className='btn btn-sm btn-success small-button'
                            onClick={()=>this.props.startDiagnostic(cell)}
                        >Finalizar</button>
                    </React.Fragment>
                }

                {row.state !== 2 &&
                    <MyModal
                        isOpen={false}
                        title="ANULACIÓN"
                        formId="cancelDiagnosticProcedure"
                        textButton='Anular'
                        classToggleButton="btn btn-sm btn-danger small-button ml-2"
                        body={(
                            <Form
                                onSubmit={this.props.cancelarColaDiagnostico}
                                initialValues={{
                                    id: row.id,
                                }}
                            />
                        )}
                    />
                }

                </div>
        );
    }

    render() {

        const options = [
            {value: 0, label: "En Cola"},
            {value: 1, label: "En Proceso"},
            {value: 2, label: "Finalizadas"},
            //{value: 2, label: "Anuladas"},
        ];

        console.log("this.props.buscar_select: ", this.props.filtro_reporte);

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 pt-5">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo"><strong>COLA DE DIAGNÓSTICO</strong></span>
                            </div>

                            <div className="top-title-toolbar">
                                <ToolbarSearchSelect
                                    buscar={this.props.searchColaDiagnostico}
                                    buscador={this.props.buscador_diagnostic_queue}

                                    buscarSelect={this.props.filtroReporte}
                                    buscadorSelect={this.props.filtro_todays_surgery}
                                    options={options}
                                    valueKey="value"
                                    labelKey="label"
                                    autofocus
                                />
                            </div>
                        </div>
                        <Table
                            onPageChange={this.props.getColaDiagnostico}
                            data={this.props.diagnostic_queue}
                            loading={this.props.cargando}
                            page={this.props.page_diagnostic_queue}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="diagnostic"
                                dataFormat={(cell, row) =>{
                                    const nombre_completo = row.diagnostic.patient.first_name  + ' ' + row.diagnostic.patient.last_name;
                                    return(
                                        <React.Fragment>
                                            {nombre_completo}
                                        </React.Fragment>
                                    );
                                }}
                                >Paciente</TableHeaderColumn>


                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                formatExtraData="diagnostic__referent__name"
                                dataSort={true}
                                dataFormat={showNestedFields}
                                dataField="diagnostic" dataSort>Referente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="created"
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                >Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="state_display" dataSort>Estado</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                formatExtraData="service__name"
                                dataSort={true}
                                dataFormat={showNestedFields}
                                dataField="service" dataSort>Procedimiento</TableHeaderColumn>

                            <TableHeaderColumn
                                width={'20%'}
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={this.actions}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        );
    };
}

export default DiagnosticQueue;
