import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/receivable';

import CuentaPorCobrar from './Receivable';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.receivable,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(CuentaPorCobrar);
