import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { TablePayments } from './TablePayments';
import Spoiler from '../../Utils/Spoiler';

class HistoryPayments extends Component {
    componentWillMount(){
        const entry_movement_id = this.props.match.params.id
        this.props.chargeEntryPayments(entry_movement_id);
    }

    cancelPayment = (id, codigo, referencia_retorno) => {
        this.props.cancelPayment(id,codigo, referencia_retorno);
        const entry_movement_id = this.props.match.params.id;

        setTimeout(() => {
            this.props.chargeEntryPayments(entry_movement_id);
            this.forceUpdate();
        }, 1000);
    }

    render() {
        const {
            data_payments,
        } = this.props;

        console.log("DATA PAYMENTS: ", this.props.data_payments);

        const ruta_retorno = '/view-entry-order/' + this.props.match.params.id + '/view';
        console.log("Ruta de retorno: ", ruta_retorno);

        return (
            <div className="content">
            <div className="m-auto row-fluid w-100">
                <HeaderSimple texto={"Regresar"} ruta={ruta_retorno}/>
                <div className="table-decoration">
                    <div className="top-title">
                        Historial de pagos
                    </div>
                    <div className="d-flex p-3 flex-column">
                        <React.Fragment>
                        {!!data_payments && Object.keys(data_payments.result).length === 0 &&
                            <center><h5>No existen anticipos o abonos asociados a la cuenta</h5></center>
                        }
                        {!!data_payments &&
                            <React.Fragment>
                                {data_payments.result.hasOwnProperty('Efectivo') && data_payments.result.Efectivo.length !== null &&
                                    <Spoiler show={false} label={"Pagos en Efectivo"}>
                                        <TablePayments data={data_payments.result.Efectivo} cancelPayment={this.props.cancelEntryPayment} ruta_retorno={ruta_retorno} />
                                    </Spoiler>
                                }
                                {data_payments.result.hasOwnProperty('Transacción') && data_payments.result['Transacción'].length !== null &&
                                <Spoiler show={false} label={"Pagos con transacción"}>
                                    <TablePayments data={data_payments.result["Transacción"]} cancelPayment={this.props.cancelEntryPayment} ruta_retorno={ruta_retorno}/>
                                </Spoiler>
                                }
                                {data_payments.result.hasOwnProperty('Tarjeta') && data_payments.result['Tarjeta'].length !== null &&
                                <Spoiler show={false} label={"Pagos con tarjeta de cŕedito"}>
                                    <TablePayments data={data_payments.result["Tarjeta"]} cancelPayment={this.props.cancelEntryPayment} ruta_retorno={ruta_retorno}/>
                                </Spoiler>
                                }
                                {data_payments.result.hasOwnProperty('Cuotas') && data_payments.result.Cuotas.length !== null &&
                                <Spoiler show={false} label={"Pagos con cuotas"}>
                                    <TablePayments data={data_payments.result.Cuotas} cancelPayment={this.props.cancelEntryPayment} ruta_retorno={ruta_retorno}/>
                                </Spoiler>
                                }
                            </React.Fragment>
                        }
                        </React.Fragment>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default HistoryPayments;
