import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderSwitch }  from 'Utils/renderField/renderField';
import { connect } from 'react-redux'
import trim from 'lodash/trim';
import validarNit from 'validar-nit-gt';

export const isEmpty = value => value === undefined || value === null || value === '';
export function NIT(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase())
        return validarNit(value) ? null : value === 'CF' ? null : 'Escriba un nit válido o CF'
    }
    return null
}

let ProviderForm = props => {
    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nit" className="m-0">Nit</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="nit" disabled={view} validate={NIT} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="direction" className="m-0">Dirección</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="direction" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="telephone" className="m-0">Telefono</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="telephone" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="email" className="m-0">Correo</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="email" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    {/* RETENCION DE ISR */}
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="retention" className="m-0">¿Se retiene ISR?</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="retention" disabled={view} component={renderSwitch} type="text" className="form-control"/>
                    </div>
                    {/* RETENCION DE IVA */}
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="retention" className="m-0">¿Se retiene IVA?</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="retention_iva" disabled={view} component={renderSwitch} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-0 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="comodion" className="m-0"></label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/providers">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

ProviderForm = reduxForm({
   form: 'provider',
   validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'nit': validators.exists()('Campo requerido.'),
       })
   }
})(ProviderForm);

export default ProviderForm
