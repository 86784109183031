import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'

class Treatments extends Component {
    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar tratamiento"} ruta={"/treatments/create"}/>
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE TRATAMIENTOS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="commercial_name" dataSort>Nombre comercial</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(value, row) => {
                                    return <div>
                                        <span>{row.active_principle}</span> /
                                        <span>{row.presentation}</span>
                                    </div>
                                }}
                                dataField="active_principle" dataSort>Principio activo / Presentación</TableHeaderColumn>
                                {/*
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="presentation" dataSort>Presentación</TableHeaderColumn>
                            */}
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="dose" dataSort>Dosis</TableHeaderColumn>                                           
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="application" dataSort>Aplicación</TableHeaderColumn>  
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}                                
                                dataField="time_duration" dataSort>Tiempo de duración</TableHeaderColumn> 
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="frecuency" dataSort>Frecuencia</TableHeaderColumn>                                                                   
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/treatments', editar: '/treatments', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Treatments;
