import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";


export class Tonometry extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false};
    }

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    selectOption = (input, value) => {
        input.onChange(value);
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        let fi = 0;
        const ff = 5;

        let ci = 0;
        const cf = 6;

        let columnas = [];

        for(fi;fi<=ff;fi++){
            ci = 0;
            let fila = [];
            for(ci;ci<=cf;ci++){
                const cantidad = fi + (ci*6);
                fila.push(cantidad.toFixed(2))
            }
            columnas.push(fila);
        }


        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h2 className="border-bottom">{title}</h2>
                    </div>
                    <table id="notations-table-modal">
                        <tr>
                        </tr>
                        <tbody>
                        {columnas.map(columna=>(
                            <tr key={columna}>
                                {columna.map(fila=>(
                                    <React.Fragment key={fila}>
                                    {fila <=40 &&
                                    <td key={fila}>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                                onClick={() => this.selectOption(input, fila)}>
                                                <input type={"radio"} value={fila} {...input} onChange={() => this.selectOption(input, fila)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{fila}
                                            </label>
                                        </div>
                                    </td>
                                    }
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <table id="notations-table-modal" className='mt-4'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="radio invisible">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                                            <input type={"radio"} value={45} {...input}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{45}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 45)}>
                                            <input type={"radio"} value={45} {...input} onChange={() => this.selectOption(input, 45)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{45}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 50)}>
                                            <input type={"radio"} value={50} {...input} onChange={() => this.selectOption(input, 50)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{50}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 55)}>
                                            <input type={"radio"} value={55} {...input} onChange={() => this.selectOption(input, 55)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{55}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 60)}>
                                            <input type={"radio"} value={60} {...input} onChange={() => this.selectOption(input, 60)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{60}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 65)}>
                                            <input type={"radio"} value={65} {...input} onChange={() => this.selectOption(input, 65)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{65}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 70)}>
                                            <input type={"radio"} value={70} {...input} onChange={() => this.selectOption(input, 70)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{70}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div className="radio invisible">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                                            <input type={"radio"} value={45} {...input}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{45}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
