import React, { Component } from 'react';
import Table from '../Utils/Grid';
import ToolbarChange from '../Utils/Toolbar/ToolbarChange';
import { BreakLine } from '../Utils/tableOptions';
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import { showNestedFields } from '../Utils/MyOptions';
import Select from 'react-select';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from "moment";

class Arqueo extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    state = {
        redirect: true
    }

    componentDidMount = () => {
        this.props.listar();
    }

    render() {
        const {
            listar,
            data,
            cargando,
            page,
            set_year,
            set_month,
        } = this.props;

        const {
            search,
            buscador,
            month,
            year,
        } = this.props;

        return (
            <div className="content">
                <div className="mx-4 mt-0 pt-5">
                    <div className="table-decoration">
                            <div className="d-flex flex-row justify-content-around align-items-center">

                                <div className="d-flex flex-row justify-content-start">
                                <div className="top-title-toolbar mt-3 d-flex">
                                    <span className="ml-2 text-uppercase text-titulo"><strong>REPORTE DE ARQUEOS</strong></span>
                                </div>
                                </div>

                                <div className='d-flex flex row'>
                                <div className="mx-2 d-flex flex-inline align-items-center">
                                    <div className="mx-2">
                                        MES:
                                    </div>
                                    <Select
                                        value={month}
                                        className="form-control form-month p-0 border-trasparent"
                                        classNamePrefix="react-select"
                                        onChange={(value)=>{set_month(value)}}
                                        theme={(theme) => {
                                            return {
                                                ...theme,
                                                borderRadius: '1em',
                                                background: 'white',
                                                colors: {
                                                    ...theme.colors,
                                                    text: '#415362',
                                                    primary25: '#ebebeb',
                                                primary: '#0a5995',
                                                },
                                            }
                                        }}
                                        options={[
                                            {value:'01', label:'Enero'},
                                            {value:'02', label:'Febrero'},
                                            {value:'03', label:'Marzo'},
                                            {value:'04', label:'Abril'},
                                            {value:'05', label:'Mayo'},
                                            {value:'06', label:'Junio'},
                                            {value:'07', label:'Julio'},
                                            {value:'08', label:'Agosto'},
                                            {value:'09', label:'Septiembre'},
                                            {value:'10', label:'Octubre'},
                                            {value:'11', label:'Noviembre'},
                                            {value:'12', label:'Diciembre'},
                                        ]}
                                        autoFocus
                                    />
                                </div>
                                <div className="d-flex flex-inline align-items-center">
                                    <div className="mx-2">
                                        AÑO:
                                    </div>
                                    <Select
                                        value={year}
                                        className="form-control form-year p-0 border-trasparent"
                                        classNamePrefix="react-select"
                                        onChange={(value)=>{set_year(value)}}
                                        theme={(theme) => {
                                            return {
                                                ...theme,
                                                borderRadius: '1em',
                                                background: 'white',
                                                colors: {
                                                    ...theme.colors,
                                                    text: '#415362',
                                                    primary25: '#ebebeb',
                                                primary: '#0a5995',
                                                },
                                            }
                                        }}
                                        options={[
                                            {value:'2010', label:'2010'},
                                            {value:'2011', label:'2011'},
                                            {value:'2012', label:'2012'},
                                            {value:'2013', label:'2013'},
                                            {value:'2014', label:'2014'},
                                            {value:'2015', label:'2015'},
                                            {value:'2016', label:'2016'},
                                            {value:'2017', label:'2017'},
                                            {value:'2018', label:'2018'},
                                            {value:'2019', label:'2019'},
                                            {value:'2020', label:'2020'},
                                            {value:'2021', label:'2021'},
                                            {value:'2022', label:'2022'},
                                            {value:'2023', label:'2023'},
                                            {value:'2024', label:'2024'},
                                            {value:'2025', label:'2025'},
                                            {value:'2026', label:'2026'},
                                            {value:'2027', label:'2027'},
                                            {value:'2028', label:'2028'},
                                            {value:'2029', label:'2029'},
                                            {value:'2030', label:'2030'},
                                            {value:'2031', label:'2031'},
                                            {value:'2032', label:'2032'},
                                            {value:'2033', label:'2033'},
                                            {value:'2034', label:'2034'},
                                        ]}
                                    />
                                </div>
                                </div>
                            </div>

                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="opening_date"
                                >
                                    Fecha
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    formatExtraData="user__first_name"
                                    dataSort={true}
                                    dataFormat={showNestedFields}
                                >
                                    Usuario Responsable
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="opening_amount"
                                    dataSort
                                    dataFormat={(cell) => { return <RenderCurrency value={cell} />}}
                                >
                                    Monto Apertura
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="amount_close"
                                    dataSort
                                    dataFormat={(cell) => { return <RenderCurrency value={cell} />}}
                                >
                                    Monto Cierre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={(cell) => {
                                        return (
                                            <a href={"/#/arching-detail/" + cell}>
                                                <img
                                                    className="action-img watch"
                                                    src={require("../../../../assets/img/icons/view-icon.png")}
                                                    alt="detalle"
                                                />
                                            </a>
                                        );
                                    }}>Acciones
                                </TableHeaderColumn>

                            </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Arqueo;
