import React, {Component} from 'react';
import Form from './DiagnosticOrderForm';

export default class DiagnosticOrderCreate extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar diagnóstico',
        search_patient: 1,
        no_patient_options: 1,
        load_products: true,
    };

    showProducts = () => {
        this.setState({
            load_products: !this.state.load_products
        });
    };

    componentDidMount() {
        window.modal_service = false
    }

    render() {
        const {
            create,
            detailPatient,
            municipios,
            services_list
        } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid card-form w-100">
                    <div className="table-decoration">
                        <div className="fields">
                            <div className="top-title">
                                NUEVA ORDEN DE DIAGNÓSTICO
                            </div>
                            <Form
                                municipios={municipios}
                                detailPatient={detailPatient}
                                showProducts={this.showProducts}
                                load_products={this.state.load_products}
                                services_list={services_list}
                                listarServicios={this.props.listarServicios}
                                addServiceToList={this.props.addServiceToList}
                                addServiceToListDiff={this.props.addServiceToListDiff}
                                data_service={this.props.data_service}
                                showPatientOptions={this.showPatientOptions}
                                page_service={this.props.page_service}
                                cargando_services={this.props.cargando_services}
                                modal_service={window.modal_service}
                                removeServiceToList={this.props.removeServiceToList}
                                no_patient_options={this.state.no_patient_options}
                                search_patient={this.state.search_patient}
                                showPatientSearch={this.showPatientSearch}
                                initializePatientBirthdate={this.initializePatientBirthdate}
                                upDownCant={this.props.upDownCant}
                                onSubmit={create}
                                searchService={this.props.searchService}
                                buscador_service={this.props.buscador_service}

                                setPaciente={this.props.setPaciente} //add ...
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
