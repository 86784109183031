import React, {Component} from 'react';

import {
    VISUAL_ACUITY,
    TONOMETRY,
    REFRACTOR,
    MUSCLES,
    LENSOMETRY,
    KERATOMERY,
    INDIRECT_EYE,
    AUTOREFRACTOR,
    LAMP,
    RECORD,
    RECONSULT,
    DIAGNOSTIC_PLAN,
    EDUCATIONAL_PLAN,
    OPTICS_ORDER,
} from '../../../../utility/variables';

import RecordForm from "../../Consult/Record/Form";
import AutorefractorForm from "../../Consult/Autorefractor/Form";
import IndirectEyeForm from "../../Consult/IndirectEye/Form";
import KeratometryForm from "../../Consult/Keratometry/Form";
import LampForm from "../../Consult/Lamp/Form";
import LensometryForm from "../../Consult/Lensometry/Form";
import MuscleForm from "../../Consult/Muscle/Form";
import RefractionForm from "../../Consult/Refraction/Form";
import TonometryForm from "../../Consult/Tonometry/Form";
import VisualAcuityForm from "../../Consult/VisualAcuity/Form";
import ReconsultForm from "../../Consult/Reconsult/Form";
import EducationalPlanForm from "../../Consult/EducationalPlan/Form";
import DiagnosticPlanForm from "../../Consult/DiagnosticPlan/Form";
import OpticsOrderForm from "../../Consult/OpticsOrder/Form";
import Patient from "../../Consult/Patient/index";
import {Link} from "react-router-dom";
import '../../Consult/Exam/exam_stayle';
import { getMuscleDetail } from '../../../../redux/modules/consult';
import './reporte_consulta_estilo';
import ScrollMenu from 'react-horizontal-scrolling-menu';

const list = [
    { name: 'Antecedentes', step: RECORD},
    { name: 'Lensometría', step: LENSOMETRY },
    { name: 'Agudeza Visual', step: VISUAL_ACUITY },
    { name: 'Autorefractómetro', step: AUTOREFRACTOR },
    { name: 'Queratómetro', step: KERATOMERY },
    { name: 'Refracción', step: REFRACTOR },
    { name: 'Tonometría', step: TONOMETRY },
    { name: 'Músculos Extraoculares', step: MUSCLES },
    { name: 'Lámpara de hendidura', step: LAMP },
    { name: 'Fondo de ojo indirecto', step: INDIRECT_EYE },
    { name: 'Plan Dx Plan Tx y Plan Educ', step: DIAGNOSTIC_PLAN },
    { name: 'Estudios y Procedimientos', step: EDUCATIONAL_PLAN },
    { name: 'Orden de Óptica', step: OPTICS_ORDER },
];

const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
};

const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

const selected = 'Antecedentes';

export const MenuItem = ({text, selected, step, changeStep, appointment_id}) => {
    return <div
        onClick={()=>changeStep(appointment_id)}
        className={`scroll-menu-item ${selected ? 'active' : ''}`}
      >{text}</div>;
};

export default class Exam extends Component {
    Menu = (list, selected) =>
        list.map(el => {
            const {name, step} = el;

            const {
                getRecordReport,
                getLensometryReport,
                getVisualAcuityReport,
                getAutorefractorReport,
                getKeratometryReport,
                getRefractorReport,
                getTonometryReport,
                getMuscleReport,
                getLampReport,
                getIndirectEyeReport,
                getDiagnosticPlanReport,
                getEducationalPlanReport,
                getOpticsOrderReport,
                match
            } = this.props;

            let changeStep;

            if(step === RECORD){
                changeStep = getRecordReport;
            }else if(step === LENSOMETRY){
                changeStep = getLensometryReport;
            }else if(step === VISUAL_ACUITY){
                changeStep = getVisualAcuityReport;
            }else if(step === AUTOREFRACTOR){
                changeStep = getAutorefractorReport;
            }else if(step === KERATOMERY){
                changeStep = getKeratometryReport;
            }else if(step === REFRACTOR){
                changeStep = getRefractorReport;
            }else if(step === TONOMETRY){
                changeStep = getTonometryReport;
            }else if(step === MUSCLES){
                changeStep = getMuscleReport;
            }else if(step === LAMP){
                changeStep = getLampReport;
            }else if(step === INDIRECT_EYE){
                changeStep = getIndirectEyeReport;
            }else if(step === DIAGNOSTIC_PLAN){
                changeStep = getDiagnosticPlanReport;
            }else if(step === EDUCATIONAL_PLAN){
                changeStep = getEducationalPlanReport;
            }else if(step === OPTICS_ORDER){
                changeStep = getOpticsOrderReport;
            }

            return <MenuItem text={name} key={step} selected={selected} changeStep={changeStep} appointment_id={match.params.id}/>;
        });

    constructor(props){
        super(props);
        this.menuItems = this.Menu(list, selected);
    }

    state = {
        open: false,
        selected,
    };

    onSelect = key => {
        this.setState({selected: key});
    }

    componentWillMount = () => {
        const { getRecordReport, match } = this.props;
        getRecordReport(match.params.id);
    }

    cambiarPaso = (paso) => {
        const {
            getRecordReport,
            getLensometryReport,
            getVisualAcuityReport,
            getAutorefractorReport,
            getKeratometryReport,
            getRefractorReport,
            getTonometryReport,
            getMuscleReport,
            getLampReport,
            getIndirectEyeReport,
            getDiagnosticPlanReport,
            getEducationalPlanReport,
            getOpticsOrderReport,
            match
        } = this.props;

        if(paso === RECORD){
            getRecordReport(match.params.id);
        }else if(paso === LENSOMETRY){
            getLensometryReport(match.params.id)
        }else if(paso === VISUAL_ACUITY){
            getVisualAcuityReport(match.params.id)
        }else if(paso === AUTOREFRACTOR){
            getAutorefractorReport(match.params.id)
        }else if(paso === KERATOMERY){
            getKeratometryReport(match.params.id)
        }else if(paso === REFRACTOR){
            getRefractorReport(match.params.id)
        }else if(paso === TONOMETRY){
            getTonometryReport(match.params.id)
        }else if(paso === MUSCLES){
            getMuscleReport(match.params.id)
        }else if(paso === LAMP){
            getLampReport(match.params.id)
        }else if(paso === INDIRECT_EYE){
            getIndirectEyeReport(match.params.id)
        }else if(paso === DIAGNOSTIC_PLAN){
            getDiagnosticPlanReport(match.params.id)
        }else if(paso === EDUCATIONAL_PLAN){
            getEducationalPlanReport(match.params.id);
            getRefractorReport(match.params.id);
        }else if(paso === OPTICS_ORDER){
            getOpticsOrderReport(match.params.id);
            getRefractorReport(match.params.id);
            getAutorefractorReport(match.params.id)
        }


        this.setState({step: paso});
    }

    render() {
        const {step} = this.props;
        const {patient} = this.props;
        //const {step} = this.state;

        const menu = this.menuItems;

        return (
            <div id="" className="content p-2 p-md-4 d-flex flex-column">

                <div className='d-flex flex-row w-100 justify-content-between flex-wrap'>
                    <div className='d-flex flex-row w-15 justify-content-center align-items-center'>
                        <Link to='/reporte-consultas'>
                            <img
                                style={{width:25}}
                                src={require('../../../../../assets/img/icons/return-icon-blue.png')}
                            />
                            &nbsp;&nbsp;
                            <span className='consult-report-return'>Regresar</span>
                        </Link>
                    </div>
                    <div className='w-85'>
                        <ScrollMenu
                            data={menu}
                            arrowLeft={ArrowLeft}
                            arrowRight={ArrowRight}
                            selected={selected}
                            onSelect={this.onSelect}
                        />
                    </div>
                </div>

                <div className="cuadro">
                    <div className="d-flex flex-row">
                        <div style={{flex:"1"}}>
                            <div className="table-decoration py-2 px-3">
                            {!!patient &&
                                <Patient
                                    patient={patient}
                                    //patient={item.patient}
                                    //last_consult={this.props.last_consult}
                                    //last_record={this.props.last_record}
                                />
                            }
                            </div>
                        </div>
                        <div style={{flex:"3"}}>
                            <div className="table-decoration py-2 ml-3 px-3">
                                <div className='d-flex flex-row'>
                                    <div className='w-50'>
                                        {(this.props.last_consult && item !== null && item.reconsult == true) &&
                                            <React.Fragment>
                                                <span>Última consulta: </span>
                                                <label>{this.props.last_consult.date}</label>
                                            </React.Fragment>
                                        }

                                        {step === RECORD && <h4 className='cl-titulo'><strong>Antecedentes</strong></h4>}
                                        {step === LENSOMETRY && <h4 className='cl-titulo'><strong>Lensometría</strong></h4>}
                                        {step === VISUAL_ACUITY && <h4 className='cl-titulo'><strong>Agudeza Visual</strong></h4>}
                                        {step === AUTOREFRACTOR && <h4 className='cl-titulo'><strong>Atorefractómetro</strong></h4>}
                                        {step === KERATOMERY && <h4 className='cl-titulo'><strong>Queratometría</strong></h4>}
                                        {step === REFRACTOR && <h4 className='cl-titulo'><strong>Refracción</strong></h4>}
                                        {step === TONOMETRY && <h4 className='cl-titulo'><strong>Tonometría</strong></h4>}
                                        {step === MUSCLES && <h4 className='cl-titulo'><strong>Músculos Extraoculares</strong></h4>}
                                        {step === LAMP && <h4 className='cl-titulo'><strong>Lámpara de Hendidura</strong></h4>}
                                        {step === INDIRECT_EYE && <h4 className='cl-titulo'><strong>Fondo De Ojo Indirecto</strong></h4>}
                                        {step === DIAGNOSTIC_PLAN && <h4 className='cl-titulo'><strong>Plan Diagnóstico</strong></h4>}
                                        {step === EDUCATIONAL_PLAN && <h4 className='cl-titulo'><strong>Plan Educacional</strong></h4>}
                                        {step === OPTICS_ORDER && <h4 className='cl-titulo'><strong>Orden de óptica</strong></h4>}
                                    </div>
                                {patient &&
                                    <div className='d-flex flex-row justify-content-end w-50'>
                                        <label className='exam-patient-name'>
                                            {`${patient.first_name} ${patient.last_name} ${patient.nickname && `(${patient.nickname})`} `}
                                        </label>
                                    </div>
                                }
                                </div>

                                {step === RECORD && (<RecordForm />)}
                                {step === LENSOMETRY && (<LensometryForm />)}
                                {step === VISUAL_ACUITY && (<VisualAcuityForm/>)}
                                {step === REFRACTOR && (<RefractionForm />)}
                                {step === TONOMETRY && (<TonometryForm />)}
                                {step === MUSCLES && (<MuscleForm />)}
                                {step === KERATOMERY && (<KeratometryForm />)}
                                {step === INDIRECT_EYE && (<IndirectEyeForm />)}
                                {step === AUTOREFRACTOR && (<AutorefractorForm />)}
                                {step === LAMP && (<LampForm />)}
                                {step === EDUCATIONAL_PLAN && (<EducationalPlanForm/>)}
                                {step === DIAGNOSTIC_PLAN && (<DiagnosticPlanForm/>)}
                                {step === OPTICS_ORDER && (<OpticsOrderForm/>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
