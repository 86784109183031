import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {
            renderField,
            renderNumber,
            renderPercentage,
            renderCurrency,
            renderSearchSelect,
            renderSelectCustom,
            renderDatePicker
        }  from 'Utils/renderField/renderField';

import { connect } from 'react-redux'

let services = [];

const getServices = (search)  =>{
    return api.get("services",{search}).catch((error) => {})
        .then((data) => {

            if (data){
                services = [];
                data.results.forEach(x=>{
                    services.push({value: x.id, label: x.name})
                })

                return services;
            }
            else{
                return [];
            }
        })
        .catch(e => { return []; })
}


let CouponForm = props => {
    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="type" className="">Tipo de cupón</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="type_coupon" autofocus disabled={view} component={renderSelectCustom} options={[
                            {value:1, label:'NORMAL'},
                            {value:2, label:'ESPECIAL'},                            
                        ]}/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="amount" className="m-0">Monto</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="amount" disabled={view} component={renderCurrency} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="service" className="m-0">Producto / Servicio</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="service"
                            component={renderSearchSelect}
                            valueKey="value"
                            labelKey="label"
                            label="service"
                            loadOptions={getServices}
                            className="no-border"
                            disabled={view}
                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="percentage" className="m-0">Porcentaje (%)</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="percentage" disabled={view} component={renderPercentage} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="expiration" className="m-0">Fecha de Vencimiento</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="expiration" isDisabled={view} component={renderDatePicker} type="text"/>

                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="quantity" className="m-0">Cantidad de cupones</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="quantity" disabled={view} component={renderNumber} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre:</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="comodin" className="m-0">Comodín</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="comodin" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/coupons">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

CouponForm = reduxForm({
   form: 'frmCoupon',
   validate: data => {
       const errors={};
        if(!data.type_coupon){
            errors.type_coupon = 'Campo requerido';
        }

        if(!data.expiration){
            errors.expiration = 'Campo requerido';
        }

        /*
        if(!data.service){
            errors.service = 'Campo requerido';
        }
        */

        if(!data.quantity){
            errors.quantity = 'Campo requerido';
        }

        if(!data.name){
            errors.name = 'Campo requerido';
        }

        if(!data.amount && !data.percentage){
            errors.amount = 'Debe ingrear un monto o porcentaje';
            errors.percentage = 'Debe ingrear un monto o porcentaje';
        }else if(data.amount && data.percentage){
            errors.amount = 'Debe ingrear sólo un monto o porcentaje';
            errors.percentage = 'Debe ingrear sólo un monto o porcentaje';
        }else{
            return errors;
        }

        return errors;
   }
})(CouponForm);

export default CouponForm;
