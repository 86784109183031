import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../../Utils/Acciones/Acciones'
import { showNestedFields } from '../../Utils/MyOptions';
import './whearhouse.css';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import ToolbarTab from '../../Utils/Toolbar/ToolbarTab';
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import moment from "moment";

class WhearhouseAdmin extends Component {
    componentWillMount() {
        if(this.props.match.params.id){
            this.props.setIdBodega(this.props.match.params.id);
        }
    }

    isExpandableRow = (cell, row) => {
        if(cell.inventario.length){
            return true;
        } else {
            return false;
        }
    }

    expandComponent = (row) => {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows expanded-rows-title">
                    <div className="w-25 d-flex justify-content-center">
                        Cantidad
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Lote
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Fecha Vencimiento
                    </div>
                </div>
                {
                    row.inventario.map((dato, index) => (
                        <div key={index} className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows">
                            <div className="w-25 d-flex justify-content-center">
                                {dato.existencias}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {dato.lote.lote.startsWith('PRO') ?
                                    ' '
                                :
                                    <React.Fragment>
                                        {dato.lote.lote}
                                    </React.Fragment>
                                }
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {moment(dato.lote.fecha_vencimiento).format("DD-MM-YYYY")}
                            </div>
                        </div>
                        )
                    )
                }
            </React.Fragment>
        );
    }

    indexNumber = (cell, row, enumObject, index) => {
        return index + 1;
    }

    alertaMinima = (cell, row) => {
        if(cell){
            return (
                <div className="d-inline-flex justify-content-center">
                    <img className="notification-whearhouse-icon" src={require("../../../../../assets/img/icons/notification-icon.png")} alt="Notification" />
                </div>
            );
        }
        else{
            return "";
        }
    }

    render() {
        const {
            data_inventario,
            buscador_inventario,
            listarInventario,
        } = this.props;


        const {
            bodega,
            chargeInventory,
            searchInventario,
            cargando_inventario,
            page_inventario,
            isNavBarOpen,
        } = this.props;

        const id_bodega = !!bodega ? bodega.id : 0 ;
        const nombre_bodega = !!bodega ? bodega.name.toUpperCase() : '';
        console.log("props en amdmin bodega x: ", this.props);

        return (

            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-100 tab-background">
                        <div className="d-flex pl-5 justify-content-start align-items-center tab">
                            <a href="/#/administracion-bodega" className="bodega-flechita">
                                <img className="bodega-tab-icon" src={require("../../../../../assets/img/icons/return-icon-white.png")} alt="icono" />
                            </a>&nbsp;
                            <span className="text-white bodega-subtitulo"> SUCURSAL / {!!bodega ? bodega.name : ""}</span>
                        </div>
                    </div>
                </div>

                    <div className="d-flex flex-wrap h-15 mt-2 mb-3 justify-content-around pl-5 pr-5">
                        <a href={`/#/administrar-bodega/${id_bodega}/ingreso`} className="w-25">
                            <div className="d-flex f-row w-100 justify-content-center align-items-center">
                                <div className="d-flex rounded-circle p-2 bodega-tab-circle-content border-blue">
                                    <div className="d-flex bodega-tab-circle rounded-circle justify-content-center align-items-center">
                                        <img className="bodega-tab-icon" src={require("../../../../../assets/img/icons/bodega-ingreso.png")} alt="icono" />
                                    </div>
                                </div>
                                <div className="d-flex w-75 tab-bodega tab-bodega-blue justify-content-center align-items-center">
                                    {/*<a href={`/#/administrar-bodega/${id_bodega}/ingreso`} className="link-tab-bodega-admin">Ingreso de bodega</a>*/}
                                    <span className="link-tab-bodega-admin">Ingreso de bodega</span>
                                </div>
                            </div>
                        </a>

                        <a href={`/#/administrar-bodega/${id_bodega}/baja`} className="w-25 link-sin-formato">
                            <div className="d-flex f-row w-100 justify-content-center align-items-center">
                                <div className="d-flex rounded-circle p-2 bodega-tab-circle-content border-green">
                                    <div className="d-flex bodega-tab-circle rounded-circle justify-content-center align-items-center">
                                        <img className="bodega-tab-icon" src={require("../../../../../assets/img/icons/bodega-baja.png")} alt="icono" />
                                    </div>
                                </div>
                                <div className="d-flex w-75 tab-bodega tab-bodega-green justify-content-center align-items-center">
                                    <span className="link-tab-bodega-admin">Baja de producto</span>
                                </div>
                            </div>
                        </a>

                        <a href={`/#/administrar-bodega/${id_bodega}/reajuste`} className="w-25 link-sin-formato">
                            <div className="d-flex f-row w-100 justify-content-center align-items-center">
                                <div className="d-flex rounded-circle p-2 bodega-tab-circle-content border-yellow">
                                    <div className="d-flex bodega-tab-circle rounded-circle justify-content-center align-items-center">
                                        <img className="bodega-tab-icon" src={require("../../../../../assets/img/icons/bodega-ajuste.png")} alt="icono" />
                                    </div>
                                </div>
                                <div className="d-flex w-75 tab-bodega tab-bodega-yellow justify-content-center align-items-center">
                                    <span className="link-tab-bodega-admin">Reajuste de existencia</span>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="table-decoration row mx-5 mt-1 mb-3 d-flex">
                        <div className="row-fluid w-100 mt-2 mb-1">


                    <div className="d-flex flex-row justify-content-between pl-3 pr-3 pt-2">
                            <div className="d-flex">
                                <span className="title-list-iventory">{"INVENTARIO DE " + nombre_bodega}</span>
                            </div>
                            <div className="flex">
                            <ToolbarTab
                                id="toolbar-tab"
                                buscar={searchInventario}
                                buscador={buscador_inventario}
                            />
                            </div>
                    </div>

                        <Table
                            onPageChange={chargeInventory}
                            data={data_inventario}
                            loading={cargando_inventario}
                            page={page_inventario}
                            expandableRow={ this.isExpandableRow }
                            expandComponent={ this.expandComponent }
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="id"
                                dataSort={true}
                                dataFormat={this.indexNumber}
                                >No.</TableHeaderColumn>

                            <TableHeaderColumn
                                isKey={true}
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name"
                                dataSort={true}>Producto / Servicio</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="cantidad"
                                dataSort={true}>Existencia</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="cost"
                                dataFormat={(cell) => {
                                    return <RenderCurrency value={cell} />;
                                }}
                                dataSort={true}>Precio Costo</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="code"
                                dataSort={true}>Código</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="barcode"
                                dataSort={true}>Código de Barras</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="alerta_minima"
                                dataSort={true}
                                dataFormat={this.alertaMinima}
                                >
                                </TableHeaderColumn>

                            {/*
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/bodega', editar: '/bodega', eliminar: destroy })}>Acciones</TableHeaderColumn>
                            */}
                        </Table>
                    </div>
                    </div>
            </div>
        )
    }
}

export default WhearhouseAdmin;
