import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';

import { renderField, renderCurrency } from '../Utils/renderField/renderField';

const RetentionPaymentForm = (props) => {
    const {
        handleSubmit,
    } = props;

    return (
        <form onSubmit={handleSubmit} id="form_retention_payment" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="id_retention_payment" component="input" type="hidden" />

            <table className="table" id="table-frm-pay-oc">
                <thead />
                <tbody className="oc-body-form">

                    <tr className="oc-white-border">
                        <th>NO. de retención</th>
                        <td>
                            <Field
                                name="document_number"
                                component={renderField}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>

                    <tr className="oc-white-border">
                        <th>Monto</th>
                        <td>
                            <Field
                                name="amount"
                                component={renderCurrency}
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>

                </tbody>
            </table>
        </form>
    );
};

export default reduxForm({
    form: 'form_retention_payment',
    validate: data => validate(data, {
        document_number: validators.exists()('Campo requerido'),
        amount: validators.exists()('Campo requerido'),
    }),
})(RetentionPaymentForm);
