import classNames from 'classnames';
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';

class Provider extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
            isNavBarOpen,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;
 
        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar Proveedor"} ruta={"/providers/create"}/>
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-4 text-uppercase text-titulo">
                                    <strong>LISTADO DE PROVEEDORES</strong>
                                </span>
                            </div>                        

                            <div className="top-title-toolbar mt-3">
                                <CustomToolbar
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                    autofocus
                                />
                            </div>
                        </div>
                        
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name"
                                dataSort={true}>Nombre</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nit" dataSort>Nit</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="telephone" dataSort>Telefono</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="direction" dataSort>Dirección</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/providers', editar: '/providers', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Provider;
