import { connect } from 'react-redux';
import PerformSurgery from './performSurgery';
import { actions } from '../../../redux/modules/surgery';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.surgery,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(PerformSurgery);
