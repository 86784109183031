import React, {Component} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import classNames from 'classnames';
import { api } from "api";

let items = [];
const getItems = (search)  =>{
    return api.get("services",{search}).catch((error) => {})
        .then((data) => {
            if (data){

                items = [];
                data.results.forEach(x=>{
                    items.push({value: x.id, label: x.name, code: x.code})
                })

                console.log("Items: ", items);

                return items;
            }
            else{
                return [];
            }
        })
        .catch(e => {
            return [];
        })
}

class FiltroProductos extends Component{
    render(){
        const valueKey = 'value';
        const labelKey = 'label';
        const invalid = false;
        const addClass = '';

        const {setFiltroServicio, filtro_servicios, quitarFiltro} = this.props;

        return(
            <div className='d-flex flex-row flex-around w-100 pl-5 mb-2 align-items-center'>

                <div className='d-flex flex-column'>
                    <label className='mr-2'>Filtrar por producto</label>
                    <AsyncSelect isClearable={true} isDisabled={false}
                            className={classNames('form-control p-0 border-trasparent', { 'is-invalid': invalid }, addClass)}
                            onChange={(e) => {
                                if(e !== null){
                                    setFiltroServicio(e);
                                }
                            }}
                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: '1em',
                                    background: 'white',
                                    colors: {
                                        ...theme.colors,
                                        text: '#415362',
                                        primary25: '#ebebeb',
                                        primary: '#0a5995',
                                    },
                                }
                            }}
                            classNamePrefix="react-select"
                            cache={false} placeholder="Escriba para buscar"
                            defaultOptions={ true }
                            searchPromptText="Escriba para buscar"
                            getOptionValue={(option) => (option[valueKey])}
                            getOptionLabel={(option) => (option[labelKey])}
                            loadOptions={getItems}
                            options={items}
                            autoFocus={this.props.autofocus ? true: false}
                    />
                </div>
                <div className='d-flex flex-row flex-wrap w-50 align-items-center ml-3'>
                    {filtro_servicios.map((servicio, i) => (
                        <span
                            className='badge badge-primary ml-2 mb-1'
                            style={{fontSize: 12}}
                        >
                            {servicio.label}
                            <span
                                onClick={()=>quitarFiltro(servicio.value)}
                                className='badge badge-light ml-2 cursor-pointer'
                            >x</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}

export default FiltroProductos;
