import React, { Component } from 'react';
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import './payment_history.css';

export const TablePayments = (props) => {
    console.log("referencia retorno en el tablaPayments: ", props.referencia_retorno);
    return(
        <div className="d-flex justify-content-center align-items-center">
            <div className="table-responsive justify-content-center d-flex" style={{overflow:"none !important"}}>
                <table className="table table-sm table-hover mb-3 w-75 table-payments">
                    <thead>
                        <tr>
                            <th>Cantidad pagada</th>
                            <th>Fecha</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.data.map((dato, i) =>
                        <tr key={i}>
                            {console.log("extra amount de dato", dato)}
                            <td className="text-center"><RenderCurrency value={parseFloat(dato.payment) + parseFloat(dato.extra_amount)} /></td>
                            <td className="text-center">{dato.made}</td>
                            <td className="text-center">
                                {dato.anticipo === true ?
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={()=>
                                                                                                                         //movement
                                        props.cancelPaymentFix(dato.id, dato.movement_ref.codigo, props.referencia_retorno, dato.movement_ref.id, dato.tipo_pago)
                                    }lm
                                >Anular</button>
                                :
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={()=>props.cancelPayment(dato.id,dato.movement_ref.codigo,props.referencia_retorno)}
                                >Anular</button>
                                }
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
