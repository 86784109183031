import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/tasks';

import Tasks from './Tasks';
import { resetStore } from '../../../redux/modules/login';

const ms2p = (state) => {
    return {
        ...state.tasks,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Tasks);
