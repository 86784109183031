import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/consult';
import Exam from '../../Exam/Exam';
import {KERATOMERY} from "../../../../../utility/variables";
import {resetStore} from "../../../../../redux/modules/login";


const ms2p = (state) => {
    let values = {};
    if(state.form && state.form.Keratometry && state.form.Keratometry.values){
        values = state.form.Keratometry.values
    }
    return {
        ...state.consult,
        ojo_derecho_k1: values.od_k1,
        ojo_derecho_k2: values.od_k2,
        ojo_izquierdo_k1: values.os_k1,
        ojo_izquierdo_k2: values.os_k2,
        titulo: 'QUERATÓMETRO',
        show_list: KERATOMERY,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Exam);
