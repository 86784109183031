import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import NumberFormat from 'react-number-format';

class ProductInList extends Component {

    handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({ [name]: value });
    }

    render(){
        return(
            <tr className="">
                <td className="text-left">{this.props.product.name}</td>
                <td className="text-center">{this.props.product.cantidad}</td>
                <td className="text-center">{this.props.product.lote}</td>
                <td className="text-center">{this.props.product.vencimiento}</td>
                <td className="text-center"><RenderCurrency value={this.props.product.precio}/></td>
                <td>
                    <button onClick={()=>this.props.removeProductToCart(this.props.i)} className="circle-button-delete">
                        <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/delete-patient-icon.png")} alt="Delete icon" />
                    </button>
                </td>
            </tr>
        );
    }
}

export default ProductInList;
