import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Coupon from './CouponView';
import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/coupon';
import { Link } from 'react-router-dom';
import { PrintContainer, actions as printActions } from "Utils/Print";
import {resetStore} from "../../../redux/modules/login";
class CouponViewContainer extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount(){
        if(!Array.isArray(this.props.updateData) && this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
        }
    }

    render() {
        const {
            updateData,
            cargando,
        } = this.props;

        let contentJustify = Array.isArray(updateData) ? " justify-content-start" : " justify-content-center"
        return (
            <div className="content">
                <div className="mx-5 my-4">
                    <div className="table-decoration">
                            <div className="top-title">
                                CUPÓN(ES)
                            </div>
                            <PrintContainer name='cupones'>
                                <div className={"d-flex flex-row flex-wrap align-items-center p-5" + contentJustify}>
                                        {Array.isArray(updateData) ?
                                            <React.Fragment>
                                                {updateData.map(coupon => (
                                                    <Coupon updateData={coupon}/>
                                                ))}
                                            </React.Fragment>
                                        :
                                            <Coupon updateData={updateData}/>
                                        }
                                </div>
                            </PrintContainer>
                            <div className="d-flex flex-row justify-content-center align-items-center p-3">
                                <Link to="/coupons" className="btn btn-secondary">Cancelar</Link>
                                <button className="btn btn-primary m-2"  onClick={(e) => {
                                    e.preventDefault();
                                    printActions.print('cupones');
                                    this.props.history.push("/coupons")
                                }}>Imprimir</button>
                            </div>
                    </div>
                </div>
            </div>

        )
    }
}

const ms2p = (state) => {
    return {
        ...state.coupon,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(CouponViewContainer);
