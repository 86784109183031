import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/EntryOrder';

import ViewEntry from './ViewEntry';
import {resetStore} from "../../../../redux/modules/login";

const ms2p = state => ({
    ...state.entry_order,
});

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(ViewEntry);
