import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";


export class Exis extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false};
    }

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    selectOption = (input, value) => {
        input.onChange(value);
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title, jaeger } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        let fi = 0;
        const ff = 4;

        let ci = 0;
        const cf = 0;

        let columnas = [];

        for(fi;fi<=ff;fi=fi+1){
            ci = 0;
            let fila = [];
            for(ci;ci<=cf;ci++){
                const cantidad = fi + (ci);
                fila.push(cantidad.toFixed(2))
            }
            columnas.push(fila);
        }

        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h3 className="border-bottom mt-4">{title}</h3>
                    </div>
                    <table className='table table-sm table-bordered' id="notations-table-modal">
                        <tr>
                            {/*
                            <th>Decimal</th>
                            <th>US</th>
                            <th>CD</th>
                            <th>X/200</th>
                            {jaeger && <th>JAEGER</th>}
                            */}
                        </tr>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 0)}>
                                            <input type={"radio"} value={0} {...input} onChange={() => this.selectOption(input, 0)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{0}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 1)}>
                                            <input type={"radio"} value={1} {...input} onChange={() => this.selectOption(input, 1)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{1}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 2)}>
                                            <input type={"radio"} value={2} {...input} onChange={() => this.selectOption(input, 2)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{2}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 3)}>
                                            <input type={"radio"} value={3} {...input} onChange={() => this.selectOption(input, 3)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{3}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                            onClick={() => this.selectOption(input, 4)}>
                                            <input type={"radio"} value={4} {...input} onChange={() => this.selectOption(input, 4)}
                                                className={classNames('form-control', { 'is-invalid': invalid })} />
                                            &nbsp;{4}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
