import React, { Component } from 'react';
import moment from 'moment';
import '../sales/payment.css';
import Factura from '../sales/Factura';
import Note from './Note/Note';

import { PrintContainer, actions as printActions } from "Utils/Print";
import LoadMask from "../../components/Utils/LoadMask/LoadMaskBill";

import TabComponent from "./deposit/TabComponent";

import MyModal from '../Utils/myModal';
import Form from './ModalConfirm';
import FormCreditNote from "./ModalConfirmCreditNote";

import { RenderCurrency } from '../../components/Utils/renderField/renderReadField';
class ViewBill extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount = () => {
        if(this.props.match.params.id){
            this.props.viewOneSale(this.props.match.params.id);
        }

        this.props.checkCashBoxMovement();
    }

    componentDidMount = () => {
        if(window.location.href.includes("print")){
            setTimeout(() => {
                printActions.print('facturas');
            }, 500);
        }else if(window.location.href.includes("cancel")){
            /*
            console.log("Es una cancelacioń");
            setTimeout(() => {
                this.props.cancelBill(this.props.match.params.id);
            }, 500);
            */
        }
    }

    render(){
        const { productsInCarOrdered, cancel_mask, openBox, history_payments, all_payments } = this.props;

        console.log("PROPS EN VIEW BILL: ", all_payments);
        console.log("HISTORIAL DE PAGOS: ", history_payments);

        let ruta_retorno = "";

        if(this.props.match.params.referency === 'movimiento'){
            ruta_retorno = 'report-movements';
        }

        if(this.props.match.params.referency === 'caja'){
            ruta_retorno = 'oppening-closing/box';
        }

        if(this.props.match.params.referency === 'cobrar'){
            ruta_retorno = 'receivable';
        }

        const FacNote = this.props.match.params.referency === 'cobrar' ? Note : Factura;

        let now = moment()
        let made_fel = moment()
        let credit_note_disabled = false
        if(productsInCarOrdered.length){
            made_fel = moment(productsInCarOrdered[0].dataBilling.fecha, "DD-MM-YYYY hh:mm:ss")
            credit_note_disabled = !(now.diff(made_fel, "days") > 5 || now.month() != made_fel.month()) || 
                                    productsInCarOrdered[0].financial_entity.factura.toUpperCase() == 'RECIBO'
        }

        return(
            <div className="content">
                <LoadMask loading={cancel_mask} dark blur>
                <div className="d-flex">
                    {productsInCarOrdered.length && productsInCarOrdered[0].dataBilling.state !== 3 ?
                    <div className="table-decoration w-50 h-100 justify-content-center mr-3 mt-4 mb-3 d-flex flex-column">
                        {productsInCarOrdered.map((factura, i) =>
                            <TabComponent
                                {...this.props}
                                factura={factura}
                                index={i}
                                chargePayments={this.props.chargePayments}
                                forceRendering={this.forceRendering}
                                ruta_retorno = {ruta_retorno}
                            />
                        )}
                    </div>
                    : null
                    }
                    <div className="table-decoration w-30 h-100 justify-content-center mx-5 mt-4 mb-3 d-flex flex-column no-print" id="">
                        <div className="d-flex justify-content-around align-items-center title-print-view p-4">
                            <b>PREVISUALIZACIÓN</b>
                        </div>
                        <div className="d-flex flex-column pt-2 pl-5 pr-5 fac-content">
                            <div className="d-flex p-2 justify-content-end">
                                <a href={"/#/" + ruta_retorno}><img className="action-img print" title="Regresar" src={require("../../../../assets/img/icons/return-icon-white.png")} alt="Regresar" /></a>
                                <img className="action-img  print" title="Imprimir" onClick={(e)=>{
                                    e.preventDefault();
                                    printActions.print('facturas');
                                }} src={require("../../../../assets/img/icons/print-icon-white.png")} alt="Imprimir" />


                                {productsInCarOrdered.length && productsInCarOrdered[0].dataBilling.state === 4 ?
                                    <a href={"/#/payments-history/" + this.props.match.params.id + "/" + this.props.match.params.referency}><img className="action-img history-icon" title="Abonos" src={require("../../../../assets/img/icons/history-icon-white.png")} alt="Abonos" /></a>
                                    : null
                                }

                                {(productsInCarOrdered.length && productsInCarOrdered[0].dataBilling.active !== false) && (productsInCarOrdered[0].dataBilling.fel_error !== "ÉXITO EN LA CREACIÓN DE LA NOTA DE CRÉDITO") ?
                                <MyModal isOpen={window.location.href.includes("cancel") ? true : false}
                                    title="Anulación de factura"
                                    formId="formulario_anulacion"
                                    image={<img className="action-img delete" title="Anular" src={require("../../../../assets/img/icons/cancel-icon-white.png")} alt="Anular" />}
                                    size="lg"
                                    body={
                                        <Form onSubmit={this.props.cancelBill} movement_id={this.props.match.params.id} openBox={openBox} />
                                    }
                                />
                                : null
                                }

                                {productsInCarOrdered.length && productsInCarOrdered[0].dataBilling.active !== false && productsInCarOrdered[0].dataBilling.numeroAutorizacion !== null ?
                                <MyModal
                                    title="Nota de crédito"
                                    formId="formulario_nota_credito"
                                    image={<img className="action-img edit" title="Nota de crédito" src={require("../../../../assets/img/icons/order-icon-white.png")} alt="Nota de Crédito" />}
                                    disabled={credit_note_disabled}
                                    size="lg"
                                    body={
                                        <FormCreditNote onSubmit={this.props.creditNote} movement_id={this.props.match.params.id} />
                                    }
                                />
                                : null
                                }

                                {(productsInCarOrdered.length && productsInCarOrdered[0].dataBilling.numeroAutorizacion === null) && (productsInCarOrdered[0].dataBilling.fel_error !== "ÉXITO EN LA CREACIÓN DE LA NOTA DE CRÉDITO") ?
                                    <a onClick={()=>this.props.createFel(this.props.match.params.id)}>
                                        <img className="action-img history-icon" title="Crear FEL" src={require("../../../../assets/img/icons/order-icon-white.png")} alt="Abonos" />
                                    </a>
                                :
                                    null
                                }

                            </div>

                            <PrintContainer name='facturas' className="impresion-venta">
                                {productsInCarOrdered.map((factura, i) =>
                                    <FacNote
                                        key={i}
                                        factura={factura}
                                        history_payments={history_payments}
                                        extra_amount_initial={this.props.extra_amount_initial}
                                    />
                                )}
                            </PrintContainer>
                        </div>
                    </div>

                    {((history_payments.length > 0 || all_payments.length > 0) && productsInCarOrdered.length && productsInCarOrdered[0].dataBilling.state === 3) &&
                        <div className="table-decoration w-30 h-100 justify-content-center mx-5 mt-4 mb-3 d-flex flex-column no-print">
                            <div className="d-flex justify-content-around align-items-center title-print-view p-4">
                                <b>HISTORIAL DE PAGOS</b>
                            </div>
                            <div className="d-flex flex-column pt-2 pl-5 pr-5 fac-content">
                                <div className="d-flex p-2 justify-content-end">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td className='font-weight-bold'>Fecha</td>
                                                <td className='font-weight-bold'>Tipo de pago</td>
                                                <td className='font-weight-bold text-right'>Monto</td>
                                            </tr>
                                        {history_payments.map((payment)=>
                                            //Acá sólo hay que sentenciar el efectivo
                                            <React.Fragment>
                                            {payment.payment_type === 1 &&
                                            <tr key={payment.id}>
                                                <td>{payment.made}</td>
                                                <td>
                                                    {payment.payment_type === 1 && 'efectivo'}
                                                    {payment.payment_type === 2 && 'tarjeta'}
                                                    {payment.payment_type === 3 && 'tarjeta en cuotas'}
                                                    {payment.payment_type === 4 && 'depósito'}

                                                </td>
                                                <td className='text-right'><RenderCurrency value={payment.payment} /></td>
                                            </tr>
                                            }
                                            </React.Fragment>
                                        )}

                                        {all_payments.length > 0 &&
                                            <React.Fragment>
                                                {all_payments.map((payment) =>
                                                <tr key={payment.id}>
                                                    <td>{payment.created}</td>
                                                    <td>
                                                        {payment.payment_type === 2 && 'tarjeta'}
                                                        {payment.payment_type === 3 && 'tarjeta en cuotas'}
                                                        {payment.payment_type === 4 && 'depósito'}

                                                    </td>
                                                    <td className='text-right'>
                                                        {payment.payment_type === 3 ?
                                                            <RenderCurrency value={parseFloat(payment.quantity) + parseFloat(payment.extra_amount)} />:
                                                            <RenderCurrency value={parseFloat(payment.quantity)} />
                                                        }
                                                    </td>
                                                </tr>
                                                )}
                                            </React.Fragment>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                </LoadMask>
            </div>
        );
    }
}

export default ViewBill;
