import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/consult';
import List from '../../List/List';
import {AUTOREFRACTOR} from "../../../../../utility/variables";
import {resetStore} from "../../../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.consult,
        titulo: "AUTOREFRACTÓMETRO",
        show_list: AUTOREFRACTOR,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(List);
