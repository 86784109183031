import React, {Component} from 'react';
import Form from './DiagnosticOrderReadForm.js';
import Spoiler from '../../Utils/Spoiler';
import Audio from './Audio.js/audio';
import './diagnostic_resume_style';
import Modal from '../DiagnosticTranscription/modal';
import InputTag from '../DiagnosticTranscription/TagInput';

class ResumeDiagnostic extends Component {
    componentWillMount = () =>{
        if(this.props.match.params.id){
            this.props.getResumeDiagnostic(this.props.match.params.id)
        }
    }

    render() {
        const data = this.props.resume_diagnostic;
        const valid = data != null ? true: false;
        let hay_imagen = false;

        let todo_completado = false;
        let cantidad_completados = 0;
        if(valid){
            data.diagnostic_service_diagnostic.forEach(procedimiento => {
                if(procedimiento.state === 2 || procedimiento.state === 3){
                    cantidad_completados ++;
                }

                if(procedimiento.diagnostic_service_diagnostic_file.length > 0){
                    hay_imagen = true;
                }
            });

            if(cantidad_completados === data.diagnostic_service_diagnostic.length){
                todo_completado = true;
            }
        }

        return (
            <div className="content">
                <div className="m-auto row-fluid card-form w-100">
                    <div className="table-decoration">
                        <div className="top-title">
                            {valid && data.patient.first_name + ' ' + data.patient.last_name}
                        </div>

                        <Form
                            onSubmit={()=>{console.log("...")}}
                            inResume={true}
                        />

                        <div style={{marginLeft:'13%'}} className='mb-3'>
                            <table className='table table-sm table-bordered w-50 dr-table-procedures'>
                                <thead>
                                    <tr>
                                        <th>Procedimiento</th>
                                        <th>Intérprete</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {valid && data.diagnostic_service_diagnostic.map((procedimiento, i) =>
                                    <tr key={procedimiento.id}>
                                        <td>{procedimiento.service ? procedimiento.service.name : ''}</td>
                                        <td>{procedimiento.interpreter ? procedimiento.interpreter.name: ''}</td>
                                        <td>
                                            {(procedimiento.state === 2 && procedimiento.diagnostic_service_diagnostic_file.length > 0) &&
                                                <button
                                                    className='btn btn-sm btn-success dr-boton-enviar'
                                                    disabled={procedimiento.delivered}
                                                    onClick={()=>{
                                                        this.props.deliveredOneDiagnostic(procedimiento.id, this.props.match.params.id);
                                                    }}
                                                >
                                                    {procedimiento.delivered ? 'Entregado' : 'Entregar'}
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>

                        {hay_imagen &&
                        <div style={{marginLeft:'13%'}} className='mb-3'>
                            <Modal
                                isOpen={this.props.show_modal_correos_resume}
                                title="Enviar los archivos al intérprete"
                                textButton={valid && data.files_sended ? 'Reenviar archivos al intérprete' : 'Enviar archivos al intérprete'}
                                classToggleButton="btn btn-sm btn-primary"
                                abrirModal={this.props.abrirModalCorreoResume}
                                disabled={!todo_completado}
                                body={
                                    <InputTag
                                        placeholder='Presione enter para agregar'
                                        aceptar={(tags)=>{
                                            this.props.enviarCorreoResume(tags, this.props.match.params.id);
                                        }}

                                        cancelar={this.props.cerrarModalCorreoResume}
                                    />
                                }
                            />
                        </div>
                        }

                        <div style={{marginLeft:'13%'}} className='mb-4'>
                            {valid && data.audio !== null ?
                                <div className="row">
                                    <Audio
                                        path={data.audio}
                                        patientName={data.patient.first_name + ' ' + data.patient.last_name}
                                    />
                                    <a
                                        className='btn btn-primary audio-btn-transcribir ml-3 pt-2 pb-2 h-100'
                                        href={'/#/transcription/' + this.props.match.params.id}
                                    >Transcribir</a>
                                </div>
                            :
                                <span className='font-weight-bold'>Aún no se ha recibido ningún audio</span>
                            }
                        </div>

                        {/*
                        <div className="pl-5 pr-5 mb-5">
                            <div className="pl-5 pr-5">
                                <div className="pl-5 pr-5">
                                    {valid && data.diagnostic_service_diagnostic.map((procedimiento, i) =>
                                        <Spoiler key={i} show={true} label={procedimiento.service.name}>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                                {procedimiento.diagnostic_service_diagnostic_file.map((imagen) =>
                                                    <img
                                                        key={imagen.id}
                                                        title="Imágen diangnóstico"
                                                        src={imagen.imagen}
                                                        style={{width:100, height:100, marginRight:20}}
                                                    />
                                                )}
                                            </div>


                                            <React.Fragment key={i}>
                                                <div className='mt-3'>
                                                    <Audio {... procedimiento}/>
                                                </div>
                                                <div className='mt-3'>
                                                    <textarea className="diagnostic-resume-textarea w-100"/>
                                                </div>
                                            </React.Fragment>

                                        </Spoiler>
                                    )}
                                </div>
                            </div>
                        </div>
                        */}
                        <div className='d-flex flex-row justify-content-center'>
                            <a
                                className='btn btn-sm btn-secondary'
                                href='/#/examenes-completados'
                            >Regresar</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        );
    };
}

export default ResumeDiagnostic;
