import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { api } from "api";

import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../../Utils/Acciones/Acciones'
import { showNestedFields } from '../../Utils/MyOptions';
import '../../whearhouse/administration/whearhouse';

import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderField, renderSearchSelect, renderDatePicker, renderSelectCustom, renderTextArea, modalTextArea, dateInput }  from '../../Utils/renderField/renderField';
import { validate, validators } from 'validate-redux-form';
import ToolbarTab from '../../Utils/Toolbar/ToolbarTab';
import ProductInList from './ProductInList';
import { BootstrapTable } from 'react-bootstrap-table';

import { RenderCurrency } from  '../../Utils/renderField/renderReadField';
import DatePicker from '../../Utils/DatePicker/datePickerCustom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import { Checkbox } from 'antd';

let providers=[];
const getProviders = (search)  =>{
    return api.get("providers/", {search}).catch((error) => {})
        .then((data) => {
            if(data){
                providers = [];
                data.results.forEach(x=>{
                    providers.push({value: x.id, label: x.name})
                })
                return providers;
            }
            else{
                return [];
            }
        })
        .catch(e => {
            return [];
        })
}

let spendings = []
const getSpendingTypes = (search)  =>{
    return api.get("spending_type",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                spendings = [];
                data.results.forEach(x=>{
                    spendings.push({value: x.id, label: x.name})
                })
                return spendings;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

function priceFormatter(cell, row) {
    return <RenderCurrency value={cell}/>;
}

class NuevaOrden extends Component {
    componentWillMount() {
        if(this.props.match.params.id){
            this.props.getPurchaseOrder(this.props.match.params.id);
        }else{
            this.props.publicData();
        }

        this.props.listarServicios();

        const {
            initializeOrderForm
        } = this.props;

        initializeOrderForm();
    }

    componentDidMount = () => {
        const {initializeOrderForm} = this.props;
        initializeOrderForm();
    }

    addToCar = (data, row, cell) => {
        return(
            <button onClick={() => {this.props.addServiceToListOrder(row)}} className="circle-button-car">
                 <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon" />
            </button>
        );
    }

    deleteFormatter = (row, cell, enumObject, index) => {
       return (<button onClick={()=>this.props.removeServiceToList(index)} className="circle-button-delete">
                    <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/delete-patient-icon.png")} alt="Delete icon" />
                </button>);
    }

    cantidadFormatter = (cell, row, enumObject, index) => {
        const id = row.id;
        const cant = row.cantidad;

        return(
            <React.Fragment>
                <div className="group-button-up-down">
                    <button className="button-content left-tab" onClick={() => {this.props.upDownCantOrder(index, false)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                    <NumberFormat className="input-cant text-left" decimalScale={0} value={cant} onValueChange={({value}) => {
                        this.props.setCantOrder(index, value)
                    }} />
                    <button className="button-content right-tab" onClick={() => {this.props.upDownCantOrder(index, true)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                </div>
            </React.Fragment>
        );
    }

    loteFormatter = (cell, row, enumObject, index) => {
        return(
            <React.Fragment>
            <div className="d-flex flex-column justify-content-center align-items-center">
            <input type="text" className="input-ingreso-bodega-lote" value={cell}
                onChange={(e) =>{
                    this.props.setLote(index, e.target.value)
                    setTimeout(() => {
                        this.forceUpdate();
                    }, 25);
                }}
            />

            </div>
            </React.Fragment>
        );
    }

    vencimientoFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <DatePicker
                    isDisabled={false}
                    value={cell}
                    //maxDate={maxDate}
                    //minDate={minDate}
                    className={'date-pricker-ingreso'}
                    onChange={(date) => {
                        this.props.setVencimiento(row.id, date);
                        setTimeout(() => {
                            this.forceUpdate();
                        }, 25);
                    }}
                />
                {row.vencimiento_error &&
                    <span>dato inválido</span>
                }
                </React.Fragment>
            );
    }

    barcodeFormatter = (cell) => {
        return <span className="barcode-service-list">{cell}</span>
    }

    setConsignment = (cell, row, enumObject, index) => {
        return(
            <React.Fragment>
                <div className='text-center'>
                    <input
                        type="checkbox"
                        defaultChecked={cell}
                        style={{WebkitAppearance:"Checkbox"}}
                        onClick={()=>this.props.setConsignment(index)}
                    />
                </div>
            </React.Fragment>
        );
    }

    render() {
        const {
            listarServicios,
            sentOrder,
            updateOrder,
            isNavBarOpen,
        } = this.props;


        const {
            id_purchase_order,
            data_service,
            cargando,
            page_service,
            cargando_service,
            form_validate,
        } = this.props;

        console.log("servicios: ", this.props);

        return (
            <React.Fragment>
                <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                    <div className="table-decoration row mx-5 mt-3 mb-3 d-flex">
                        <div className="row-fluid w-100 mt-2 mb-1">

                        <form className="py-3 px-3" name="in-bodega" id="in-bodega">
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                <div className="m-2 w-25">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="provider" className="m-0">Proveedor</label>
                                    </div>
                                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field
                                            name="provider"
                                            valueKey="value"
                                            labelKey="label"
                                            label="provider"
                                            component={renderSearchSelect}
                                            loadOptions={getProviders}
                                            className="no-border"
                                            autofocus
                                        />
                                    </div>
                                </div>

                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="date" className="m-0">Fecha</label>
                                    </div>
                                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field name="date" component={dateInput} type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                <div className="m-2  w-70">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="spending_type" className="m-0">Tipo de gasto</label>
                                    </div>
                                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field
                                            name="spending_type"
                                            valueKey="value"
                                            labelKey="label"
                                            label="spending_type"
                                            component={renderSearchSelect}
                                            loadOptions={getSpendingTypes}
                                            className="no-border"
                                            autoFocus
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                <div className="m-2 w-70">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="note" className="m-0">Anotaciones u otros comentarios</label>
                                    </div>
                                    <Field
                                        name="note"
                                        valueKey="value"
                                        labelKey="label"
                                        component={modalTextArea}
                                        className="ingreso-textarea"
                                    />
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>


                <div className="table-decoration w-50 h-100 mx-5 ml-5 d-flex flex-column sales-services" id="left-tab-bodega">
                    <div className="fields">

                        <div className="m-auto row-fluid w-100 h-100">
                            <div className="table-decoration rcp-table-decoration" id="comodin-table">
                                <div className="pl-3 pr-3 d-flex flex-wrap h-25 justify-content-start align-items-center">
                                    <div className="d-flex pb-2 flex-column w-50 justify-content-center align-items-center">
                                        <div className="d-inline-flex w-100 pt-3 justify-content-start service-title">
                                            LISTADO DE SERVICIOS
                                        </div>
                                        <ToolbarTab
                                            id="toolbar-tab"
                                            buscar={this.props.searchService}
                                            buscador={this.props.buscador_service}
                                        />
                                    </div>

                                        <div className="table-responsive">
                                            <Table
                                                onPageChange={listarServicios}
                                                data={data_service}
                                                loading={cargando_service}
                                                page={page_service}
                                                maxHeight={370}
                                            >

                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataField="name"
                                                dataSort
                                                >Servicio</TableHeaderColumn>

                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataField="barcode"
                                                dataSort
                                                dataFormat={this.barcodeFormatter}
                                                >Código de Barras</TableHeaderColumn>

                                            <TableHeaderColumn
                                                dataField="id"
                                                isKey={true}
                                                dataAlign="center"
                                                dataFormat={this.addToCar}
                                                ></TableHeaderColumn>
                                            </Table>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-decoration h-100  align-items-around d-flex flex-column sales-services" id="right-tab-bodega">
                    <div className="table-responsive-sm">
                        <BootstrapTable data={this.props.productsInList}
                            maxHeight={400}
                            minHeight={400}
                            trClassName="customClass"
                        >
                            <TableHeaderColumn width='100' dataField='name'>Producto</TableHeaderColumn>
                            <TableHeaderColumn width='100' dataField='cantidad'
                                dataFormat={this.cantidadFormatter}
                            >Cantidad</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='consignment'
                                dataFormat={this.setConsignment}
                            >Consignación</TableHeaderColumn>

                            <TableHeaderColumn width='50' dataField='id' isKey
                                dataFormat={this.deleteFormatter}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                    {/*
                    <div className="d-flex justify-content-end align-items-center p-3 bodega-ingreso-footer">
                        <div className="d-flex element-footer-bodega">Monto Total</div>
                        <div className="d-flex element-footer-bodega"><RenderCurrency value={this.props.total_in_list} /></div>
                    </div>
                    */}
                    <div className="d-flex flex-row justify-content-end align-items-center h-100 pr-5">
                        <button className="btn btn-secondary btn-sm" onClick={()=>this.props.cancelOrder()}>Cancelar</button>
                        {this.props.productsInList.length > 0 && form_validate && cargando==false?
                            <button type="button" className="btn btn-primary btn-sm ml-3"
                                //onClick={(id_purchase_order > 0) ? updateOrder : sentOrder}
                                onClick={(id_purchase_order > 0) ? () => {this.props.updateOrder(null, false, false)} : sentOrder}
                            >{(id_purchase_order > 0) ? "Guardar Orden" : "Generar Orden"}</button>
                            :
                            <button className="btn btn-primary btn-sm ml-3 disabled">{(id_purchase_order > 0) ? "Guardar Orden" : "Generar Orden"}</button>
                        }
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

NuevaOrden = reduxForm({
    form: 'nuevaOrden',

   validate: data => {
    const errors={};

     if(!data.provider){
         errors.provider = 'Campo requerido';
     }

     if(data.provider == null){
        errors.provider = 'Campo requerido';
     }

     if(!data.document_number){
         errors.document_number = 'Campo requerido';
     }

     if(data.spending_type == null ){
        errors.spending_type = 'Campo requerido';
    }

     return errors;
    }
 })(NuevaOrden);

const selector = formValueSelector('nuevaOrden');

NuevaOrden = connect(state => {
    let form_validate = true;


    const provider = selector(state, "provider")
    const date = selector(state, "date");
    const spending_type = selector(state, "spending_type");

    if(provider === undefined || provider === null){
        form_validate = false;
    }

    if(date === undefined || provider === null){
        form_validate = false;
    }

    if(spending_type === undefined || spending_type === null){
        form_validate = false;
    }

    return {
        form_validate
    };

})(NuevaOrden);

export default NuevaOrden;
