import _ from "lodash";

const validate = values => {
    const errors = {};
    if(!values.appointment_holder){
        errors.appointment_holder = 'Campo requerido';
    }
    if(!values.patient_name){
        errors.patient_name = 'Campo requerido';
    }
    /*
    if(!values.phone_holder){
        errors.phone_holder = 'Campo requerido';
    }
    */
    if(!values.cellphone_holder){
        errors.cellphone_holder = 'Campo requerido';
    }
    if(!values.doctor){
        errors.doctor = 'Campo requerido';
    }

    if(!values.referent){
        errors.referent = 'Campo requerido';
    }

    if(!values.date){
        errors.date = 'Campo requerido';
    }
    if(!values.start){
        errors.start = 'Campo requerido';
    }
    if(!values.end){
        errors.end = 'Campo requerido';
    }
    if(!!values.start && !!values.end){
        if(values.end.value <= values.start.value)
            errors.end = 'Hora inválida';
    }

    if(!values.patient){
        errors.patient = 'Campo requerido';
    }

    // Patient requiered fields inside the Appointment's form

    if(!values.first_name){
        errors.first_name = 'Campo requerido';
    }
    if(!values.last_name){
        errors.last_name = 'Campo requerido';
    }
    if(!values.gender){
        errors.gender = 'Campo requerido';
    }
    if(!values.birthdate){
        errors.birthdate = 'Campo requerido';
    }
    if(!values.nationality){
        errors.nationality = 'Campo requerido';
    }

    return errors
};

export default validate;
