import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import './arqueo.css';
import { PrintContainer, actions as printActions } from "Utils/Print";
import {RenderDateTime} from "../Utils/renderField/renderReadField";

class Categoria extends Component{
    render(){
        const {categoria} = this.props;
        return(
            <table className="table table-sm table-bordered w-100">
                <thead>

                </thead>
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                {categoria.nombre} &nbsp;
                                <RenderCurrency value={categoria.total}/>
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_efectivo}/>
                                        </div>

                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_efectivo ? categoria.movimientos_efectivo.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_tarjeta}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_tarjeta ? categoria.movimientos_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_cuota}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_cuota ? categoria.movimientos_cuota.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_deposito ? categoria.movimientos_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                            </ul>
                        </th>
                    </tr>
                </tbody>
            </table>
        );
    }
}

const TableDescription = (props) =>{
    //const total = parseFloat(parseFloat(props.total_efectivo).toFixed(2)) + parseFloat(parseFloat(props.total_abonos).toFixed(2)) + parseFloat(parseFloat(props.total_tarjeta).toFixed(2)) + parseFloat(parseFloat(props.total_ingreso).toFixed(2));
    const total = parseFloat(parseFloat(props.ventas.totales.total_efectivo).toFixed(2)) + parseFloat(parseFloat(props.total_abonos_efectivo).toFixed(2)) + parseFloat(parseFloat(props.total_ingreso).toFixed(2));

    let summary_category = [];
    if(props.summary_category && props.summary_category.hasOwnProperty(props.usuario_fel)){
        summary_category = props.summary_category[props.usuario_fel];
    }

    if(props.summary_category && props.summary_category.length > 0){
        props.summary_category.forEach((categoria, i) => {
            if(categoria.nombre === props.usuario_fel){
                summary_category = categoria.categorias;
            }
        });
    }

    console.log("SUMARY CATEGORY: ", props);


    const ref = props.usuario_fel.replace(' ', '_').toLowerCase();

    return(
        <div className="table-decoration">
            <PrintContainer name={ref}>
            <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-center mt-2 ml-4"><span className='titulo-detalle-arqueo'>{props.usuario_fel} | {props.bank_account} | <RenderDateTime value={props.oppeging_clossing_box ? props.oppeging_clossing_box.closing_date : ""} /></span></div>

            <div className="d-flex flex-row p-3">
                    <table className="table table-sm tbl-arqueo w-50">
                        <thead>
                            <tr>
                                <td colSpan={2} className="titulo">Tipos de pago</td>
                            </tr>
                            <tr className="arqueo-encabezado">
                                <th className='borde-izquierdo'>Tipos de pago</th>
                                <th className='borde-derecho'>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <th colSpan={2}>
                            <span>
                                VENTAS &nbsp;
                                <RenderCurrency value={props.ventas.totales.total}/>
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_efectivo}/>
                                        </div>

                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_efectivo ? props.ventas.movs_efectivo.map((mov, i)=>
                                            mov.efective_quantity > 0
                                            ?
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1 " style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                            :
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                            <span className="badge badge-secondary m-1 fac-red" style={{fontSize:10}} key={i}>
                                                {mov.correlative} - Q.{mov.efective_quantity}
                                            </span>
                                        </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_tarjeta}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_tarjeta ? props.ventas.movs_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.card_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_cuotas}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_cuotas ? props.ventas.movs_cuotas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {console.log('movimiento ', mov.id)}
                                                    {mov.correlative} - Q.{parseFloat(mov.due_quantity)}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_deposito ? props.ventas.movs_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{mov.deposit_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                            </ul>

                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                CUENTAS ABIERTAS (ANTICIPOS) &nbsp;
                                <RenderCurrency value={props.cuentas_abiertas.totales.total == null ? 0 : props.cuentas_abiertas.totales.total} />
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_efectivo == null ? 0 : props.cuentas_abiertas.totales.total_efectivo}/>
                                        </div>
                                    </div>
                                     <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_efectivo ? props.cuentas_abiertas.movs_efectivo.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/cobrar`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_tarjeta == null ? 0 : props.cuentas_abiertas.totales.total_tarjeta}/>
                                        </div>
                                    </div>
                                     <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_tarjeta ? props.cuentas_abiertas.movs_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/cobrar`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative?mov.correlative: 'ID: '+mov.id} - Q.{mov.card_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_cuotas == null ? 0 : props.cuentas_abiertas.totales.total_cuotas}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_cuotas ? props.cuentas_abiertas.movs_cuotas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/cobrar`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative? mov.correlative + '': 'ID: '+mov.id} - Q.{(parseFloat(mov.due_quantity)).toFixed(2)}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_deposito == null ? 0 : props.cuentas_abiertas.totales.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_deposito ? props.cuentas_abiertas.movs_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/cobrar`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                {mov.correlative?mov.correlative: 'ID: '+ mov.id} - Q.{mov.deposit_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Sin Anticipo</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_sin_anticipo == null ? 0 : props.cuentas_abiertas.totales.total_sin_anticipo}/>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                CUENTAS POR COBRAR (ABONOS) &nbsp;
                                <RenderCurrency value={props.cuentas_cobrar.totales.total} />
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_efectivo}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_efectivo ? props.cuentas_cobrar.movs_efectivo.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_tarjeta}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_tarjeta ? props.cuentas_cobrar.movs_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.card_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_cuotas}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_cuotas ? props.cuentas_cobrar.movs_cuotas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{mov.due_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_deposito ? props.cuentas_cobrar.movs_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{mov.deposit_quantity}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Sin Abono</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_sin_anticipo}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_sin_abono ? props.cuentas_cobrar.movs_sin_abono.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{mov.payment}
                                                </span>
                                            </a>
                                        ) : null}
                                    </div>
                                </li>
                            </ul>
                        </th>
                    </tr>
                            <tr>
                                <th>Agregado en efectivo</th>
                                <td className="right-text"><RenderCurrency value={props.total_ingreso}/></td>
                            </tr>
                            <tr>
                                <th>Abonos y Anticipos</th>
                                <td className="right-text"><RenderCurrency  value={props.total_abonos_completo   }/></td>
                            </tr>
                            <tr>
                                <th className="ident">Abonos y Anticipos, Efectivo</th>
                                <td className="right-text"><RenderCurrency  value={props.total_abonos_efectivo}/></td>
                            </tr>
                            <tr>
                                <th>Retenciones</th>
                                <td className="right-text"><RenderCurrency value={props.total_retention}/></td>
                            </tr>
                            <tr>
                                <th>Ventas en efectivo</th>
                                <td className="right-text"><RenderCurrency  value={props.ventas.totales.total_efectivo}/></td>
                            </tr>
                            <tr>
                                <th>Ventas con tarjeta</th>
                                <td className="right-text"><RenderCurrency value={props.total_tarjeta}/></td>
                            </tr>
                            <tr>
                                <th>Depósitos</th>
                                <td className="right-text"><RenderCurrency value={props.total_deposito   }/></td>
                            </tr>
                            <tr>
                                <th style={{fontSize: "1rem"}}>Total de ventas</th>
                                <td style={{fontSize: "1rem"}} className="right-text"><RenderCurrency value={props.ventas.totales.total + (props.cuentas_abiertas.totales.total == null ? 0 : props.cuentas_abiertas.totales.total) + props.cuentas_cobrar.totales.total + props.total_retention}/></td>
                            </tr>
                            <tr>
                                <th>
                                    Notas de credito y anulaciones
                                <div>
                                    {props.ncredito_anulaciones.facturas ? props.ncredito_anulaciones.facturas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{(parseFloat(mov.payment) + parseFloat(mov.extra_amount ? mov.extra_amount : 0)).toFixed(2)}
                                                </span>
                                            </a>
                                        ) : null}
                                </div>
                                </th>
                                <td className="right-text mb-2"><RenderCurrency value={props.ncredito_anulaciones.total   }/></td>
                            </tr>

                            <tr>
                                <th style={{fontSize: "1rem"}}>TOTAL EFECTIVO EN CAJA</th>
                                <td style={{fontSize: "1rem"}} className="right-text"><RenderCurrency  value={total}/></td>
                            </tr>
                            <tr>
                                <th>Anticipos previos
                                    <div className='w-100 flex-wrapp'>
                                        {props.Anticipos ? props.Anticipos.anticipos.map((mov, i)=>
                                           
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlativo} - Q.{mov.subtotal}
                                                </span>
                                            </a>
                                            
                                        ) : ""}
                                        
                                    </div>
                                </th>
                                <td className="right-text"><RenderCurrency value={props.Anticipos ? props.Anticipos.total : 0 }/></td>
                                
                            </tr>
                            <tr>
                                <th />
                                <td />
                            </tr>
                            <tr>
                                <th style={{fontSize: "1rem"}}>Factura inicial y final del día</th>
                                <td>{props.rango}</td>
                            </tr>

                        </tbody>
                    </table>


                <div className="d-flex flex-column justify-content-end align-items-end">
                    <div className='separador-arqueo h-75'></div>
                </div>

                {summary_category.length > 0 ?
                    <div className="d-flex flex-row justify-content-end w-50">
                        <table className="table table-sm table-bordered table-resume-category ">
                            <thead>
                                <tr><th colSpan={2}>Resumen Por Categoría</th></tr>
                            </thead>
                            <tbody>
                                {summary_category ? summary_category.map((detalle, i) => (
                                    <Categoria key={i} categoria={detalle} />
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                :
                    null
                }
            </div>
            <div className="d-flex flex-row justify-content-end">
                <button
                    type="button"
                    className='btn btn-sm btn-primary m-2'
                    onClick={(e) => {
                        e.preventDefault();
                        printActions.print(ref);
                    }}
                >Imprimir</button>
            </div>
            </div>

            </PrintContainer>
        </div>
    );
}

export default TableDescription;
