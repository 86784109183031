import Table from "../../Utils/Grid";

import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import ToolbarSelect from '../../Utils/Toolbar/ToolbarSelect';
import moment from "moment";
import { Link } from 'react-router-dom';
import {RenderCurrency} from "../../Utils/renderField/renderReadField";
import NumberFormat from "react-number-format";

const SUPERUSER = 'superuser';

class CuentaAbierta extends Component{
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            setInitialDate,
            setFinalDate,

            openAccount,
            cancelOpenAccount,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            saldo_total,
        } = this.props;

        console.log("OPEN ACCOUNTS: ", data);

        const options = [
            {value: 0, label: "Todas"},
            {value: 1, label: 'Cuenta'},
            {value: 2, label: 'Cirugía'},
            {value: 3, label: 'Diagnóstico'},
            {value: 4, label: 'Excluir IGSS'}
        ];

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">


                    <div className="d-flex flex-row justify-content-around align-items-center">
                    {/*
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-2 text-uppercase text-titulo"><strong>CUENTAS ABIERTAS</strong></span>
                    </div>
                    */}
                    <div className="top-title-toolbar">
                        <ToolbarSelect 
                            titulo="CUENTAS ABIERTAS"
                            buscar={this.props.changeFilter}
                            buscador={this.props.select_search}
                            options={options} 
                            valueKey="value"
                            labelKey="label"
                            autofocus
                            ancho="w-75"
                        />
                    </div>

                    <div className="d-flex flex-row justify-content-end align-items-center w-50">
                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20"
                            value={initial_date}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    setInitialDate(values.formattedValue);
                                }                                                                                
                            }}
                            autoFocus
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />

                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20 ml-2 mr-2"
                            value={final_date}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    setFinalDate(values.formattedValue);
                                }                                                                                
                            }}
                            
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />
                    <div className="top-title-toolbar mt-3">
                        <CustomToolbar
                            buscar={this.props.search}
                            buscador={this.props.buscador}                            
                        />
                    </div>
                    <div className='font-weight-bold ml-3 d-flex flex-row' style={{fontSize: 16}}>
                        Total:&nbsp;<RenderCurrency value={saldo_total} />
                    </div>
                    </div>
                    </div>

                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >

                        <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataField="type_procedure"
                            dataSort
                            dataFormat={(cell) => {
                                console.log("Tipo procedimiento: ", cell)
                                if(cell === 1){
                                    return (
                                        <span style={{background:'#7F7F7F', padding:2, color:'white', borderRadius:5, fontSize:11}}>cuenta</span>
                                    );
                                }else if(cell === 2){
                                    return(
                                        <span style={{background:'#0a5995', padding:2, color:'white', borderRadius:5, fontSize:11}}>cirugía</span>
                                    );
                                }else if(cell === 3){
                                    return(
                                        <span style={{background:'#459058', padding:2, color:'white', borderRadius:5, fontSize:11}}>diagnóstico</span>
                                    );
                                }
                            }}
                            >Tipo de cuenta</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    if(row.patient){
                                        return row.patient.first_name + " " + row.patient.last_name;
                                    }else{
                                        return "---";
                                    }

                                }}
                                >Paciente</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="referent"
                                dataSort
                                dataFormat={(cell, row) => {
                                    if(row.referent){
                                        return row.referent.name;
                                    }else{
                                        return "";
                                    }
                                }}
                                >Referente</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row)=>{
                                    return (
                                        <React.Fragment>
                                            <span>Saldo pendiente:</span><br />
                                            <span>Q{row.saldo_pendiente}</span>
                                        </React.Fragment>
                                    );
                                }}
                                dataSort>Estado</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="made"
                                dataSort>Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"

                                dataFormat={(cell)=>{
                                    return(
                                        <React.Fragment>
                                            <span className="account" onClick={()=>openAccount(cell)}>Cargar</span>
                                            {this.props.usuario.role_name === SUPERUSER &&
                                                <span  className="cancel-account" onClick={()=>cancelOpenAccount(cell)}>Anular</span>
                                            }
                                        </React.Fragment>
                                    );
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default CuentaAbierta;
