import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { RenderCurrency } from  '../../Utils/renderField/renderReadField';
import {
    renderField,
    renderSearchSelect,
    modalCurrency,
    renderCurrency,
    renderSwitch,
    renderFieldDateFormat
} from '../../Utils/renderField/renderField';
import { connect } from 'react-redux'
import { api } from "api";
import moment from 'moment';

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getSmallBoxes = (search)  =>{
    return api.get("small_box",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                let small_boxes = [];
                data.results.forEach(x=>{
                    small_boxes.push({value: x.id, label: x.name, nit: x.nit});
                })
                return small_boxes;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let initialValues = {
    transaction_fecha_fac: moment(Date()).format("DD/MM/YYYY"),
    card_fecha_fac: moment(Date()).format("DD/MM/YYYY"),
    due_fecha_fac: moment(Date()).format("DD/MM/YYYY")
};

let PaymentForm = (props) => {
    const {
            handleSubmit,
            id_purchase,
            url_retorno,
            transaction_payment,
            cash_payment,
            card_payment,
            due_payment,
            solicitar_factura,
            label_factura="FACTURA",
            retiene,
            descuento,
        } = props;

    initialValues.id_purchase = id_purchase;
    initialValues.url_retorno = url_retorno;

    const totalPagar = props.total;


    /*
    let total_label = 0
    if(retiene){
        if(props.total > 2800){
            total_label = parseFloat((props.total * 0.05).toFixed(2));
            props.change('retention_amount', total_label);
        }
    }

    let totalPagar = props.total - total_label;

    if(descuento > 0){
        totalPagar -= descuento
    }
    */

    return (
        <form onSubmit={handleSubmit} id="form_payment" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="id_purchase" component={"input"} type="hidden"/>
            <Field name="url_retorno" component={"input"} type="hidden" />

            <table className="table" id="table-frm-pay-oc">
                <thead></thead>
                <tbody className="oc-body-form">
                    <tr>
                        <th className="oc-custom-table">
                            TOTAL A PAGAR
                        </th>
                        <td className="oc-custom-table">
                            {/*<RenderCurrency value={props.total} className="oc-total-pago"/>*/}
                            <RenderCurrency value={totalPagar} className="oc-total-pago"/>
                        </td>
                    </tr>
                    <tr>
                        <th className="oc-custom-table-middle">
                            EFECTIVO
                        </th>
                        <td className="oc-custom-table-middle">
                            <Field
                                name="cash"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {cash_payment &&
                        <React.Fragment>
                        <tr>
                            <th>NO. DOCUMENTO</th>
                            <td>
                                <Field
                                    name="no_document"
                                    component={renderField}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr className=""><th colSpan={2} className="oc-custom-table"><span className="pl-2">CAJA CHICA</span></th></tr>
                        <tr className="oc-white-border mx-5">
                            <td colSpan={2} className="oc-custom-table">
                                <div className="d-flex flex-row justify-content-around">
                                <Field
                                    name="small_box"
                                    valueKey="value"
                                    labelKey="nit"
                                    label="name"
                                    component={renderSearchSelect}
                                    loadOptions={getSmallBoxes}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />

                                <Field
                                    name="small_box"
                                    valueKey="value"
                                    labelKey="label"
                                    label="name"
                                    component={renderSearchSelect}
                                    loadOptions={getSmallBoxes}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                </div>
                            </td>
                        </tr>
                        <tr><td>&nbsp;&nbsp;</td></tr>
                        </React.Fragment>
                    }
                    <tr>
                        <th className="oc-custom-table-middle">
                            TARJETA
                        </th>
                        <td className="oc-custom-table-middle">
                            <Field
                                name="card"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {card_payment &&
                        <React.Fragment>
                            <tr>
                                <th>FECHA DE PAGO
                                    <br/>
                                    <span style={{fontSize: "10px"}}>(TARJETA)</span>
                                </th>
                                <td>
                                    <Field
                                        name="card_fecha_fac"
                                        component={renderFieldDateFormat}
                                        type="text"
                                        className="form-control oc-input-modal"
                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                    }
                    <tr>
                        <th className="oc-custom-table-middle">
                            VISA CUOTAS
                        </th>
                        <td className="oc-custom-table-middle">
                            <Field
                                name="due"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {due_payment &&
                        <React.Fragment>
                            <tr>
                                <th>FECHA DE PAGO
                                    <br/>
                                    <span style={{fontSize: "10px"}}>(Visa cuotas)</span>
                                </th>
                                <td>
                                    <Field
                                        name="due_fecha_fac"
                                        component={renderFieldDateFormat}
                                        type="text"
                                        className="form-control oc-input-modal"
                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                    }
                    <tr>
                        <th>
                            TRANSACCIÓN
                        </th>
                        <td>
                            <Field
                                name="transaction"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {transaction_payment &&
                        <React.Fragment>
                        <tr>
                            <th>FECHA DE PAGO
                                <br/>
                                <span style={{fontSize: "10px"}}>(TRANSACCIÓN)</span>
                            </th>
                            <td>
                                <Field
                                    name="transaction_fecha_fac"
                                    component={renderFieldDateFormat}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>NO. DOCUMENTO</th>
                            <td>
                                <Field
                                    name="no_document"
                                    component={renderField}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr className="oc-white-border"><th colSpan={2} className="oc-custom-table"><span className="pl-2">CUENTA BANCARIA</span></th></tr>
                        <tr className="oc-white-border">
                            <td colSpan={2} className="oc-custom-table">
                                <div className="d-flex flex-row justify-content-around">
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="number"
                                    label="cuenta"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="label"
                                    label="referent"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                </div>
                            </td>
                        </tr>
                        </React.Fragment>
                    }

                    {solicitar_factura &&
                        <React.Fragment>
                            <tr className="oc-white-border">
                                <th className="oc-custom-table-middle">
                                    NO. {label_factura}
                                </th>
                                <td className="oc-custom-table-middle">
                                    <Field
                                        name="bill_number"
                                        component={renderField}
                                        type="text"
                                        className="form-control oc-input-modal"
                                        addClass="w-50"
                                    />
                                </td>
                            </tr>
                            {/*
                            <tr>
                                <th>FECHA FACTURA</th>
                                <td>
                                    <Field
                                        name="fecha_fac"
                                        component={renderFieldDateFormat}
                                        type="text"
                                        className="form-control oc-input-modal"
                                        addClass="w-50"
                                    />
                                </td>
                            </tr>
                            */}
                        </React.Fragment>
                    }
                </tbody>
            </table>
        </form>
    );
}

PaymentForm = reduxForm({
    form: 'form_payment',
    initialValues,
    validate: data => {
        const errors={};
        if(data.transaction){
            if(!data.bank_account){
                errors.bank_account = 'Campo requerido';
            }

            if(!data.no_document){
                errors.no_document = 'Campo requerido';
            }

            if(!data.transaction_fecha_fac){
                errors.transaction_fecha_fac = 'Campo requerido'
            } else {
                const fecha = moment(data.transaction_fecha_fac, "DD/MM/YYYY")
                if(!fecha.isValid() || fecha.year() < 2000){
                    errors.transaction_fecha_fac = 'Fecha inválida'
                }
            }
        }

        if(data.cash){
            if(!data.small_box){
                errors.small_box = 'Campo requerido';
            }

            if(!data.no_document){
                errors.no_document = 'Campo requerido';
            }
        }

        if(data.card){
            if(!data.card_fecha_fac){
                errors.card_fecha_fac = 'Campo requerido'
            } else {
                const fecha = moment(data.card_fecha_fac, "DD/MM/YYYY")
                if(!fecha.isValid() || fecha.year() < 2000){
                    errors.card_fecha_fac = 'Fecha inválida'
                }
            }
        }

        if(data.due){
            if(!data.due_fecha_fac){
                errors.due_fecha_fac = 'Campo requerido'
            } else {
                const fecha = moment(data.due_fecha_fac, "DD/MM/YYYY")
                if(!fecha.isValid() || fecha.year() < 2000){
                    errors.due_fecha_fac = 'Fecha inválida'
                }
            }
        }

        return errors;
    }
 })(PaymentForm);

const selector = formValueSelector('form_payment');

PaymentForm = connect(state => {
    const transaction = selector(state, "transaction");
    const cash = selector(state, "cash");
    const card = selector(state, "card");
    const due = selector(state, "due");
    //const discount = selector(state, "discount");

    let transaction_payment = transaction > 0 ? true : false;

    let cash_payment = cash > 0 ? true : false;

    let card_payment = card > 0 ? true : false;

    let due_payment = due > 0 ? true : false;

    /*
    let descuento = 0;
    descuento = (discount > 0) ? discount : 0;
    */

    return {
        transaction_payment,
        cash_payment,
        card_payment,
        due_payment,
    };

})(PaymentForm);

export default PaymentForm;
