import React from "react";
import moment from 'moment';
import Footer from './Footer';

const DiagnosticPrint = props => {
    const { handleSubmit, data_procedimiento, transcription } = props;
    const diagnostic_id = data_procedimiento ? data_procedimiento.id : 0

    var trans = document.getElementById('transcription-description');
    if(trans){
        trans.innerHTML = transcription;
    }

    return (        
        <div className='w-100'>
            <div className="row pb-2">
                <div className="col-9">
                    <img
                        src={require("../../../../../assets/img/logo_prolaser_gris.png")}
                        alt="Prolaser_logo"
                        className="logo-transcription"
                    />
                </div>
                <div className="col-3">
                    <p className="parrafo-descripcion">
                        <strong>Doctor:&nbsp;</strong>
                        {data_procedimiento &&
                        data_procedimiento.referent
                            ? data_procedimiento.referent.name
                            : ""}
                    </p>
                    <p className="parrafo-descripcion">
                        <strong>Paciente:&nbsp;</strong>
                        {data_procedimiento &&
                        data_procedimiento.patient
                            ? data_procedimiento.patient.first_name +
                                " " +
                                data_procedimiento.patient.last_name
                            : ""}
                    </p>
                    <p className="parrafo-descripcion">
                        <strong>Fecha:&nbsp;</strong>
                        {data_procedimiento ?
                                moment(data_procedimiento.created).format(
                                    "DD/MM/YYYY"
                                )
                            : ""}
                    </p>
                </div>
            </div>
            <div>
                <div
                    id="transcription-description"
                    style={{
                        textTransform: "none",
                        height: "auto",
                        wordWrap: "break-word",
                        fontSize: "17px",                                                
                    }}                        
                />
            </div>
            <Footer />
        </div>        
    );
};

export default DiagnosticPrint;