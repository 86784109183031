import React from 'react';

const Icon = ({
    className = '',
}) => (
    <div className="nav-icon">
        <div className={`icon ${className}`} />
    </div>
);

export default Icon;
