import Table from '../Utils/Grid';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import { formatoMoneda, formatoPorcentaje } from '../Utils/renderField/renderReadField';
import ToolbarSelect from '../Utils/Toolbar/ToolbarSelect';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import ToolbarSearchSelect from '../Utils/Toolbar/ToolbarSearchSelect';
import moment from 'moment';

class TodaysSurgery extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        //this.props.setUpdateData();
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        const options = [
            {value: 0, label: "Todas"},
            {value: 1, label: "Pendientes"},
            {value: 2, label: "Realizadas"},
        ];


        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 pt-5">
                    <div className="table-decoration">
                        {/*
                        <div className="top-title">
                            LISTADO DE CUPONES
                        </div>
                        */}

                        <div className="top-title-toolbar">
                            <ToolbarSearchSelect
                                titulo="CIRUGÍAS DE HOY"
                                buscar={this.props.search}
                                buscador={this.props.buscador}
                                buscarSelect={this.props.filtro}
                                buscadorSelect={this.props.filtro_todays_surgery}
                                options={options}
                                valueKey="value"
                                labelKey="label"
                                autofocus
                            />
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort
                                dataFormat={(cell)=>{
                                    return !!cell ? (cell.first_name + ' ' + cell.last_name) : "---";
                                }}
                                >Paciente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="doctor"
                                dataSort
                                dataFormat={(cell)=>{
                                    return cell.name;
                                }}
                                >Médico asignado</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="referent"
                                dataSort
                                dataFormat={(cell)=>{
                                    return cell.name;
                                }}
                            >
                                Referente
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="surgery_services"
                                dataSort
                                dataFormat={(cell)=>{
                                    let procedimiento = 'Sin procedimiento'
                                    if(!!cell && cell.length > 0){
                                        procedimiento = cell[0].service.name;
                                    }
                                    return procedimiento
                                }}
                            >
                                Servicio
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="date"
                                dataFormat={(cell) => {
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                dataSort
                                >Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="date"
                                dataFormat={(cell, row)=>{
                                    return (
                                        <React.Fragment>
                                            <span>de {row.start} </span> <br/>
                                            <span>a&nbsp;&nbsp; {row.end}</span>
                                        </React.Fragment>
                                    );
                                }}
                                dataSort>Horario</TableHeaderColumn>


                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell, row)=>{
                                    return (
                                        <React.Fragment>
                                            {row.active ?
                                                <a href={'/#/perform-surgery/' + cell}
                                                    className='btn btn-sm btn-success'
                                                >Iniciar Cirugía
                                                </a>
                                                :
                                                ''
                                            }
                                        </React.Fragment>
                                    );
                                }}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default TodaysSurgery;
