import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'

class Tasks extends Component {
  
  componentWillMount() {
    this.props.listar();
  }
    
  componentWillUnmount() {
      this.props.resetStore();
  }
      
  render(){
    const {
      data,
      listar,
      cargando,
      page,
      descargarArchivo,
      destroy
  } = this.props;

    return(
      <div className="content">
        <div className="m-auto row-fluid w-100">
            
            <div className="table-decoration">
                <div className="top-title">
                    LISTADO DE TAREAS
                </div>
                <Table
                    onPageChange={listar}
                    data={data}
                    loading={cargando}
                    page={page}
                >
                    <TableHeaderColumn                        
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="name" dataSort>Nombre</TableHeaderColumn>

                  <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="state" dataSort
                        dataFormat={(field, row)=>{                          
                          const state = row.state === 1 ? 'Iniciada' : row.state === 2 ? 'Pendiente' : 'Finalizada';

                          return(
                            <label>{state}</label>
                          )
                        }}
                      >Estado</TableHeaderColumn>

                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="state"
                        dataSort
                        dataFormat={(field, row)=>{
                          const state = row.state === 1 ? true : row.state === 2 ? true : false; 

                          return(
                            <React.Fragment>
                              {row.type === 1 ?
                                <button 
                                  className='btn btn-success' 
                                  disabled={state}
                                  onClick={()=> descargarArchivo(row.id)}
                                >Descargar</button>
                              :
                                <label>No contiene</label>
                              }
                            </React.Fragment>             
                          )
                        }} 
                      >Archivo</TableHeaderColumn>

                    <TableHeaderColumn
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        dataField="percentage"
                        dataSort
                        dataFormat={(field)=>{                          
                          return(
                            <label>
                              {field}%
                            </label>
                          )
                        }} 
                      >Porcentaje</TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="id"
                      isKey={true}
                      dataAlign="center"
                      dataFormat={activeFormatter({ eliminar: destroy })}
                    >Acciones</TableHeaderColumn>                      
                  </Table>
            </div>
        </div>
      </div>
    )      
  }
}

export default Tasks;