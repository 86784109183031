import Table from '../../Utils/Grid';
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../../Utils/Acciones/Acciones'

class RecordList extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        if(this.props.match.params && this.props.match.params.id) {
            this.props.listarRecords(this.props.match.params.id);
        }
    }
    componentDidMount() {
        if(this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id;
            const { setUrl, listarRecords } = this.props;
            setUrl(id);
            listarRecords(id);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params && prevProps.match.params.id) {
            if(this.props.match.params.id != prevProps.match.params.id) {
                let id = this.props.match.params.id;
                const { setUrl, listarRecords } = this.props;
                setUrl(id);
                listarRecords(id);
            }
        }
    }
    procesarEliminar = (id, row) => {
        if(row) {
            const { deleteData, page } = this.props;
            deleteData(row, page);
        }
    }
    render() {
        const { titulares,
            id,
            cargando,
            data,
            listarRecords,
            page,
            deleteData
        } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={titulares.button} ruta={`/records/${id}/create`}/>
                    <div className="table-decoration">
                        <div className="top-title">
                            { titulares.listar }
                        </div>
                            <Table
                                onPageChange={(page) => {
                                    console.log("page: ", page)
                                    listarRecords(id, page)
                                }}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="name" dataSort>Nombre
                                </TableHeaderColumn>
                                { id == 1 && (
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="md_treatment" dataSort>Tratamiento medico
                                    </TableHeaderColumn>
                                )}
                                { id == 5 && (
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="fam_family" dataSort>Familia
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={activeFormatter({ ver: `/records_edit`, editar: `/records_edit`, eliminar: (id, cell) => {
                                        this.procesarEliminar(id, cell)
                                    } })}>Acciones</TableHeaderColumn>
                            </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecordList;
