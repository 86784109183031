import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { api } from "api";
import { BreakLine } from '../Utils/tableOptions';
import ToolbarTab from '../Utils/Toolbar/ToolbarTab';
import { RenderCurrency } from  '../Utils/renderField/renderReadField';
import FormInter from "./formInter";
import MyModal from "../Utils/myModal";

function priceFormatter(cell, row) {
    return <RenderCurrency value={cell}/>;
}
export default class ServicesSurgery extends Component {
    componentWillMount() {
        this.props.listarServicios();
    }

    addServiceToList = (data, row, cell) => {
        return(
            <button onClick={() => {this.props.addServiceToList(row)}} className="circle-button-car">
                 <img className="card-img-top d-block" src={require("../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon" />
            </button>
        );
    }

    barcodeFormatter = (cell) => {
        return <span className="barcode-service-list">{cell}</span>
    }

    addToCar = (cell, row) => {
        let options=[];
        const _membership = (this.props.selected_referent.receipt) ? "recibo" : "factura";

        options.push({
            label: this.props.selected_referent.usuario_fel,
            value: this.props.selected_referent.financial_entity,
            membership: _membership,
        })

        row.financial_entities.forEach(entity => {
            if(this.props.selected_referent !== null && (this.props.selected_referent.financial_entity !== entity.financial_entity.id)){
                options.push(
                    {
                        label: entity.financial_entity.usuario_fel,
                        value: entity.financial_entity.id,
                        membership: entity.financial_entity.afiliacion_fel,
                    }
                );
            }
         });

         if(options.length === 1){
             row.financial_entity = options[0];
         }

        return(
            <React.Fragment>
            {(options.length > 1) ?
                <MyModal isOpen={false}
                    title=""
                    formId="selectEntityInServiceSurgery"
                    imageButton={<img className="card-img-top d-block" src={require("../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon" />}
                    classToggleButton="circle-button-car"
                    aceptar={this.props.addServiceToListDiff}
                    body={
                        <FormInter
                            //onSubmit={this.props.addServiceToListDiff}
                            onSubmit={()=>{console.log('')}}
                            options={options}
                            initialValues={{
                                id: row.id,
                                name: row.name,
                                price: row.price,
                                discount: row.discount,
                                total: row.price,
                                barcode: row.barcode,
                            }}
                        />
                    }
                />
            :
                <button type="button" onClick={() => {this.props.addServiceToList(row)}} className="circle-button-car">
                    <img className="card-img-top d-block" src={require("../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon blue"/>
                </button>
            }
            </React.Fragment>
        );
    }

    render() {
        const {
            listarServicios,
        } = this.props;


        const {
            data_service,
            page_service,
            cargando_services,

            searchService,
            buscador_service,
        } = this.props;

        return (
            <React.Fragment>
                <div className="m-auto row-fluid w-100 h-100">
                    <div className="table-decoration rcp-table-decoration" id="comodin-table">
                        <div className="pl-3 pr-3 d-flex flex-wrap h-25 justify-content-start align-items-center">
                            <div className="d-flex pb-2 flex-row w-100 justify-content-center align-items-center">
                                <div className="d-inline-flex w-100 pt-3 justify-content-start service-title">
                                    SERVICIOS
                                </div>
                                <ToolbarTab
                                    id="toolbar-tab"
                                    buscar={searchService}
                                    buscador={buscador_service}
                                    autofocus={true}
                                />
                            </div>

                                <div className="table-responsive">
                                    <Table
                                        onPageChange={listarServicios}
                                        data={data_service}
                                        loading={cargando_services}
                                        page={page_service}
                                        maxHeight={350}
                                    >

                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="name"
                                        dataSort
                                        >Servicio</TableHeaderColumn>

                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="barcode"
                                        dataSort
                                        dataFormat={this.barcodeFormatter}
                                        >Código de Barras</TableHeaderColumn>

                                    {/*
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="id"
                                        dataSort
                                        dataFormat={this.addToCar}
                                        >Comodín</TableHeaderColumn>
                                    */}

                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        //dataFormat={this.addServiceToList}
                                        dataFormat={this.addToCar}
                                        ></TableHeaderColumn>
                                    </Table>
                                </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
