import moment from "moment";
import Table from '../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { showNestedFields } from '../../Utils/MyOptions';

import MyModal from '../../Utils/myModal';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import ToolbarSearchSelect from '../../Utils/Toolbar/ToolbarSearchSelect';
import NumberFormat from "react-number-format";

class DiagnosticCompleted extends Component {
    state = {
        fechaInicial: this.props.initial_date,
        fechaFinal: this.props.final_date,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount = () =>{
        this.props.getDiagnosticosFinalizados();
        this.consultaContinua();
    }

    consultaContinua = () => {
        this.timer = setInterval(() => this.props.getDiagnosticosFinalizados(), 30000);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.props.resetStore();
    }

    setFechaInicial(value) {
        this.setState({ fechaInicial: value });
    }

    setFechaFinal(value) {
        this.setState({ fechaFinal: value });
    }

    actions = (cell, row) => {
        return(
            <React.Fragment>
                {row.state === 0 &&
                    <div className='d-flex flex-row justify-content-center'>
                        <button
                            className='btn btn-sm btn-primary mr-2 small-button'
                            onClick={()=>this.props.startDiagnostic(cell)}
                        >Iniciar</button>
                        <MyModal
                            isOpen={false}
                            title="ANULACIÓN"
                            formId="cancelDiagnosticProcedure"
                            textButton='Anular'
                            classToggleButton="btn btn-sm btn-danger small-button"
                            body={(
                                <Form
                                    onSubmit={this.props.cancelarColaDiagnostico}
                                    initialValues={{
                                        id: row.id,
                                    }}
                                />
                            )}
                        />
                    </div>
                }

                {row.state === 1 &&
                    <React.Fragment>
                        <button className='btn btn-sm btn-success small-button'>Finalizar</button>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    render() {

        const options = [
            {value: 0, label: "En Cola"},
            {value: 1, label: "En Proceso"},
            {value: 2, label: "Finalizadas"},
            //{value: 2, label: "Anuladas"},
        ];

        const {
            initial_date, final_date,
            set_final_date,
            set_initial_date
        } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 pt-5">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo"><strong>DIAGNÓSTICOS COMPLETADOS</strong></span>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    value={this.state.fechaInicial}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            this.setFechaInicial(values.formattedValue);
                                        }                                                                                
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2 mr-2"
                                    value={this.state.fechaFinal}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            this.setFechaFinal(values.formattedValue)
                                        }                                                                                
                                    }}
                                    
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />
                                <div className="top-title-toolbar mt-3">
                                    <ToolbarSearchSelect
                                        buscar={this.props.searchDiagnosticCompleted}
                                        buscador={this.props.buscador_diagnostic_completed}
                                        date = {{
                                            setInitialDate: set_initial_date,
                                            fechaInicial: this.state.fechaInicial,
                                            setFinalDate: set_final_date,
                                            fechaFinal: this.state.fechaFinal,
                                        }}
                                        buscarSelect={this.props.filtroReporte}
                                        buscadorSelect={this.props.filtro_todays_surgery}
                                        options={options}
                                        valueKey="value"
                                        labelKey="label"
                                        autofocus
                                    />
                                </div>
                            </div>
                        </div>
                        <Table
                            onPageChange={this.props.getDiagnosticosFinalizados}
                            data={this.props.diagnostic_completed}
                            loading={this.props.cargando}
                            page={this.props.page_diagnostic_completed}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="diagnostic"
                                dataFormat={(cell, row) =>{
                                    const nombre_completo = row.patient.first_name  + ' ' + row.patient.last_name;
                                    return(
                                        <React.Fragment>
                                            {nombre_completo}
                                        </React.Fragment>
                                    );
                                }}
                                >Nombre del paciente</TableHeaderColumn>


                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                formatExtraData="referent__name"
                                dataSort={true}
                                dataFormat={showNestedFields}
                                dataField="referent" dataSort>Referente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="created"
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}                                
                                >Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="procedimientos" dataSort>Cantidad de Procedimientos</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataSort={true}
                                dataField="estados" dataSort>Completados</TableHeaderColumn>

                            <TableHeaderColumn
                                width={'20%'}
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell, row)=>{
                                    return (
                                        <a href={'/#/resumen-procedimientos/' + cell}>
                                            <img
                                                className="action-img watch"
                                                title="Ver"
                                                src={require("../../../../../assets/img/icons/view-icon.png")}
                                                alt="Ver"
                                            />
                                        </a>
                                    );
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        );
    };
}

export default DiagnosticCompleted;
