import React from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { modalTextArea, modalEditor } from 'Utils/renderField/renderField';
import { PrintContainer, actions as printActions } from "Utils/Print";
import Footer from './Footer';

import Modal from './modal';
import InputTag from './TagInput';

let DiagnosticTranscriptionForm = props => {
    const { handleSubmit, data_procedimiento } = props;
    const diagnostic_id = data_procedimiento ? data_procedimiento.id : 0


    return (
        <React.Fragment>
        <form onSubmit={handleSubmit} className="">
            <div>
                <div className="row pb-2">
                    <div className="col-9">
                        <img
                            src={require("../../../../../assets/img/logo_prolaser_gris.png")}
                            alt="Prolaser_logo"
                            className="logo-transcription"
                        />
                    </div>
                    <div className="col-3">
                        <p className="parrafo-descripcion">
                            <strong>Doctor:&nbsp;</strong>
                            {data_procedimiento &&
                            data_procedimiento.referent
                                ? data_procedimiento.referent.name
                                : ""}
                        </p>
                        <p className="parrafo-descripcion">
                            <strong>Paciente:&nbsp;</strong>
                            {data_procedimiento &&
                            data_procedimiento.patient
                                ? data_procedimiento.patient.first_name +
                                  " " +
                                  data_procedimiento.patient.last_name
                                : ""}
                        </p>
                        <p className="parrafo-descripcion">
                            <strong>Fecha:&nbsp;</strong>
                            {data_procedimiento ?
                                    moment(data_procedimiento.created).format(
                                        "DD/MM/YYYY"
                                    )
                                : ""}
                        </p>
                    </div>
                </div>
                <div>
                    <Field
                        name="diagnostic_id"
                        component='input'
                        type='hidden'
                    />
                    <Field
                        name="descripcion"                        
                        component={modalEditor}
                        className="annotations w-100 transcription-textarea-text"
                    />
                </div>
                <Footer />
                {/*
                <div className="d-flex flex-row justify-content-center transcription-image-content">
                    {data_procedimiento && data_procedimiento.diagnostic_service_diagnostic_file.map((imagen) =>
                        <img
                            key={imagen.id}
                            title="Imágen diangnóstico"
                            src={imagen.imagen}
                            style={{width:300, height:300}}
                        />
                    )}
                </div>

                <Footer />
                */}
            </div>
            <div className="row-fluid mt-5">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link
                        className="btn btn-secondary m-3"
                        to={"/resumen-procedimientos/" + diagnostic_id}
                    >
                        Cancelar
                    </Link>
                    <button type="submit" className="btn btn-primary m-3">
                        Guardar
                    </button>

                    {data_procedimiento && data_procedimiento.transcription != null &&
                        <React.Fragment>

                        {/*data_procedimiento && !data_procedimiento.delivered &&
                            <button type="button" className="btn btn-info m-3"
                                onClick={()=>props.deliveredOneDiagnostic(data_procedimiento.id)}
                            >
                                Entregado
                            </button>
                        */}

                        {/*data_procedimiento && data_procedimiento.delivered &&
                            <button type="button" className="btn btn-info m-3 disabled">
                                Entregado
                            </button>
                        */}

                        {/*data_procedimiento && !data_procedimiento.sent &&
                            <button type="button" className="btn btn-success m-3"
                                onClick={()=>props.sentOneDiagnostic(data_procedimiento.id)}
                            >
                                Enviado
                            </button>
                        */}

                        {/*data_procedimiento && data_procedimiento.sent &&
                            <button type="button" className="btn btn-success m-3 disabled">
                                Enviado
                            </button>
                        */}

                        <Modal
                            isOpen={props.show_modal_correos}
                            title="Enviar la transcripción al intérprete"
                            textButton={(data_procedimiento && data_procedimiento) ? 'Transcripción Enviada' : 'Enviar Transcripción'}
                            classToggleButton="btn btn-sm btn-success"
                            abrirModal={props.abrirModalCorreo}
                            //disabled={data_procedimiento && data_procedimiento.sent}

                            body={
                                <InputTag
                                    placeholder='Presione enter para agregar'
                                    aceptar={(tags)=>{
                                        props.enviarCorreo(tags, diagnostic_id);
                                    }}

                                    cancelar={props.cerrarModalCorreo}
                                />
                            }
                        />

                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                printActions.print('transcription')
                            }}
                            className='btn btn-sm btn-primary m-3'
                            >
                        Imprimir</button>

                        </React.Fragment>
                    }
                </div>
            </div>
        </form>

        </React.Fragment>
    );
};

DiagnosticTranscriptionForm = reduxForm({
    form: "diagnosticTranscriptionForm",
    validate: data => {
        return validate(data, {
            descripcion: validators.exists()('Campo requerido.'),
        })
    }
})(DiagnosticTranscriptionForm);

export default DiagnosticTranscriptionForm;
