import React, {
    Component
} from 'react';
import { Link } from 'react-router-dom';


class Show extends Component {

    componentWillMount() {
        if(this.props.match.params && this.props.match.params.id) {
            const { getRecordDetail } = this.props;
            getRecordDetail(this.props.match.params.id);
        }
    }
    render() {
        const { record_data } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <div className="top-title">
                            Detalle del registro
                        </div>
                        <form className="py-3 px-5">
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="name" className="m-0">Nombre</label>
                                    </div>
                                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <input name="name" type="text" className="form-control" value={
                                            record_data.name
                                        } disabled/>
                                    </div>
                                </div>
                            </div>
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            { record_data.record_type == 1 && (
                                                <label htmlFor="name" className="m-0">
                                                    Medico tratamiento
                                                </label>
                                            )}
                                            { record_data.record_type == 5 && (
                                                <label htmlFor="name" className="m-0">
                                                    Familia
                                                </label>
                                            )}
                                    </div>
                                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    { record_data.record_type == 1 && (
                                        <input name="name" type="text" className="form-control" value={
                                            record_data.md_treatment
                                        } disabled/>
                                    )}
                                    { record_data.record_type == 5 && (
                                        <input name="name" type="text" className="form-control" value={
                                            record_data.fam_family
                                        } disabled/>
                                    )}
                                    </div>
                                </div>
                            </div>
                            <div className="row-fluid">
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link className="btn btn-secondary m-4" to={`/records/${record_data.record_type}`}>Regresar</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default Show;
