import {api} from 'api';
import React from "react";
import { Link } from 'react-router-dom';
import { Field, reduxForm } from "redux-form";
import validate from './validationExam';
import { renderField, renderDatePicker, renderSearchSelect, renderImagePicker, renderMyUpload, modalTextArea } from "Utils/renderField/renderField";

//import myUpLoad from '../Utils/renderField/myUpLoad';

let referents = [];

const getReferents = (search) => {
    return api.get("referents", {search}).catch((error) => {})
    .then((data) => {
        if (data){
            referents = [];
            data.results.forEach(x=>{
                referents.push({value: x.id, label: x.name})
            })
            return referents;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

let interpreters = [];
const getInterpreters = (search) => {
    return api.get("interpreter", {search}).catch((error) => {})
    .then((data) => {
        if (data){
            interpreters = [];
            data.results.forEach(x=>{
                interpreters.push({value: x.id, label: x.first_name})
            })
            return interpreters;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

let ExamOrderForm = props => {
    const { handleSubmit, setFiles, foto } = props;
    return (
        <form
            onSubmit={handleSubmit}
            className="pb-3 pt-5 d-flex justify-content-center"
        >
            <div className="form-body-width">
                <Field name='id' component='input' type='hidden'/>

                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="patient_name" className="">
                                Nombre del paciente
                            </label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="nombre_completo" component={renderField} type="text" className="form-control" disabled={true}/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referent" className="">
                                Médico referente
                            </label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="referent" valueKey="value" labelKey="label" label="name"
                                component={renderSearchSelect} loadOptions={getReferents} className="no-border" disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">
                                Fecha
                            </label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            {/*<Field name="date" component={renderDatePicker} type="text" className="form-control" disabled={true}/>*/}
                            <Field name="date" component='input' type="text" className="form-control" disabled={true}/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referent" className="">
                                Tipo de procedimiento
                            </label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="nombre_servicio" component={renderField} type="text" className="form-control"  disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">
                                Asignar interprete
                            </label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="interpreter" valueKey="value" labelKey="label" label="name"
                            autofocus
                            component={renderSearchSelect} loadOptions={getInterpreters} className="no-border" />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">
                                Archivos (imágenes o PDF)
                            </label>
                        </div>
                        <div
                            className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100"
                            style={{maxWidth: '600px'}}
                            >
                            <Field
                                name="files"
                                setFiles={setFiles}
                                type='file'
                                multiple
                                component={renderMyUpload}/>

                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2 w-100">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">
                                Notas
                            </label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="notas"
                                component={modalTextArea}
                                className="annotations form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Link className="btn btn-secondary btn-sm m-3" to="/cola-diagnosticos">Cancelar</Link>
                        <button type="submit" className="btn btn-primary btn-sm m-3">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

ExamOrderForm = reduxForm({
    form: "examorder",
    validate
})(ExamOrderForm);

export default ExamOrderForm;
