import React, { Component } from 'react';
import classNames from 'classnames';

import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import ToolbarSelect from '../Utils/Toolbar/ToolbarSelect';
import moment from "moment";
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';

class Orders extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        //this.props.listar()
        this.props.listarOrdenesSolicitadas(this.props.page);
        console.log(" .................................................. Ordenes solicitadas");
    }

    paidFormatter = (cell) => {
        if(cell){
            return (
                <img
                    className="action-img view"
                    src={require("../../../../assets/img/icons/check-icon-green.png")}
                    alt="check-icon"
                />
            );
        }else{
            return (
                <img
                    className="action-img"
                    src={require("../../../../assets/img/icons/history-icon-orange.png")}
                    alt="history-icon"
                />
            );
        }

    }

    estadoFormatter = (cell) => {
        if(cell === "Solicitado"){
            return <span style={{color:"#60c579"}}>Solicitado</span>
        }else{
            return <span>{cell}</span>
        }
    }

    render() {
        const {
            //listar,
            listarOrdenesSolicitadas,
            detail,
            create,
            update,
            destroy,
            customSearchOrdenesSolicitadas,
        } = this.props;


        const {
            cargando,
            page,
            data,
            isNavBarOpen,
            buscar_orden_solicitada
        } = this.props;
 
        console.log("Función de búsqueda: ", customSearchOrdenesSolicitadas);
        console.log("palabra a buscar: ", buscar_orden_solicitada)

        const options = [
            {value: 0, label: "Todas"},
            {value: 1, label: 'Enviadas'},
            {value: 3, label: 'Rechazadas'},
        ];
        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Nueva orden de compra"} ruta={"/new-purchase-order"}/>
                    <div className="table-decoration">
                        {/*
                        <div className="top-title">
                            LISTADO DE BODEGAS
                        </div>
                        */} 
                        <div className='d-flex flex-row w-100 align-items-center'>
                            <div className="top-title-toolbar w-75">
                                <ToolbarSelect
                                    titulo="LISTADO DE ORDENES ENVIADAS"
                                    buscar={this.props.searchOrdenesSolicitadas}
                                    buscador={this.props.buscador}                                
                                    options={options}
                                    valueKey="value"
                                    labelKey="label"
                                    autofocus
                                    ancho="w-50"
                                />                                                    
                            </div>
                            <div className='d-flex flex-row w-25 mr-1'>
                                <CustomToolbar
                                    buscar={customSearchOrdenesSolicitadas}
                                    buscador={buscar_orden_solicitada}
                                />
                            </div>
                        </div>

                        <Table
                            onPageChange={listarOrdenesSolicitadas}
                            data={data}
                            loading={cargando}
                            page={page}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="id">No. Orden</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="order_date" 
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                >Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                formatExtraData="provider__name" dataSort={true} dataFormat={showNestedFields}
                                dataField="name" dataSort>Proveedor</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="state_label" dataSort dataFormat={this.estadoFormatter}>Estado</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pagos_entrada" dataSort dataFormat={this.paidFormatter}>Pagado</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/new-purchase', editar_oc: '/new-purchase'})}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Orders;
