const custom_validate = (values, props) => {
    const errors = {}
    if(!values.name) {
        errors.name = 'Campo requerido';
    } else if (values.name.trim() === '') {
        errors.name = 'Debe de escribir un nombre';
    }
    if(props.urlId == 1) {
        if(!values.md_treatment) {
            errors.md_treatment = 'Campo requerido';
        } else if (values.name.trim() === '') {
            errors.md_treatment = 'Debe de escribir un valor';
        }
    }

    if(props.urlId == 5) {
        if(!values.fam_family) {
            errors.fam_family = 'Campo requerido';
        } else if (values.name.trim() === '') {
            errors.fam_family = 'Debe de escribir un valor';
        }
    }
    return errors;
};
export default custom_validate;

