import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'

function formatSubsidairy(cell){
    if (cell){
        return <span>{cell.name}</span>
    } else {
        return <span></span>
    }
}

class SmallBox extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        console.log("PROPS: ", this.props);

        this.props.listar();
    }

    render() {

        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 pt-3">
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE CAJAS CHICAS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre de la caja</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nit" dataSort>Nit</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatSubsidairy}
                                dataField="subsidiary" dataSort>Sucursal</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ adm_smbx: '/adm-smbx' })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default SmallBox;
