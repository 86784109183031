import Table from "../../Utils/Grid";

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import { activeFormatter } from '../../Utils/Acciones/Acciones';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import moment from "moment";
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";

class IngresoBodega extends Component {
    state = {
        fechaInicial: this.props.initial_date_ingresos,
        fechaFinal: this.props.final_date_ingresos,
    };

    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        this.props.listarIngresos()
    }

    setFechaInicial(value) {
        this.setState({ fechaInicial: value });
    }

    setFechaFinal(value) {
        this.setState({ fechaFinal: value });
    }

    render() {
        const {
            listarIngresos,
            setInitialDateIngresos,
            setFinalDateIngresos,
        } = this.props;

        const {
            cargando,
            page_ingresos,
            data_ingresos,
            initial_date_ingresos,
            final_date_ingresos,
        } = this.props;

        console.log("THIS.PROPS: ", this.props);
        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-2 text-uppercase text-titulo"><strong>INGRESOS A BODEGA as</strong></span>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center">
                            <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    value={this.state.fechaInicial}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            this.setFechaInicial(values.formattedValue);
                                        }                                                                                
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2 mr-2"
                                    value={this.state.fechaFinal}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            this.setFechaFinal(values.formattedValue)
                                        }                                                                                
                                    }}
                                    
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />
                                <div className="top-title-toolbar mt-3">
                                    <CustomToolbar
                                        buscar={this.props.searchIngresos}
                                        buscador={this.props.buscador_ingresos}   
                                        date = {{
                                            setInitialDate: setInitialDateIngresos,
                                            fechaInicial: this.state.fechaInicial,
                                            setFinalDate: setFinalDateIngresos,
                                            fechaFinal: this.state.fechaFinal,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Table
                            onPageChange={listarIngresos}
                            data={data_ingresos}
                            loading={cargando}
                            page={page_ingresos}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="creado"
                                dataSort
                                >Fecha de creación</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="document_number"
                                dataSort>Número</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="bodega"
                                dataSort>Bodega</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row)=>{
                                    return row.user.name;
                                }}
                                dataSort>Usuario</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"

                                dataFormat={(cell)=>{
                                    return(
                                        <Link to={`/reporte-ingresos/${cell}`}>
                                        <img
                                            src={require('../../../../../assets/img/icons/view-icon.png')}
                                            className='action-img watch'
                                        />
                                        </Link>
                                    );
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default IngresoBodega;
