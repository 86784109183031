import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import './couponStyle';

class CouponView extends Component {
    render(){
        return(
            <div className="d-flex p-2 justify-content-start coupon">
                <div className="d-flex flex-column justify-content-center align-items-center w-50">

                        {!this.props.updateData.used ? <span className="hasta general">Hasta:&nbsp;</span> : null}
                        {(this.props.updateData.amount !== null) ?
                            <NumberFormat   prefix='Q'
                                displayType={'text'}
                                thousandSeparator={true}
                                value={this.props.updateData.amount}
                                className={"amount general"}
                            />
                            :
                            <NumberFormat   suffix='%'
                                displayType={'text'}
                                thousandSeparator={true}
                                value={this.props.updateData.percentage}
                                className={"amount general"}
                            />
                        }



                    <span className="code general">{this.props.updateData.code}</span>
                    <span className="service">{this.props.updateData.service != null ? this.props.updateData.service.label : null}</span>
                    <span className="coupon-separation general" id="separacion">Expira</span>
                    <span className="expiration general">{this.props.updateData.expiration}</span>
                    <span className="name general">{this.props.updateData.name}</span>
                </div>
            </div>
        );
    }
}

export default CouponView;
