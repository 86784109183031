import classNames from 'classnames';
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
class WhearhouseAdmin extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
            isNavBarOpen,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className={classNames('content pt-3', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                        {/*
                        <div className="top-title">
                            LISTADO DE BODEGAS
                        </div>
                        */}
                        <div className="top-title-toolbar">
                            <ToolbarSimple
                                titulo="LISTADO DE BODEGAS"
                                buscar={this.props.search}
                                buscador={this.props.buscador}
                                autofocus
                            />
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="subsidiary" formatExtraData="subsidiary__name" dataFormat={showNestedFields} dataSort={true}>Sucursal</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ administrar: '/administrar-bodega' })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhearhouseAdmin;
