import React, { Component } from 'react';
import doctor from '../../../redux/modules/doctor';

export default class SurgeryHistory extends Component{
    componentDidMount = () => {
        const {patient_id, getSurgeryHistory} = this.props;
        getSurgeryHistory(patient_id);
    }

    render(){
        const {surgery_history} = this.props;
        console.log("HISTORIAL DE CIRUGÍA ***** ", surgery_history);

        return(
            <div className='d-flex flex-row justify-content-center w-100'>
                <table className='table table-bordered mt-5 surgery-history-table'>
                    <thead style={{background:'gray'}}>
                        <tr>
                            <th>Médico que atendió</th>
                            <th>Hora de inicio</th>
                            <th>Fecha</th>
                            <th>Diagnóstico que utilizó</th>
                        </tr>
                    </thead>
                    <tbody style={{background:'white'}}>
                        {surgery_history.map((surgery, index) => (
                            <tr key={index}>
                                <td>{surgery.doctor.name}</td>
                                <td>{surgery.start}</td>
                                <td>{surgery.date}</td>
                                <td>{surgery.surgery_services[0].service.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
