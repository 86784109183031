import React, { Component } from "react";
// pdf
import Header from "./Header";
import Table from "./Table";
import moment from "moment";

export default class PDF extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.getReport(this.props.match.params.id);
        }
    }

    calculateAge = (birthday) => {
        var birthday_arr = birthday.split("-");
        var birthday_date = new Date(
            birthday_arr[0],
            birthday_arr[1] - 1,
            birthday_arr[2]
        );
        var ageDifMs = Date.now() - birthday_date.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    render() {
        const { surgery_report } = this.props;

        const valid =
            !!surgery_report && surgery_report !== undefined ? true : false;

        const age = valid
            ? this.calculateAge(surgery_report.patient.birthdate)
            : 0;
        let total_extra = 0;
        if (valid) {
            surgery_report.sugery_detail_service_surgery.forEach((insumo) => {
                const subtotal =
                    (insumo.initial
                        ? insumo.used_quantity - insumo.requested_quantity
                        : insumo.used_quantity) * insumo.price;

                if (subtotal > 0) {
                    total_extra += subtotal;
                }
            });
        }

        let total_cirugia = 0;
        if (
            !!surgery_report &&
            surgery_report.movement_surgery !== undefined &&
            surgery_report.movement_surgery !== null &&
            surgery_report.movement_surgery.length
        ) {
            surgery_report.movement_surgery.forEach((mov) => {
                total_cirugia += parseFloat(parseFloat(mov.payment).toFixed(2));
            });
        }

        let diagnostico = "";
        if (
            !!surgery_report &&
            surgery_report.movement_surgery !== undefined &&
            surgery_report.movement_surgery !== null &&
            surgery_report.movement_surgery.length
        ) {
            diagnostico =
                surgery_report.movement_surgery[0].movement_detail_movement[0]
                    .service.name;
        }

        let horas = "";
        if (!!surgery_report) {
            const fin = new Date(
                new Date().toDateString() + " " + surgery_report.end
            );
            const inicio = new Date(
                new Date().toDateString() + " " + surgery_report.start
            );
            const minutos = (fin - inicio) / 1000 / 60;
            horas = minutos / 60;
        }

        return (
            <React.Fragment>
                <div
                    className="dont-print"
                    style={{
                        display: "flex",
                        justifyContent: "right",
                        margin: "20px 20px 20px 0px",
                    }}
                >
                    <button
                        className="btn btn-primary ml-3 mr-3"
                        style={{
                            maxWidth: "100px",
                        }}
                        onClick={() => print()}
                    >
                        IMPRIMIR
                    </button>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "800px",
                            height: "100%",
                            backgroundColor: "white",
                        }}
                    >
                        {surgery_report && (
                            // <PDFViewer style={{ width: "100%", height: "99vh" }}>
                            <div>
                                <Header
                                    data_pdf={{
                                        medico: valid
                                            ? surgery_report.doctor.name
                                            : "Sin dato",
                                        nombre_paciente: valid
                                            ? surgery_report.patient
                                                  .first_name +
                                              " " +
                                              surgery_report.patient.last_name
                                            : "Sin dato",
                                        diagnostico: diagnostico,
                                        fecha: valid
                                            ? moment(
                                                  surgery_report.date
                                              ).format("DD-MM-YYYY")
                                            : "Sin dato",
                                        edad: age,
                                        genero:
                                            valid &&
                                            surgery_report.patient.gender == 1
                                                ? "Masculino"
                                                : surgery_report.patient
                                                      .gender == 2
                                                ? "Femenino"
                                                : "Sin definir",
                                        ojo: valid
                                            ? surgery_report.eye
                                            : "Sin dato",
                                        hora: valid
                                            ? surgery_report.start
                                            : "0",
                                        horas: horas,
                                        total: total_extra,
                                        movement_surgery:
                                            surgery_report.movement_surgery,
                                        institucion: surgery_report.institution,
                                        referent_name:
                                            surgery_report.referent_name,
                                        anotaciones: valid
                                            ? surgery_report.annotations
                                            : "Sin anotaciones",
                                        lenses_number: valid
                                            ? surgery_report.lenses_number
                                            : "Sin número de lentes",
                                        is_surgery:
                                            valid && surgery_report.is_surgery,
                                    }}
                                />
                                <Table
                                    observaciones={
                                        valid ? surgery_report.observations : ""
                                    }
                                    total={total_extra}
                                    data_pdf={
                                        surgery_report &&
                                        surgery_report.sugery_detail_service_surgery &&
                                        surgery_report.sugery_detail_service_surgery
                                    }
                                />
                            </div>

                            // </PDFViewer>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
