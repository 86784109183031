import React, { Component } from 'react';
import { PrintContainer, actions as printActions } from '../../Utils/Print';
import MyModal from '../../Utils/myModal';
import Formato from './Format';
// import PaymentForm from "../../purchase_order/payment/paymentForm";
import PaymentForm from '../../purchase_order/payment/paymentEntryForm';

const returnIconWhite = require('../../../../../assets/img/icons/return-icon-white.png');
const printIconWhite = require('../../../../../assets/img/icons/print-icon-white.png');
const cancelIconWhite = require('../../../../../assets/img/icons/cancel-icon-white.png');
const historyIconWhite = require('../../../../../assets/img/icons/history-icon-white.png');
const cobrarIconBlue = require('../../../../../assets/img/icons/cobrar-icon-blue.png');

class ViewEntry extends Component {
    componentWillMount = () => {
        if (this.props.match.params.id) {
            this.props.viewOneEntry(this.props.match.params.id);
            this.props.chargeEntryPayments(this.props.match.params.id);
        }
    }

    render() {
        const { entry_detail, payments } = this.props;
        const { addEntryPayment } = this.props;

        console.log('Detalle entrada: ', entry_detail);

        const total_entrada = entry_detail ? parseFloat(parseFloat(entry_detail.total).toFixed(2)) : 0;
        const total_pagado = entry_detail ? parseFloat(parseFloat(entry_detail.paid).toFixed(2)) : 0;
        const total_retencion = entry_detail ? parseFloat(parseFloat(entry_detail.retention_amount).toFixed(2)) : 0;
        const descuento = entry_detail ? parseFloat(parseFloat(entry_detail.discount).toFixed(2)) : 0;
        const label_factura = entry_detail && entry_detail.entrymovex_movement && 
            entry_detail.entrymovex_movement.length == 1 && 
            entry_detail.entrymovex_movement[0].spending_type.name.includes("RETENCION") ?
            "FORMULARIO" : "FACTURA"

        const total = parseFloat((total_entrada - total_pagado - total_retencion - descuento).toFixed(2));

        return (
            <div className="content">
                <div className="d-flex">
                    <div className="table-decoration w-35 h-100 justify-content-center mx-5 mt-4 mb-3 d-flex flex-column no-print">
                        <div className="d-flex justify-content-around align-items-center title-print-view p-4">
                            <strong>Detalle de Entrada</strong>
                        </div>
                        <div className="d-flex flex-column pt-2 pl-5 pr-5 fac-content">
                            <div className="d-flex p-2 justify-content-end">
                                <a href="/#/to-pay"><img className="action-img print" title="Regresar" src={returnIconWhite} alt="Regresar" /></a>

                                {(total > 0 && !!entry_detail  && (entry_detail.purchase == null  || entry_detail.purchase.paid === 2 || entry_detail.is_a_retention == true)) && (
                                    <MyModal
                                        isOpen={false}
                                        title="MÉTODOS DE PAGO"
                                        formId="form_payment"
                                        imageButton={<img className="action-img receivable" title="Pagar" src={cobrarIconBlue} alt="Pagar" />}
                                        classToggleButton="d-block h-100 button-service-icon"
                                        body={(
                                            <PaymentForm 
                                                total={total}
                                                onSubmit={addEntryPayment}
                                                id_purchase={this.props.match.params.id}
                                                label_factura={label_factura}
                                                url_retorno={'to-pay'}
                                                //retiene={entry_detail.purchase.provider.retention}
                                                retiene={!!entry_detail.purchase ? entry_detail.purchase.provider.retention : entry_detail.provider.retention}
                                                solicitar_factura={!!entry_detail.bill_number ? false : true}
                                            />
                                        )}
                                    />
                                )}

                                {!!entry_detail && (entry_detail.purchase == null || entry_detail.purchase.paid === 2 || entry_detail.is_a_retention == true) && (
                                    <a href={`/#/view-entry-order/${this.props.match.params.id}/payment-history`}>
                                        <img className="action-img history-icon" title="Abonos" src={historyIconWhite} alt="Pagos" />
                                    </a>
                                )}

                                <a onClick={() => this.props.cancelEntry(this.props.match.params.id)}>
                                    <img className="action-img delete" title="anular" src={cancelIconWhite} alt="anular" />
                                </a>

                                <img
                                    className="action-img print"
                                    title="Imprimir"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        printActions.print('facturas');
                                    }}
                                    src={printIconWhite}
                                    alt="Imprimir"
                                />
                            </div>
                        </div>
                        <div className="pr-5 pl-5">
                            <PrintContainer name="facturas" className="impresion-venta">
                                {!!entry_detail && (
                                    <Formato factura={entry_detail} payments={payments} />
                                )}
                            </PrintContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewEntry;
