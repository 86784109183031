import classNames from 'classnames';
import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { api } from "api";

import { BreakLine } from '../../Utils/tableOptions';
import '../../whearhouse/administration/whearhouse';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderField, renderSearchSelect, renderDatePicker, modalTextArea, renderFieldCheck, dateInput }  from '../../Utils/renderField/renderField';
import ToolbarTab from '../../Utils/Toolbar/ToolbarTab';
import { BootstrapTable } from 'react-bootstrap-table';

import { RenderCurrency } from  '../../Utils/renderField/renderReadField';
import { renderFieldDateFormat  } from  '../../Utils/renderField/renderField';
import DatePicker from '../../Utils/DatePicker/datePickerCustom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import MyModal from '../../Utils/myModal';
import PaymentForm from '../payment/paymentForm';

import '../purchase_order.css';

let providers=[];
const getProviders = (search)  =>{
    return api.get("providers/", {search}).catch((error) => {})
        .then((data) => {
            if(data){
                providers = [];
                data.results.forEach(x=>{
                    providers.push({value: x.id, label: x.name})
                })
                return providers;
            }
            else{
                return [];
            }
        })
        .catch(e => {
            return [];
        })
}

let bodegas=[];
const getBodegas = (search) => {
    return api.get("whearhouse/obtenerBodegas", {search}).catch((error) => {})
        .then((data) => {
            if(data){
                bodegas = [];
                //data.results.forEach(x=>{
                data.forEach(x=>{
                    bodegas.push({value: x.id, label: x.name})
                })
                return bodegas;
            }
            else{
                return [];
            }
        })
        .catch(e => {
            return [];
        })
}

class NuevaOrden extends Component {
    componentWillMount() {
        if(this.props.match.params.id){
            this.props.getPurchaseOrder(this.props.match.params.id, true);
        }


        this.props.listarServicios();

        const {
            initializeOrderForm
        } = this.props;

        initializeOrderForm();
    }

    addToCar = (data, row, cell) => {
        return(
            <button onClick={() => {this.props.addServiceToListOrder(row, 0)}} className="circle-button-car">
                 <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon" />
            </button>
        );
    }

    deleteFormatter = (row, cell, enumObject, index) => {
       return ("");
    }

    cantidadFormatter = (cell, row, enumObject, index) => {
        const cant = row.cantidad_ingresa;
        return(
            <React.Fragment>
                <div className="group-button-up-down">
                    <button className="button-content left-tab" onClick={() => {this.props.upDownCantEntry(index, false,0)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                    <NumberFormat className="input-cant text-left" decimalScale={0} value={cant} onValueChange={({value}) => {
                        this.props.setCantEntry(index, value)
                    }} />
                    <button className="button-content right-tab" onClick={() => {this.props.upDownCantEntry(index, true,0)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                </div>
            </React.Fragment>
        );
    }

    notaFormatter = (cell, row, enumObject, index) => {
        return(
            <input
                type="text"
                onClick={(e)=>{
                    const value = e.target.value;
                    this.props.setNote(value);
                    }
                }
            />
        );
    }

    precioFormatter = (cell, row, enumObject, index) => {
        const id = row.id;
        const precio = row.precio;

        console.log("Row en la orden: ", row);

        return(
            <React.Fragment>
                <NumberFormat value={precio} prefix='Q ' thousandSeparator={true} className="oc_costo"
                decimalScale={3}
                    onValueChange={(values) => {
                        const {value} = values;
                        const valor = (value === '') ? 0 : value;
                        this.props.setPrice(index, valor, true);
                    }}
                />
            </React.Fragment>
        );
    }

    loteFormatter = (cell, row, enumObject, index) => {
        return(
            <React.Fragment>
            <div className="d-flex flex-column justify-content-center align-items-center">
            <input type="text" className="input-ingreso-bodega-lote w-100" value={cell}
                onChange={(e) =>{
                    this.props.setLote(index, e.target.value)
                }}
            />

            </div>
            </React.Fragment>
        );
    }

    vencimientoFormatter = (cell, row, enumObject, index) => {
        return (
            <React.Fragment>
                {/*
                <DatePicker
                    isDisabled={false}
                    value={cell}
                    //maxDate={maxDate}
                    //minDate={minDate}
                    className={'date-pricker-ingreso'}
                    onChange={(date) => {
                        this.props.setVencimiento(index, date);
                    }}
                />
                */}

                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                    className="ml-2 mr-2"
                    value={cell}
                    onValueChange={(values) => {
                        const dia = values.formattedValue.indexOf("D")
                        const mes = values.formattedValue.indexOf("M")
                        const anio = values.formattedValue.indexOf("Y")

                        if((dia + mes + anio) < 0){
                            this.props.setVencimiento(index, values.formattedValue)
                        }
                    }}

                    onFocus={(e) => {
                        e.target.setSelectionRange(0,10);
                    }}
                />

                {row.vencimiento_error &&
                    <span>dato inválido</span>
                }
                </React.Fragment>
            );
    }

    subtotalFormatter = (cell, row) => {
        return <RenderCurrency value={cell}/>;
    }

    barcodeFormatter = (cell) => {
        return <span className="barcode-service-list">{cell}</span>
    }

    render() {
        const {
            listarServicios,
            purchaseAuthorization,
            isNavBarOpen,
        } = this.props;


        const {
            id_purchase_order,
            data_service,
            cargando,
            page_service,
            cargando_service,
            total_in_list,
        } = this.props;

        return (
            <React.Fragment>
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                    <div className="table-decoration row mx-5 mt-3 mb-3 d-flex">
                        <div className="row-fluid w-100 mt-2 mb-1">

                        <form className="py-3 px-3" name="in-bodega" id="in-bodega">
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <div className="mt-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="provider" className="m-0">Proveedor</label>
                                    </div>
                                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field
                                            name="provider"
                                            valueKey="value"
                                            labelKey="label"
                                            label="provider"
                                            component={renderSearchSelect}
                                            loadOptions={getProviders}
                                            className="no-border"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="spending_type" className="m-0">Tipo de gasto</label>
                                    </div>
                                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field
                                            name="spending_type"
                                            valueKey="value"
                                            labelKey="label"
                                            label="spending_type"
                                            component={renderSearchSelect}
                                            disabled={true}
                                            // loadOptions={getSpendingTypes}
                                            className="no-border"
                                            autoFocus
                                        />
                                    </div>
                                </div>

                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="date" className="m-0">Fecha</label>
                                    </div>
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field name="date" component={dateInput} className="form-control" isDisabled={true} />
                                    </div>
                                </div>

                                <div className="pl-4 d-flex flex-row">
                                    <Field
                                        name="check_ingreso"
                                        component={renderFieldCheck}
                                        type="text"
                                        className="form-control m-0"
                                        onChange={(e)=>{
                                            this.props.setAllEntry(e.target.checked);
                                        }}
                                        label={"¿Ingresa todo?"}
                                    />
                                </div>
                            </div>

                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center w-">
                                <div className="m-2 w-60">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="note" className="m-0">Anotaciones u otros comentarios</label>
                                    </div>
                                    <Field
                                        name="note"
                                        valueKey="value"
                                        labelKey="label"
                                        component={modalTextArea}
                                        className="ingreso-textarea"
                                        disabled={true}
                                    />
                                </div>

                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="date" className="m-0">Número de Factura</label>
                                    </div>
                                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field
                                            name="bill_number"
                                            component="input"
                                            type="text"
                                            className='form-control'
                                        />
                                    </div>
                                </div>

                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="fecha_fac" className="">FECHA FACTURA</label>
                                    </div>
                                    <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field name="fecha_fac"  component={renderFieldDateFormat} />
                                    </div>
                                </div>

                                <div className="m-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="bodega" className="m-0">Bodega</label>
                                    </div>
                                    <Field
                                        name="bodega"
                                        valueKey="value"
                                        labelKey="label"
                                        component={renderSearchSelect}
                                        loadOptions={getBodegas}
                                        className="no-border"
                                        addClass="oc-select-modal"
                                        autofocus
                                    />
                                </div>

                            </div>
                        </form>
                        </div>


                    <div className="d-flex flex-row w-100 justify-content-end p-2 pr-5">
                        <span className="oc-total-price"><b>Monto total: </b><RenderCurrency value={this.props.total_in_list}/></span>
                    </div>

                    <div className="table-responsive-sm">
                        <BootstrapTable data={this.props.productsInList}
                            maxHeight={350}
                            minHeight={350}
                            trClassName="customClass"
                            headerStyle={ { textAlign: 'center' } }
                        >
                            <TableHeaderColumn width='100' dataField='name'>Producto</TableHeaderColumn>
                            <TableHeaderColumn width='65' dataField='cantidad'>Enviados</TableHeaderColumn>
                            <TableHeaderColumn width='65' dataField='cantidad_recibida'>Total recibido</TableHeaderColumn>
                            <TableHeaderColumn width='100' dataField='cantidad_ingresa'
                                dataFormat={this.cantidadFormatter}
                            >Recibidos</TableHeaderColumn>

                            <TableHeaderColumn width='180' dataField='lote'
                                dataFormat={this.loteFormatter}
                            >Lote</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='vencimiento'
                                dataFormat={this.vencimientoFormatter}
                            >Vencimiento</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='precio'
                                dataFormat={this.precioFormatter}
                            >Costo</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='total'
                                dataFormat={this.subtotalFormatter}
                            >Subtotal</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='nota'
                                dataFormat={this.notaFormatter}
                            >Notas</TableHeaderColumn>

                            <TableHeaderColumn width='50' dataField='id' isKey
                                dataFormat={this.deleteFormatter}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                    {this.props.total_in_list > 0 &&
                    <div className="d-flex w-100 justify-content-end align-items-center p-3 bodega-ingreso-footer oc-background-label">
                        <div className="d-flex element-footer-bodega">Monto Total</div>
                        <div className="d-flex element-footer-bodega"><RenderCurrency value={total_in_list} /></div>
                    </div>
                    }

                    <div className="d-flex justify-content-end align-items-end h-100 w-100 p-3">
                        <a className="btn btn-secondary btn-sm ml-2" href="/#/pending-orders/">Cancelar</a>
                        <button className="btn btn-primary btn-sm ml-2" onClick={()=>this.props.finishEntry()}>Finalizar Ingreso</button>
                    </div>

                </div>
            </div>
            </React.Fragment>
        )
    }
}

NuevaOrden = reduxForm({
    form: 'nuevaOrden',

   validate: data => {
    const errors={};

     if(!data.provider){
         errors.provider = 'Campo requerido';
     }

     if(data.provider == null){
        errors.provider = 'Campo requerido';
     }

     if(!data.document_number){
         errors.document_number = 'Campo requerido';
     }

     return errors;
    }
 })(NuevaOrden);

const selector = formValueSelector('nuevaOrden');

NuevaOrden = connect(state => {
    let form_validate = true;


    const provider = selector(state, "provider")
    const date = selector(state, "date");
    if(provider === undefined || provider === null){
        form_validate = false;
    }

    if(date === undefined || provider === null){
        form_validate = false;
    }

    return {
        form_validate
    };

})(NuevaOrden);

export default NuevaOrden;
