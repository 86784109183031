import moment from "moment";
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { showNestedFields } from '../Utils/MyOptions';
import NumberFormat from "react-number-format";
import {RenderCurrency} from "../Utils/renderField/renderReadField";

function formatCellDate(cell){
    if (cell){
        return <span>{moment(cell).format('DD/MM/YYYY')}</span>
    } else {
        return <span></span>
    }
}
class Expense extends Component {
    state = {
        fechaInicial: this.props.initial_date_ajustes,
        fechaFinal: this.props.final_date_ajustes,
    };

    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar();
    }
    existeFecha(fecha){
        var fechaf = fecha.split("/");
        var d = fechaf[0];
        var m = fechaf[1];
        var y = fechaf[2];
        return m > 0 && m < 13 && y > 1900 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
    }

    setFechaInicial(value) {
        this.setState({ fechaInicial: value });
    }

    setFechaFinal(value) {
        this.setState({ fechaFinal: value });
    }
    
    render() {

        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            set_initial_date,
            set_final_date
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto={"Registrar Gasto"}
                        ruta={"/income-expense/create"}
                    />
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo"><strong>LISTADO GASTOS</strong></span>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    style={{minWidth: 125}}
                                    value={initial_date}
                                    onValueChange={(values) => {                                        
                                        if(this.existeFecha(values.formattedValue)){
                                            this.setFechaInicial(values.formattedValue);
                                        }                                                                                
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2"
                                    style={{minWidth: 125}}
                                    value={final_date}
                                    onValueChange={(values) => {                                        
                                        if(this.existeFecha(values.formattedValue)){
                                            set_final_date(values.formattedValue)
                                        }                                                                                 
                                    }}
                                    
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />
                                <button
                                    className="ml-2 btn-primary btn-sm"
                                    onClick={()=>{
                                        this.state.fechaInicial && set_final_date(this.state.fechaFinal)
                                        this.state.fechaFinal && set_final_date(this.state.fechaFinal)
                                    }}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name"
                                formatExtraData="spending_type__name"
                                dataSort={true} dataFormat={showNestedFields}
                                >Tipo de gasto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell)=>{
                                    return <RenderCurrency value={cell} />
                                }}
                                dataField="amount" dataSort>Monto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="payment_type_label" dataSort>Modo de pago</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatCellDate}
                                dataField="document_date" dataSort>Fecha Pago</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="document_number_payment" dataSort>No. Factura</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatCellDate}
                                dataField="document_date_payment" dataSort>Fecha Factura</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="document_number_entry" dataSort>No. Doc. Ingreso</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/income-expense' })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Expense;
