import React from 'react';
import { renderField, renderTextArea } from '../Utils/renderField/renderField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { connect } from 'react-redux'

export const simpleInput = (props) => {
    return(
        <input type="text" value="Algo" />
    );
}

let initialValues = {};

let FormConfirmCreditNote = (props) => {
    const {
        handleSubmit,
        movement_id,
        openBox,
    } = props;
    initialValues.movement_id = movement_id;

    return (
    <form onSubmit={handleSubmit} id="formulario_nota_credito" className="py-3 px-5">
        <Field name="movement_id" component={renderField} type="hidden"/>

        <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <div className="m-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="name" className="m-0">Motivo de la nota de crédito</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="reason" component={renderField} type="text" className="form-control"/>
                </div>
            </div>
        </div>
    </form>
    );
}

FormConfirmCreditNote = reduxForm({
    form: 'FormConfirmCreditNote',
    initialValues,
    validate: data => {
         return validate(data, {
             'reason': validators.exists()('Campo requerido.'),             
        })
    }
 })(FormConfirmCreditNote);

export default FormConfirmCreditNote;