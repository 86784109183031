import Table from '../Utils/Grid';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import { formatoMoneda, formatoPorcentaje } from '../Utils/renderField/renderReadField';
import ToolbarSelect from '../Utils/Toolbar/ToolbarSelect';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import ToolbarSearchSelect from '../Utils/Toolbar/ToolbarSearchSelect';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from "moment";
import NumberFormat from "react-number-format";

class SurgeryReport extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        //this.props.setUpdateData();
        this.props.listarReporte()
    }

    render() {
        const {
            listarReporte,
            anularRegistroCirugia,
            set_final_date,
            set_initial_date,
        } = this.props;


        const {
            cargando,
            page,
            data,
            data_report,
            initial_date,
            final_date,
            page_report,
        } = this.props;

        const options = [
            {value: 0, label: "Todas"},
            {value: 1, label: "Pendientes"},
            {value: 2, label: "Realizadas"},
        ];

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 pt-5">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">
                            <div className="top-title-toolbar mt-3 d-flex  flex-fluid">
                                <span className="ml-1 text-uppercase text-titulo"><strong>REPORTE DE CIRUGÍAS</strong></span>
                            </div>

                            <div className="d-flex flex-row justify-content-end align-items-center w-75">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    value={initial_date}
                                    onValueChange={(values) => {
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            set_initial_date(values.formattedValue);
                                        }
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2 mr-2"
                                    value={final_date}
                                    onValueChange={(values) => {
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            set_final_date(values.formattedValue);
                                        }
                                    }}

                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}
                                />
                                <div className="top-title-toolbar">
                                    <ToolbarSearchSelect
                                        buscar={this.props.searchReport}
                                        buscador={this.props.buscador}

                                        buscarSelect={this.props.filtroReporte}
                                        buscadorSelect={this.props.filtro_todays_surgery}
                                        options={options}
                                        valueKey="value"
                                        labelKey="label"
                                        autofocus
                                    />
                                </div>
                            </div>
                        </div>
                        <Table
                            onPageChange={listarReporte}
                            data={data_report}
                            loading={cargando}
                            page={page_report}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort
                                dataFormat={(cell)=>{
                                    return cell ? cell.first_name + ' ' + cell.last_name : '---';
                                }}
                                >Paciente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="doctor"
                                dataSort
                                dataFormat={(cell)=>{
                                    return cell.name;
                                }}
                                >Médico asignado</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="phone_holder"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return (
                                        <React.Fragment>
                                            {row.patient != null ? <React.Fragment><span>tel: {row.patient.phone}</span><br/> </React.Fragment>: null}
                                            {row.patient != null ? <React.Fragment><span>cel: {row.patient.cellphone}</span></React.Fragment> : null}
                                        </React.Fragment>
                                    );
                                }}
                                >Contacto</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="date"
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                >Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="date"
                                dataFormat={(cell, row)=>{
                                    return (
                                        <React.Fragment>
                                            <span>de {row.start} </span> <br/>
                                            <span>a&nbsp;&nbsp; {row.end}</span>
                                        </React.Fragment>
                                    );
                                }}
                                dataSort>Horario</TableHeaderColumn>


                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell)=>{
                                    return (
                                        <React.Fragment>
                                            <a href={'/#/detalle-cirugia/' + cell}>
                                                <img
                                                    className="action-img watch"
                                                    title="Ver"
                                                    src={require("../../../../assets/img/icons/view-icon.png")}
                                                    alt="Ver"
                                                />
                                            </a>

                                            <img
                                                className="action-img delete"
                                                title="Eliminar Registro"
                                                src={require("../../../../assets/img/icons/delete-icon.png")}
                                                alt="Eliminar"
                                                onClick={()=>anularRegistroCirugia(cell)}
                                            />
                                        </React.Fragment>
                                    );
                                }}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default SurgeryReport;
