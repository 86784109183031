import {api} from 'api';
import React from "react";
import { Link } from 'react-router-dom';
import { Field, reduxForm } from "redux-form";
import { renderField, renderDatePicker, renderSearchSelect, renderImagePicker, renderMyUpload } from "Utils/renderField/renderField";

export const validate = values => {
    const errors = {};
    if(!values.date){
        errors.name = 'Campo requerido';
    }

    return errors;
}

let InformationSheetForm = props => {
    const { handleSubmit, setFiles, openModalScan, cargando } = props;

    return (
        <form
            onSubmit={handleSubmit}
            className="pb-3 pt-5 d-flex justify-content-center"
        >
            <div className="form-body-width">
                <Field name='id' component='input' type='hidden'/>
                <Field name='appointment_id' component='input' type='hidden' />
                <Field name='patient_id' component='input' type='hidden'/>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">
                                Archivos (imágenes o PDF)
                            </label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="files" setFiles={setFiles} type='file' component={renderMyUpload}/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className='d-flex flex-column justify-content-center'>
                            <label>Fecha</label>
                            <Field name="date" component={renderDatePicker} type="text"/>
                        </div>
                    </div>
                </div>
                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button
                            className='btn btn-secondary btn-sm'
                            onClick={()=>openModalScan(false)}
                            type='button'
                        >Cancelar</button>
                        {cargando==false 
                        ?
                        <button type="submit" className="btn btn-primary btn-sm m-3">Guardar</button>
                        :""}
                    </div>
                </div>
            </div>
        </form>
    );
};

InformationSheetForm = reduxForm({
    form: "InformationSheet",
    validate
})(InformationSheetForm);

export default InformationSheetForm;
