import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {renderField, renderSwitch} from 'Utils/renderField/renderField';
import custom_validate from './validation';

let Form = props => {
    const {handleSubmit, change_password} = props;
    return (
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div
                className="px-md-5 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div
                        className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="first_name" className="">Nombre*</label>
                    </div>
                    <div
                        className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="first_name" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div
                        className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="username" className="m-0">Username</label>
                    </div>
                    <div
                        className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="username" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div
                className="px-md-5 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div
                        className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="change_password" className="m-0">¿Cambiar contraseña?</label>
                    </div>
                    <div
                        className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="change_password" component={renderSwitch} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            {
                change_password && (
                    <React.Fragment>
                        <div
                            className="px-md-5 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <div className="m-2">
                                <div
                                    className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="password_old" className="m-0">Contraseña antigua</label>
                                </div>
                                <div
                                    className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field name="password_old" component={renderField} className="form-control" type="password"/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="px-md-5 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <div className="m-2">
                                <div
                                    className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="password" className="m-0">Contraseña</label>
                                </div>
                                <div
                                    className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field name="password" component={renderField} className="form-control" type="password"/>
                                </div>
                            </div>
                            <div className="m-2">
                                <div
                                    className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="password_confirm" className="m-0">Confirmar contraseña</label>
                                </div>
                                <div
                                    className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field name="password_confirm" component={renderField} className="form-control" type="password"/>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
            <div className="row-fluid">
                <div
                    className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-4" to="/home">Cancelar</Link>
                    <button type="submit" className="btn btn-primary m-3">Guardar</button>
                </div>
            </div>
        </form>
    );
};

Form = reduxForm({
    form: 'profile',
    validate: custom_validate
})(Form);


Form = connect(state => {
    return {
        change_password: (state.form.profile && state.form.profile.values ? state.form.profile.values.change_password : false)
    };
})(Form);
export default Form;
