import React from "react";
import PropTypes from "prop-types";

function InputSearch({ label, sytleContainer, inputVal, setInputVal, name }) {
    return (
        <div
            className={`form-group has-feedback flex-shrink-1 ml-2 ${sytleContainer}`}
        >
            <label className="control-label">{label}</label>
            <input
                name={name}
                className="form-control"
                placeholder="Buscar... "
                type="text"
                value={inputVal}
                onChange={(e) => {
                    setInputVal(e.target.value);
                }}
            />
        </div>
    );
}

InputSearch.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sytleContainer: PropTypes.string,
    inputVal: PropTypes.string.isRequired,
    setInputVal: PropTypes.func.isRequired,
};

export { InputSearch };
