import React, {Component} from "react";
import moment from 'moment';
import Footer from '../DiagnosticOrder/DiagnosticTranscription/Footer';
import '../DiagnosticOrder/DiagnosticTranscription/transcription_style';
import ReactAudioPlayer from 'react-audio-player';
import Spoiler from '../Utils/Spoiler';

export default class DiagnosticTranscriptionForm extends Component{
    render(){
        const { handleSubmit, diagnostic } = this.props;

        console.log("diagnostic: ", diagnostic);

        return (
            <div className='d-flex flex-column w-100 justify-content-center align-items-center diagnostic-card-container'>
                {/*
                <div className='d-flex flex-column flex-wrap mt-2 mb-2 w-75'>
                {diagnostic && diagnostic.diagnostic_service_diagnostic.map((procedimiento, i) =>
                        <Spoiler key={i} show={true} label={procedimiento.service.name}>
                                {procedimiento.diagnostic_service_diagnostic_file.map((imagen) =>
                                    <img
                                        key={imagen.id}
                                        title="Imágen diangnóstico"
                                        src={imagen.imagen}
                                        style={{width:100, height:100, marginRight:20}}
                                    />
                                )}
                        </Spoiler>
                )}
                </div>

                <ReactAudioPlayer
                    src={diagnostic.audio}
                    controls
                    style={{outline: 'none'}}
                />
                */}

                <div className='d-flex flex-column w-75 justify-content-center pt-4 pl-5 pr-5'
                    style={{background: 'white'}}
                >
                    <div className="d-flex flex-row mb-2">
                        <div className="col-9">
                            <img
                                src={require("../../../../assets/img/logo_prolaser_gris.png")}
                                alt="Prolaser_logo"
                                className="logo-transcription"
                            />
                        </div>
                        <div className="col-3">
                            <p className="parrafo-descripcion">
                                <strong>Doctor:&nbsp;</strong>
                                {diagnostic &&
                                diagnostic.referent
                                    ? diagnostic.referent.name
                                    : ""}
                            </p>
                            <p className="parrafo-descripcion">
                                <strong>Paciente:&nbsp;</strong>
                                {diagnostic &&
                                diagnostic.patient
                                    ? diagnostic.patient.first_name +
                                    " " +
                                    diagnostic.patient.last_name
                                    : ""}
                            </p>
                            <p className="parrafo-descripcion">
                                <strong>Fecha:&nbsp;</strong>
                                {diagnostic ?
                                        moment(diagnostic.created).format(
                                            "DD/MM/YYYY"
                                        )
                                    : ""}
                            </p>
                        </div>
                    </div>

                        <textarea className='annotations w-100 diagnostic-card-ttt'>
                            {diagnostic ? diagnostic.transcription : ''}
                        </textarea>

                    <Footer />
                </div>
            </div>
        );
    }
};
