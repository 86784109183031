import React, {Component, useReducer} from 'react';
import { Link } from 'react-router-dom';

class DetalleBajaBodega extends Component{

    componentWillMount = () => {
        if(this.props.match.params.id){
            this.props.listarDetallesReajustes(this.props.match.params.id);
        }
    }

    render(){
        const {
            cargando,
            data_detalles_reajustes
        } = this.props;
        return(
            <div className="content">
                <div className="m-auto row-fluid w-100 py-4">
                    <Link to="/reporte-bajas">
                        <div className="d-flex align-items-center ml-3 mr-3 back-blue-arrow"/>
                    </Link>
                    <div className="table-decoration mt-3 p-3" style={{color:'black'}}>
                        <div className='d-flex flex-row justify-content-around reporte-cirugia-encabezado'>
                            <div className='d-flex flex-row'>
                                <div className='d-flex flex-row justify-content-around align-items-start'>
                                        <div>
                                            <label>Fecha: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_reajustes && data_detalles_reajustes.creado} disabled />
                                        </div>
                                        <div>
                                            <label>Bodega: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_reajustes && data_detalles_reajustes.bodega} disabled />
                                        </div>
                                        <div>
                                            <label>Usuario: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_reajustes && data_detalles_reajustes.user.name} disabled />
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row resporte-cirugia-separador' style={{height:30}}></div>

                        <div className='d-flex flex-row justify-content-around reporte-cirugia-insumos'>
                            <table className='table'>
                                <thead style={{background:'gray', color:'white'}}>
                                    <tr>
                                        <th>LOTE</th>
                                        <th>PRODUCTO</th>
                                        <th>CANTIDAD ANTERIOR</th>
                                        <th>CANTIDAD DISMINUIDA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data_detalles_reajustes && data_detalles_reajustes.detalles.map((detail, i) =>
                                        <tr key={i}>
                                            <td>{detail.lote}</td>
                                            <td>{detail.producto}</td>
                                            <td>{detail.cantidad_anterior}</td>
                                            <td>{detail.cantidad}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleBajaBodega
