import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderFieldCheck }  from 'Utils/renderField/renderField';
import { connect } from 'react-redux'

let TreatmentForm = props => {

    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-3">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Nombre comercial</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="commercial_name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Principio activo</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="active_principle" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Presentación</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="presentation" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Dosis</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="dose" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Aplicación</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="application" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Tiempo de duración</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="time_duration" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Frecuencia</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="frecuency" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/treatments">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

TreatmentForm = reduxForm({
   form: 'treatment',
   validate: data => {
        return validate(data, {
            'commercial_name': validators.exists()('Campo requerido.'),
       })
   }
})(TreatmentForm);

/*
const selector = formValueSelector('treatment');

TreatmentForm = connect(state => {
  
    const role = selector(state, "role.value");
    let is_referent = false;
    let no_superuser = false;

    if(role == referent_role){
        is_referent = true
    }

    if(role > 1){
        no_superuser = true;
    }
    return {
        no_superuser,
        is_referent
    };  
})(TreatmentForm);
*/
export default TreatmentForm
