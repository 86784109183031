import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import Form from "./InformationSheetForm";
import {
    ButtonBack,
    ButtonFirst,
    ButtonLast,
    ButtonNext,
    CarouselProvider,
    DotGroup,
    ImageWithZoom,
    Slide,
    Slider
} from 'pure-react-carousel';
import DiagnosticCard from './DiagnosticCard';

export default class DiagnosticHistory extends Component{
    componentWillMount = () => {
        const {getDiagnosticHistory, patient_id} = this.props;
        getDiagnosticHistory(patient_id);
    }

    render(){
        const {
            openModalScan,
            open_modal_scan,
            openModalUpdateScan,
            open_modal_update_scan,
            saveScan,
            diagnostic_history,
            patient_id
        } = this.props;

        return(
            <React.Fragment>
                <div className='d-flex flex-row justify-content-center w-100 mt-3 mb-2'>
                    {/*
                    <Modal open={open_modal_scan} onClose={() => openModalScan(false)}>
                        <Form openModalScan={openModalScan} saveScan={saveScan} patient_id={patient_id} appointment_id={0}/>
                    </Modal>
                    <div className='d-flex flex-row justify-content-end aling-items-center w-75'
                    >
                        <button
                            onClick={()=> openModalScan(true)}
                            className='btn btn-success btn-sm'
                        >Cargar Archivos</button>
                    </div>
                    */}
                </div>

                {diagnostic_history.length > 0 &&
                <CarouselProvider
                    visibleSlides={1}
                    totalSlides={diagnostic_history.length}
                    step={1}
                    naturalSlideWidth={200}
                    naturalSlideHeight={150}
                    //hasMasterSpinner

                    style={{                        
                        width: 900,
                        marginLeft: '10%',
                    }}                    
                >   
                    <div className='d-flex flex-row justify-content-center mb-2'>
                        <DotGroup />
                    </div>
                    <Slider>
                        {diagnostic_history && diagnostic_history.map((diagnostic, i) => (
                            <Slide index={i} key={i}>
                                <DiagnosticCard
                                    diagnostic={diagnostic}
                                    open_modal_update_scan={open_modal_update_scan}
                                    openModalUpdateScan={openModalUpdateScan}
                                    saveScan={saveScan}
                                    patient_id={patient_id}
                                />
                            </Slide>
                        ))}
                    </Slider>                    
                </CarouselProvider>
                }
            </React.Fragment>
        );
    }
}
