import Table from '../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../Utils/tableOptions';
import {activeFormatter} from "../Utils/Acciones/Acciones";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import AsyncSelect from 'react-select/lib/Async';
import moment from "moment";
import {api} from "../../../api/api";
import NumberFormat from "react-number-format";

const getActions = (search) => {
    return api.get("bitacora/acciones", {search}).catch((error) => {})
        .then((data) => {
            // if (data){
            //     persons = [];
            //     data.results.forEach(x=>{
            //         persons.push({value: x.id, label: x.name})
            //     })
            //     return persons
            // }
            // else{
            //     return []
            // }
            return data
        })
        .catch(e => {
            return []
        })
}


class Bitacora extends Component {

    componentWillMount = () => {
        const {listar} = this.props;
        listar();
    };


    render() {
        const {
            listar,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            set_initial_date,
            search,
            set_final_date
        } = this.props;


        return (
            <div className="content">

                <div className={"m-auto row-fluid w-100 pt-5"}>
                    <div className="" />
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo"><strong>BITÁCORA</strong></span>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    value={initial_date}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            set_initial_date(values.formattedValue);
                                        }                                                                                
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2"
                                    value={final_date}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            set_final_date(values.formattedValue);
                                        }                                                                                
                                    }}
                                    
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />
                                <AsyncSelect
                                    isClearable
                                    className="form-control p-0 border-trasparent"
                                    onChange={(e) => {
                                        if (e)
                                            search(e.action)
                                        else
                                            search("")
                                    }}
                                    autoFocus
                                    theme={(theme) => {
                                        return {
                                            ...theme,
                                            borderRadius: '1em',
                                            background: 'white',
                                            colors: {
                                                ...theme.colors,
                                                text: '#415362',
                                                primary25: '#ebebeb',
                                                primary: '#0a5995',
                                            },
                                        }
                                    }}

                                    classNamePrefix="react-select"
                                    cache={false} placeholder="Escriba para buscar"
                                    defaultOptions={ true }
                                    searchPromptText="Escriba para buscar"
                                    getOptionValue={(option) => (option['action'])}
                                    getOptionLabel={(option) => (option['action'])}
                                    loadOptions={getActions}
                                />
                            </div>
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="user"
                                dataSort>Responsable</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="action"
                                dataFormat={cell => cell.toUpperCase()}
                                dataSort>Acción</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="created"
                                dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({
                                    ver: '/bitacora',
                                })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Bitacora;
