import Table from '../Utils/Grid';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import { formatoMoneda, formatoPorcentaje } from '../Utils/renderField/renderReadField';
import ToolbarSelect from '../Utils/Toolbar/ToolbarSelect';
import moment from "moment";

class Coupon extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.setUpdateData();
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        const options = [
            {value: 0, label: "Todos"},
            {value: 1, label: "Disponibles"},
            {value: 2, label: 'Utilizados'},
            {value: 3, label: 'Vencidos'},
            {value: 4, label: 'Especiales'},
            {value: 5, label: 'Normales'},
        ];


        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar Cupón"} ruta={"/coupons/create"}/>
                    <div className="table-decoration">
                        {/*
                        <div className="top-title">
                            LISTADO DE CUPONES
                        </div>
                        */}
                        <div className="top-title-toolbar">
                            <ToolbarSelect
                                titulo="LISTADO DE CUPONES"
                                buscar={this.props.filtrarCupones}
                                buscador={this.props.buscador}
                                options={options}
                                valueKey="value"
                                labelKey="label"
                                autofocus
                            />
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="code" dataSort>Código</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="type_coupon_label" dataSort>Tipo</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="amount"
                                dataFormat={formatoMoneda}
                                dataSort>Valor</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatoPorcentaje}
                                dataField="percentage" dataSort>Porcentaje</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="percentage"
                                formatExtraData="service__name"
                                dataFormat={showNestedFields}
                                dataSort>Producto / Servicio</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="expiration"
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                >Expira</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="used"
                                dataSort
                                dataFormat={(cell) => {
                                    if(cell){
                                        return "No";
                                    }else{
                                        return "Sí"
                                    }
                                }}
                                >Disponible</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell)=>{
                                    return (
                                        <React.Fragment>
                                        <a href={"/#/coupons/" + cell + '/view'}>
                                            <img
                                                className="action-img watch"
                                                title="anular"
                                                src={require("../../../../assets/img/icons/view-icon.png")}
                                                alt="anular"
                                            />
                                        </a>
                                        <img
                                            className="action-img anulate-icon"
                                            title="anular"
                                            src={require("../../../../assets/img/icons/delete-icon.png")}
                                            alt="anular"
                                            onClick={()=>this.props.anularCupon(cell)}
                                        />
                                        </React.Fragment>
                                    );
                                }}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Coupon;
