import React, { Fragment, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Table from "../../../Utils/Grid";
import { BreakLine } from "../../../Utils/tableOptions";
import { showNestedFields } from "../../../Utils/MyOptions";
import { actions } from "../../../../../redux/modules/reportes/consolidadoGastos";
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";

function formatCellDate(cell) {
    if (cell) {
        return <span>{moment(cell).format("DD/MM/YYYY")}</span>;
    } else {
        return <span></span>;
    }
}
export const TableConsolidadoGastos = (props) => {
    const { listar, cargando, page, data } = props;

    useEffect(() => {
        listar(1);
    }, []);

    return (
        <Fragment>
            <Table
                onPageChange={listar}
                data={
                    data || {
                        count: 0,
                        next: null,
                        previous: null,
                        results: [],
                    }
                }
                loading={cargando}
                page={page}
            >
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    width="280px"
                    dataFormat={(cell, row) => {
                        // quitar " :: del string y dejar solo :"
                        return row.detail
                            ? row.detail.replace(" ::", " : ")
                            : "No hay detalle";
                    }}
                >
                    DETALLE
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="name"
                    //formatExtraData="spending_type__name"                    
                    dataSort={true}
                    isKey={true}
                    //dataFormat={showNestedFields}
                    dataFormat={(cell, row) =>{                        
                        if(row.another_spending_type)
                            return <span>{row.another_spending_type}</span>
                        else
                            return <span>{row.spending_type.name}</span>
                    }}
                >
                    Tipo de gasto
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="payment_type_label"
                    dataSort
                >
                    Modo de pago
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={formatCellDate}
                    dataField="document_date"
                    dataSort
                >
                    Fecha Pago
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    //dataField="document_number_payment"
                    dataFormat={(cell, row)=> {
                        if(row.document_number_payment)
                            return row.document_number_payment;
                        else if(row.expense_bill_number)
                            return row.expense_bill_number;
                        else 
                            return "";
                    }}
                    dataSort
                >
                    No. Factura
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    //dataFormat={formatCellDate}
                    dataFormat={(cell, row)=> {
                        if(cell)
                            return formatCellDate(cell);
                        else if(row.expense_bill_date)
                            return formatCellDate(row.expense_bill_date);
                        else
                            return '';
                        
                    }}
                    dataField="document_date_payment"
                    dataSort
                >
                    Fecha Factura
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    //dataFormat={formatCellDate}
                    dataFormat={(cell, row)=>{
                        if(row.created)
                            return formatCellDate(row.created)
                        else
                            return formatCellDate(row.creado)
                    }}
                    dataField="creado"
                    dataSort
                >
                    Fecha Creado
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataField="document_number_entry"
                    formatExtraData="document_number_entry"
                    dataFormat={showNestedFields}
                    dataSort
                >
                    NO. TRANSFERENCIA
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return row.provider && row.provider.name || "No hay proveedor";
                    }}
                >
                    Proveedor
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    formatExtraData="bank_transaction__bank_account__owner"
                    dataFormat={showNestedFields}
                >
                    USUARIO CUENTA
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    formatExtraData="bank_transaction__bank_account__account_number"
                    dataFormat={showNestedFields}
                >
                    NO. CUENTA
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    formatExtraData="small_box__nit"
                    dataFormat={showNestedFields}
                >
                    NIT DE CAJA CHICA
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    formatExtraData="small_box__name"
                    dataFormat={showNestedFields}
                >
                    CAJA CHICA
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cel, row) => {
                        if (row.provider &&
                            row.provider.retention &&
                            !row.is_expense_purchase &&
                            !row.is_a_retention
                        ) {
                            if (row.amount >= 2800) {
                                let retention_amount = (
                                    (row.amount / 1.12) *
                                    0.05
                                ).toFixed(2);
                                return (
                                    <RenderCurrency value={retention_amount} />
                                );
                            }

                        }
                        return " --- ";
                    }}
                >
                    Retencion ISR
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cel, row) => {
                        if (row.provider &&
                            row.provider.retention_iva &&
                            !row.is_expense_purchase &&
                            !row.is_a_retention
                        ) {
                            if (row.amount >= 2500) {
                                let iva_retention_amount = (
                                    (row.amount / 1.12) *
                                    0.12 *
                                    0.15
                                ).toFixed(2);
                                return (
                                    <RenderCurrency
                                        value={iva_retention_amount}
                                    />
                                );
                            }
                        }
                        return " --- ";
                    }}
                >
                    Retencion IVA
                </TableHeaderColumn>
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => {
                        return <RenderCurrency value={cell} />;
                    }}
                    dataField="amount"
                    dataSort
                >
                    Monto
                </TableHeaderColumn>
            </Table>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    ...state.reporte_consolidado_gastos,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableConsolidadoGastos);
