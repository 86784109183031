import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { RenderCurrency } from  '../Utils/renderField/renderReadField';
import {
    renderField,
    renderSearchSelect,
    modalCurrency,
    renderCurrency,
    renderSwitch,
    renderFieldDateFormat
} from '../Utils/renderField/renderField';
import { connect } from 'react-redux'
import { api } from "api";

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getSmallBoxes = (search)  =>{
    return api.get("small_box",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                let small_boxes = [];
                data.results.forEach(x=>{
                    small_boxes.push({value: x.id, label: x.name, nit: x.nit});
                })
                return small_boxes;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let initialValues = {};

let PaymentForm = (props) => {
    const {
            handleSubmit,
            id_purchase,
            url_retorno,
            retiene,
            descuento,
            solicitar_factura,
        } = props;

    initialValues.id_purchase = id_purchase;
    initialValues.url_retorno = url_retorno;

    const totalPagar = props.total;

    return (
        <form onSubmit={handleSubmit} id="paymentGroup" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="cuentas" component={"input"} type="hidden"/>
            <Field name="total" component={"input"} type="hidden"/>

            <table className="table" id="table-frm-pay-oc">
                <thead></thead>
                <tbody className="oc-body-form">
                    <tr>
                        <th className="oc-custom-table">
                            TOTAL A PAGAR
                        </th>
                        <td className="oc-custom-table">
                            {/*<RenderCurrency value={props.total} className="oc-total-pago"/>*/}
                            <RenderCurrency value={totalPagar} className="oc-total-pago"/>
                        </td>
                    </tr>
                    {/*
                    <tr>
                        <th>
                            TRANSACCIÓN
                        </th>
                        <td>
                            <Field
                                name="transaction"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    */}

                        <React.Fragment>
                        <tr>
                            <th>FECHA DE PAGO</th>
                            <td>
                                <Field
                                    name="fecha_fac"
                                    component={renderFieldDateFormat}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>NO. DOCUMENTO</th>
                            <td>
                                <Field
                                    name="no_document"
                                    component={renderField}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr className="oc-white-border"><th colSpan={2} className="oc-custom-table"><span className="pl-2">CUENTA BANCARIA</span></th></tr>
                        <tr>
                            <td colSpan={2} className="oc-custom-table">
                                <div className="d-flex flex-row justify-content-around">
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="number"
                                    label="cuenta"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="label"
                                    label="referent"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                </div>
                            </td>
                        </tr>
                        {solicitar_factura &&
                        <tr className="oc-white-border">
                            <th className="oc-custom-table-middle">
                                NO. FORMULARIO
                            </th>
                            <td className="oc-custom-table-middle">
                                <Field
                                    name="bill_number"
                                    component={renderField}
                                    type="text"
                                    className="form-control oc-input-modal"
                                    addClass="w-50"
                                />
                            </td>
                        </tr>
                        }
                        </React.Fragment>
                </tbody>
            </table>
        </form>
    );
}

PaymentForm = reduxForm({
    form: 'paymentGroup',
    initialValues,
    validate: data => {
        const errors={};
        console.log(data.transaction);

        if(!data.bank_account){
            errors.bank_account = 'Campo requerido';
        }

        if(!data.no_document){
            errors.no_document = 'Campo requerido';
        }

        return errors;
    }
 })(PaymentForm);

export default PaymentForm;
