import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '../Utils/Grid';
import MyModal from '../Utils/myModal';
import Form from './RetentionPaymentForm';
import { BreakLine } from '../Utils/tableOptions';
import { RenderCurrency } from '../Utils/renderField/renderReadField';

const addIconBlue = require('../../../../assets/img/icons/add-icon-blue.png')

class RetentionPayment extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar();
    }

    deatilFormatter = (cell, row) => {
        if (row.purchase) {
            return (
                <React.Fragment>
                    <span>Por orden de compra</span>
                    &nbsp;
                    <a
                        href={`/#/pending-orders/${row.purchase.id}/entry`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            ver
                    </a>
                </React.Fragment>
            );
        } if (row.entry_movement) {
            return (
                <React.Fragment>
                    <span>Por entrada de producto</span>
                    &nbsp;
                    <a href={`/#/view-entry-order/${row.entry_movement.id}/view`}>ver</a>
                </React.Fragment>
            );
        }

        return ' ----- ';
    }

    paidFormatter = (cell, row) => {
        const { bank_transaction: bt } = row;
        if (bt) {
            return (
                <span>
                    Transcacción bancaria de la cuenta
                    {bt.bank_account.owner}
                    No.:
                    {bt.bank_account.account_number}
                    , No. de documento
                    {bt.no_document}
                </span>
            );
        }

        return ' ----- ';
    }

    accionesFormatter = (cell, row) => {
        return (row.activo
            ? (
                <MyModal
                    isOpen={false}
                    title="AGREGAR NO. DE RETENCIÓN"
                    formId="form_retention_payment"
                    imageButton={<img className="action-img add" title="pagar" src={addIconBlue} alt="pagar" />}
                    classToggleButton="d-block h-100 button-service-icon"
                    body={(
                        <Form
                            onSubmit={this.props.addDocumentNumber}
                            initialValues={{
                                id_retention_payment: row.id,
                                document_number: row.document_number,
                                amount: row.amount,
                            }}
                        />
                    )}
                />
            ) : <span>Cancelada</span>
        );
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 pt-5">

                    <div className="table-decoration">
                        <div className="top-title">
                            RETENCIONES POR PAGAR
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="fecha_creacion"
                                dataSort
                            >
                                Fecha
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell) => { return <RenderCurrency value={cell} /> ;}}
                                dataField="amount"
                                dataSort
                            >
                                Monto
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={this.deatilFormatter}
                                dataField="id"
                                dataSort
                            >
                                Detalle
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell) => { return (cell) ? 'Pagado' : 'Pendiente de pago'; }}
                                dataField="paid"
                                dataSort
                            >
                                Detalle
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell) => { return (cell) || " ---- "; }}
                                dataField="document_number"
                                dataSort
                            >
                                No. Retención
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={this.paidFormatter}
                                dataField="bank_transaction"
                                dataSort
                            >
                                Detalle Pago
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                dataFormat={this.accionesFormatter}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default RetentionPayment;
