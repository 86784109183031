import React, { useState } from "react";

// Components
import {
    AsyncSelectInput,
    InputDate,
    InputSearch,
    TableConsolidadoGastos,
} from "./components";
import {
    promiseOptionsGastos,
    promiseOptionsProveedores,
    IconSearch,
    IconSpreadSheet,
} from "./contextConsolidado";

function ConsolidadoGastos(props) {
    const {
        listar,

        buscadorFact,
        setSearch,

        tipo_gasto,
        setTipoGasto,

        proveedor,
        setProveedor,

        initial_date,
        final_date,
        setInitialDate,
        setFinalDate,
        generarExcelConsolidadoGastos,
    } = props;

    return (
        <div className="content pt-3">
            <div className="table-decoration">
                {/* ENCABEZADO DEL MODULO */}
                <div className=" d-flex justify-content-between px-2 py-1">
                    <strong className="text-titulo p-0 m-0 mb-2">
                        CONSOLIDADO GASTOS
                    </strong>
                    <button className="btn btn-success btn-sm" onClick={generarExcelConsolidadoGastos}>
                        <IconSpreadSheet /> Generar Excel
                    </button>
                </div>
                {/* FILTROS */}
                <div className="d-flex justify-content-start px-2 py-1 flex-wrap">
                    <AsyncSelectInput
                        label={"Tipo de gasto"}
                        promiseOptions={promiseOptionsGastos}
                        selectedOptions={tipo_gasto}
                        setSelectedOptions={setTipoGasto}
                        placeholder={"Buscar nombre del tipo de gasto"}
                        withDiv={"420px"}
                    />
                    <AsyncSelectInput
                        label={"Proveedor"}
                        promiseOptions={promiseOptionsProveedores}
                        selectedOptions={proveedor}
                        setSelectedOptions={setProveedor}
                        placeholder={"Buscar nombre del proveedor"}
                        stylesContainer={"flex-fill ml-2"}
                        withDiv={"420px"}
                    />
                </div>
                <div className="d-flex justify-content-start align-items-center px-2 py-1 flex-wrap">
                    <InputDate
                        styleContainer={
                            "d-flex flex-column justify-content-center align-items-start flex-shrink-1"
                        }
                        label={"Fecha Pago"}
                        initial_date={initial_date}
                        final_date={final_date}
                        set_initial_date={setInitialDate}
                        set_final_date={setFinalDate}
                    />

                    <InputSearch
                        label={"No. Factura"}
                        name="buscadorFact"
                        inputVal={buscadorFact}
                        setInputVal={setSearch}
                    />

                    <button
                        className="btn btn-primary btn-sm p-0 m-0 ml-4"
                        style={{
                            width: "20px",
                            height: "26px",
                            display: "block",
                            position: "relative",
                            top: "5px",
                        }}
                        onClick={() => listar(1)}
                    >
                        <IconSearch /> Listar
                    </button>
                </div>

                <TableConsolidadoGastos />
            </div>
        </div>
    );
}

export { ConsolidadoGastos };
