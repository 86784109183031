import React from "react";

const Header = ({ data_pdf = {} }) => {
    let total = 0;

    return (
        <div style={{color: 'black'}}>
            {/* ENCABEZADO */}
            <div
                style={{
                    textAlign: "center",
                }}
            >
                {/* institucion */}
                <div
                    style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                    }}
                >
                    {data_pdf.institucion}
                </div>

                {/* nombre paciente */}
                <div style={{ fontSize: "16px" }}>
                    {data_pdf.nombre_paciente}
                </div>

                {/* diagnostico */}
                <div
                    style={{
                        fontSize: "16px",
                    }}
                >
                    {data_pdf.diagnostico}
                </div>

                {/* doctor */}
                <div
                    style={{
                        fontSize: "16px",
                    }}
                >
                    Dr: {data_pdf.medico}
                </div>
            </div>

            {/* RESUMEN */}
            <div
                style={{
                    display: "flex",
                }}
            >
                {/* anotaciones */}
                <div
                    style={{
                        width: "400px",
                        margin: "auto",
                        textAlign: "center",
                    }}
                >
                    <div
                        style={{
                            // fontSize: "10",
                            fontStyle: "italic",
                            fontWeight: "bold",
                            // fontWeight: 700,
                        }}
                    >
                        Anotaciones:
                    </div>
                    <div
                        style={{
                            fontSize: "10",
                            margin: "auto",
                        }}
                    >
                        {data_pdf.anotaciones
                            ? data_pdf.anotaciones
                            : "Sin anotaciones"}
                    </div>
                </div>

                {/* resumen */}
                <div
                    style={{
                        width: "400px",
                        margin: "auto",
                        textAlign: "center",
                    }}
                >
                    {data_pdf.movement_surgery[0].movement_detail_movement.map(
                        (movimiento) => {
                            total += movimiento.quantity * movimiento.price;
                            return movimiento.description.includes("Insumos") ||
                                movimiento.description.includes("insumos") ||
                                movimiento.description.includes("INSUMOS") ? (
                                <div
                                    key={movimiento.id}
                                    style={{
                                        fontSize: "10",
                                        margin: "auto",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Insumos:
                                    </span>
                                    <span> </span>
                                    {movimiento.quantity * movimiento.price}
                                </div>
                            ) : !data_pdf.is_surgery &&
                              !movimiento.description.includes("Insumos") ? (
                                <div
                                    key={movimiento.id}
                                    style={{
                                        fontSize: "10",
                                        margin: "auto",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Alquiler:
                                    </span>
                                    <span> </span>
                                    {movimiento.quantity * movimiento.price}
                                </div>
                            ) : data_pdf.is_surgery &&
                              !movimiento.description.includes("Insumos") ? (
                                <div
                                    key={movimiento.id}
                                    style={{
                                        fontSize: "10",
                                        margin: "auto",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Cirugia:
                                    </span>
                                    <span> </span>
                                    {movimiento.quantity * movimiento.price}
                                </div>
                            ) : (
                                <div
                                    key={movimiento.id}
                                    style={{
                                        fontSize: "10",
                                        margin: "auto",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: 700,
                                        }}
                                    >
                                        Pago:
                                    </span>
                                    <span> </span>
                                    {movimiento.quantity * movimiento.price}
                                </div>
                            );
                        }
                    )}

                    <div
                        style={{
                            width: "100px",
                            margin: "auto",
                            borderBottom: "1.5px solid black",
                        }}
                    />

                    <div
                        style={{
                            fontSize: "10",
                            margin: "auto",
                        }}
                    >
                        <span
                            style={{
                                fontStyle: "italic",
                                fontWeight: 700,
                            }}
                        >
                            TOTAL:
                        </span>
                        <span> </span>
                        {total.toFixed(2)}
                    </div>
                </div>
            </div>

            {/* DATOS */}
            <span style={{ fontWeight: "bold" }}>Datos de la consulta:</span>
            <div style={{ display: "flex" }}>
                <div
                    style={{
                        width: "200px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        Fecha:
                    </span>
                    <span> </span>
                    {data_pdf.fecha}
                </div>

                <div
                    style={{
                        width: "200px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Hora:</span>
                    <span> </span>
                    {data_pdf.hora}
                </div>

                <div
                    style={{
                        width: "200px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Horas:</span>
                    <span> </span>
                    {data_pdf.horas}
                </div>

                <div
                    style={{
                        width: "200px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>No. Lente:</span>
                    <span> </span>
                    {data_pdf.lenses_number}
                </div>
            </div>

            <div style={{ display: "flex" }}>
                <div
                    style={{
                        width: "200px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Ojo:</span>
                    <span> </span>
                    {data_pdf.ojo}
                </div>
                <div
                    style={{
                        width: "200px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Referente:</span>
                    <span> </span>
                    {data_pdf.referent_name}
                </div>
            </div>

            <span style={{ fontWeight: "bold" }}>Datos del paciente:</span>

            <div style={{ display: "flex" }}>
                <div
                    style={{
                        width: "320px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Nombre:</span>
                    <span> </span>
                    {data_pdf.nombre_paciente}
                </div>

                <div
                    style={{
                        width: "250px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Edad:</span>
                    <span> </span>
                    {data_pdf.edad}
                </div>

                <div
                    style={{
                        width: "250px",
                        color: "#212529",
                        fontSize: "10",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>Sexo:</span>
                    <span> </span>
                    {data_pdf.genero}
                </div>
            </div>

        </div>
    );
};

export default Header;
