import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { TablePayments } from './table_payments';
import Spoiler from '../Utils/Spoiler';

class PaymentsHistory extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount(){
        const movement_id = this.props.match.params.id
        this.props.chargePayments(movement_id);
    }

    cancelPayment = (id, codigo, referencia_retorno) => {
        this.props.cancelPayment(id,codigo, referencia_retorno);
        const movement_id = this.props.match.params.id;

        setTimeout(() => {
            this.props.chargePayments(movement_id);
            this.forceUpdate();
            console.log(" -------- ****** -------- ****** --------- ");
        }, 1000);
    }

    render() {
        const {
            data_payments,
        } = this.props;

        const referencia = this.props.match.params.referency;
        console.log("referencia: ", referencia);


        console.log("ID DE LA URL EN PAYMENTS.JS: ", this.props. match.params.referency);

        let ruta_retorno = "";
        if(referencia === 'caja'){
            ruta_retorno = '/report-movements/view-bill/' + this.props.match.params.id + '/caja';
        }else if(referencia === 'movimiento'){
            ruta_retorno = '/report-movements/view-bill/' + this.props.match.params.id + '/movimiento';
        }else if(referencia === 'cobrar'){
            ruta_retorno = '/report-movements/view-bill/' + this.props.match.params.id + '/cobrar';
        }else if(referencia === 'deposito'){
            ruta_retorno = '/oppening-closing/deposit/';
        }

        //const ruta_retorno = referencia === 'sale' ? '/oppening-closing/deposit/' : '/report-movements/view-bill/' + this.props.match.params.id;
        //let funCancelPayment = referencia === 'deposito' ? this.props.cancelPayment : null;

        console.log("DATA PAYMENTS: ", this.props.data_payments);

        return (
            <div className="content">
            <div className="m-auto row-fluid w-100">
                <HeaderSimple texto={"Regresar"} ruta={ruta_retorno}/>
                <div className="table-decoration">
                    <div className="top-title">
                        Historial de pagos
                    </div>
                    <div className="d-flex p-3 flex-column">
                        <React.Fragment>
                        {!!data_payments && Object.keys(data_payments.result).length === 0 &&
                            <center><h5>No existen anticipos o abonos asociados a la cuenta</h5></center>
                        }
                        {!!data_payments &&
                            <React.Fragment>
                                {data_payments.result.hasOwnProperty('Efectivo') && data_payments.result.Efectivo.length !== null &&
                                    <Spoiler show={false} label={"Pagos en Efectivo"}>
                                        <TablePayments data={data_payments.result.Efectivo} cancelPaymentFix={this.props.cancelPaymentFix} cancelPayment={this.props.cancelPayment} referencia_retorno={ruta_retorno} id_movimiento={this.props.match.params.id}/>
                                    </Spoiler>
                                }
                                {data_payments.result.hasOwnProperty('Deposito') && data_payments.result.Deposito.length !== null &&
                                <Spoiler show={false} label={"Pagos con depósito"}>
                                    <TablePayments data={data_payments.result.Deposito} cancelPaymentFix={this.props.cancelPaymentFix} cancelPayment={this.props.cancelPayment} referencia_retorno={ruta_retorno} id_movimiento={this.props.match.params.id}/>
                                </Spoiler>
                                }
                                {data_payments.result.hasOwnProperty('Tarjeta de crédito') && data_payments.result['Tarjeta de crédito'].length !== null &&
                                <Spoiler show={false} label={"Pagos con tarjeta de cŕedito"}>
                                    <TablePayments data={data_payments.result["Tarjeta de crédito"]} cancelPaymentFix={this.props.cancelPaymentFix} cancelPayment={this.props.cancelPayment} referencia_retorno={ruta_retorno} id_movimiento={this.props.match.params.id}/>
                                </Spoiler>
                                }
                                {data_payments.result.hasOwnProperty('Cuotas') && data_payments.result.Cuotas.length !== null &&
                                <Spoiler show={false} label={"Pagos con cuotas"}>
                                    <TablePayments data={data_payments.result.Cuotas} cancelPayment={this.props.cancelPayment} cancelPaymentFix={this.props.cancelPaymentFix} referencia_retorno={ruta_retorno} id_movimiento={this.props.match.params.id}/>
                                </Spoiler>
                                }
                            </React.Fragment>
                        }
                        </React.Fragment>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default PaymentsHistory;
