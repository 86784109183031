import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/consult';
import List from './List';
import {resetStore} from "../../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.consult,
        titulo: 'COLA DE CONSULTA',
        show_list: 0,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(List);
