import React, {Component, useReducer} from 'react';
import { Link } from 'react-router-dom';

class DetalleIngresoBodega extends Component{

    componentWillMount = () => {
        if(this.props.match.params.id){
            this.props.listarDetallesIngresos(this.props.match.params.id);
        }
    }

    render(){
        const {
            cargando,
            data_detalles_ingresos
        } = this.props;
        console.log("Props detalle")
        console.log(this.props)
        return(
            <div className="content">
                <div className="m-auto row-fluid w-100 py-4">
                    <Link to="/reporte-ingresos">
                        <div className="d-flex align-items-center ml-3 mr-3 back-blue-arrow"/>
                    </Link>
                    <div className="table-decoration mt-3 p-3" style={{color:'black'}}>
                        <div className='d-flex flex-row justify-content-around reporte-cirugia-encabezado'>
                            <div className='d-flex flex-row'>
                                <div className='d-flex flex-row justify-content-around align-items-start'>
                                        <div>
                                            <label>Número: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_ingresos && data_detalles_ingresos.document_number} disabled />
                                        </div>
                                        <div>
                                            <label>Fecha: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_ingresos && data_detalles_ingresos.creado} disabled />
                                        </div>
                                        <div>
                                            <label>Bodega: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_ingresos && data_detalles_ingresos.bodega} disabled />
                                        </div>
                                        <div>
                                            <label>Usuario: </label>
                                            <input type="text" className='form-control w-75' value={data_detalles_ingresos && data_detalles_ingresos.user.name} disabled />
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row resporte-cirugia-separador' style={{height:30}}></div>

                        <div className='d-flex flex-row justify-content-around reporte-cirugia-insumos'>
                            <table className='table'>
                                <thead style={{background:'gray', color:'white'}}>
                                    <tr>
                                        <th>LOTE</th>
                                        <th>PRODUCTO</th>
                                        <th>CANTIDAD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data_detalles_ingresos && data_detalles_ingresos.detalles.map((detail, i) =>
                                        <tr key={i}>
                                            <td>{detail.lote}</td>
                                            <td>{detail.producto}</td>
                                            <td>{detail.quantity}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleIngresoBodega
