import { connect } from 'react-redux';
import Plan from './PlanCrear';
import { actions } from '../../../../redux/modules/plan';
import {resetStore} from "../../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.plan,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Plan);
