import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/reportes/consultas';

import Consulta from './Consulta';
import {resetStore} from "../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.reporte_consulta,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Consulta);
