import Table from "../../Utils/Grid";

import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import moment from "moment";
import { Link } from 'react-router-dom';
import {RenderCurrency} from "../../Utils/renderField/renderReadField";
import NumberFormat from "react-number-format";

const SUPERUSER = 'superuser';

class PagosGrupales extends Component{
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            setInitialDate,
            setFinalDate,

            openAccount,
            cancelOpenAccount,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,            
        } = this.props;

        console.log("PAGOS GRUPALES: ", data);

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">


                    <div className="d-flex flex-row justify-content-around align-items-center">

                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-2 text-uppercase text-titulo"><strong>CUENTAS PAGADAS DE FORMA GRUPAL</strong></span>
                    </div>

                    <div className="d-flex flex-row justify-content-end align-items-center w-50">
                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20"
                            value={initial_date}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    setInitialDate(values.formattedValue);
                                }                                                                                
                            }}
                            autoFocus
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />

                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20 ml-2 mr-2"
                            value={final_date}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    setFinalDate(values.formattedValue);
                                }                                                                                
                            }}
                            
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />
                    <div className="top-title-toolbar mt-3">
                        <CustomToolbar
                            buscar={this.props.search}
                            buscador={this.props.buscador}                            
                        />
                    </div>                    
                    </div>
                    </div>

                    <Table
                        onPageChange={listar}
                        data={data}
                        loading={cargando}
                        page={page}
                    >

                    <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataField="modified"
                            dataSort
                            dataFormat={(cell)=>{
                                return cell;
                            }}
                            >Fecha</TableHeaderColumn>

                        <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataField="paid"
                            dataSort
                            dataFormat={(cell)=>{
                                return `Q${cell}`;
                            }}
                            >Monto</TableHeaderColumn>

                        <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataField="document_number"
                            dataSort                            
                            >No. Documento</TableHeaderColumn>
                                                
                        <TableHeaderColumn
                            dataField="id"
                            isKey={true}
                            dataAlign="center"

                            dataFormat={(cell)=>{
                                return(
                                    <a
                                        href={`/#/view-entry-order/${cell}/view`}
                                        target='_blank'
                                    >
                                        <img 
                                            className='action-img watch'
                                            src={require('../../../../../assets/img/icons/view-icon.png')}
                                        />                                        
                                    </a>
                                );
                            }}
                            >Acciones</TableHeaderColumn>
                    </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default PagosGrupales;