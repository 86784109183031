import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/coupon';
import CouponCrear from './CouponCrear';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.coupon,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(CouponCrear);
