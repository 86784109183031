import React from 'react';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency, renderSearchSelect, renderSelectCustom } from '../../Utils/renderField/renderField';
import { api } from "api";
import { connect } from 'react-redux';

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

export const detailRow =({ fields, meta: {error, submitFailed}, type, disabled}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                    <Field
                        key={index}
                        name={`${field}.id`}
                        component={renderField}
                        type={type}
                        className="form-control oc-input-modal"
                        disabled={disabled}
                    />
                )
            )
        }
    </React.Fragment>
)

let PayForm = (props) => {
    const {
        handleSubmit,
        caja_chica,
    } = props;

    return (
        <form onSubmit={handleSubmit} id="formPaymentFee" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="doctor_id" component="input" type="hidden" />
            <FieldArray
                name="details"
                component={detailRow}
                type="hidden"
                disabled={true}
            />

            <table className="table" id="table-frm-pay-oc">
                <thead />
                <tbody className="oc-body-form">
                    <tr className="oc-white-border">
                        <th>Doctor:</th>
                        <td colSpan={2}>
                            <Field
                                name="doctor_name"
                                component={renderField}
                                type="text"
                                className="form-control oc-input-modal"
                                disabled={true}
                            />
                        </td>
                    </tr>
                    <tr className="oc-white-border">
                        <th>Monto</th>
                        <td colSpan={2}>
                            <Field
                                name="total"
                                component={renderCurrency}
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    <tr className="oc-white-border">
                        <th>No. Documento</th>
                        <td colSpan={2}>
                            <Field
                                name="document_number"
                                component={renderField}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    <tr className="oc-white-border">
                        <th>Método de Pago</th>
                        <td colSpan={2}>
                            <Field name="payment_method" disabled={true} component={renderSelectCustom} options={[
                                {value:1, label:'Caja Chica'},
                                {value:2, label:'Transacción Bancaria'},
                            ]}
                            />
                        </td>
                    </tr>
                    {!caja_chica &&
                        <tr className="oc-white-border">
                            <th>Cuenta</th>
                            <td>
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="number"
                                    label="cuenta"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    addClass="no-border"
                                />
                            </td>
                            <td>
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="label"
                                    label="cuenta"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    addClass="no-border"
                                />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </form>
    );
};

PayForm = reduxForm({
    form: 'formPaymentFee',
    validate: data => validate(data, {
        document_number: validators.exists()('Campo requerido'),
        total: validators.exists()('Campo requerido'),
        bank_account: validators.exists()('Campo requerido'),
    }),
})(PayForm);

const selector = formValueSelector('formPaymentPayback');

PayForm = connect(state => {
    const metodo_pago = selector(state, "payment_method");
    console.log("metodo_pago: ", metodo_pago);

    let caja_chica = false;
    if(metodo_pago !== undefined && metodo_pago.value === 1){
        caja_chica = true;
    }

    return {
        caja_chica,
    };

})(PayForm);

export default PayForm;
