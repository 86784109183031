import { connect } from 'react-redux';
import _ from "lodash";
import Surgery from './SurgeryCreate';
import { actions } from '../../../redux/modules/surgery';
import { municipios } from 'variables';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    let _municipios = [];
    let is_surgery_val = true;
    try {
        const { departamento, is_surgery } = state.form.surgery.values;
        is_surgery_val = !!is_surgery;
        if (!!departamento) {
            _municipios = _.filter(municipios, {departamento: departamento.value});
        }
    } catch (e) {
    }
    return {
        ...state.surgery,
        municipios: _municipios,
        is_surgery:is_surgery_val,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Surgery);
