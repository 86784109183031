import MethodPayment from './depositPayment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class tabsComponent extends Component{
    state = {
        tab_active: "venta"
    }

    changeTab(tabName){
        this.props.cancelDataPayment(this.props.index,tabName);
        this.setState({tab_active: tabName});
        this.props.forceRendering();
        this.forceUpdate();
    }

    forceRendering = () => {
        this.forceUpdate();
    }

    render(){
        console.log("THIS.PROPS en movements/tabComponents", this.props)

        let tventa = (this.state.tab_active === "venta") ? "tp-active" : "tp-inactive";
        let tcredito = (this.state.tab_active === "credito") ? "tp-active" : "tp-inactive";
        let firstactive = (this.state.tab_active === "venta") ? "first-active" : "";

        console.log("PROPS EN TABCOMPONENT: ", this.props.factura);
        console.log("THIT.PROPS.RUTA_RETORNO: ", this.props.ruta_retorno);

        return(
            <div className="ml-4 mr-4 d-flex flex-column h-75 p-3">
                <div className={"d-flex flex-wrap flex-row justify-content-start align-items-end w-100 b-white"}>
                    <div className={"d-flex w-30 tab-payment justify-content-center align-items-center " + tventa}>ABONO</div>
                    <div className={"d-flex w-60 tab-comodin justify-content-center align-items-center"} >
                        <React.Fragment>
                        {/*<button className="btn btn-success btn-sm btn-historial">Historial</button> */}
                        {/* <a href={"/#/payments-history/" + this.props.factura.dataBilling.id + "/deposito"} className="btn btn-success btn-sm btn-historial">Historial</a>*/}
                        </React.Fragment>
                    </div>
                </div>
                <div className={"d-flex tab-payment-content b-white p-3 " + firstactive}>
                    {
                        (this.state.tab_active == "venta") ?
                            <React.Fragment>
                                <MethodPayment {...this.props} forceRendering={this.forceRendering} />
                            </React.Fragment>
                        :
                            <MethodPayment {...this.props} forceRendering={this.forceRendering} />
                    }
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mb-1">
                    <Link className="btn btn-secondary btn-sm mt-3 ml-2" to={"/" + this.props.ruta_retorno}>Cancelar</Link>
                    {parseFloat(this.props.factura.dataBilling.total_paid) === parseFloat(this.props.factura.calculate.total) ?
                        <button type="submit" className="btn btn-primary btn-sm mt-3 ml-2 disabled" >Guardar</button>
                        :
                        <button type="submit" className="btn btn-primary btn-sm mt-3 ml-2" onClick={() => this.props.addDepositMovement(this.props.factura.dataBilling.id)}>Guardar</button>
                    }
                </div>
            </div>
        );
    }
}
