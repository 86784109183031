import React, { Component } from 'react';
import { Field, reduxForm,  formValueSelector } from 'redux-form';
import { renderSelectCustom, modalCurrency, renderCurrency, renderField } from 'Utils/renderField/renderField';

class RowComponent extends Component{
    state = {
        due_detail: [],
        percentage: undefined,
        totalWithSurcharge: undefined,
    }
     componentWillMount() {
        const item = this.props.fields.get(this.props.index)
         // inicializar los valores de totalWithSurcharge
        if(item && item.amount && item.monto_extra){
            this.setState({totalWithSurcharge: (parseFloat(item.amount)+ parseFloat(item.monto_extra))})
        }
        // inicializar los valores de porcentaje
        if(item && item.due_detail){
            const detail = this.props.fields.get(this.props.index).dues.due_detail
            let percentage = 0
            detail.forEach((det) => {
                if (det.id == item.due_detail.value) {
                    percentage = det.custumer_rate
                }
            })
            this.setState({ percentage: percentage});
        }
        // inicializar los datos para cuotas
        if(item && item.dues && item.dues.due_detail){
            let array = [];
            item.dues.due_detail.forEach(element => {
                array.push( {label:element.dues, value:element.id} )
            });
            this.setState({ due_detail: array});
        }
    }

    setDueDetail = (arrayRow) => {
        if(arrayRow != undefined && arrayRow.hasOwnProperty('due_detail')){
            let array = [];
            arrayRow.due_detail.forEach(element => {
                array.push( {label:element.dues, value:element.id} )
            });
            this.setState({ due_detail: array});
        }
    }

    setTotalWithSurcharge = (option) => {
        let amount = this.props.fields.get(this.props.index).amount
        if(amount){
            amount = parseFloat(amount).toFixed(2)
        }
        const detalle = this.props.fields.get(this.props.index).dues.due_detail
        let percentage = 0
        detalle.forEach((det)=>{
            if(det.id == option.value){
                percentage = det.custumer_rate
            }
        })
        const total = (parseFloat(amount) + parseFloat((amount *(percentage/100)/1.12)))
        this.setState({totalWithSurcharge: total, percentage })
    }

    setTotalWithSurchargeInAmount = (amountValue)=>{
        const percentage = this.state.percentage
        if(this.state.percentage> 0 && amountValue){
            const total = (parseFloat(amountValue) + parseFloat((amountValue *(percentage/100))/1.12))
            this.setState({totalWithSurcharge: total })
        }else{
            this.setState({totalWithSurcharge: undefined })
        }
    }

    render(){
        return(
            <React.Fragment>
                <td>
                    <Field
                        type="text"
                        disabled={false}
                        className="form-control"
                        component={modalCurrency}
                        name={`${this.props.field}.amount`}
                        customChange={this.setTotalWithSurchargeInAmount}
                    />
                </td>
                <td>
                    <Field
                        name={`${this.props.field}.dues`}
                        valueKey="value"
                        labelKey="label"
                        label="name"
                        component={renderSelectCustom}
                        options={this.props.dues}
                        className="no-border"
                        disabled={false}
                        onChange={(e) =>
                            this.setDueDetail(e)
                        }
                    />
                </td>

                <td className="col-1">
                    <Field
                        name={`${this.props.field}.due_detail`}
                        valueKey="value"
                        labelKey="label"
                        label="name"
                        component={renderSelectCustom}
                        options={this.state.due_detail}
                        className="no-border"
                        onChange={(e) =>
                            this.setTotalWithSurcharge(e)
                        }
                        disabled={false}
                    />
                </td>

                <td>
                    <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.percentage? `${this.state.percentage} %`: undefined}
                        name={`${this.props.field}.porcentage`}
                    />
                </td>

                <td>
                    <Field
                        type="text"
                        disabled={true}
                        initialValue={this.state.totalWithSurcharge}
                        className="form-control"
                        component={modalCurrency}
                        name={`${this.props.field}.total_with_surcharge`}
                    />
                </td>
                <td className="text-center">
                    <img className="action-img delete-button-r2"
                        src={require("../../../../../assets/img/icons/delete-icon.png")}
                        onClick={() => {this.props.fields.remove(this.props.index)}}
                        alt="Eliminar" />
                </td>
            </React.Fragment>
        );
    }
}

export default RowComponent;
