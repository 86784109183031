import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Table from '../Utils/Grid';
import ToolbarChange from '../Utils/Toolbar/ToolbarChange';
import { BreakLine } from '../Utils/tableOptions';
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import { showNestedFields } from '../Utils/MyOptions'; //Mi archivo (Ricky)
import MyModal from '../Utils/myModal';
import Form from './payForm';

const addIconBlue = require('../../../../assets/img/icons/payback_payment_icon.png');

class Sales extends Component {
    state = {
        redirect: true
    }

    componentDidMount = () => {
        this.props.listar();
    }

    payPayback = (cell, row) => {
        console.log("Row: ", row);

        return (
        <MyModal
            isOpen={false}
            title="PAGO DE PAYBACK"
            formId="formPaymentPayback"
            imageButton={<img className="action-img" title="pagar" src={addIconBlue} alt="pagar" />}
            classToggleButton="d-block h-100 button-service-icon"
            size={'lg'}
            body={(
                <Form
                    onSubmit={this.props.create}
                    initialValues={{
                        referent_id: row.referent.id,
                        referent_name: row.referent.name ,
                        total: row.total,
                        details: row.ids_detalle,
                        payment_method: {value:1, label:'Caja Chica'},
                    }}
                />
            )}
        />
        );
    }

    render() {
        const {
            listar,
            data,
            cargando,
            page,
        } = this.props;

        const {
            search,
            buscador,
        } = this.props;

        return (
            <div className="content-with-tabs">
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/payback" className="d-flex justify-content-center align-items-center tab tab-br-br">
                            PAYBACK
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/payback-paid" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                            PAYBACK POR PAGAR
                        </a>
                    </div>
                </div>
                <div className="table-decoration row mx-5 mt-5 mb-3 d-flex">
                        <div className="row-fluid w-100 mt-2 mb-1">
                                <div className="mt-2 mb-2">
                                    <ToolbarChange
                                        titulo="PAYBACK POR PAGAR"
                                        buscar={search}
                                        buscador={buscador}
                                        autofocus
                                    />
                                </div>
                                <div className="d-flex flex-row w-100 justify-content-center">
                                    <Table
                                        onPageChange={listar}
                                        data={data}
                                        loading={cargando}
                                        page={page}
                                    >

                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="referent"
                                            formatExtraData="referent__name"
                                            dataSort={true}
                                            dataFormat={showNestedFields}
                                        >
                                            Referente
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="total" dataSort
                                            dataFormat={(cell) => { return <RenderCurrency value={cell} />}}
                                        >
                                            Total
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="referent"
                                            isKey={true}
                                            dataAlign="center"
                                            dataFormat={this.payPayback}>Acciones
                                        </TableHeaderColumn>

                                    </Table>
                                </div>
                        </div>
                 </div>
            </div>
        )
    }
}

export default Sales;
