import moment from "moment";
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import { RenderMoneda }  from 'Utils/renderField/renderTableField';
import ToolbarSelect from '../Utils/Toolbar/ToolbarSelect';
import SearchSelect from "Utils/Toolbar/Select";
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import NumberFormat from "react-number-format";

class CuentasPorCobrar extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
        } = this.props;


        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            set_final_date,
            set_initial_date
        } = this.props;

        const options=[
            {value:3, label:"Todas"},
            {value:2, label:"Pendientes de pago"},
            {value:1, label:"Cuentas pagadas"},
        ];

        return (
            <div className="content">
                <div className="pt-5 row-fluid w-100">
                    <div className="table-decoration">
                    <div className="d-block d-md-flex flex-row justify-content-around align-items-center">
                        <div className="top-title-toolbar mt-3 d-flex">
                            <span className="ml-1 text-uppercase text-titulo"><strong>LISTADO DE CUENTAS POR COBRAR</strong></span>
                        </div>
                        <div className="d-block d-md-flex flex-row justify-content-end align-items-center w-60">
                            {/* <span className="mr-2">filtrar por:</span> */}
                            <div className="d-block d-md-flex flex-row justify-content-start align-items-center w-100">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-25"
                                    value={initial_date}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            set_initial_date(values.formattedValue);
                                        }                                                                                
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-25 ml-2 mr-2"
                                    value={final_date}
                                    onValueChange={(values) => {                                        
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            set_final_date(values.formattedValue);
                                        }                                                                                
                                    }}
                                    
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}                                    
                                />
                                <React.Fragment>
                                    <SearchSelect { ...this.props}
                                    buscar={this.props.filtro}
                                    buscador={this.props.filtro_receivable}
                                    options={options}/>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                        {/* <div className="top-title-toolbar">
                        </div> */}

                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="made" dataSort>Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient" formatExtraData="patient__first_name" dataSort dataFormat={showNestedFields}>Paciente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name">Cliente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="correlative" dataSort>Factura</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={{textAlign:"right"}}
                                thStyle={BreakLine}
                                dataField="payment"
                                dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                                dataSort>Monto Total</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={{textAlign:"right"}}
                                thStyle={BreakLine}
                                dataField="payment"
                                dataFormat={(cell, row) => <RenderMoneda monto={ (row.payment - row.paid).toFixed(2) } />}
                                dataSort>Monto Pendiente</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver_factura_cobrar: '/report-movements/view-bill', ruta_retorno: 'cobrar' })}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default CuentasPorCobrar;
