import React, { Component } from 'react';
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import '../../Payments/payment_history.css';

export const TablePayments = (props) => {
    const {ruta_retorno}  = props;

    return(
        <div className="d-flex justify-content-center align-items-center">
            <div className="table-responsive justify-content-center d-flex" style={{overflow:"none !important"}}>
                <table className="table table-sm table-hover mb-3 w-75 table-payments">
                    <thead>
                        <tr>
                            <th className="text-center">Cantidad pagada</th>
                            <th className="text-center">Fecha</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.data.map((dato, i) =>
                        <tr key={i}>
                            <td className="text-center"><RenderCurrency value={dato.amount} /></td>
                            <td className="text-center">{dato.creado}</td>
                            <td className="text-center">
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={()=>props.cancelPayment(dato.id, ruta_retorno)}
                                >Anular
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
