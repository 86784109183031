import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/consult';
import Exam from '../../Exam/Exam';
import {LAMP} from "../../../../../utility/variables";
import {resetStore} from "../../../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.consult,
        titulo: 'LAMPARA DE HENDIDURA',
        show_list: LAMP,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Exam);
