import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import AsyncSelect from 'react-select/lib/Async';
import { validate, validators } from 'validate-redux-form';
import moment from "moment";
import { renderField, renderSelectCustomSmall, renderDatePicker } from 'Utils/renderField/renderField';
import { connect } from 'react-redux';

export const renderItemRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3 d-flex justify-content-between align-items-center" key={index}>
                    <div className="package-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center date-lock-input">
                        <Field name={`${field}.date`} isDisabled={view} component={renderDatePicker} type="text" addClass="lock-date-size"/>
                    </div>
                    <div className="package-small-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name={`${field}.start`} component={renderSelectCustomSmall} options={[
                            // {value:0.0, label:'00:00'}, {value:0.5, label:'00:30'},
                            // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                            // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                            // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                            // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                            // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                            // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                            {value:7.0, label:'07:00'}, {value:7.5, label:'07:30'},
                            {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                            {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                            {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                            {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                            {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                            {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                            {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                            {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                            {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                            {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                            {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                            // {value:19.0, label:'19:00'}, {value:19.5, label:'19:30'},
                            // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                            // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                            // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                            // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                        ]}/>
                    </div>
                    <div className="package-small-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name={`${field}.end`} component={renderSelectCustomSmall} options={[
                            // {value:0.5, label:'00:30'},
                            // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                            // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                            // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                            // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                            // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                            // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                            // {value:7.0, label:'07:00'},

                            {value:7.5, label:'07:30'},
                            {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                            {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                            {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                            {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                            {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                            {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                            {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                            {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                            {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                            {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                            {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                            {value:19.0, label:'19:00'},

                            // {value:19.5, label:'19:30'},
                            // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                            // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                            // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                            // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                            // {value:24.0, label:'24:00'}
                        ]}/>
                    </div>
                    <div className="package-small-item d-inline-flex justify-content-center">
                        <img className="action-img delete-item-package" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                    </div>
                </div>
                )
            )
        }
        <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
            <div className="package-small-item d-inline-flex justify-content-center">
                <img onClick={() => fields.push({start: {value:10.0, label:'10:00'}, end: {value:10.5, label:'10:30'}, date: moment(new Date()).format("YYYY-MM-DD") })} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
            </div>
        </div>
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

const Form = props => {

   const {
       handleSubmit
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">

            <div className="mx-5">
                <div className="package-item-row-container locked-appointments-table">
                    <div className="package-items-title-row d-flex pl-md-3 mt-0 locked-appointments-table-style justify-content-between">
                        <label htmlFor="date-lock" className="package-item-title my-0">Fecha</label>
                        <label htmlFor="start-lock" className="package-small-item-title my-0">Inicio</label>
                        <label htmlFor="end-lock" className="package-small-item-title my-0">Fin</label>
                        <label htmlFor="lock-delete" className="package-small-item-title my-0">Eliminar</label>
                    </div>
                    <div>
                        <FieldArray
                            name="locks"
                            component={renderItemRow}
                        />
                    </div>
                </div>
            </div>


            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    {
                        window.location.href.includes("week") ?
                            <Link className="btn btn-secondary m-3" to="/schedule">Cancelar</Link>
                        :
                        <Link className="btn btn-secondary m-3" to="/surgeries">Cancelar</Link>
                    }
                    <button type="submit" className="btn btn-primary m-3">Guardar</button>
                </div>
            </div>
        </form>
    )
};

const ScheduleLockForm = reduxForm({
   form: 'scheduleLock',
//    validate: data => {
//        return validate(data, {
//            'bank_name': validators.exists()('Campo requerido.'),
//            'account_number': validators.exists()('Campo requerido.'),
//            'owner': validators.exists()('Campo requerido.'),
//        })
//    }
})(Form);

export default ScheduleLockForm
