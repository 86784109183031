import classNames from "classnames";
import React, { Component } from "react";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import PaymentForm from "./PaymentForm";
import MyModal from "../Utils/myModal";
import "./toPayStyle.css";
import LoadMask from "../Utils/LoadMask/LoadMask";
import moment from "moment";
import { RenderCurrency } from "../Utils/renderField/renderReadField";
import NumberFormat from "react-number-format";
import ToolbarSimple from "../Utils/Toolbar/ToolbarSimple";

class CuentasPorPagar extends Component {
    state = {
        seleccion: [],
        seleccionados: [],
        total: 0,
        open_modal: false,
        table_ref: React.createRef(),
        fecha_clicked: false,
        fecha_fac_clicked: false,
        table_orders: {
            fecha_fac: "",
            fecha: "",
        },
        fechaInicial: this.props.initial_date,
        fechaFinal: this.props.final_date,
    };

    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar();
    }

    agregarSeleccion = (e, row) => {
        let seleccion_temp = [],
            seleccionados = [];
        //add
        const retention = !!row.purchase
            ? row.purchase.provider.retention
            : row.provider.retention || row.provider.retention_iva;

        if (e.target.checked) {
            seleccion_temp = this.state.seleccion;
            seleccion_temp.push({
                id: row.id,
                total: parseFloat(row.total),
                //retention: parseFloat(row.purchase.provider.retention),
                retention,
                retention_amount: parseFloat(row.retention_amount),
                bill_number: row.bill_number
            });
            seleccionados = this.state.seleccionados;
            seleccionados.push(row.id);
        } else {
            seleccion_temp = this.state.seleccion.filter((sel) => {
                return row.id !== sel.id;
            });
            seleccionados = this.state.seleccionados.filter((sel) => {
                return sel !== row.id;
            });
        }

        let total = 0;

        seleccion_temp.forEach((item) => {
            let calculo = 0;
            if (item.retention === false) {
                calculo = item.total;
            } else {
                calculo = item.total - item.retention_amount;
            }

            total += calculo;
        });
        total = parseFloat(total.toFixed(2));

        this.setState({
            seleccion: seleccion_temp,
            total,
            open_modal: false,
            seleccionados,
        });
    };

    setFechaInicial(value) {
        this.setState({ fechaInicial: value });
    }

    setFechaFinal(value) {
        this.setState({ fechaFinal: value });
    }

    orderBy() {
        let order = [];
        if (this.state.table_orders.fecha != "")
            order.push(this.state.table_orders.fecha);
        if (this.state.table_orders.fecha_fac != "")
            order.push(this.state.table_orders.fecha_fac);
        this.props.listar(1, order);
    }

    render() {
        const {
            isNavBarOpen,
            PayGroupEntry,

            setFinalDate,
            setInitialDate,
        } = this.props;

        const { cargando, data, initial_date, final_date, total_pay } =
            this.props;

        return (
            <div
                className={classNames("content", {
                    "content-min": isNavBarOpen,
                })}
            >
                <div className="pt-3 row-fluid w-100">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <span className="ml-3 text-uppercase text-titulo">
                                <strong>LISTADO DE CUENTAS POR PAGAR</strong>
                            </span>
                        </div>
                        <LoadMask loading={cargando} opacity={true}>
                            <div className="d-flex flex-row justify-content-around align-items-center">
                                <button
                                    className="m-3 btn-success btn-sm cursor-pointer"
                                    onClick={() => {
                                        this.setState({ open_modal: true });
                                    }}
                                    disabled={
                                        this.state.total > 0 ? false : true
                                    }
                                >
                                    Pagar selección Q
                                    {this.state.total.toFixed(2)}
                                </button>

                                <div className="top-title-toolbar mt-3 d-flex">
                                    <span className="ml-2 text-uppercase text-titulo">
                                        Total a pagar{" "}
                                        <RenderCurrency value={total_pay} />
                                    </span>
                                </div>

                                <MyModal
                                    isOpen={this.state.open_modal}
                                    title="PAGO DE CUENTAS"
                                    formId="paymentGroup"
                                    classToggleButton="d-block h-100 button-service-icon"
                                    body={
                                        <PaymentForm
                                            total={this.state.total}
                                            solicitar_factura={this.state.seleccion.map(seleccionado => {
                                                if(seleccionado.bill_number){
                                                    if(seleccionado.bill_number.replaceAll(" ", "") != "")
                                                        return true
                                                    else
                                                        return false
                                                }
                                                return false
                                            }).includes(false)}
                                            onSubmit={async (data) => {
                                                const result =
                                                    await PayGroupEntry(data);
                                                if (result == true) {
                                                    this.setState({
                                                        seleccion: [],
                                                        seleccionados: [],
                                                        total: 0,
                                                    });
                                                    window.scrollTo(0, 0);
                                                    this.state.table_ref.current.scrollTo(
                                                        0,
                                                        0
                                                    );
                                                }
                                                this.setState({
                                                    open_modal: false,
                                                });
                                            }}
                                            initialValues={{
                                                cuentas: this.state.seleccion,
                                                total: this.state.total,
                                                fecha_fac: moment(
                                                    Date()
                                                ).format("DD/MM/YYYY"),
                                            }}
                                        />
                                    }
                                />
                                {(this.state.fecha_clicked ||
                                    this.state.fecha_fac_clicked) && (
                                    <button
                                        className="m-3 btn-sm cursor-pointer"
                                        onClick={async () => {
                                            await this.setState({
                                                fecha_clicked: false,
                                                fecha_fac_clicked: false,
                                                table_orders: {
                                                    fecha_fac: "",
                                                    fecha: "",
                                                },
                                            });
                                            this.props.listar();
                                        }}
                                    >
                                        &#10226; Restaurar orden
                                    </button>
                                )}
                                <div className="d-flex flex-row justify-content-end align-items-center w-30">
                                    <NumberFormat
                                        format="##/##/####"
                                        placeholder="DD/MM/YYYY"
                                        mask={[
                                            "D",
                                            "D",
                                            "M",
                                            "M",
                                            "Y",
                                            "Y",
                                            "Y",
                                            "Y",
                                        ]}
                                        className="form-control w-50"
                                        value={this.state.fechaInicial}
                                        onValueChange={(values) => {
                                            const dia =
                                                values.formattedValue.indexOf(
                                                    "D"
                                                );
                                            const mes =
                                                values.formattedValue.indexOf(
                                                    "M"
                                                );
                                            const anio =
                                                values.formattedValue.indexOf(
                                                    "Y"
                                                );

                                            if (dia + mes + anio < 0) {
                                                this.setFechaInicial(values.formattedValue);
                                            }
                                        }}
                                        autoFocus
                                        onFocus={(e) => {
                                            e.target.setSelectionRange(0, 10);
                                        }}
                                    />

                                    <NumberFormat
                                        format="##/##/####"
                                        placeholder="DD/MM/YYYY"
                                        mask={[
                                            "D",
                                            "D",
                                            "M",
                                            "M",
                                            "Y",
                                            "Y",
                                            "Y",
                                            "Y",
                                        ]}
                                        className="form-control w-50 ml-2 mr-2"
                                        value={this.state.fechaFinal}
                                        onValueChange={(values) => {
                                            const dia =
                                                values.formattedValue.indexOf(
                                                    "D"
                                                );
                                            const mes =
                                                values.formattedValue.indexOf(
                                                    "M"
                                                );
                                            const anio =
                                                values.formattedValue.indexOf(
                                                    "Y"
                                                );

                                            if (dia + mes + anio < 0) {
                                                this.setFechaFinal(values.formattedValue)
                                            }
                                        }}
                                        onFocus={(e) => {
                                            e.target.setSelectionRange(0, 10);
                                        }}
                                    />
                                </div>
                                <div className="top-title-toolbar mt-2">
                                    <ToolbarSimple
                                        buscar={this.props.search}
                                        buscador={this.props.buscador}
                                        date = {{
                                            setInitialDate: setInitialDate,
                                            fechaInicial: this.state.fechaInicial,
                                            setFinalDate: setFinalDate,
                                            fechaFinal: this.state.fechaFinal,
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className="table-ctas-pagar"
                                ref={this.state.table_ref}
                            >
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr style={{ background: "#555252" }}>
                                            <th className="text-center">
                                                Seleccionar
                                            </th>
                                            <th className="text-center">
                                                No. Orden
                                            </th>
                                            <th className="text-center">
                                                No. Factura
                                            </th>
                                            <th
                                                className="text-center"
                                                style={{ cursor: "pointer" }}
                                                onClick={async () => {
                                                    await this.setState({
                                                        fecha_clicked: true,
                                                        table_orders: {
                                                            fecha_fac:
                                                                this.state
                                                                    .table_orders
                                                                    .fecha_fac,
                                                            fecha:
                                                                this.state
                                                                    .table_orders
                                                                    .fecha ==
                                                                "created"
                                                                    ? "-created"
                                                                    : "created",
                                                        },
                                                    });
                                                    this.orderBy();
                                                }}
                                            >
                                                Fecha&nbsp;
                                                {this.state.fecha_clicked && (
                                                    <React.Fragment>
                                                        {this.state.table_orders
                                                            .fecha ==
                                                            "created" && (
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                            >
                                                                &#8593;
                                                            </span>
                                                        )}
                                                        {this.state.table_orders
                                                            .fecha ==
                                                            "-created" && (
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                            >
                                                                &#8595;
                                                            </span>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </th>
                                            <th
                                                className="text-center"
                                                style={{ cursor: "pointer" }}
                                                onClick={async () => {
                                                    await this.setState({
                                                        fecha_fac_clicked: true,
                                                        table_orders: {
                                                            fecha: this.state
                                                                .table_orders
                                                                .fecha,
                                                            fecha_fac:
                                                                this.state
                                                                    .table_orders
                                                                    .fecha_fac ==
                                                                "fecha_fac"
                                                                    ? "-fecha_fac"
                                                                    : "fecha_fac",
                                                        },
                                                    });
                                                    this.orderBy();
                                                }}
                                            >
                                                Fecha Factura&nbsp;
                                                {this.state
                                                    .fecha_fac_clicked && (
                                                    <React.Fragment>
                                                        {this.state.table_orders
                                                            .fecha_fac ==
                                                            "fecha_fac" && (
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                            >
                                                                &#8593;
                                                            </span>
                                                        )}
                                                        {this.state.table_orders
                                                            .fecha_fac ==
                                                            "-fecha_fac" && (
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                            >
                                                                &#8595;
                                                            </span>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </th>
                                            <th className="text-center">
                                                Bodega
                                            </th>
                                            <th className="text-center">
                                                Proveedor
                                            </th>
                                            {/*<th className='text-center'>Orden</th>*/}
                                            <th className="text-center">
                                                Nota
                                            </th>
                                            <th className="text-center">
                                                Monto Total
                                            </th>
                                            <th className="text-center">
                                                Monto Pendiente
                                            </th>
                                            <th className="text-center">
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.length > 0 &&
                                            data.map((row, e) => (
                                                <tr key={e}>
                                                    <td>
                                                        {parseFloat(
                                                            Math.abs(
                                                                parseFloat(
                                                                    row.paid
                                                                )
                                                            ) +
                                                                Math.abs(
                                                                    parseFloat(
                                                                        row.retention_amount
                                                                    )
                                                                )
                                                        ) !==
                                                            Math.abs(
                                                                parseFloat(
                                                                    row.total
                                                                )
                                                            ) && (
                                                            <input
                                                                type="checkbox"
                                                                name="seleccion[]"
                                                                value={row.id}
                                                                className="check-box"
                                                                checked={
                                                                    this.state.seleccionados.filter(
                                                                        (val) =>
                                                                            val ==
                                                                            row.id
                                                                    ) > 0
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e) =>
                                                                    this.agregarSeleccion(
                                                                        e,
                                                                        row
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {row.purchase
                                                            ? row.purchase.id
                                                            : ""}
                                                    </td>
                                                    <td>{row.bill_number}</td>
                                                    <td>
                                                        {moment(
                                                            row.created
                                                        ).format("DD-MM-YYYY")}
                                                    </td>
                                                    <td>
                                                        {row.fecha_fac &&
                                                            moment(
                                                                row.fecha_fac
                                                            ).format(
                                                                "DD-MM-YYYY"
                                                            )}
                                                    </td>
                                                    <td>
                                                        {!!row.warehouse
                                                            ? row.warehouse.name
                                                            : ""}
                                                    </td>
                                                    <td>
                                                        {!!row.purchase
                                                            ? row.purchase
                                                                  .provider.name
                                                            : !!row.provider
                                                            ? row.provider.name
                                                            : ""}
                                                    </td>
                                                    <td>
                                                        {!!row.purchase
                                                            ? row.purchase
                                                                  .comment
                                                            : ""}
                                                    </td>
                                                    <td>
                                                        <RenderMoneda
                                                            monto={Math.abs(
                                                                row.total
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <RenderMoneda
                                                            monto={Math.abs(
                                                                (
                                                                    row.total -
                                                                    row.paid -
                                                                    row.retention_amount
                                                                ).toFixed(2)
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        {Math.abs(
                                                            parseFloat(
                                                                row.total
                                                            )
                                                        ) ===
                                                        Math.abs(
                                                            parseFloat(row.paid)
                                                        ) +
                                                            Math.abs(
                                                                parseFloat(
                                                                    row.retention_amount
                                                                )
                                                            ) ? (
                                                            <a
                                                                href={`/#/view-entry-order/${row.id}/view`}
                                                            >
                                                                <img
                                                                    className="action-img watch"
                                                                    title="ver"
                                                                    alt="ver"
                                                                    src={require("../../../../assets/img/icons/view-icon.png")}
                                                                />
                                                            </a>
                                                        ) : (
                                                            <a
                                                                href={`/#/view-entry-order/${row.id}/view`}
                                                            >
                                                                <img
                                                                    className="action-img view"
                                                                    title="ver"
                                                                    alt="ver"
                                                                    src={require("../../../../assets/img/icons/cobrar-icon-blue.png")}
                                                                />
                                                            </a>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default CuentasPorPagar;
