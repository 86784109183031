import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/records';
import RecordEdit from './RecordEdit';
import {resetStore} from "../../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.records
    };
};

const md2p = {
    ...actions, resetStore
};

export default connect(ms2p, md2p)(RecordEdit);
