import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CrearForm from "../Keratometry/Form";


class Lensometry extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {foto: null};
    }

    crear = (data) => {
        if(data){
            this.props.crear(data, {name: "foto", file: this.state.foto});
        }
    };

    render() {
        const { loader } = this.props;
        return (
            <div id="" className="content p-2 p-md-4 d-flex flex-column">
                <div className="cuadro">
                    <LoadMask loading={loader} light>
                        <CrearForm onSubmit={this.crear} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Lensometry;
