import React, { Component } from 'react';
import Form from './ExamOrderForm';
import LoadMask from '../Utils/LoadMask/LoadMaskImages'

class ExamOrder extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    state = {
        archivos: [], //Este debe llamarse files (porque en la api así lo llama)
    }

    componentDidMount = () => {
        if(this.props.match.params.id){
            this.props.getOneDiagnostic(this.props.match.params.id);
        }
    }

    setFiles = (archivos) => {
        this.setState({ archivos });
    }

    submit = (data) => {
        if(data){
            this.props.endDiagnostic(this.state.archivos);
        }
    };

    render() {
        const { loader } = this.props
        return (
            <div className="content">
                <div className="m-auto row-fluid card-form w-100">
                    <div className="table-decoration">
                        <div className="fields">
                            <div className="top-title">
                                Orden de Exámen
                            </div>
                            <LoadMask loading={loader}>
                                <Form
                                    onSubmit={this.submit}
                                    setFiles={this.setFiles}/>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ExamOrder;
