import moment from "moment";
import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import { activeFormatter }  from '../../Utils/Acciones/Acciones'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { showNestedFields } from '../../Utils/MyOptions';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {RenderCurrency} from '../../Utils/renderField/renderReadField';
import NumberFormat from "react-number-format";

class Gastos extends Component {
    state = {
        tipo_gasto: '',
        tipo: ''
    }

    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        this.props.listar();
    }

    render() {

        const {
            listar,
            detail,
            create,
            update,
            destroy,
            getDetalleGastos,
            clearDetalleGastos,
            generarExcel,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            setInitialDate,
            setFinalDate,
            detalle_gastos,
        } = this.props;

        console.log("this.props.detalle_gastos: ", detalle_gastos);

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo">
                                    <strong>LISTADO GASTOS</strong> &nbsp; &nbsp;
                                    <strong>{data && <RenderCurrency value={data.total} /> }</strong>
                                </span>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center w-50">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    value={initial_date}
                                    onValueChange={(values) => {
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            setInitialDate(values.formattedValue);
                                        }
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2 mr-2"
                                    value={final_date}
                                    onValueChange={(values) => {
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            setFinalDate(values.formattedValue);
                                        }
                                    }}

                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}
                                />
                                <button
                                    className=" btn-primary btn-sm"
                                    onClick={()=>{
                                    listar();
                                }}>
                                    <i className="fa fa-search"></i>
                                </button>
                                &nbsp; &nbsp;
                                <button
                                    className='btn btn-success btn-sm'
                                    onClick={() => generarExcel()}
                                >Generar Excel</button>
                            </div>
                        </div>

                        <LoadMask loading={cargando}>
                            <div className="d-flex flex-row table-responsive" style={{maxHeight: 400}}>
                                <table className="table">
                                    <thead>
                                        <tr style={{background: 'gray'}}>
                                            <th className='text-center'>Monto</th>
                                            <th className='text-center'>Tipo</th>
                                            <th className='text-center'>Tipo gasto</th>
                                            {/*
                                            <th className='text-center'>Fecha</th>
                                            <th className='text-center'>Doc. Ingreso</th>
                                            <th className='text-center'>Doc. Pago</th>
                                            */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {data.list && data.list.map((detalle, d)=>{
                                        return(
                                            <React.Fragment>
                                            <tr
                                                key={d}
                                                onClick={()=> {
                                                    console.log("detalle.tipo_gasto: ", detalle.tipo_gasto, " state.tipo_gasto: ", this.state.tipo_gasto);
                                                    if( (detalle.tipo === 'gasto' || detalle.tipo === 'cuenta por pagar') && detalle.tipo_gasto !== this.state.tipo_gasto){
                                                        getDetalleGastos(detalle.tipo_gasto, detalle.tipo);
                                                        this.setState({tipo_gasto: detalle.tipo_gasto, tipo: detalle.tipo});
                                                    }else{
                                                        clearDetalleGastos();
                                                        this.setState({tipo_gasto: ''});
                                                    }
                                                }}
                                            >
                                                <td
                                                    className='text-right'
                                                >
                                                    <RenderCurrency value={detalle.monto}/>
                                                </td>
                                                <td className='text-center'>{detalle.tipo}</td>
                                                <td className='text-center'>{detalle.tipo_gasto}</td>

                                                {/*
                                                <td className='text-center'>{moment(detalle.fecha).format('DD/MM/YYYY HH:mm:ss')}</td>switch
                                                <td className='text-center'>{detalle.documento_ingreso}</td>
                                                <td className='text-center'>{detalle.documento_pago}</td>
                                                */}
                                            </tr>
                                            {detalle_gastos && detalle_gastos.length > 0 && detalle_gastos[0].spending_type.name === detalle.tipo_gasto && detalle.tipo === this.state.tipo &&
                                                <tr>
                                                        <td colSpan={3} className='w-100'>

                                                                <table className='table table-sm table-bordered w-75 float-center m-auto'>
                                                                    <thead>
                                                                        <tr style={{background: 'gray'}}>
                                                                            <th className='text-center'>Monto</th>
                                                                            <th className='text-center'>No. Documento</th>
                                                                            <th className='text-center'>Fecha</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        this.state.tipo === 'gasto'
                                                                        ?
                                                                            detalle_gastos.map((detalle, key) => (
                                                                                <tr>
                                                                                    <td className='text-right'>Q{detalle.amount}</td>
                                                                                    <td className='text-center'>{detalle.document_number_payment}</td>
                                                                                    <td className='text-center'>{moment(detalle.creado).format("DD-MM-YYYY")}</td>
                                                                                </tr>
                                                                            ))
                                                                        :
                                                                            detalle_gastos.map((detalle, key) => (
                                                                                <tr>
                                                                                    <td className='text-right'>Q{detalle.total}</td>
                                                                                    <td className='text-center'>{detalle.entry_movement.bill_number}</td>
                                                                                    <td className='text-center'>{moment(detalle.created).format("DD-MM-YYYY")}</td>
                                                                                </tr>
                                                                            ))
                                                                    }

                                                                    </tbody>
                                                                </table>
                                                        </td>
                                                </tr>
                                            }
                                            </React.Fragment>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gastos;
