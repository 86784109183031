const custom_validate = values => {
    const errors = {};
    if (values.first_name === undefined || values.first_name === null) {
        errors.first_name = 'Campo requerido';
    } else if (values.first_name.trim() === '') {
        errors.first_name = 'Campo requerido';
    }
    if (values.username === undefined || values.username === null) {
        errors.username = 'Campo requerido';
    } else if (values.username.trim() === '') {
        errors.username = 'Campo requerido';
    }

    if (values.change_password === true) {
        // Si acepto el cambio de contrasenia se validan los otros campos
        if (values.password_old === undefined || values.password_old === null) {
            errors.password_old = 'Campo requerido';
        } else if (values.password_old.trim() === '') {
            errors.password_old = 'Campo requerido';
        }
        if (values.password === undefined || values.password === null) {
            errors.password = 'Campo requerido';
        } else if (values.password.trim() === '') {
            errors.password = 'Campo requerido';
        }
        if (values.password_confirm === undefined || values.password_confirm === null) {
            errors.password_confirm = 'Campo requerido';
        } else if (values.password_confirm !== values.password) {
            errors.password_confirm = 'Las contraseñas no coinciden';
        } else if (values.password_confirm.trim() === '') {
            errors.password_confirm = 'Campo requerido';
        }
    }
    return errors;
};

export default custom_validate;
