import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/retentionPayment';

import RetentionPayment from './RetentionPayment';
import {resetStore} from "../../../redux/modules/login";

const ms2p = state => ({
    ...state.retention_payment,
});

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(RetentionPayment);
