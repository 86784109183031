import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/reportes/pagoGrupal';

import PagosGrupales from './PagosGrupales';
import {resetStore} from "../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.reporte_pago_grupal,
        usuario: state.login.me,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(PagosGrupales);