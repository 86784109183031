import React, { Component } from 'react';
import './payment.css';
import Factura from './Factura';
import { PrintContainer, actions as printActions } from "Utils/Print";

class ViewFinishSale extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    render(){

        //const { productsInCarOrdered } = this.props;
        const { productsInCarOrderedView } = this.props;

        return(
            <div className="content-with-tabs">
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            CAJA
                        </a>
                    </div>
                </div>

                <div className="table-decoration w-25 h-100 justify-content-center mx-5 mt-4 mb-3 d-flex flex-column sales-services no-print" id="right-tab">
                    <div className="d-flex justify-content-around align-items-center title-print-view p-4">
                        <button onClick={() => this.props.crearNuevaVenta()} className="btn btn-primary btn-sm">Nueva Venta</button>

                        <button type="button" className="btn btn-sm btn-success"  onClick={(e) => {
                                e.preventDefault();
                                printActions.print('facturas');
                            }}>Imprimir</button>
                    </div>
                    <div className="d-flex flex-column p-5 fac-content">
                        <PrintContainer name='facturas' className="impresion-venta">
                            {productsInCarOrderedView.map((factura, i) =>
                                <Factura
                                    key={i}
                                    factura={factura}
                                />
                            )}
                        </PrintContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewFinishSale;
