import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import '../scheduleSurgery/schedule.css';

import './surgery.css';
import surgery from '../../../redux/modules/surgery';

import MyModal from '../Utils/myModal';
import ServiceForm from './servicesForm';

export default class PerformSurgery extends Component {
    state = {
        barra_desplegada: true,
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.props.performSurgery(this.props.match.params.id);
        }
    }

    toogleBarraLateral(){
        const valor = this.state.barra_desplegada;
        this.setState({ barra_desplegada: !valor});
        this.props.toggleModalServicios(false);
    }

    render() {
        const {
            cargando,
            services_perform_surgery,
            end_surgery_loader
        } = this.props;

        const {
            surgery_info
        } = this.props;

        const claseBarraLateral = (this.state.barra_desplegada) ? "barra-cirujia-contenido" : "barra-cirujia-contraida";
        const claseBarra = (this.state.barra_desplegada) ? "barra-cirujia-derecha" : "barra-cirujia-derecha-contraida";
        const barrita = (this.state.barra_desplegada) ? "barra-cirujia-desplegable" : "barra-cirujia-desplegable-contraida"
        const descripcionCirujia = (this.state.barra_desplegada) ? "decripcion-cirujia" : "decripcion-cirujia-expandida"
        const flex = (this.state.barra_desplegada) ? 'd-flex' : '';

        let procedimiento = '';
        if(!!surgery_info && surgery_info.surgery_services.length > 0){
            procedimiento = surgery_info.surgery_services[0].service.name;
        }

        console.log("Surgery Info: ", surgery_info);
        console.log("UpdateData ------------>>>>> ", this.props.updateData);

        return (
            <div className="content-with-tabs">
                <LoadMask loading={end_surgery_loader}>
                <div className="d-inline-flex w-100 h-100">
                <div className={"ml-5 mr-5 mt-2 my-4 d-flex flex-column " + descripcionCirujia}>
                    <div className="mb-2">
                        <a href='/#/cirugias-de-hoy/'>
                            <img className="bodega-tab-icon" src={require("../../../../assets/img/icons/return-icon-blue.png")} alt="icono" />
                            <span className='link-retorno'>&nbsp; Regresar</span>
                        </a>
                    </div>

                    <div className="table-decoration p-3 d-flex week-width tab-div-height">
                        <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-row justify-content-between mt-2 mb-3 w-100">
                            <label>{procedimiento}</label>
                            <MyModal
                                isOpen={this.props.modal_service}
                                title="Agregar Productos"
                                formId="selectCard"
                                textButton="Agregar"
                                classToggleButton="btn btn-xs btn-success"
                                searchService={this.props.searchService}
                                body={
                                    <ServiceForm
                                        onSubmit={this.props.addServiceToListInSurgery}
                                        data_service={this.props.data_service}
                                        page_service={this.props.page_service}
                                        cargando_services={this.props.cargando_services}
                                        listarServicios={this.props.listarServicios}
                                        addServiceToList={this.props.addServiceToListInSurgery}
                                        searchService={this.props.searchService}
                                        buscador_service={this.props.buscador_service}

                                        selected_referent={this.props.updateData.referent}
                                        addServiceToListDiff={this.props.addServiceToListDiffInSurgery}
                                    />
                                }
                            />
                        </div>

                        <div className="table-surgery-content">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-secondary">
                                    <th className="text-black">Servicio</th>
                                    <th className="text-black">Código (barras)</th>
                                    {/*<th className="text-black">Cantidad</th>*/}
                                    <th className="text-black">Precio</th>
                                    <th className="text-black">Productos Usados</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                 services_perform_surgery.map(element => (
                                    <React.Fragment>
                                        {element.type !== 2 &&
                                        <tr key={element.id}>
                                            <td>{element.name}</td>
                                            <td>{element.barcode}</td>
                                            {/* <td>{element.quantity}</td> */}
                                            <td>{element.price}</td>
                                            <td>
                                                <div className="group-button-up-down">
                                                    <button type="button" className="button-content left-tab" onClick={() => {this.props.upDownCantInSurgery(element.id, false)}}>
                                                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                                                    </button>

                                                    {/*<span className="input-cant text-left" style={{width:"50px",textAlign:"center"}}>{element.used}</span>*/}
                                                    <input
                                                        className='input-cant text-left'
                                                        type="number"
                                                        value={element.used}
                                                        onChange={(e)=>{
                                                            const valor = e.target.value === '' ? 0 : parseFloat(e.target.value);
                                                            this.props.setCantInSurgery(element.id, valor);
                                                        }}
                                                    />
                                                    <button type="button" className="button-content right-tab" onClick={() => {this.props.upDownCantInSurgery(element.id, true)}}>
                                                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                                                    </button>

                                                    {!element.initial &&
                                                    <img
                                                        className="ml-3 action-img delete"
                                                        onClick={() => this.props.removeServiceToListInSurgery(element.id)}
                                                        src={require("../../../../assets/img/icons/delete-icon.png")}
                                                        alt="eliminar"
                                                    />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        }
                                     </React.Fragment>
                                 ))
                                }
                            </tbody>
                        </table>
                        </div>
                        <div className="d-flex flex-column">
                            <label>Observaciones</label>
                            <textarea className="w-100 annotations" style={{borderRadius:15}} onChange={(e)=>this.props.setObservations(e)}/>
                        </div>
                        </div>
                    </div>
                </div>

                <div className={"d-flex flex-row align-items-center " + claseBarra}>
                    <div className={"d-flex flex-row " + barrita} onClick={()=>this.toogleBarraLateral()}>

                    </div>
                    <div className={flex + " flex-column align-items-start " + claseBarraLateral}>

                        <div className="d-flex flex-row align-self-center mt-4">
                            <button
                                className='btn btn-sm btn-info'
                                onClick={()=>this.props.endSurgery(this.props.match.params.id)}
                            >Finalizar Cirugía</button>
                        </div>

                        <div className="p-3 mt-3">
                            <span className="font-weight-bold title-size">CIRUGÍA</span>
                        </div>

                        <div className="pl-3">
                            <span className="font-weight-bold content-subtitle-size">Médico encargado</span>
                        </div>
                        <div className="pl-3 w-100">
                            <div className="data-barra p-1">
                                <span className="pl-3 content-text-size">{surgery_info != null ? surgery_info.doctor.name : ''}</span>
                            </div>
                        </div>

                        <div className="pl-3 mt-3">
                            <span className="font-weight-bold content-subtitle-size">Nombre del paciente</span>
                        </div>
                        <div className="pl-3 w-100">
                            <div className="data-barra p-1">
                                <span className="pl-3 content-text-size">{surgery_info != null && surgery_info.patient != null ? surgery_info.patient.first_name : ''} &nbsp; {surgery_info != null && surgery_info.patient != null ? surgery_info.patient.last_name : ''}</span>
                            </div>
                        </div>

                        {/* REFERETE */}
                        <div className="pl-3 mt-3">
                            <span className="font-weight-bold content-subtitle-size">Referente</span>
                        </div>

                        <div className="pl-3 w-100">
                            <div className="data-barra p-1">
                                <span className="pl-3 content-text-size">{surgery_info && surgery_info.referent ? surgery_info.referent.name : 'Sin referente'} &nbsp;</span>
                            </div>
                        </div>
                        {/* FIN REFERETE */}

                        {!!surgery_info && surgery_info.eye !== null &&
                        <React.Fragment>
                            <div className="pl-3 mt-3">
                                <span className="font-weight-bold content-subtitle-size">OJO</span>
                            </div>
                            <div className="pl-3 w-100">
                                <div className="data-barra p-1">
                                    <span className="pl-3 content-text-size">{!!surgery_info && surgery_info.eye}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }

                        <div className="pl-3 mt-3">
                            <span className="font-weight-bold content-subtitle-size">Fecha</span>
                        </div>
                        <div className="pl-3 w-100">
                            <div className="data-barra p-1">
                                <span className="pl-3 content-text-size">{surgery_info != null ? surgery_info.date : ''}</span>
                            </div>
                        </div>

                        <div className="pl-3 mt-3">
                            <span className="font-weight-bold content-subtitle-size">Hora</span>
                        </div>
                        <div className="pl-3">
                            <div className="data-barra p-1 d-inline">
                                <span className="pl-1 pr-1 content-text-size">{surgery_info != null ? surgery_info.start : ''}</span>
                            </div>

                            <span className="pl-1 pr-1 content-text-size d-inline"> Hrs. &nbsp;&nbsp; A</span>

                            <div className="data-barra p-1 d-inline">
                                <span className="pl-1 pr-1 content-text-size">{surgery_info != null ? surgery_info.end : ''}</span>
                            </div>

                            <span className="pl-1 pr-1 content-text-size d-inline"> Hrs.</span>
                        </div>
                        <div className="pl-3 mt-3">
                            <span className="font-weight-bold content-subtitle-size">Anotaciones</span>
                        </div>
                        <div className="pl-3 w-100">
                            <div className="data-barra p-1">
                                <span className="content-text-size">{surgery_info != null ? surgery_info.annotations : ''} </span>
                            </div>
                        </div>

                    </div>
                </div>

                </div>

                </LoadMask>
            </div>
        )
    }
}
