
import React, { Component } from 'react';
import Select, { AsyncCreatable, Async } from 'react-select';

class MySelect extends Component {
    state = {
        isFocus: false,
    };

    onStateChange = (value) => {
        this.setState({ isFocus: value});
    }

    logChange = (val) => {
        console.log("Selected: " + val);
    }

    render(){
        const {input, changeFunction, options, defaultValue, index, maxMenuHeight, valueKey, labelKey} = this.props;

        return(
            <React.Fragment>
                <Select
                    onChange={(e) => {
                        input.onChange(e ? e : null)

                    }}
                    options={options}
                    defaultValue={defaultValue}
                    valueKey={valueKey}
                    labelKey={labelKey}
                    maxMenuHeight={maxMenuHeight}
                    onFocus={()=> this.onStateChange(true)}
                    onBlur={()=> this.onStateChange(false)}
                    onChange={(e) =>{
                        //this.props.setLote(index, e.target.value)
                        console.log("Tarjet: ", e);
                        changeFunction(index, e);
                    }}
                />

                {this.state.isFocus ? <div><br /><br /><br /><br /><br /><br /></div> : null }
            </React.Fragment>
        );
    }
}

export default MySelect;
