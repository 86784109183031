import Table from "../../Utils/Grid";

import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import moment from "moment";
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";

class BajaBodega extends Component {
    state = {
        fechaInicial: this.props.initial_date_bajas,
        fechaFinal: this.props.final_date_bajas,
    };

    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        this.props.listarBajas()
    }

    setFechaInicial(value) {
        this.setState({ fechaInicial: value });
    }

    setFechaFinal(value) {
        this.setState({ fechaFinal: value });
    }

    render() {
        const {
            listarBajas,
            setInitialDateBajas,
            setFinalDateBajas,
        } = this.props;

        const {
            cargando,
            page_bajas,
            data_bajas,
            initial_date_bajas,
            final_date_bajas,
        } = this.props;

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">


                    <div className="d-flex flex-row justify-content-around align-items-center">

                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-2 text-uppercase text-titulo"><strong>BAJAS A BODEGA</strong></span>
                    </div>

                    <div className="d-flex flex-row justify-content-end align-items-center">
                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20"
                            value={this.state.fechaInicial}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    this.setFechaInicial(values.formattedValue);
                                }                                                                                
                            }}
                            autoFocus
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />


                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20 ml-2 mr-2"
                            value={this.state.fechaFinal}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    this.setFechaFinal(values.formattedValue)
                                }                                                                                
                            }}
                            
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />
                    <div className="top-title-toolbar mt-3">
                        <CustomToolbar
                            buscar={this.props.searchBajas}
                            buscador={this.props.buscador_bajas}
                            date = {{
                                setInitialDate: setInitialDateBajas,
                                fechaInicial: this.state.fechaInicial,
                                setFinalDate: setFinalDateBajas,
                                fechaFinal: this.state.fechaFinal,
                            }}
                        />
                    </div>
                    </div>
                    </div>

                        <Table
                            onPageChange={listarBajas}
                            data={data_bajas}
                            loading={cargando}
                            page={page_bajas}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="creado"
                                dataSort
                                >Fecha de creación</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="bodega"
                                dataSort
                                >Bodega</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row)=>{
                                    return row.user.name;
                                }}
                                dataSort>Usuario</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"

                                dataFormat={(cell)=>{
                                    return(
                                        <Link to={`/reporte-bajas/${cell}`}>
                                        <img
                                            src={require('../../../../../assets/img/icons/view-icon.png')}
                                            className='action-img watch'
                                        />
                                        </Link>
                                    );
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default BajaBodega;
