import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/movements';

import Movements from './Movements';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.movement,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Movements);
