import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';

import Table from '../Utils/Grid';
import { BreakLine, BreakLineCenter } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';
import NumberFormat from "react-number-format";

import './movements.css';
class Movements extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    estadoMovimiento = (cell, row) => {
        //console.log("cell: ", cell, " row: ", row);
        if(cell === 2){
            return "Cuenta Abierta";

        }else if(cell === 3){
            return "Venta";
        }else if(cell === 6){
            return "Nota de crédito";
        }else if(cell === 4){
            let texto = "Por Cobrar";
            if(parseFloat(row.payment) === parseFloat(row.paid)){
                texto += " (saldada)";
            }
            return texto;
        }
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
            set_initial_date,
            set_final_date,
            cargando,
            page,
            data,
            initial_date,
            final_date,
            isNavBarOpen,
        } = this.props;

        console.log("THIS.PROPS: ", this.props);

        return (
            <div className={classNames('content pt-3', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                            <div className="d-flex flex-row justify-content-around align-items-center">

                                    <div className="top-title-toolbar mt-3 d-flex">
                                        <span className="ml-2 text-uppercase text-titulo"><strong>LISTADO DE MOVIMIENTOS</strong></span>
                                    </div>

                                    <div className="d-flex flex-row justify-content-end align-items-center w-50">
                                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                            className="form-control w-20"
                                            value={initial_date}
                                            onValueChange={(values) => {
                                                const dia = values.formattedValue.indexOf("D")
                                                const mes = values.formattedValue.indexOf("M")
                                                const anio = values.formattedValue.indexOf("Y")

                                                if((dia + mes + anio) < 0){
                                                    set_initial_date(values.formattedValue);
                                                }
                                            }}
                                            autoFocus
                                            onFocus={(e) => {
                                                e.target.setSelectionRange(0,10);
                                            }}
                                        />

                                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                            className="form-control w-20 ml-2 mr-2"
                                            value={final_date}
                                            onValueChange={(values) => {
                                                const dia = values.formattedValue.indexOf("D")
                                                const mes = values.formattedValue.indexOf("M")
                                                const anio = values.formattedValue.indexOf("Y")

                                                if((dia + mes + anio) < 0){
                                                    set_final_date(values.formattedValue);
                                                }
                                            }}

                                            onFocus={(e) => {
                                                e.target.setSelectionRange(0,10);
                                            }}
                                        />
                                    <div className="top-title-toolbar mt-3">
                                        <CustomToolbar
                                            buscar={this.props.search}
                                            buscador={this.props.buscador}
                                            autofocus
                                        />
                                    </div>
                                </div>
                            </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="correlative" dataSort>Correlativo</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="made" dataSort>Fecha de creación</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="made_fel" dataSort>Fecha de Factura</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient" formatExtraData="patient__first_name" dataSort dataFormat={showNestedFields}>Paciente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="referent" formatExtraData="referent__name" dataSort dataFormat={showNestedFields}>Referente</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="doctor" formatExtraData="refracted__name" dataSort dataFormat={showNestedFields}>Refractado Por</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="doctor" formatExtraData="doctor__name" dataSort dataFormat={showNestedFields}>Doctor que atendió</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="state" dataFormat={this.estadoMovimiento} dataSort>Movimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="fel_status" 
                                width="90px"
                                dataFormat={ cell =>  ( cell === 4 ? <span style={{color: 'red'}}>Sí</span> : <span>No</span> ) }
                                dataSort>Anulado</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                width="200px"

                                tdStyle={{
                                    paddingLeft: "0px",
                                    textAlign: 'center'
                                }}
                                thStyle={{
                                    paddingLeft: "0px",
                                    textAlign: 'center'
                                }}
                                dataFormat={
                                    activeFormatter({
                                        imp_factura: '/report-movements/print-bill',
                                        ver_factura: '/report-movements/view-bill',
                                        anular_factura: '/report-movements/cancel-bill',
                                        ruta_retorno: 'movimiento',
                                    })
                                }
                                >Acciones</TableHeaderColumn>

                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Movements;
