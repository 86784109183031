import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import ToolbarTab from '../Utils/Toolbar/ToolbarTab';
import MyModal from "../Utils/myModal";
import Form from "./MovementForm";
import './small_box';
import { replaceWithMeasure } from 'rc-mentions/lib/util';
import {RenderCurrency} from "../Utils/renderField/renderReadField"
import moment from "moment";
import NumberFormat from "react-number-format";


class SmallBoxAdmin extends Component {
    constructor() {
        super();
        this.state={
             idBox: 0
        }
    }

    componentWillMount() {
        if(this.props.match.params.id){
            this.setState({idBox: this.props.match.params.id})
            this.props.setSmallBox(this.props.match.params.id);
            this.props.getSmallBoxMovements(1, this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this.props.setInitialData()
    }

    transactionFormatter = (cell, row) => {
        if(row.bank_transaction){
            let mensaje = "Transacción Cta. No.: " + row.bank_transaction.bank_account.account_number + " (" + row.bank_transaction.bank_account.owner + ") monto: Q." + row.bank_transaction.haber
            return mensaje;
        }else if(row.payment){
            return(
                <React.Fragment>
                    <span>Pago de cuenta por pagar:</span>&nbsp;
                    <a href={'/#/view-entry-order/' + row.payment.entry_movement.id + '/view'} target="_blank">ver</a>
                </React.Fragment>
            );
        }else{
            //let mensaje = 'Pago de gasto: ' + row.spending_type.name
            let mensaje = row.description;
            return mensaje
        }
    }
    getSmallBoxMovements = (page) => {
        const {getSmallBoxMovements} = this.props;
        getSmallBoxMovements(page, this.props.match.params.id);
    }

    existeFecha(fecha){
        var fechaf = fecha.split("/");
        var d = fechaf[0];
        var m = fechaf[1];
        var y = fechaf[2];
        return m > 0 && m < 13 && y > 1900 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
    }

    render() {
        const {
            data_movements,
            buscador_movement,
            createMovement,
        } = this.props;


        const {
            small_box,
            getSmallBoxMovements,
            searchMovements,
            cargando_movements,
            page_movements,
            total_small_box,
            initialDate,
            finalDate,
        } = this.props;

        const id_caja_chica = !!small_box ? small_box.id : 0 ;
        const nombre_caja_chica = !!small_box ? small_box.name.toUpperCase() : '';
        const nit_caja_chica = !!small_box ? small_box.nit.toUpperCase() : '';


        return (

            <div className="content">
                <div className="d-flex flex-inline align-items-center">
                    <Link to="/adm-smbx">
                        <div className="d-flex my-4 align-items-center ml-3 mr-3 back-blue-arrow"/>
                    </Link>

                    <MyModal isOpen={false}
                        title="Nuevo ingreso"
                        formId="formSmallBoxMovement"
                        textButton="Realizar ingreso"
                        classToggleButton="btn btn-primary my-2 mx-2"
                        size="lg"
                        body={
                            <Form onSubmit={createMovement} ingreso={true}></Form>
                        }
                    />

                    {/*
                    <MyModal isOpen={false}
                        title="Nuevo egreso"
                        formId="bank_transaction_form"
                        textButton="Realizar egreso"
                        classToggleButton="btn btn-primary my-2 mx-2"
                        size="lg"
                        body={
                            <Form onSubmit={create_transaction} initialValues={{bank_account: this.props.updateData.id, transaction_type: EXPENDITURY, date: moment(new Date()).format("YYYY-MM-DD"), hour: moment(new Date())}}></Form>
                        }
                    />
                    */}
                </div>

                <div className="table-decoration pb-4 row-fluid w-100">

                        <div className="row-fluid w-100 mt-2 mb-1">


                    <div className="d-flex flex-row justify-content-between pl-3 pr-3 pt-2">
                            <div className="d-flex">
                                <span className="title-list-small-box">
                                    {"CAJA CHICA " + nombre_caja_chica + ", Nit: " + nit_caja_chica}
                                    &nbsp;&nbsp;&nbsp;&nbsp;Total: <RenderCurrency value={total_small_box} />
                                </span>
                            </div>
                        <div className="d-flex flex-row justify-content-end align-items-center w-50 pb-2">
                            <NumberFormat
                                name={`fecha_inicial-${this.state.idBox}`}
                                format="##/##/####" placeholder="DD/MM/YYYY"
                                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                className="form-control w-20"
                                value={initialDate?initialDate:moment().date(1).format("DD/MM/YYYY")}
                                onValueChange={(values) => {
                                    if(this.existeFecha(values.formattedValue)){
                                        this.props.setInitialDate1(values.formattedValue)
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.setSelectionRange(0, 10);
                                }}
                            />
                            <NumberFormat
                                name={`fecha_final-${this.state.idBox}`}
                                format="##/##/####" placeholder="DD/MM/YYYY"
                                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                className="form-control w-20 ml-2 mr-2"
                                value={finalDate?finalDate:moment().format("DD/MM/YYYY") }
                                onValueChange={(values) => {
                                   if(this.existeFecha(values.formattedValue)){
                                        this.props.setFinalDate1(values.formattedValue);
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.setSelectionRange(0, 10);
                                }}
                            />
                        </div>
                            <div className="flex">
                                <ToolbarTab
                                    id="toolbar-tab"
                                    buscar={searchMovements}
                                    buscador={buscador_movement}
                                    autofocus
                                />
                            </div>
                    </div>

                        <Table
                            onPageChange={this.getSmallBoxMovements}
                            data={data_movements}
                            loading={cargando_movements}
                            page={page_movements}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="entry_type_label"
                                width={'90'}
                                dataSort={true}
                                >Tipo</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="created"
                                width={'90'}
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD-MM-YYYY")
                                }}
                                >
                                Fecha movimiento
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="spending_type"
                                dataFormat={(cell, row)=>{
                                    return <div>{cell && cell.name? cell.name: ""}</div>
                                }}
                            >
                                Nombre Tipo de Gasto
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                isKey={true}
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="amount"
                                width={'90'}
                                dataFormat={(cell, row)=>{
                                    return <RenderCurrency value={cell} />
                                }}
                            >
                                Monto
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="document_number"
                                dataSort={true}>NO. FACTURA</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="alerta_minima"
                                dataSort={true}
                                dataFormat={this.transactionFormatter}
                                >
                                Detalle
                                </TableHeaderColumn>

                            {/*
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/bodega', editar: '/bodega', eliminar: destroy })}>Acciones</TableHeaderColumn>
                            */}
                        </Table>
                    </div>
                    </div>
            </div>
        )
    }
}

export default SmallBoxAdmin;
