import { api } from "api";
import moment from "moment";
import Form from './ScheduleLockForm';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import AsyncSelect from 'react-select/lib/Async';

let persons = []

const getPersons = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

export default class ScheduleLock extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    state = {
        editar: false,
        mensaje: 'Bloquear agenda',
        password: false,
        doctor_id: '',
        date: ""
    }
    
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                    editar: true,
                    mensaje: 'Bloqueo'
                });
        }
        this.setState({
            date: moment()
        })
    }

    afterSubmit = (data) => {
        this.props.daily_create(data, this.state.doctor_id)
    }

    render() {

        const {
            listar,
            daily_create,
        } = this.props;

        // const {
        //     updateData,
        //     cargando,
        // } = this.props;

        return (
            <div className="content-with-tabs">
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/surgeries" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            CIRUGÍAS DE HOY
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/surgeries-schedule" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            AGENDA
                        </a>
                    </div>
                </div>
                <div className="mx-5 my-4">
                    <div className="table-decoration">
                        <div className="fields">
                            <div className="top-title">
                                BLOQUEO DE AGENDA
                            </div>

                            <div className="px-md-5 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <div className="my-4 ml-2">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="doctor" className="">Médico para consulta</label>
                                    </div>
                                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <AsyncSelect
                                            isClearable
                                            className="form-control p-0 border-trasparent"
                                            classNamePrefix="react-select"
                                            theme={(theme) => {
                                                return {
                                                    ...theme,
                                                    borderRadius: '1em',
                                                    background: 'white',
                                                    colors: {
                                                        ...theme.colors,
                                                        text: '#415362',
                                                        primary25: '#ebebeb',
                                                        primary: '#0a5995',
                                                    },
                                                }
                                            }}
                                            classNamePrefix="react-select"
                                            cache={false} placeholder="Todos los doctores"
                                            defaultOptions={ true }
                                            searchPromptText="Todos los doctores"
                                            loadOptions={getPersons}
                                            onChange={(el) => {
                                                if (el)
                                                    this.setState({
                                                        doctor_id: el.value
                                                    }, () => {
                                                        this.props.listar(this.state.doctor_id)
                                                    })
                                                else
                                                    this.setState({
                                                        doctor_id: ''
                                                    })
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.doctor_id &&
                                    <Form onSubmit={this.afterSubmit}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
