import React, { Component } from 'react';
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import { Link } from 'react-router-dom';

export default class SurgeryReport extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.getReport(this.props.match.params.id);
        }
    }

    calculateAge = (birthday) => {
        var birthday_arr = birthday.split("-");
        var birthday_date = new Date(birthday_arr[0], birthday_arr[1] - 1, birthday_arr[2]);
        var ageDifMs = Date.now() - birthday_date.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    isMoblie = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    };

    render() {
        const {
            surgery_report,
        } = this.props;

        const valid = !!surgery_report && surgery_report !== undefined ? true : false;
        console.log("surgery_report: ", surgery_report);
        console.log("valido: ", valid);

        const age = valid ? this.calculateAge(surgery_report.patient.birthdate) : 0;
        const sexo = valid ? surgery_report.patient.gender == 1 ? 'Masculino' : 'Femenino' : '';

        let total_extra = 0
        if(valid){
            surgery_report.sugery_detail_service_surgery.forEach(insumo=>{

                const subtotal = (insumo.initial ? insumo.used_quantity - insumo.requested_quantity : insumo.used_quantity) * insumo.price;

                if(subtotal > 0){
                    total_extra += subtotal;
                }
            });
        }

        let total_cirugia = 0;
        if(!!surgery_report && surgery_report.movement_surgery !== undefined && surgery_report.movement_surgery !== null && surgery_report.movement_surgery.length){
            surgery_report.movement_surgery.forEach(mov =>{
                total_cirugia += parseFloat(parseFloat(mov.payment).toFixed(2));
            });
        }

        let diagnostico = '';
        if(!!surgery_report && surgery_report.movement_surgery !== undefined && surgery_report.movement_surgery !== null && surgery_report.movement_surgery.length){
            diagnostico = surgery_report.movement_surgery[0].movement_detail_movement[0].service.name;
        }

        let horas = '';
        if(!!surgery_report){
            const fin = new Date (new Date().toDateString() + ' ' + surgery_report.end);
            const inicio = new Date (new Date().toDateString() + ' ' + surgery_report.start);
            const minutos = ((fin - inicio) / 1000) / 60;
            horas = minutos / 60;
            //console.log("Minutos pasados: ", minutos);
            //console.log("Horas pasadas: ", minutos / 60 );
        }


        return (
            <div className="content">
                <div className="m-auto row-fluid w-100 py-4">

                    <div className="d-flex justify-content-between align-middle">
                        <Link to="/reporte-cirugias">
                            <div className="d-flex align-items-center ml-3 mr-3 back-blue-arrow"/>
                        </Link>
                        {surgery_report ? (
                            <a 
                                className={'btn btn-primary ml-3 mr-3'}
                                href={`#/pdf/${this.props.match.params.id}`}
                                target='blank'
                                style={{textDecoration:'None', color: 'white'}}
                            >
                                IMPRIMIR
                            </a>
                        ):(
                            <button className="btn btn-primary ml-3 mr-3" disabled={true}>Imprimir</button>
                        )}
                    </div>
                    <div className="table-decoration mt-3 p-3" style={{color:'black'}}>
                        <div className='d-flex flex-fill flex-row justify-content-around reporte-cirugia-encabezado'>
                            <div className='d-flex flex-row'>
                                <div className='col d-flex flex-row justify-content-around align-items-start'>
                                    <div className='d-flex flex-column justify-content-around align-items-start pt-3 pb-3 pl-0 pr-0'>
                                        <div>
                                            <label>Médico que atendió: </label>
                                            <input type="text" className='form-control w-75' value={valid ? surgery_report.doctor.name : ''} disabled />
                                        </div>
                                        <div>
                                            <label>Fecha: </label>
                                            <input type="text" className='form-control w-75' value={valid ? surgery_report.date : ''} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className='col d-flex pr-0 flex-row justify-content-around align-items-start'>
                                    <div className='d-flex flex-column justify-content-around align-items-start pt-3 pb-3 pl-0 pr-0'>
                                        <div>
                                            <label>Hora inicio: </label>
                                            <input type="text" className='form-control w-75 mr-0' value={valid ? surgery_report.start : ''} disabled />
                                        </div>
                                        <div>
                                            <label>Horas: </label>
                                            <input type="text" className='form-control w-75 mr-0' value={horas} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col w-100 flex-row justify-content-around align-items-start'>
                                <div className='flex-column justify-content-around align-items-start pt-3 pb-3 pl-0 pr-0'>
                                    <div>
                                        <label>Nombre del paciente: </label>
                                        <input type="text" className='form-control w-100'
                                               value={valid ? surgery_report.patient.first_name + ' ' + surgery_report.patient.last_name : ''}
                                               disabled/>
                                    </div>
                                    <div>
                                        <label>Diagnóstico: </label>
                                        <input type="text" className='form-control w-100' value={diagnostico}
                                               disabled/>
                                    </div>
                                    {valid && surgery_report.annotations && surgery_report.annotations != '' &&
                                        <div>
                                            <label>Anotaciones: </label>
                                            <textarea className='form-control w-100' value={valid ? surgery_report.annotations : ''}
                                                disabled/>
                                        </div>
                                    }
                                    {valid && surgery_report.observations && surgery_report.observations != '' &&
                                        <div>
                                            <label>Observaciones: </label>
                                            <textarea className='form-control w-100' value={valid ? surgery_report.observations : ''}
                                                disabled/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col col-3 d-flex flex-row justify-content-around align-items-start'>
                                <div className='flex-column justify-content-around align-items-start pt-3 pb-3 pl-0 pr-0'>
                                    <div>
                                        <label>Sexo: </label>
                                        <input type="text" className='form-control w-100' value={valid ? sexo : ''} disabled />
                                    </div>
                                    <div className='d-flex flex-row justify-content-start'>
                                        <div className="w-50">
                                            <label>Edad: </label>
                                            <div className="form-group p-0 m-0 mr-3 d-flex justify-content-start">
                                                <input type="text" className='form-control w-100' value={age} disabled/>
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <label>Ojo: </label>
                                            <div className="form-group p-0 m-0 d-flex justify-content-start">
                                                <input type="text" className='form-control w-100'
                                                       value={valid ? surgery_report.eye : ''} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*
                        <div className='d-flex flex-row justify-content-around reporte-cirugia-encabezado'>
                            <div className='d-flex flex-column justify-content-around align-items-start'>
                                <span>PRIVADO</span>
                                <span>2989</span>
                                <span>VISITANTE</span>
                            </div>
                            <div className='d-flex flex-column justify-content-around align-items-center'>
                                <span>PROLASER</span>
                                <span>{valid && surgery_report.patient.first_name} {valid && surgery_report.patient.last_name}</span>
                                <span>ALQUILER SALA CIRUGÍA CON CENTURIÓN</span>
                                <span>Dr.: {valid && surgery_report.doctor.name}</span>
                            </div>
                            <div className='d-flex flex-column justify-content-around align-items-end'>
                                <span><span className="font-weight-bold">Ciguría:</span> 1500.00</span>
                                <span><span className="font-weight-bold">Alcon:</span> 123.00</span>
                                <span><span className="font-weight-bold">Insumos:</span>    50.60</span>
                                <span>____________________</span>
                                <span><span className="font-weight-bold">TOTAL:</span> <RenderCurrency value={total_cirugia}/></span>
                            </div>
                        </div>

                        <div className='d-flex flex-row resporte-cirugia-separador' style={{height:30}}></div>

                        <div className='d-flex flex-row justify-content-around reporte-cirugia-datos'>
                            <div className='d-flex flex-column justify-content-around align-items-start'>
                                <div>
                                    <span><span className="font-weight-bold">Fecha:</span> {valid && surgery_report.date}</span>
                                    &nbsp;&nbsp;
                                    <span><span className="font-weight-bold">Hora:</span> {valid && surgery_report.start}</span>
                                </div>
                                <div>
                                    <span><span className="font-weight-bold">Anestecia:</span> No*</span>
                                    &nbsp;&nbsp;
                                    <span><span className="font-weight-bold">Horas:</span> 0*</span>
                                </div>
                                <span><span className="font-weight-bold">Enfermero/a Tit: </span> ELODIA GALVEZ *</span>
                                <span><span className="font-weight-bold">Enfermero/a. Aux:</span> FANCISCO TAX *</span>
                                <span><span className="font-weight-bold">Diagnóstico:</span> {diagnostico}</span>
                            </div>
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <span><span className="font-weight-bold">Expediente</span></span>
                                <span><span className="font-weight-bold">Anestesista</span></span>
                                <span><span className="font-weight-bold">QA.:</span> 0</span>
                            </div>
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <span><span className="font-weight-bold">Edad:</span> {age}</span>
                                <span>&nbsp;&nbsp;</span>
                                <span><span className="font-weight-bold">Comisión:</span> Sí</span>
                            </div>
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <span><span className="font-weight-bold">Sexo:</span> {sexo}</span>
                                <span>&nbsp;&nbsp;</span>
                                <span><span className="font-weight-bold">Q.:</span> 100.00</span>
                            </div>
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <span><span className="font-weight-bold">Ojo:</span> Derecho</span>
                            </div>
                        </div>
                        */}

                        <div className='d-flex flex-row resporte-cirugia-separador' style={{height:30}}></div>

                        <div className='d-flex flex-row justify-content-around reporte-cirugia-insumos'>
                            <table className='table'>
                                <thead style={{background:'gray', color:'white'}}>
                                    <tr>
                                        <th>CÓDIGO</th>
                                        <th>CÓDIGO DE BARRAS</th>
                                        <th>PRODUCTO</th>
                                        <th>Incluye</th>
                                        <th>Utilizó</th>
                                        <th>Extra</th>
                                        <th>Total</th>
                                        <th>Precio</th>
                                        <th>Total Extra Fact</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {valid && surgery_report.sugery_detail_service_surgery.map((detail, i) =>
                                        <tr key={i}>
                                            <td>{detail.service.code}</td>
                                            <td>{detail.service.barcode}</td>
                                            <td>{detail.service.name}</td>
                                            <td>{detail.initial ? detail.requested_quantity : 0}</td>
                                            {/*<td>{detail.initial ? detail.used_quantity: 0}</td>*/}

                                            <td>{detail.used_quantity}</td>

                                            <td>{detail.initial ? (detail.used_quantity - detail.requested_quantity <= 0) ? 0 : detail.used_quantity - detail.requested_quantity : detail.used_quantity}</td>
                                            <td>{detail.used_quantity}</td>
                                            <td className='text-right'><RenderCurrency value={detail.price}/></td>
                                            <td className='text-right'><RenderCurrency value={(detail.initial ? (detail.used_quantity - detail.requested_quantity <= 0) ? 0 : detail.used_quantity - detail.requested_quantity : detail.used_quantity) * detail.price} /></td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td colSpan={8}></td>
                                        <td className='font-weight-bold text-right'><RenderCurrency value={total_extra}/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
