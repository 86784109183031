import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/bitacora';

import Bitacora from './Bitacora';
import DetalleBitacora from './DetalleBitacora';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.bitacora,
    };
};

const md2p = { ...actions, resetStore };

const detalle = connect(ms2p, md2p)(DetalleBitacora);

export default connect(ms2p, md2p)(Bitacora);


export { detalle as DetalleBitacora };
