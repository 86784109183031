import React, { Component } from 'react';
import Table from '../../Utils/Grid';
import ToolbarChange from '../../Utils/Toolbar/ToolbarChange';
import { BreakLine } from '../../Utils/tableOptions';
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import { showNestedFields } from '../../Utils/MyOptions'; //Mi archivo (Ricky)
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from "moment";
import NumberFormat from "react-number-format";

class PaybackPaid extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    state = {
        redirect: true
    }

    componentDidMount = () => {
        this.props.listarPaybackPagado();
    }

    render() {
        const {
            listarPaybackPagado,
            data_paid,
            cargando_pagado,
            page_paid,
            cancelPaybackPaid,
        } = this.props;

        const {
            searchPaid,
            buscador_pagado,

            final_date,
            initial_date,
            set_initial_date,
            set_final_date,
        } = this.props;

        return (
            <div className="content-with-tabs">
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/fee-paid" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            HONORARIOS PAGADOS
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/fee" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            HONORARIOS POR PAGAR
                        </a>
                    </div>
                </div>


                <div className="m-4">
                    <div className="table-decoration">
                            <div className="d-flex flex-row justify-content-around align-items-center">

                                    <div className="top-title-toolbar mt-3 d-flex">
                                        <span className="ml-2 text-uppercase text-titulo"><strong>REPORTE DE HONORARIOS PAGADOS</strong></span>
                                    </div>

                                    <div className="d-flex flex-row justify-content-end align-items-center w-50">
                                    <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                        className="form-control w-20"
                                        value={initial_date}
                                        onValueChange={(values) => {                                        
                                            const dia = values.formattedValue.indexOf("D")
                                            const mes = values.formattedValue.indexOf("M")
                                            const anio = values.formattedValue.indexOf("Y")

                                            if((dia + mes + anio) < 0){
                                                set_initial_date(values.formattedValue);
                                            }                                                                                
                                        }}
                                        autoFocus
                                        onFocus={(e) => {
                                            e.target.setSelectionRange(0,10);
                                        }}                                    
                                    />

                                    <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                        className="form-control w-20 ml-2 mr-2"
                                        value={final_date}
                                        onValueChange={(values) => {                                        
                                            const dia = values.formattedValue.indexOf("D")
                                            const mes = values.formattedValue.indexOf("M")
                                            const anio = values.formattedValue.indexOf("Y")

                                            if((dia + mes + anio) < 0){
                                                set_final_date(values.formattedValue);
                                            }                                                                                
                                        }}
                                        
                                        onFocus={(e) => {
                                            e.target.setSelectionRange(0,10);
                                        }}                                    
                                    />
                                    <div className="top-title-toolbar mt-3">
                                        <CustomToolbar
                                            buscar={searchPaid}
                                            buscador={buscador_pagado}                                            
                                        />
                                    </div>
                                </div>
                            </div>

                            <Table
                                onPageChange={listarPaybackPagado}
                                data={data_paid}
                                loading={cargando_pagado}
                                page={page_paid}
                            >

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="doctor"
                                    formatExtraData="doctor__name"
                                    dataSort={true}
                                    dataFormat={showNestedFields}
                                >
                                    Doctor
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="total" dataSort
                                    dataFormat={(cell) => { return <RenderCurrency value={cell} />}}
                                >
                                    Monto
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="document_number"
                                >
                                    No. Documento
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="payment_type"
                                >
                                    Tipo de pago
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="created"
                                >
                                    Fecha
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={(cell) => {
                                        return <img
                                            className="action-img delete-item-package"
                                            src={require("../../../../../assets/img/icons/delete-icon.png")}
                                            onClick={() => cancelPaybackPaid(cell)}  alt="Eliminar" />
                                    }}>Acciones
                                </TableHeaderColumn>

                            </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaybackPaid;
