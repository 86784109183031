import React from 'react';
import { renderSelectCustom } from '../Utils/renderField/renderField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { connect } from 'react-redux'

let FrmInter = (props) => {
    const {
        handleSubmit,
        options,
    } = props;

    return (
        <form onSubmit={handleSubmit} id="selectEntityInServiceSurgery" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <div className="form-group">
                <Field name="id" component={"input"} type="hidden"/>
                <Field name="name" component={"input"} type="hidden" />
                <Field name="price" component={"input"} type="hidden" />
                <Field name="discount" component={"input"} type="hidden" />
                <Field name="total" component={"input"} type="hidden" />
                <Field name="barcode" component={"input"} type="hidden" />


                <label>Seleccione el ente financiero</label>
                <Field
                    name="financial_entity"
                    labelKey="label"
                    valueKey="value"
                    component={renderSelectCustom}
                    className="form-control"
                    options={options}
                />
            </div>
        </form>
    );
}

FrmInter = reduxForm({
    form: 'frmInterSurgery',
    validate: data => {
         return validate(data, {
             'financial_entity': validators.exists()('Campo requerido.'),
        })
    }
 })(FrmInter);

export default FrmInter;
