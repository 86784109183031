import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/lib/Async";

// Styles para el componente AsyncSelect de React-Select
const customStylesReactSelect = {
    option: (provided, state) => {
        return {
            ...provided,
            color: "#484848",
            padding: "0.5rem",
            backgroundColor: state.isFocused ? "#FBD0CB" : "#fff",
            ":active": {
                backgroundColor: "#ef5543",
                color: "#fff",
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? "#f5f6f7" : "#f1f1f1",
        color: "#484848",
        borderRadius: "1rem",
        minWidth: "390px",
        borderColor: state.isFocused ? "#FBD0CB" : provided.borderColor,
        border: "unset",
        boxShadow: "unset",
    }),
};

function AsyncSelectInput({
    label,
    stylesContainer,
    customStyles = {},
    promiseOptions,
    selectedOptions,
    setSelectedOptions,
    placeholder,
    withDiv = "410px",
}) {
    /* comprobar que todos los props necesarios estén definidos
    y no sean nulos o undefined (excepto customStyles)
    y no renderizar el componente si no es así */
    if (
        !label ||
        !promiseOptions ||
        !selectedOptions ||
        !setSelectedOptions ||
        !placeholder
    ) {
        return null;
    }
    return (
        <div
            className={`form-group has-feedback flex-fill ${stylesContainer}`}
            style={{ maxWidth: withDiv }}
        >
            <label className="ml-2">{label}</label>
            <AsyncSelect
                loadOptions={promiseOptions}
                placeholder={placeholder}
                styles={{ ...customStylesReactSelect, ...customStyles }}
                isSearchable={true}
                isMulti={true}
                defaultOptions
                isOptionDisabled={() => selectedOptions.length >= 20}
                onChange={(selectedOptions) => {
                    setSelectedOptions(selectedOptions);
                }}
                noOptionsMessage={() => "No hay opciones disponibles"}
            />
        </div>
    );
}

AsyncSelectInput.propTypes = {
    label: PropTypes.string.isRequired,
    stylesContainer: PropTypes.string,
    customStyles: PropTypes.object,
    promiseOptions: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    setSelectedOptions: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    withDiv: PropTypes.string,
};

export { AsyncSelectInput };
