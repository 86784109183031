import React, { PureComponent } from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import Switch from "react-switch";
import LoadMask from '../Utils/LoadMask/LoadMask';
import SideBarOptions from './Menu';
import './sidebar.css';

class SideBar extends PureComponent {
    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.logOut = this.logOut.bind(this);
        this.state = { dropdownOpen: false };
    }

    componentWillMount() {
        const {
            getInfo,
        } = this.props;

        getInfo();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.setState({ verMenu: false });
        }
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    homeActive = (item, icon) => {
        const {
            url,
        } = this.props;

        if (url.includes(item)) {
            return `${icon}-active active d-flex align-items-center`;
        }
        // the whitespace at the beginning of the string is very important to make it works properly
        return `${icon} d-flex align-items-center`;
    };

    toggleMenu() {
        return (e) => {
            e.preventDefault();
            this.setState({ verMenu: !this.state.verMenu });
        };
    }

    logOut(event) {
        this.props.logOut();
    }

    render() {
        const {
            me,
            url,
            history,
            location,
            children,
            isNavBarOpen,
            setNavBarClass,
            loader
        } = this.props;
        const { dropdownOpen } = this.state;

        return (
            <LoadMask loading={loader} blur>
            <div className="main-background">
                <div className="row-fluid header d-flex justify-content-between align-items-center">
                    <img src={require('../../../../assets/img/logo_prolaser.png')} alt="Prolaser_logo" />
                    {/* me && me.permisos && me.permisos.dashboard && this.props.location.pathname === '/home' ? 
                        <center>
                            <div style={{ color: 'white' }}>
                                {me.dashboard_visualize ? 
                                    <b>Desactivar </b>
                                    :
                                    <b>Activar </b>
                                }   <b>Dashboard</b>
                            </div>
                            <Switch
                                onColor="#0a5995"
                                offColor="#ccc"
                                onChange={(value) => {
                                    this.props.dashboardVisualize(value)
                                }}
                
                                checked={me.dashboard_visualize}
                                id="normal-switch"
                            />
                        </center>
                            : <div/>*/}
                    <Dropdown
                        isOpen={dropdownOpen}
                        className="user-name-display"
                        toggle={this.toggle}
                    >
                        <DropdownToggle caret>
                            { me && <label>{me.username} {me.role_name === "superuser" ? `(${me.user_name})` : ""}</label> }
                            <img src={require('../../../../assets/img/icons/logged-user-icon.png')} alt="Prolaser_logo" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <a
                                tabIndex="0"
                                href="#/user-profile"
                                className="no-underline cursor-pointer"
                            >
                                <DropdownItem>
                                    Mi perfil
                                </DropdownItem>
                            </a>
                            <a
                                tabIndex="0"
                                href=""
                                style={{cursor:'pointer'}}
                                className="no-underline cursor-pointer"
                                onClick={()=>this.logOut()}
                            >
                                <DropdownItem>
                                    Salir
                                </DropdownItem>
                            </a>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="rel-container">
                    <SideBarOptions
                        me={me}
                        url={url}
                        history={history}
                        location={location}
                        isNavBarOpen={isNavBarOpen}
                        setNavBarClass={setNavBarClass}
                    />

                    {children}
                </div>
            </div>
            </LoadMask>
        );
    }
}

SideBar.propTypes = {};

export default SideBar;
