import React, { Component } from 'react';

import RowComponent from './RowComponent';

export const renderDueRow =({ fields, dues, due_detail, meta: {error, submitFailed}, edit, view}) => (

    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} >
                    <RowComponent fields={fields} dues={dues} field={field} index={index} />
                </tr>
                )
            )
        }
                <tr id="add-row">
                    <td colSpan={5}></td>
                    <td className="text-center">
                            <img onClick={() => fields.push()} className="action-img add-button-r2" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </td>
                </tr>
        {/* row for the add button */}
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);
