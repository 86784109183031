import React from 'react';
import { renderSelectCustom } from '../../Utils/renderField/renderField';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector } from 'redux-form';

let FrmCancelProcedure = (props) => {
    const {
        handleSubmit,
    } = props;

    return (
        <form onSubmit={handleSubmit} id="cancelDiagnosticProcedure" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="id" component={"input"} type="hidden"/>
            <div className="form-group">
                <div className="text-center">
                <label>Motivo de anulación</label>
                </div>
                <Field  name="reason_cancellation"
                        labelKey="label"
                        valueKey="value"
                        component={renderSelectCustom}
                        className="form-control text-left"
                        options={[
                            {value:1, label:'No aplica como realizable'},
                            {value:2, label:'No es posible realizar (por la condición del paciente)'},
                        ]}
                />

            </div>
        </form>
    );
}

FrmCancelProcedure = reduxForm({
    form: 'frmCancelDiagnosticProcedure',
    validate: data => {
         return validate(data, {
             'reason_cancellation': validators.exists()('Campo requerido.'),
        })
    }
 })(FrmCancelProcedure);

export default FrmCancelProcedure;
