import SmallBox from './SmallBox';
import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/smallBox';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.small_box,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(SmallBox);
