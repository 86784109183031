import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/payback';

import PaybackPaid from './PaybackPaid';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.payback,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(PaybackPaid);
