
import './transcription_style';
import React, {Component} from 'react';
import Form from './DiagnosticTranscriptionForm';
import DiagnisticPrint from './DiagnosticPrint';
import Spoiler from '../../Utils/Spoiler';
import { PrintContainer, actions as printActions } from "Utils/Print";
import AudioReproductor from '../DiagnosticResume/Audio.js/AudioReproductor';
//import Audio from './Audio.js/audio';
import Audio from '../DiagnosticResume/Audio.js/audio';

class DiagnosticTranscription extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount = () =>{
        if(this.props.match.params.id){
            //this.props.getProcedimiento(this.props.match.params.id)
            this.props.getResumeDiagnostic(this.props.match.params.id);
        }
    }

    render() {
        const data = this.props.resume_diagnostic;
        const valid = data != null ? true: false;
        console.log("Data actual en la transcripción... ", data);

        const { updateOneDiagnostic } = this.props;

        /*
        const { data_procedimiento, updateOneDiagnostic } = this.props;
        const data = this.props;
        const valid = data != null && data != undefined ? true: false;
        */

        const paciente = valid ? data.patient.first_name + ' ' + data.patient.last_name : '';
        let procedimientos = [];
        if(valid){
            data.diagnostic_service_diagnostic.forEach((servicio) => {
                procedimientos.push(servicio.name);
            });
        }

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration mt-3">
                        <div className="top-title">
                            <span className='transcription-title'>PACIENTE {paciente}</span>
                            {valid &&
                                <React.Fragment>
                                    {procedimientos.map((servicio, key) => (
                                        <span key={key}>{servicio}</span>
                                    ))}
                                </React.Fragment>
                            }
                        </div>


                        {/* valid &&
                        <div className='d-flex flex-row justify-content-center'>
                            <div className='w-75'>
                                <Spoiler show={true} label={procedimiento}>
                                        {data_procedimiento.diagnostic_service_diagnostic_file.map((imagen) =>
                                            <img
                                                key={imagen.id}
                                                title="Imágen diangnóstico"
                                                src={imagen.imagen}
                                                style={{width:100, height:100, marginRight:20}}
                                            />
                                        )}
                                </Spoiler>
                            </div>
                        </div>
                        */}

                        {valid &&
                            <div className="d-flex flex-row justify-content-start w-75 mt-4" style={{marginLeft:125}}>
                                <Audio
                                    path={data.audio}
                                />
                            </div>
                        }
                        
                        <PrintContainer name='transcription' className="">
                            <div className='container-transcription-print contenedor-transcripcion'>
                                <DiagnisticPrint data_procedimiento={data}                                 
                                    transcription={valid ? data.transcription: ''}                                
                                />
                            </div>
                        </PrintContainer>

                        {/*<PrintContainer name='transcription' className="">*/}
                            <div className="container-transcription">
                                <Form
                                    onSubmit={updateOneDiagnostic}
                                    initialValues={{
                                        diagnostic_id: this.props.match.params.id,
                                        descripcion: valid ? data.transcription: '',
                                    }}
                                    data_procedimiento={data}
                                    sentOneDiagnostic={this.props.sentOneDiagnostic}
                                    deliveredOneDiagnostic={this.props.deliveredOneDiagnostic}

                                    show_modal_correos={this.props.show_modal_correos}
                                    cerrarModalCorreo={this.props.cerrarModalCorreo}
                                    abrirModalCorreo={this.props.abrirModalCorreo}
                                    enviarCorreo={this.props.enviarCorreo}
                                />
                            </div>
                        {/*</PrintContainer>*/}
                    </div>
                </div>
            </div>
        );
    };
}

export default DiagnosticTranscription;
