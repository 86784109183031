import classNames from 'classnames';
import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { api } from "api";

import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../../Utils/Acciones/Acciones'
import { showNestedFields } from '../../Utils/MyOptions';
import '../administration/whearhouse.css';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderTextArea, modalTextArea }  from '../../Utils/renderField/renderField';
import { validate, validators } from 'validate-redux-form';
import ToolbarTab from '../../Utils/Toolbar/ToolbarTab';

import { BootstrapTable } from 'react-bootstrap-table';

import { RenderCurrency } from  '../../Utils/renderField/renderReadField';
import DatePicker from '../../Utils/DatePicker/datePickerCustom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux'
import Select, { AsyncCreatable, Async } from 'react-select';

import "./baja.css";
import "../administration/whearhouse.css";

import MySelect from "./MySelect";
 
class BajaBodega extends Component {
    componentWillMount() {
        if(this.props.match.params.id){
            this.props.setIdBodega(this.props.match.params.id);
        }
        this.props.listarServicios();
    }

    componentWillUnmount() {
        this.props.resetStore();
    }
    
    isExpandableRow = (cell, row) => {
        if(cell.inventario.length){
            return true;
        } else {
            return false;
        }
    }

    expandComponent = (row) => {
        console.log("ROW: ", row);

        const nombre_producto = row.name;

        return (
            <React.Fragment>
                <div className="d-flex justify-content-around mr-md-3 pr-md-3 py-md-2 expanded-prices-rows">
                    <div className="w-25 d-flex justify-content-center">
                        Cantidad
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Lote
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Acción
                    </div>
                </div>
                {
                    row.inventario.map((dato, index) => (
                        <div key={index} className="d-flex justify-content-around mr-md-3 pr-md-3 py-md-2 expanded-prices-rows">
                            <div className="w-25 d-flex justify-content-center">
                                {dato.existencias}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {dato.lote.lote.startsWith('PRO') ?
                                    ' '
                                :
                                    <React.Fragment>
                                        {dato.lote.lote}
                                    </React.Fragment>
                                }
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                <button onClick={() => {this.props.addServiceToList(dato,nombre_producto)}} className="circle-button-car">
                                    <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon" />
                                </button>
                            </div>
                        </div>
                        )
                    )
                }
            </React.Fragment>
        );
    }

    addToCar = (data, row, cell) => {
        return(
            <button onClick={() => {this.props.addServiceToList(row)}} className="circle-button-car">
                 <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/add-icon-blue.png")} alt="Add icon" />
            </button>
        );
    }

    deleteFormatter = (row, cell, enumObject, index) => {
       return (<button onClick={()=>this.props.removeServiceToList(index)} className="circle-button-delete">
                    <img className="card-img-top d-block" src={require("../../../../../assets/img/icons/delete-patient-icon.png")} alt="Delete icon" />
                </button>);
    }

    cantidadFormatter = (cell, row, enumObject, index) => {
        const id = row.id;
        const cant = row.cantidad;

        return(
            <React.Fragment>
                <div className="group-button-up-down">
                    <button className="button-content left-tab" onClick={() => {this.props.upDownCant(index, false)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                    <span className="input-cant text-left" style={{width:"50px",textAlign:"center"}}>{cant}</span>
                    <button className="button-content right-tab" onClick={() => {this.props.upDownCant(index, true)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                </div>
            </React.Fragment>
        );
    }

    existeFormatter = (cell, row, enumObject, index) => {
        return(
            <React.Fragment>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <input
                        value={cell}
                        className="ingreso-bodega-number-format text-center"
                        type="number"
                        onChange={(e)=>{
                            const valor = e.target.value === '' ? 0 : parseFloat(e.target.value);
                            this.props.setCantidadBaja(index, valor);
                        }}
                    />
                    {/*
                    <NumberFormat   prefix=''
                        value={(cell) != 0 ? cell : ''}
                        onValueChange={(values) => {
                            this.props.setCantidadBaja(index, values.value);
                        }}
                        className="ingreso-bodega-number-format text-right pr-2"
                    />
                    */}
                    {row.precio_error &&
                        <span className="text-red">campo requerido</span>
                    }
                </div>
            </React.Fragment>
        );
    }

    barcodeFormatter = (cell) => {
        return <span className="barcode-service-list">{cell}</span>
    }

    render() {
        const {
            chargeInventory,
            isNavBarOpen,
        } = this.props;


        const {
            bodega,
            data_inventario,
            cargando,
            page_inventario,
            cargando_service,
            form_validate,
        } = this.props;

        //console.log("servicios: ", this.props);
        console.log("Products in Car: ", this.props.productsInList);

        return (
            <React.Fragment>
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-100 tab-background-low">
                        <div className="d-flex pl-5 justify-content-start align-items-center tab">
                            <a href={"/#/administrar-bodega/" + this.props.match.params.id + "/admin"} className="bodega-flechita">
                            <img className="bodega-tab-icon" src={require("../../../../../assets/img/icons/return-icon-white.png")} alt="icono" />
                            </a>&nbsp;
                            <span className="text-white bodega-subtitulo">BAJA DE Producto - {bodega !== null ? bodega.subsidiary.name + " / " + bodega.name : ""}</span>
                        </div>
                    </div>
                </div>


                    <div className="table-decoration row mx-5 mt-3 mb-3 d-flex">
                        <div className="row-fluid w-100 mt-2 mb-1">

                        <form className="py-3 px-3" name="in-bodega" id="in-bodega">
                            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                <div className="m-2 w-80">
                                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <label htmlFor="note" className="m-0">Motivo de la baja</label>
                                    </div>
                                    <Field
                                        name="note"
                                        valueKey="value"
                                        labelKey="label"
                                        component={modalTextArea}
                                        className="ingreso-textarea"
                                    />
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>


                <div className="table-decoration w-50 h-100 mx-5 ml-5 d-flex flex-column sales-services" id="left-tab-bodega">
                    <div className="fields">

                        <div className="m-auto row-fluid w-100 h-100">
                            <div className="table-decoration rcp-table-decoration" id="comodin-table">
                                <div className="pl-3 pr-3 d-flex flex-wrap h-25 justify-content-start align-items-center">
                                    <div className="d-flex pb-2 flex-column w-50 justify-content-center align-items-center">
                                        <div className="d-inline-flex w-100 pt-3 justify-content-start service-title">
                                            LISTADO DE SERVICIOS
                                        </div>
                                        <ToolbarTab
                                            id="toolbar-tab"
                                            buscar={this.props.searchInventario}
                                            buscador={this.props.buscador_inventario}
                                        />
                                    </div>

                                        <div className="table-responsive">
                                            <Table
                                                onPageChange={chargeInventory}
                                                data={data_inventario}
                                                loading={cargando}
                                                page={page_inventario}
                                                maxHeight={370}
                                                expandableRow={ this.isExpandableRow }
                                                expandComponent={ this.expandComponent }
                                            >

                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataField="name"
                                                dataSort
                                                >Servicio</TableHeaderColumn>

                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataField="barcode"
                                                dataSort
                                                dataFormat={this.barcodeFormatter}
                                                >Código de barras</TableHeaderColumn>

                                            <TableHeaderColumn
                                                dataField="id"
                                                isKey={true}
                                                dataAlign="center"
                                                dataField="cantidad"
                                                ></TableHeaderColumn>
                                            </Table>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-decoration h-100  align-items-around d-flex flex-column sales-services" id="right-tab-bodega">
                    <div className="table-responsive-sm">
                        <BootstrapTable data={this.props.productsInList}
                            maxHeight={400}
                            minHeight={400}
                            trClassName="customClass"
                        >

                            <TableHeaderColumn width='100' dataField='nombre_producto'>Producto</TableHeaderColumn>
                            <TableHeaderColumn width='100'
                                dataField='lote'
                                dataFormat={(cell) => {
                                    if(cell && cell.startsWith('PRO')){
                                        return ' ';
                                    }else{
                                        return cell;
                                    }
                                }}
                            >Lote</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='cantidad_por_lote'
                                //dataFormat={this.cantidadFormatter}
                            >Existencia en sistema</TableHeaderColumn>

                            <TableHeaderColumn
                                //dataFormat={ this.priceFormatter }
                                dataFormat={ this.existeFormatter }
                                width='100'
                                dataField='cantidad_baja'
                            >Baja de Producto</TableHeaderColumn>
                            <TableHeaderColumn width='50' dataField='id' isKey
                                dataFormat={this.deleteFormatter}
                            >
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <div className="d-flex justify-content-end align-items-center p-3 bodega-ingreso-footer">

                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-center h-100 pr-5">
                        <button className="btn btn-secondary btn-sm" onClick={()=>this.props.cancelEntry()}>Cancelar</button>
                        {this.props.productsInList.length > 0  && form_validate ?
                            <button type="button" className="btn btn-primary btn-sm ml-3"
                                onClick={this.props.finishBaja}
                            >Finalizar</button>
                            :
                            <button className="btn btn-primary btn-sm ml-3 disabled">Finalizar</button>
                        }
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

BajaBodega = reduxForm({
    form: 'bajaBodega',

   validate: data => {
    const errors={};
    if(!data.note || data.note == null){
        errors.note = 'Campo requerido';
    }

     return errors;
    }
 })(BajaBodega);

const selector = formValueSelector('bajaBodega');

BajaBodega = connect(state => {
    let form_validate = true;

    const note = selector(state, "note");
    if(note === undefined || note=== ''){
        form_validate = false;
    }

    return {
        form_validate
    };

})(BajaBodega);

export default BajaBodega;
