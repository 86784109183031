import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/reportes/procedimientosPromedio';
import ProcedimientosPromedio from './ProcedimientosPromedio';


const ms2p = (state) => {
    return {
        ...state.procedimientos_promedio,
    };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProcedimientosPromedio);