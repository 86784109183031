import { connect } from 'react-redux';
import Schedule from './ScheduleLock';
import { actions } from '../../../redux/modules/scheduleLockSurgery';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.scheduleLockSurgery,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Schedule);
