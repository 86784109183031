import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from "classnames";
import {
    VISUAL_ACUITY,
    TONOMETRY,
    REFRACTOR,
    MUSCLES,
    LENSOMETRY,
    KERATOMERY,
    INDIRECT_EYE,
    AUTOREFRACTOR,
    LAMP,
    RECORD,
    RECONSULT,
    DIAGNOSTIC_PLAN,    
    EDUCATIONAL_PLAN,    
    OPTICS_ORDER,
    ENDED
} from "../../../../utility/variables";

import RecordForm from "../Record/Form";
import AutorefractorForm from "../Autorefractor/Form";
import IndirectEyeForm from "../IndirectEye/Form";
import KeratometryForm from "../Keratometry/Form";
import LampForm from "../Lamp/Form";
import LensometryForm from "../Lensometry/Form";
import MuscleForm from "../Muscle/Form";
import RefractionForm from "../Refraction/Form";
import TonometryForm from "../Tonometry/Form";
import VisualAcuityForm from "../VisualAcuity/Form";
import OpticsOrderForm from "../OpticsOrder/Form";

import ReconsultForm from "../Reconsult/Form";
import EducationalPlanForm from "../EducationalPlan/Form";
import DiagnosticPlanForm from "../DiagnosticPlan/Form";

import Patient from "../Patient";
import {Link} from "react-router-dom";
import Step from "./Step";
import './exam_stayle';
import Swal from "sweetalert2";
export default class Exam extends Component {
    state = {open: false};

    componentDidMount() {
        const { leer, match, show_list } = this.props;
        leer(match.params.id);
        //this.props.simularDetail();
        const { getLastRecordPlan } = this.props;
        getLastRecordPlan(match.params.id);

        if(show_list === RECORD){
            this.props.getRecordDetail(match.params.id);
        }else if(show_list === LENSOMETRY){
            this.props.getLensometryDetail(match.params.id);
        }else if(show_list === VISUAL_ACUITY){
            this.props.getVisualAcuityDetail(match.params.id);
        }else if(show_list === AUTOREFRACTOR){
            this.props.getAutorefractorDetail(match.params.id);
        }else if(show_list === KERATOMERY){
            this.props.getKeratometryDetail(match.params.id);
        }else if(show_list === REFRACTOR){
            this.props.getRefractorDetail(match.params.id);
        }else if(show_list === MUSCLES){
            this.props.getMuscleDetail(match.params.id);
        }else if(show_list === TONOMETRY){
            this.props.getTonometryDetail(match.params.id);
        }else if(show_list === LAMP){
            this.props.getLampDetail(match.params.id);
        }else if(show_list === INDIRECT_EYE){
            this.props.getIndirectEyeDetail(match.params.id);
        }else if(show_list === RECONSULT){
            this.props.getReconsultDetail(match.params.id);
        }else if(show_list === DIAGNOSTIC_PLAN){
            this.props.getDiagnosticPlan(match.params.id);
        }else if(show_list === EDUCATIONAL_PLAN){
            this.props.getEducationalPlan(match.params.id);
            this.props.getRefractorDetail(match.params.id);
        }else if(show_list === OPTICS_ORDER){
            this.props.getOpticsOrderDetail(match.params.id);
            this.props.getRefractorDetail(match.params.id);
            this.props.getAutorefractorDetail(match.params.id);
        }
    }

    saveOpticsOrder = async () => {
        const appointment_id = this.props.match.params.id
        await this.props.saveConsultStep(appointment_id, OPTICS_ORDER, true)
    }

    submitForm = (data) => {
        this.props.setStep(data, this.props.match.params.id, this.props.show_list, false); //add...
        this.props.setOpenModalStep(false);
    };

    render() {
        const {item, show_list } = this.props;
        //const {open} = this.state;
        const open = this.props.open_modal_step;

        const steps_list = [RECORD, LENSOMETRY, VISUAL_ACUITY, AUTOREFRACTOR, KERATOMERY, REFRACTOR, TONOMETRY, MUSCLES, LAMP, INDIRECT_EYE, EDUCATIONAL_PLAN, DIAGNOSTIC_PLAN, OPTICS_ORDER, ENDED];
            
        return (
            <div id="" className="content p-2 p-md-4 d-flex flex-column">
                <Modal open={open} onClose={() => this.setState({ open: false })} >
                    <Step
                        nextStep={this.submitForm}
                        complete_steps={this.props.complete_steps}
                        //cancelar={() => this.setState({open:false})}
                        cancelar={() => this.props.setOpenModalStep(false)}
                        //setStep={this.props.setStep} //add..
                    />
                </Modal>
                <div className="cuadro">
                    <div className="d-flex flex-row">
                        
                        <div style={{flex:"1"}}>
                            <div className="table-decoration py-2 px-3 d-flex flex-column" 
                            style={{ 
                                position: '-webkit-sticky',
                                position: 'sticky',
                                top: 0,
                                maxWidth: 250
                            }}>
                                {/*
                                <Patient
                                    patient={item.patient}
                                    last_consult={this.props.last_consult}
                                    last_record={this.props.last_record}
                                />
                                 */}

                                    {steps_list.map(step => {
                                    const step_mod = show_list == 23 ? 22 : show_list == 22 ? 23 : show_list
                                    const op_sel = step_mod == step

                                    return(
                                        <span
                                            onClick={()=> {                                                              
                                                const appointment_id = this.props.match.params.id
                                                if(show_list === RECORD){ 
                                                    this.props.saveRecord(appointment_id, RECORD);
                                                }else if(show_list === LENSOMETRY){
                                                    this.props.saveConsultStep(appointment_id, LENSOMETRY);
                                                }else if(show_list === VISUAL_ACUITY){
                                                    this.props.saveConsultStep(appointment_id, VISUAL_ACUITY);
                                                }else if(show_list === AUTOREFRACTOR){
                                                    this.props.saveConsultStep(appointment_id, AUTOREFRACTOR);
                                                }else if(show_list === KERATOMERY){
                                                    this.props.saveConsultStep(appointment_id, KERATOMERY);
                                                }else if(show_list === REFRACTOR){
                                                    this.props.saveConsultStep(appointment_id, REFRACTOR);
                                                }else if(show_list === MUSCLES){
                                                    this.props.saveConsultStep(appointment_id, MUSCLES);
                                                }else if(show_list === TONOMETRY){
                                                    this.props.saveConsultStep(appointment_id, TONOMETRY);
                                                }else if(show_list === LAMP){
                                                    this.props.saveConsultStep(appointment_id, LAMP);
                                                }else if(show_list === INDIRECT_EYE){
                                                    this.props.saveConsultStep(appointment_id, INDIRECT_EYE);
                                                }else if(show_list === RECONSULT){
                                                    this.props.saveReconsultStep(appointment_id, RECONSULT);
                                                }else if(show_list === EDUCATIONAL_PLAN){
                                                    this.props.saveConsultStep(appointment_id, EDUCATIONAL_PLAN);
                                                }else if(show_list === DIAGNOSTIC_PLAN){ 
                                                    this.props.saveConsultStep(appointment_id, DIAGNOSTIC_PLAN);
                                                }else if(show_list === OPTICS_ORDER){
                                                    this.props.saveConsultStep(appointment_id, OPTICS_ORDER);
                                                }                                                                                        
                                                setTimeout(()=>{
                                                    if (step === ENDED) {
                                                        Swal({
                                                            title: '¿Está seguro que desea finalizar la consulta?',
                                                            text: 'No podrá deshacer esta acción',
                                                            type: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Sí, continuar',
                                                            cancelButtonText: 'No, cancelar',
                                                            reverseButtons: true
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                //this.props.setStep(step, this.props.match.params.id, this.props.show_list, true);                                          
                                                                this.props.topBarSetStep(step, this.props.match.params.id, this.props.show_list, true); 
                                                            }
                                                        })
                                                    }else{                                                
                                                        this.props.topBarSetStep(step, this.props.match.params.id, this.props.show_list, true);                             
                                                    } 
                                                }, 700) 
                                                                                        
                                            }}
                                            className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(step) })}
                                            style={{ border: "solid 1px #0a5995", padding: "4px", height: "min-content", width: "100%", background: op_sel ? '#0a5995' : 'inherit', color: op_sel ? 'white' : 'inherit' }}
                                        >
                                            {
                                                step == RECORD ? 'ANTECEDENTES' :
                                                step == LENSOMETRY ? 'LENSOMETRÍA' :
                                                step == VISUAL_ACUITY ? 'AGUDEZA' :
                                                step == AUTOREFRACTOR ? 'AUTOREFRACTÓMETRO' :
                                                step == KERATOMERY ? 'QUERATÓMETRO' :
                                                step == REFRACTOR ? 'REFRACCIÓN' :
                                                step == TONOMETRY ? 'TONOMETRÍA' :
                                                step == MUSCLES ? 'MÚSCULOS EXTRAOCULARES' :
                                                step == LAMP ? 'LÁMPARA DE HENDIDURA' :
                                                step == INDIRECT_EYE ? 'FONDO DE OJO' :
                                                step == EDUCATIONAL_PLAN ? 'PLAN DX TX' :
                                                step == DIAGNOSTIC_PLAN ? 'ESTUDIOS Y PROC' :
                                                step == OPTICS_ORDER ? 'ORDEN DE ÓPTICA' : 
                                                step == ENDED ? 'FINALIZAR' :
                                                ''                                        
                                            } 
                                        </span>
                                    );
                                })}                                 
                            </div>
                        </div>
                        
                        <div style={{flex:"3"}}>                                                    
                            <div className='table-decoration ml-3 px-2 d-flex'>                                  
                                <Patient
                                    patient={item.patient}
                                    last_consult={this.props.last_consult}
                                    last_record={this.props.last_record}
                                />                               
                            </div>

                            <div className="table-decoration py-2 ml-3 px-3">
                                <div className='d-flex flex-row'>
                                    <div className='w-50'>
                                        {(this.props.last_consult && item !== null && item.reconsult == true) &&
                                            <React.Fragment>
                                                <span>Última consulta: </span>
                                                <label>{this.props.last_consult.date}</label>
                                            </React.Fragment>
                                        }
                                    </div>
                                {item.patient &&
                                    <div className='d-flex flex-row justify-content-end w-50'>
                                        <label className='exam-patient-name'>
                                            {`${item.patient.first_name} ${item.patient.last_name}`}
                                            &nbsp;{item.patient.nickname !== null && `(${item.patient.nickname})`}
                                        </label>
                                    </div>
                                }
                                </div>

                                {(show_list === VISUAL_ACUITY) && (<VisualAcuityForm/>)}
                                {(show_list === TONOMETRY) && (<TonometryForm />)}
                                {(show_list === REFRACTOR) && (<RefractionForm />)}
                                {(show_list === MUSCLES) && (<MuscleForm />)}
                                {(show_list === LENSOMETRY) && (<LensometryForm />)}
                                {(show_list === KERATOMERY) && (<KeratometryForm {...this.props}/>)}
                                {(show_list === INDIRECT_EYE) && (<IndirectEyeForm />)}
                                {(show_list === AUTOREFRACTOR) && (<AutorefractorForm />)}
                                {(show_list === LAMP) && (<LampForm />)}
                                {(show_list === RECORD) && (<RecordForm />)}
                                {(show_list === EDUCATIONAL_PLAN) && (<EducationalPlanForm/>)}
                                {(show_list === DIAGNOSTIC_PLAN) && (<DiagnosticPlanForm receta={this.props.receta}/>)}
                                {(show_list === OPTICS_ORDER) && (<OpticsOrderForm orden_optica={this.props.orden_optica} saveOpticsOrder={this.saveOpticsOrder}/>)}
                                {(show_list === RECONSULT) && (
                                    <ReconsultForm
                                        this_consult={item}
                                        resolveDiagnosticPlan={this.props.resolveDiagnosticPlan}
                                    />
                                )}
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    {show_list === RECONSULT &&
                                        <button className="btn bth-primary ml-1" onClick={() => this.props.setOpenModalStep(true)}>Siguiente</button>
                                    }

                                    {show_list === RECORD &&
                                        <Link to={"/record"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === LENSOMETRY &&
                                        <Link to={"/lensometry"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === VISUAL_ACUITY &&
                                        <Link to={"/visual_acuity"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === AUTOREFRACTOR &&
                                        <Link to={"/autorefractor"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === KERATOMERY &&
                                        <Link to={"/keratometry"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === REFRACTOR &&
                                        <Link to={"/refractor"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === MUSCLES &&
                                        <Link to={"/muscle"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === TONOMETRY &&
                                        <Link to={"/tonometry"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === LAMP &&
                                        <Link to={"/lamp"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === INDIRECT_EYE &&
                                        <Link to={"/indirect_eye"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === RECONSULT &&
                                        <Link to={"/reconsult"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === EDUCATIONAL_PLAN &&
                                        <Link to={"/educational_plan"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === DIAGNOSTIC_PLAN &&
                                        <Link to={"/diagnostic_plan"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }
                                    {show_list === OPTICS_ORDER &&
                                        <Link to={"/optics-order"} className="btn btn-secondary ml-1 mr-1">Cancelar</Link>
                                    }

                                    {show_list !== RECONSULT &&
                                    <button className='btn btn-sm btn-info'
                                        onClick={() => {
                                            const appointment_id = this.props.match.params.id
                                            if(show_list === RECORD){
                                                this.props.saveRecord(appointment_id, RECORD);
                                            }else if(show_list === LENSOMETRY){
                                                this.props.saveConsultStep(appointment_id, LENSOMETRY);
                                            }else if(show_list === VISUAL_ACUITY){
                                                this.props.saveConsultStep(appointment_id, VISUAL_ACUITY);
                                            }else if(show_list === AUTOREFRACTOR){
                                                this.props.saveConsultStep(appointment_id, AUTOREFRACTOR);
                                            }else if(show_list === KERATOMERY){
                                                this.props.saveConsultStep(appointment_id, KERATOMERY);
                                            }else if(show_list === REFRACTOR){
                                                this.props.saveConsultStep(appointment_id, REFRACTOR);
                                            }else if(show_list === MUSCLES){
                                                this.props.saveConsultStep(appointment_id, MUSCLES);
                                            }else if(show_list === TONOMETRY){
                                                this.props.saveConsultStep(appointment_id, TONOMETRY);
                                            }else if(show_list === LAMP){
                                                this.props.saveConsultStep(appointment_id, LAMP);
                                            }else if(show_list === INDIRECT_EYE){
                                                this.props.saveConsultStep(appointment_id, INDIRECT_EYE);
                                            }else if(show_list === RECONSULT){
                                                this.props.saveReconsultStep(appointment_id, RECONSULT);
                                            }else if(show_list === EDUCATIONAL_PLAN){
                                                this.props.saveConsultStep(appointment_id, EDUCATIONAL_PLAN);
                                            }else if(show_list === DIAGNOSTIC_PLAN){
                                                this.props.saveConsultStep(appointment_id, DIAGNOSTIC_PLAN);
                                            }else if(show_list === OPTICS_ORDER){
                                                this.props.saveConsultStep(appointment_id, OPTICS_ORDER);
                                            }
                                        }}
                                    >Guardar</button>
                                    }

                                    {/*
                                    <button className="btn bth-primary ml-1" onClick={() => this.setState({open:true})}>Finalizar</button>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
