import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/whearhouse';

import Whearhouse from './Whearhouse';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.whearhouse,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Whearhouse);
