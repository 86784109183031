import React from "react";
import { api } from "api";

const IconSearch = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
        >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
    );
};

const IconSpreadSheet = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-file-earmark-spreadsheet"
            viewBox="0 0 16 16"
        >
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
        </svg>
    );
};

const promiseOptionsGastos = (inputValue) => {
    return api
        .get(`reporte/consolidado-gastos/getTiposGastos/?search=${inputValue}`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
        });
};
const promiseOptionsProveedores = (inputValue) => {
    return api
        .get(`reporte/consolidado-gastos/getProveedores/?search=${inputValue}`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
        });
};

export {
    promiseOptionsGastos,
    promiseOptionsProveedores,
    IconSearch,
    IconSpreadSheet,
};
