import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/reportes/bodega';

import IngresoBodega from './IngresoBodega';
import {resetStore} from "../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.reporte_bodega,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(IngresoBodega);
