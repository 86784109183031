import React, { Component } from 'react';
import Form from './ProfileForm';
import LoadMask from 'Utils/LoadMask/LoadMask';


class Profile extends Component {
    componentDidMount() {
        this.props.carga_inicial();
    }

    render() {
        const { update_profile, cargando } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            <div className="fields">
                                <div className="top-title">
                                    ACTUALIZAR PERFIL
                                </div>
                                <Form onSubmit={update_profile}/>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;
