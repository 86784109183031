import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { RenderCurrency } from  '../Utils/renderField/renderReadField';
import { renderField, renderSearchSelect, modalCurrency, renderCurrency } from '../Utils/renderField/renderField';
import { connect } from 'react-redux'
import { api } from "api";

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let initialValues = {};
let SmallBoxMovementForm = (props) => {
    const { handleSubmit, ingreso } = props;
    const entry_type = (ingreso) ? 1 : 2;
    initialValues.entry_type = entry_type;

    return (
        <form onSubmit={handleSubmit} className="py-3" id="formSmallBoxMovement">
            <Field name="entry_type" component="input" type="hidden"/>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_number" className="m-0">No. Cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="bank_account"
                            valueKey="value"
                            labelKey="number"
                            label="cuenta"
                            component={renderSearchSelect}
                            loadOptions={getBankAccounts}
                            className="no-border"
                            addClass="no-border"
                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_number" className="m-0">Usuario Cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="bank_account"
                            valueKey="value"
                            labelKey="label"
                            label="referent"
                            component={renderSearchSelect}
                            loadOptions={getBankAccounts}
                            className="no-border"
                            addClass="no-border"
                        />
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="amount" className="m-0">Monto</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="amount" component={renderCurrency} className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="document_number" className="m-0">NO. FACTURA</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="document_number" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
        </form>
    );
}

SmallBoxMovementForm = reduxForm({
    form: 'formSmallBoxMovement',
    initialValues,
    validate: data => {
        const errors={};
        if(!data.bank_account){
            errors.bank_account = 'Campo requerido';
        }

        if(!data.amount){
            errors.amount = 'Campo requerido';
        }

        if(!data.document_number){
            errors.document_number = 'Campo requerido';
        }

        return errors;
    }
 })(SmallBoxMovementForm);

export default SmallBoxMovementForm;
