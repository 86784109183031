import React, {Component} from 'react';
import Modal from 'react-responsive-modal';

class Pregunta extends Component{
    render(){
        const {consulta, reconsulta} = this.props;

        return(
            <div className='d-flex flex-column'>
                <div className='d-flex flex-row mt-4'>
                    <h3>Seleccione una opción</h3>
                </div>
                <div className='d-flex flex-row justify-content-around'>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={()=>consulta()}
                    >Consulta</button>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={()=>reconsulta()}
                    >Re consulta</button>
                </div>
            </div>
        );
    }
}

export default Pregunta;
