import Table from "../../Utils/Grid";

import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import moment from "moment";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import NumberFormat from "react-number-format";

class ReporteConsulta extends Component {

    state = {
        fechaInicial: this.props.initial_date,
        fechaFinal: this.props.final_date,
    };

    componentWillMount() {
        this.props.listarMedicos();
        this.props.listarConsultas();
    }

    setFechaInicial(value) {
        this.setState({ fechaInicial: value });
    }

    setFechaFinal(value) {
        this.setState({ fechaFinal: value });
    }

    render() {
        const {
            listarConsultas,
            setInitialDate,
            setFinalDate,
            filtroMedico,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            doctors,
        } = this.props;

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                    <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">

                    <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">
                        <div className="top-title-toolbar mt-3 d-flex">
                            <span className="ml-2 text-uppercase text-titulo"><strong>REPORTE DE CONSULTAS</strong></span>
                        </div>

                        <div style={{width: 150}} className='mr-2'>
                        <Select
                            className={"w-100"}
                            onChange={(e) => {
                                filtroMedico(e.value);
                            }}
                            classNamePrefix="react-select"
                            defaultOptions
                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: '1em',
                                    background: 'white',
                                    colors: {
                                        ...theme.colors,
                                        text: '#415362',
                                        primary25: '#ebebeb',
                                        primary: '#0a5995',
                                    },
                                }
                            }}

                            searchPromptText="Escriba para buscar" valueKey={'id'} labelKey={'name'}
                            options={doctors}
                        />
                        </div>
                        <div className='d-flex flex-row w-30 mr-2'>
                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-50"
                            value={this.state.fechaInicial}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    this.setFechaInicial(values.formattedValue);
                                }                                                                                
                            }}
                            autoFocus
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />

                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-50 ml-2 mr-2"
                            value={this.state.fechaFinal}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    this.setFechaFinal(values.formattedValue)
                                }                                                                                
                            }}
                            
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />
                        </div>
                    <div className="top-title-toolbar mt-3">
                        <CustomToolbar
                            buscar={this.props.search}
                            buscador={this.props.buscador}
                            date = {{
                                setInitialDate: setInitialDate,
                                fechaInicial: this.state.fechaInicial,
                                setFinalDate: setFinalDate,
                                fechaFinal: this.state.fechaFinal,
                            }}
                            autofocus
                        />
                    </div>
                    </div>
                    </div>

                        <Table
                            onPageChange={listarConsultas}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="doctor"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    if(row.doctor){
                                        return row.doctor.name;
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                >Médico</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort
                                dataFormat={(cell, row) => {
                                    if(row.patient){
                                        if(row.patient.referred_by){
                                            return row.patient.referred_by.name;
                                        }else{
                                            return 'Sin asginar';
                                        }
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                >Referente</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField='patient'
                                dataFormat={(cell, row)=>{
                                    console.log("row: ", row);
                                    if(row.patient){
                                        return row.patient.first_name + ' ' + row.patient.last_name;
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                dataSort>Paciente</TableHeaderColumn>



                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField='date'
                                dataSort>Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell)=>{
                                    return(
                                        <Link to={`/reporte-consultas/${cell}`}>
                                        <img
                                            src={require('../../../../../assets/img/icons/view-icon.png')}
                                            className='action-img watch'
                                        />
                                        </Link>
                                    );
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReporteConsulta;
