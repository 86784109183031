import Table from '../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';
import ToolbarTab from '../Utils/Toolbar/ToolbarTab';
import moment from "moment";

class DiagnosticOrder extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    constructor(props) {
        super(props);
    }

    componentWillMount = () =>{
        this.props.listar();
    }

    render() {

        const options = [];

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto={"Crear orden de diagnóstico"}
                        ruta={"/diagnostic-order/create"}
                    />
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-between pl-3 pr-3 pt-2">
                                <div className="d-flex">
                                    <span className="title-list-iventory">ORDENES DE DIAGNÓSTICO</span>
                                </div>
                                <div className="d-flex mb-2">
                                <ToolbarTab
                                    id="toolbar-tab"
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                                </div>
                        </div>
                        <Table
                            onPageChange={this.props.listar}
                            data={this.props.data}
                            loading={this.props.cargando}
                            page={this.props.page}
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort={true}
                                dataFormat={(cell)=>{
                                    return cell.first_name + ' ' + cell.last_name;
                                }}
                                >Paciente</TableHeaderColumn>


                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY")
                                }}
                                dataField="created" dataSort>Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell)=>{
                                    return (
                                        <img
                                            onClick={()=> this.props.quitDiagnosticOrder(cell)}
                                            className="action-img delete"
                                            title="quitar"
                                            src={require("../../../../assets/img/icons/delete-icon.png")}
                                            alt="quitar"
                                        />
                                    );
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        );
    };
}

export default DiagnosticOrder;
