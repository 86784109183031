import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';

import { renderField, renderTextArea, renderSwitch } from '../../Utils/renderField/renderField';
import { connect } from 'react-redux'

const Form = props => {
    const {
        handleSubmit,
        tipo,
    } = props;

    const view = window.location.href.includes("view");
    const paquete = window.location.href.includes("paquete");

    let enlace = '';
    if(window.location.href.includes("paquete")){
        enlace = 'paquete';
    }else if(window.location.href.includes("procedimiento")){
        enlace = 'procedimiento';
    }else if(window.location.href.includes("examen")){
        enlace = 'examen';
    }

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nombre" className="">Nombre</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="nombre" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                {paquete &&
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="clinic" className="">Incluye</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="incluye" disabled={view} component={renderTextArea} type="text" className="form-control"/>
                    </div>
                </div>
                }
                <Field name="tipo_plan" disabled={view} component='input' type="hidden" className="form-control"/>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <label htmlFor="clinic" className="">¿Es cirugía?</label>
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="cirugia" disabled={view} component={renderSwitch} type="text" className="form-control"/>
                </div>
                <div className="m-1 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="comodin" className="">Comodín</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="comodin" disabled={view} component={renderTextArea} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to={`/plan-${enlace}`}>{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

let PlanForm = reduxForm({
   form: 'plan',
   validate: data => {
       return validate(data, {
           'nombre': validators.exists()('Campo requerido.'),
       })
   }
})(Form);

const selector = formValueSelector('plan');

PlanForm = connect(state => {
    const tipo = selector(state, "tipo");

    return {
        tipo,
    };

})(PlanForm);

export default PlanForm;
