import React from 'react';
import {api} from 'api';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {departamentos} from 'variables';
import {
    renderField,
    renderSearchSelect,
    modalTextArea,
    renderDatePicker,
    renderSelectCustom,
    renderSearchSelectPatient
} from 'Utils/renderField/renderField';
import MyModal from "../Utils/myModalServices";
import ServiceForm from "../surgery/servicesForm";
import {Link} from "react-router-dom";
import validate from './validation';

let referents = [];
let patients = [];
let persons = [];

const getPersons = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {
    })
        .then((data) => {
            if (data) {
                persons = [];
                data.results.forEach(x => {
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            } else {
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getReferents = async (search) => {
    try {
        const response = await api.get(`configuration/getConfiguration`);
        const default_financial_entity = response.default_financial_entity;

        const data = await api.get("referents/getReferentsWithEntity/", {search});

        if (data) {
            referents = [];
            data.forEach(x => {
                let idFinancialEntity = 0;
                let usuarioFel = "";
                let idReferentCategory = 0;
                let receipt = false;

                if (x.institution !== null) {
                    idFinancialEntity = x.institution.referent_category.financial_entity.id;
                    usuarioFel = x.institution.referent_category.financial_entity.usuario_fel;
                    idReferentCategory = x.institution.referent_category.id;
                    receipt = x.institution.referent_category.issue_receipt;
                } else {
                    idFinancialEntity = default_financial_entity.id;
                    usuarioFel = default_financial_entity.usuario_fel;
                    idReferentCategory = 0;
                    receipt = default_financial_entity.afiliacion_fel === "exento" ? true : false;
                }

                referents.push(
                    {
                        value: x.id,
                        label: x.name,
                        usuario_fel: usuarioFel,
                        financial_entity: idFinancialEntity,
                        idReferentCategory: idReferentCategory,
                        receipt
                    }
                )
            });
            return referents;
        } else {
            return [];
        }

    } catch (error) {
        console.error(error);
    }
};

const getPatients = (search) => {
    return api.get("patients", {search}).catch((error) => {
    })
        .then((data) => {
            if (data) {
                patients = [];
                data.results.forEach(x => {
                    patients.push({value: x.id, label: x.first_name + " " + x.last_name, nit: x.nit, id_referent: x.referred_by != null ? x.referred_by.id : undefined })
                })

                console.log("PACIENTES FILTRADOS: ", patients);
                return patients
            } else {
                return []
            }
        })
        .catch(e => {
            return []
        })
};

let DiagnosticOrderForm = props => {
    const {
        municipios,
        handleSubmit,
        search_patient,
        showPatientSearch,
        no_patient_options,
        showPatientOptions,
        detailPatient,
        setUpdateData,
        initializePatientBirthdate,

        showProducts,
        load_products,
        services_list,

        addServiceToListDiff,
    } = props;

    const view = false;

    return (
        <form onSubmit={handleSubmit} className="pb-3 pt-5 d-flex justify-content-center">
            <div className="form-body-width">
                {/*
                */}
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">Paciente</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="patient"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getPatients}
                                onChange={(e) => props.setPaciente(e)}
                                className="no-border"
                                autofocus
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referent" className="">Referente</label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="referent"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getReferents}
                                className="no-border"
                            />
                        </div>
                    </div>
                </div>

                {props.referente !== null && props.referente !== undefined &&
                <React.Fragment>
                <div className="mt-3 mx-2 mb-4 row-fluid d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="patient-label mr-md-3">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label className="m-0">CARGAR PRODUCTOS</label>
                        </div>
                    </div>
                    <div className="line-patient mr-3 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                        {/* This empty div is important to show the line among each category-prices row  */}
                    </div>
                    <div onClick={showProducts} className={`${load_products == true ? 'patient-up-icon' : 'patient-down-icon'} my-2`}>
                        <div>
                        </div>
                    </div>
                </div>
                {
                    load_products ?
                    <React.Fragment>
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-secondary">
                                    <th className="text-black">Servicio</th>
                                    <th className="text-black">Cantidad</th>
                                    <th className="text-black">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                 services_list.map(element => (
                                     <tr key={element.id}>
                                        <td>{element.name}</td>
                                        <td>
                                            <div className="group-button-up-down">
                                                <button type="button" className="button-content left-tab" onClick={() => {props.upDownCant(element.id, false)}}>
                                                    <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                                                </button>
                                                <span className="input-cant text-left" style={{width:"50px",textAlign:"center"}}>{element.quantity}</span>
                                                <button type="button" className="button-content right-tab" onClick={() => {props.upDownCant(element.id, true)}}>
                                                    <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <a onClick={() => props.removeServiceToList(element.id)}> <img className="action-img delete" src={require("../../../../assets/img/icons/delete-icon.png")} alt="eliminar" /></a>
                                        </td>
                                     </tr>
                                 ))
                                }
                                <tr>
                                    <td colSpan={2}></td>
                                    <td>
                                        <MyModal 
                                            isOpen={window.modal_service}
                                            title="Agregar Producto"
                                            formId="form_surgery_services"
                                            imageButton={<img className="action-img back-green" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />}
                                            classToggleButton="d-block h-100 button-service-icon"
                                            aceptar={props.addServiceToList}
                                            body={
                                                <React.Fragment>

                                                <ServiceForm
                                                    //onSubmit={props.addServiceToList}
                                                    onSubmit={()=>{console.log(' --- ')}}
                                                    data_service={props.data_service}
                                                    page_service={props.page_service}
                                                    cargando_services={props.cargando_services}
                                                    listarServicios={props.listarServicios}
                                                    addServiceToList={props.addServiceToList}
                                                    searchService={props.searchService}
                                                    buscador_service={props.buscador_service}
                                                    selected_referent={props.referente}
                                                    //addServiceToListDiff={addServiceToListDiff}
                                                    addServiceToListDiff={props.addServiceToListDiff}
                                                />

                                                </React.Fragment>
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                    :
                    null
                }
                </React.Fragment>
                }
                <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2 d-flex flex-column w-100">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="annotations">Anotaciones</label>
                        </div>
                        <div className="">
                            <Field
                                name="annotations"
                                disabled={view}
                                component={modalTextArea}
                                className="annotations w-100"
                            />
                        </div>
                    </div>
                </div>

                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary btn-sm m-3" onClick={() => {if(setUpdateData) setUpdateData({})}} to="/diagnostic-order">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" onClick={() => {if(setUpdateData) setUpdateData({})}} className="btn btn-primary btn-sm m-3">Guardar</button>)}
                    {props.surgery_id &&
                        <Link className="btn btn-success btn-sm m-3" to={"/perform-surgery/" + props.surgery_id}>Pasar a cirugía</Link>
                    }
                    </div>
                </div>
            </div>
        </form>
    );
};

DiagnosticOrderForm = reduxForm({
    form: 'diagnostic',
    validate
})(DiagnosticOrderForm);

const selector = formValueSelector('diagnostic');
DiagnosticOrderForm = connect(state => {
    const referente = selector(state, 'referent');
    return {
        referente
    }
})(DiagnosticOrderForm);
export default DiagnosticOrderForm;
