import classNames from 'classnames';
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions';
import ToolbarSelect from '../Utils/Toolbar/ToolbarSelect';
import moment from "moment";
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';
import './purchase_order.css';

class Entry extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        //this.props.listar()
        this.props.listarOrdenesPendientes();
    }

    isExpandableRow = (cell, row) => {
        if(cell.entradas.length || cell.pagos_orden.length){
            return true;
        } else {
            return false;
        }
    }

    expandComponent = (row) => {
        return (
            <React.Fragment>
                {row.entradas.length > 0 &&
                <div className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows expanded-rows-title">
                    <div className="w-25 d-flex justify-content-center">
                        No. Ingreso
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Bodega
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Fecha
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Ver
                    </div>
                </div>
                }
                {
                    row.entradas.map((dato, index) => (
                        <div key={index} className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows">
                            <div className="w-25 d-flex justify-content-center">
                                {dato.id}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {dato.warehouse ? dato.warehouse.name : "Pago en autorización"}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {dato.created}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                <a href={"/#/view-entry-order/" + dato.id + "/view"} target="_blank"><img className="action-img watch" title="ver" src={require("../../../../assets/img/icons/view-icon.png")} alt="Ver" /></a>
                            </div>
                        </div>
                        )
                    )
                }

                {row.pagos_orden.length > 0 ?
                    <React.Fragment>
                    <div className="p2">&nbsp;</div>
                    <div className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows expanded-rows-title">
                        <div className="w-25 d-flex justify-content-center">
                            Tipo de pago
                        </div>
                        <div className="w-25 d-flex justify-content-center">
                            Fecha
                        </div>
                        <div className="w-25 d-flex justify-content-center">
                            Monto
                        </div>
                        <div className="w-25 d-flex justify-content-center">
                            Acciones
                        </div>
                    </div>
                    {row.pagos_orden.map((dato, index) => (
                        <div key={index} className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows">
                            <div className="w-25 d-flex justify-content-center">
                                {dato.payment_type}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {dato.creado}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                Q{dato.amount}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                {dato.activo ?
                                    <img
                                        onClick={()=>this.props.cancelPurchasePayment(row.id, 'pending-orders')}
                                        className="action-img paid"
                                        title="anular"
                                        src={require("../../../../assets/img/icons/delete-patient-icon.png")}
                                        alt="Anular"
                                    />
                                    :
                                    <span>Cancelado</span>
                                }
                            </div>
                        </div>
                    ))}
                    </React.Fragment>
                :
                    null
                }
            </React.Fragment>
        );
    }

    showState = (cell) => {
        return(<span>{cell}</span>);
    }

    paidFormatter = (cell) => {

        if(cell){
            return (
                <img
                    className="action-img view"
                    src={require("../../../../assets/img/icons/check-icon-green.png")}
                    alt="check-icon"
                />
            );
        }else{
            return (
                <img
                    className="action-img"
                    src={require("../../../../assets/img/icons/history-icon-orange.png")}
                    alt="history-icon"
                />
            );
        }

    }

    estadoFormatter = (cell) => {
        if(cell){
            return <span>Pendiente</span>
        }else{
            return <span style={{color:"#60c579"}}>Completo</span>
        }
    }

    render() {
        const {
            listarOrdenesPendientes,
            detail,
            create,
            update,
            destroy,
            isNavBarOpen,
            customSearchOrdenesRecepcion,
        } = this.props;


        const {
            cargando,
            page,
            data,
            buscar_orden_recepcion,
        } = this.props;

        const options = [
            {value: 0, label: "Todas"},
            {value: 21, label: 'Completadas'},
            {value: 22, label: 'Pendientes Ingreso'},
            {value: 11, label: 'Pagadas'},
            {value: 12, label: 'No Pagadas'}
        ];

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100 pt-3">
                    <div className="table-decoration">
                        <div className='d-flex flex-row w-100 align-items-center justify-content-between'>
                            <div className="top-title-toolbar w-70">
                                <ToolbarSelect
                                    titulo="LISTADO DE RECEPCIONES"
                                    buscar={this.props.searchOrdenesPendientesEntrada}
                                    buscador={this.props.buscador}
                                    options={options}
                                    valueKey="value"
                                    labelKey="label"
                                    autofocus
                                />
                            </div>
                            <div className='d-flex flex-row w-25 mr-1'>
                                <CustomToolbar
                                    buscar={customSearchOrdenesRecepcion}
                                    buscador={buscar_orden_recepcion}
                                />
                            </div>                            
                        </div>
                        <Table
                            onPageChange={listarOrdenesPendientes}
                            data={data}
                            loading={cargando}
                            page={page}
                            expandableRow={ this.isExpandableRow }
                            expandComponent={ this.expandComponent }
                        >

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="id">No. Orden</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="order_date"
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                >Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                formatExtraData="provider__name" dataSort={true} dataFormat={showNestedFields}
                                dataField="name" dataSort>Proveedor</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="entrada_pendiente" dataFormat={this.estadoFormatter} dataSort>Ingreso</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="pagos_entrada" dataFormat={this.paidFormatter} dataSort>Pagado</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell, row)=>{
                                    console.log("pendiente: ", row.entrada_pendiente);


                                    if(row.entrada_pendiente === true){
                                        return(
                                            <a href={`/#/pending-orders/${cell}/entry`}>
                                                <img
                                                    className="action-img edit"
                                                    title="Ingresar Productos"
                                                    src={require("../../../../assets//img/icons/box-icon-white.png")}
                                                    alt="Realizar ingreso" />
                                            </a>
                                        );
                                    }
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Entry;
