import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const existeFecha = (fecha) => {
    var fechaf = fecha.split("/");
    var d = fechaf[0];
    var m = fechaf[1];
    var y = fechaf[2];
    return m > 0 && m < 13 && y > 1900 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
}

function InputDate({
    styleContainer,
    label,
    initial_date,
    final_date,
    set_initial_date,
    set_final_date,
}) {
    return (
        <div className={`form-group ${styleContainer}`}>
            <label className="ml-2">{label}</label>
            <div className=" d-flex flex-row justify-content-end aling-items-center flex-fill">
                <NumberFormat
                    format="##/##/####"
                    placeholder="DD/MM/YYYY"
                    mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                    className="form-control w-50 text-center"
                    style={{ minWidth: 125 }}
                    value={initial_date}
                    onValueChange={(values) => {
                        if (existeFecha(values.formattedValue)) {
                            set_initial_date(values.formattedValue);
                        }
                    }}
                    autoFocus
                    onFocus={(e) => {
                        e.target.setSelectionRange(0, 10);
                    }}
                />

                <NumberFormat
                    format="##/##/####"
                    placeholder="DD/MM/YYYY"
                    mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                    className="form-control w-50 ml-2 text-center"
                    style={{ minWidth: 125 }}
                    value={final_date}
                    onValueChange={(values) => {
                        if (existeFecha(values.formattedValue)) {
                            set_final_date(values.formattedValue);
                        }
                    }}
                    onFocus={(e) => {
                        e.target.setSelectionRange(0, 10);
                    }}
                />
            </div>
        </div>
    );
}

InputDate.propTypes = {
    styleContainer: PropTypes.string,
    label: PropTypes.string.isRequired,
    initial_date: PropTypes.string.isRequired,
    final_date: PropTypes.string.isRequired,
    set_initial_date: PropTypes.func.isRequired,
    set_final_date: PropTypes.func.isRequired,
};


export {InputDate};
