import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';

const Form = props => {

    const {
        handleSubmit
    } = props;

    const view = window.location.href.includes("view");
    const edit = window.location.href.includes("edit");

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="">Nombre del gasto</label>
                    </div>
                    <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/spending-types">{!view ? ("Cancelar") : ("Regresar")}</Link>
                   {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

const SmallBoxForm = reduxForm({
    form: 'spendingType',
    validate: data => {
        return validate(data, {
           'name': validators.exists()('Campo requerido.'),
        })
    }
})(Form);

export default SmallBoxForm;
