import Form from './ExpensesForm';
import React, { Component } from 'react';
import Card from 'Utils/Cards/cardFormulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Swal from "sweetalert2";

export default class SpendingTypeCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Registrar gasto',
        password: false,
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar un gasto'
            });
        }else{
            this.props.getSmallBoxLimit();
        }
    }

    render() {

        const {
            create,
            update
        } = this.props;

        const {
            updateData,
            cargando,
            me,
            smallbox_limit,
        } = this.props;
        const view = window.location.href.includes("view");

        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div className="fields">
                                        <div className="top-title">
                                            VER GASTO
                                        </div>
                                        <Form
                                            onSubmit={update}
                                            updateData={updateData}
                                            editando={true}
                                        />
                                    </div>
                            :
                                <div className="fields">
                                    <div className="top-title">
                                        REGISTRO DE GASTO
                                    </div>
                                    <Form onSubmit={create} smallbox_limit={smallbox_limit}/>
                                </div>
                            }
                            {(!!me && me.role_name == 'superuser' && view) && (
                                <button type="button" onClick={() => {
                                    Swal({
                                        title: '¿Desea eliminar este registro?',
                                        text: 'No puedes deshacer esta acción',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Sí, eliminar',
                                        cancelButtonText: 'No, cancelar',
                                        reverseButtons: true
                                    }).then((result) => {
                                        if (result.value) {
                                            this.props.destroy(this.props.match.params.id);
                                        }
                                    });
                                }} className="btn btn-danger m-3">Anular Gasto</button>
                            )}
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
