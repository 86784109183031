import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { renderCurrency } from 'Utils/renderField/renderField';
import { Field, FieldArray, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import MyModal from '../../Utils/myModal';
import { renderDepositRow } from "./depositRow";
import { connect } from 'react-redux';

let index = 0;
class DepositForm extends Component{
    render(){
        index = this.props.index;

        const {
            handleSubmit,
        } = this.props;

        return(
            <form onSubmit={handleSubmit} id="frm-deposito">
                <table className="table table-sm" id="table-tarjet">
                    <thead className="thead-tarjet">
                        <tr>
                            <th>Monto</th>
                            <th>No. Documento</th>
                            <th>Fecha</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray
                            name="deposit_payments"
                            component={renderDepositRow}
                        />
                    </tbody>
                </table>
            </form>
        );
    }
}

DepositForm = reduxForm({
    form: 'depositForm',
    enableReinitialize: true,
    validate: data => {
        const errors = {};
        const membersArrayErrors = [];

        // console.log("data.deposit_payments: ", data.deposit_payments);

        data.deposit_payments.forEach((member, memberIndex) => {
            const memberErrors = {}
            if(!member || !member.amount){
                memberErrors.amount = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }

            if(!member || !member.document_number){
                memberErrors.document_number = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }
        });

        if (membersArrayErrors.length) {
            errors.deposit_payments = membersArrayErrors
        }

        return errors;

    }
 })(DepositForm);


 DepositForm = connect(
     state => { return ({
         initialValues: state.movement.productsInCarOrdered[index].dataPayments
     })}
 )(DepositForm)


 export default DepositForm;
