import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/whearhouseAdmin';

import ReajusteBodega from './Reajuste';
import {resetStore} from "../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.whearhouseAdmin,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(ReajusteBodega);
