import { connect } from 'react-redux';
import _ from "lodash";
import DiagnosticOrder from './DiagnosticOrder';
import { actions } from '../../../redux/modules/diagnostic_orden';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.diagnostic_orden,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(DiagnosticOrder);
