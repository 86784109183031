import React from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import LoadMask from '../Utils/LoadMask/LoadMask';
import { RenderCurrency, RenderNumberPercentage, RenderNumber } from '../Utils/renderField/renderReadField';

const RenderCard = (props) => {
    return (
        <div className="mx-auto mb-5 row-fluid w-100">
            <div className="table-decoration px-4 pb-5">
                {props.children}
            </div>
        </div>
    );
};

const RenderEstadisticasUtilidad = (props) => {
    const { utilities_by_product_category } = props;
    return (
        <RenderCard>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12">
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-1 text-uppercase text-titulo mb-3">
                            <strong>ESTADÍSTICAS DE UTILIDAD</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="3">Diario</th>
                            </tr>
                        </thead>
                        <tbody>
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => (
                                <tr key={categoria.id}>
                                    <td className="text-left pl-1">{categoria.name}</td>
                                    <td className="text-right"><RenderCurrency value={categoria.totals_by_day.income - categoria.totals_by_day.costs} /></td>
                                    <td className="text-right"><RenderNumber value={categoria.totals_by_day.total} /></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="3">Semanal</th>
                            </tr>
                        </thead>
                        <tbody>
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => (
                                <tr key={categoria.id}>
                                    <td className="text-left pl-1">{categoria.name}</td>
                                    <td className="text-right"><RenderCurrency value={categoria.totals_by_week.income - categoria.totals_by_week.costs} /></td>
                                    <td className="text-right"><RenderNumber value={categoria.totals_by_week.total} /></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="3">Mensual</th>
                            </tr>
                        </thead>
                        <tbody>
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => (
                                <tr key={categoria.id}>
                                    <td className="text-left pl-1">{categoria.name}</td>
                                    <td className="text-right"><RenderCurrency value={categoria.totals_by_month.income - categoria.totals_by_month.costs} /></td>
                                    <td className="text-right"><RenderNumber value={categoria.totals_by_month.total} /></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
            </div>
        </RenderCard>
    );
};

const RenderEstadisticasIngreso = (props) => {
    const { utilities_by_product_category } = props;
    return (
        <RenderCard>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12">
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-1 text-uppercase text-titulo mb-3">
                            <strong>ESTADÍSTICAS DE INGRESOS</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="2">Diario</th>
                            </tr>
                        </thead>
                        <tbody>
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => (
                                <tr key={categoria.id}>
                                    <td className="text-left pl-1">{categoria.name}</td>
                                    <td className="text-right"><RenderCurrency value={categoria.totals_by_day.income} /></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="2">Semanal</th>
                            </tr>
                        </thead>
                        <tbody>
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => (
                                <tr key={categoria.id}>
                                    <td className="text-left pl-1">{categoria.name}</td>
                                    <td className="text-right"><RenderCurrency value={categoria.totals_by_week.income} /></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="2">Mensual</th>
                            </tr>
                        </thead>
                        <tbody>
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => (
                                <tr key={categoria.id}>
                                    <td className="text-left pl-1">{categoria.name}</td>
                                    <td className="text-right"><RenderCurrency value={categoria.totals_by_month.income} /></td>
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
            </div>
        </RenderCard>
    );
};

const RenderEstadisticasPorCategoria = (props) => {
    const { utilities_by_product_category } = props;
    return (
        <RenderCard>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12">
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-1 text-uppercase text-titulo mb-3">
                            <strong>ESTADÍSTICAS DE PRODUCTOS POR CATEGORÍA</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-12">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center text-uppercase">Categorías de producto</th>
                                <th className="text-center text-uppercase">Totalizado</th>
                                <th className="text-center text-uppercase">% de ganancia</th>
                            </tr>
                        </thead>
                        <tbody className="content-fs-medium">
                            { utilities_by_product_category && utilities_by_product_category.map(categoria => {
                                const { name, totals_by_month: { income, costs } } = categoria;
                                return (
                                    <tr key={categoria.id}>
                                        <td className="px-2 px-md-5 text-left">{name}</td>
                                        <td className="px-2 px-md-5 text-right"><RenderCurrency value={income} /></td>
                                        <td className="px-2 px-md-5 text-right">(<RenderNumberPercentage value={income !== 0 ? ((income - costs) / income) : 0} decimalScale={0} />)</td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </table>
                </div>
            </div>
        </RenderCard>
    );
};

const RenderComparativaVentas = (props) => {
    let { income_by_years } = props, data_by_years = {};
    let nombre_meses = [], lista_anios = [];
    let dataChart = [];
    for (let index=1; index<=12; index++) {
        // Obtención de los nombres de los 12 meses del año
        let monthName = moment(`${index}`, 'MM').format('MMMM');
        monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        nombre_meses.push(monthName);
    }
    // Construcción de la data para que el ingreso sea accedido como data[nombre_anio][nombre_mes]
    income_by_years && Object.keys(income_by_years).reverse().forEach(key => lista_anios.push(key));
    dataChart.push(['Mes', ...lista_anios]);
    income_by_years && Object.keys(income_by_years).forEach(key => {
        let year = income_by_years[key], months = {};
        if (year) {
            for (let index=1; index<=12; index++) {
                let monthName = moment(`${index}`, 'MM').format('MM');
                let income = year[`${monthName}`] || 0;
                monthName = moment(`${index}`, 'MM').format('MMMM');
                monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                months[monthName] = income;
            }
        }
        data_by_years[key] = months;
    });
    // Construcción de la información para el gráfico de barras
    nombre_meses.forEach(mes => {
        let data_mes = [mes];
        lista_anios.forEach(anio => {
            data_mes.push(data_by_years[anio][mes]);
        });
        dataChart.push(data_mes)
    });
    return (
        <RenderCard>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12">
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-1 text-uppercase text-titulo mb-3">
                            <strong>COMPARATIVA DE VENTAS</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row col-12 d-flex">
            <Chart
                width={'100%'}
                height={'450px'}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={[...dataChart]}
                options={{
                    colors: ['#205995', '#60C579'],
                    chart: {
                        // title: 'Company Performance',
                        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                    },
                    vAxis: {format: 'decimal'},
                }}
            />
            </div>
        </RenderCard>
    );
};

const RenderTop10Referentes = (props) => {
    const { best_referents } = props;
    return (
        <RenderCard>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12">
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-1 text-uppercase text-titulo mb-3">
                            <strong>TOP 10 DE REFERENTES</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-12">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-blue">
                                <th className="text-center" colSpan="2">Referentes</th>
                                <th className="text-center">Cantidad de referencias</th>
                                <th className="text-center">Ventas por referencia</th>
                            </tr>
                        </thead>
                        <tbody className="content-fs-medium">
                            { best_referents && best_referents.map((referent, index) => {
                                return (
                                    <tr key={referent.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="px-2 px-md-5 text-left">{referent.name}</td>
                                        <td className="px-2 px-md-5 text-center">{referent.total_references}</td>
                                        <td className="px-2 px-md-5 text-right"><RenderCurrency value={referent.total_sales} /></td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </table>
                </div>
            </div>
        </RenderCard>
    );
};

const RenderSaldosCuentas = (props) => {
    const { higher_accounts_payable, higher_accounts_receivable, total_income_and_expenses } = props;
    let total_cuentas_x_pagar = 0, total_cuentas_x_cobrar = 0;
    higher_accounts_payable && higher_accounts_payable.forEach(cuenta => total_cuentas_x_pagar+= cuenta.total_balances);
    higher_accounts_receivable && higher_accounts_receivable.forEach(cuenta => total_cuentas_x_cobrar+= cuenta.total_balances);
    return (
        <RenderCard>
            <div className="row d-flex">
                <div className="col-6">
                    <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-12">
                            <div className="top-title-toolbar mt-3 d-flex justify-content-center">
                                <span className="ml-1 text-uppercase text-titulo mb-3">
                                    <strong>CUENTAS POR PAGAR</strong>
                                </span>
                            </div>
                            <div className="top-title-toolbar mt-3 d-flex justify-content-center">
                                <span className="text-uppercase text-titulo mt-4 mb-2">
                                    <h1 className="text-titulo gray-g">
                                        <RenderCurrency value={total_cuentas_x_pagar} prefix="Q. " />
                                    </h1>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12">
                            <table className="table table-dashboard table-hover">
                                <thead>
                                    <tr className="bg-bright-red">
                                        <th className="text-center" colSpan="2">Proveedor</th>
                                    </tr>
                                </thead>
                                <tbody className="content-fs-medium">
                                    { higher_accounts_payable && higher_accounts_payable.map(cuenta_x_pagar => (
                                        <tr key={cuenta_x_pagar.id}>
                                            <td className="px-2 px-md-4 text-left">{cuenta_x_pagar.name}</td>
                                            <td className="px-2 px-md-4 text-right"><RenderCurrency value={cuenta_x_pagar.total_balances} /></td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-12">
                            <div className="top-title-toolbar mt-3 d-flex justify-content-center">
                                <span className="ml-1 text-uppercase text-titulo mb-3">
                                    <strong>CUENTAS POR COBRAR</strong>
                                </span>
                            </div>
                            <div className="top-title-toolbar mt-3 d-flex justify-content-center">
                                <span className="text-uppercase text-titulo mt-4 mb-2">
                                    <h1 className="text-titulo gray-g">
                                        <RenderCurrency value={total_cuentas_x_cobrar} prefix="Q. " />
                                    </h1>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12">
                            <table className="table table-dashboard table-hover">
                                <thead>
                                    <tr className="bg-blue">
                                        <th className="text-center" colSpan="2">Cliente</th>
                                    </tr>
                                </thead>
                                <tbody className="content-fs-medium">
                                    { higher_accounts_receivable && higher_accounts_receivable.map(cuenta_x_cobrar => (
                                        <tr key={cuenta_x_cobrar.id}>
                                            <td className="px-2 px-md-4 text-left">{`${cuenta_x_cobrar.first_name} ${cuenta_x_cobrar.last_name}`}</td>
                                            <td className="px-2 px-md-4 text-right"><RenderCurrency value={cuenta_x_cobrar.total_balances} /></td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex mt-5">
                <div className="col-6">
                    <div className="row d-flex justify-content-center">
                        <div className="col-8">
                            <table className="table table-dashboard">
                                <thead>
                                    <tr className="bg-bright-red">
                                        <th className="text-center py-3">
                                            <span className="text-uppercase text-titulo">
                                                <strong>GASTOS DEL MES</strong>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">
                                            <div className="top-title-toolbar my-5">
                                                <span className="text-uppercase text-titulo">
                                                    <h1 className="text-titulo gray-g">
                                                        <RenderCurrency value={total_income_and_expenses.spends} prefix="Q. " />
                                                    </h1>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row d-flex justify-content-center">
                        <div className="col-8">
                            <table className="table table-dashboard">
                                <thead>
                                    <tr className="bg-blue">
                                        <th className="text-center py-3">
                                            <span className="text-uppercase text-titulo">
                                                <strong>INGRESOS DEL MES</strong>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">
                                            <div className="top-title-toolbar my-5">
                                                <span className="text-uppercase text-titulo">
                                                    <h1 className="text-titulo gray-g">
                                                        <RenderCurrency value={total_income_and_expenses.income} prefix="Q. " />
                                                    </h1>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </RenderCard>
    );
};

const RenderAlertaProductos = (props) => {
    const { products_with_alert } = props;
    return (
        <RenderCard>
            <div className="row d-flex justify-content-start align-items-center">
                <div className="col-12">
                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-1 text-uppercase text-titulo mb-3">
                            <strong>PRODUCTOS CON ALERTA</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-12">
                    <table className="table table-dashboard table-hover">
                        <thead>
                            <tr className="bg-bright-red">
                                <th className="text-center">PRODUCTOS</th>
                                <th className="text-center">ALERTA</th>
                            </tr>
                        </thead>
                        <tbody className="content-fs-medium">
                            { products_with_alert && products_with_alert.map((product, index) => {
                                return (
                                    <tr key={product.id}>
                                        <td className="px-2 px-md-5 text-left">{product.name}</td>
                                        <td className="px-2 px-md-5 text-left">{product.alert_text}</td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </table>
                </div>
            </div>
        </RenderCard>
    );
};

const RenderTiemposPromedio = (props) => {
    const { average_times_by_procedure, average_times_by_consult } = props;
    return (
        <div className="row d-flex">
            <div className="col-12 col-md-6">
                <RenderCard>
                    <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-12">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo mb-3">
                                    <strong>TIEMPO PROMEDIO PROCEDIMIENTO</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12 my-2">
                            <table className="table table-dashboard table-hover">
                                <thead>
                                    <tr className="bg-blue">
                                        <th className="text-center py-3" colSpan="2">
                                            <span className="text-uppercase text-titulo">
                                                <strong>PROMEDIO POR PROCEDIMIENTO</strong>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { average_times_by_procedure && Object.keys(average_times_by_procedure).map(key => (
                                        <tr key={key}>
                                            <td className="text-left pl-1">{key}</td>
                                            <td className="text-right"><RenderNumber value={average_times_by_procedure[key] / 60} decimalScale={2} suffix=" min." /></td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </RenderCard>
            </div>
            <div className="col-12 col-md-6">
                <RenderCard>
                    <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-12">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo mb-3">
                                    <strong>TIEMPO PROMEDIO CONSULTA</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12 my-2">
                            <table className="table table-dashboard table-hover">
                                <thead>
                                    <tr className="bg-blue">
                                        <th className="text-center py-3" colSpan="2">
                                            <span className="text-uppercase text-titulo">
                                                <strong>PROMEDIO POR CONSULTA</strong>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { average_times_by_consult && Object.keys(average_times_by_consult).map(key => (
                                        <tr key={key}>
                                            <td className="text-left pl-1">{key}</td>
                                            <td className="text-right"><RenderNumber value={average_times_by_consult[key] / 60} decimalScale={2} suffix=" min." /></td>
                                        </tr>
                                    )) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </RenderCard>
            </div>
        </div>
    );
};

const Dashboard = (props) => {
    const { dashboard: { loader, data, enable_to_generate }, getDashboardData, getIgssAfilation } = props;

    return (        
        <React.Fragment>
            <button 
                disabled={!enable_to_generate}
                className='btn btn-primary'
                onClick={()=> getDashboardData()}
                type='button'
            >Generar Dashboard</button>

            {/*<button onClick={()=> getIgssAfilation() }>Probar igss</button>*/}
            <br /><br />
            {!enable_to_generate &&
                <LoadMask loading={loader} blur>            
                    <RenderEstadisticasUtilidad {...data} />
                    <RenderEstadisticasIngreso {...data} />
                    <RenderEstadisticasPorCategoria {...data} />
                    <RenderComparativaVentas {...data} />
                    <RenderTop10Referentes {...data} />
                    <RenderSaldosCuentas {...data} />
                    <RenderAlertaProductos {...data} />
                    <RenderTiemposPromedio {...data} />
                </LoadMask>
            }
        </React.Fragment>
    )
};

export default Dashboard;
