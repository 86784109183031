import React, { Component } from 'react';
import Table from '../Utils/Grid';
import ToolbarChange from '../Utils/Toolbar/ToolbarChange';
import { BreakLine } from '../Utils/tableOptions';
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import { showNestedFields } from '../Utils/MyOptions';
import Select from 'react-select';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from "moment";
import TableDescription from './TableDescription';
import { Link } from 'react-router-dom';
import './arqueo.css';

class DetalleArqueo extends Component {
    componentWillMount = () => {
        this.props.detail(this.props.match.params.id);
    }

    render() {
        const {
            cargando,
            updateData,
            category_detail,
        } = this.props;

        const {
            openAccount
        } = this.props;

        const username = updateData.length && updateData[0].oppeging_clossing_box ? updateData[0].oppeging_clossing_box.user.first_name : null;

        return (
            <div className="content">
                <div className="m-2">
                    <a href='/#/arqueos'>
                        <img className="bodega-tab-icon" src={require("../../../../assets/img/icons/return-icon-blue.png")} alt="icono" />
                        <span className='link-retorno'>&nbsp; detalle de caja de {username}</span>
                    </a>
                </div>

                <div className="m-2">
                    {updateData.length > 0 ?
                        <React.Fragment>
                            {updateData.map((detalle, i) =>
                                <TableDescription
                                    key={i}
                                    {...detalle}
                                    summary_category={category_detail}
                                    openAccount={openAccount}
                                />
                            )}
                        </React.Fragment>
                        : <h1>No hay movimientos</h1>
                    }


                </div>
            </div>
        )
    }
}

export default DetalleArqueo;
