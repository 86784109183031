import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/provider';

import Provider from './Provider';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.provider,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Provider);
