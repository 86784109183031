import {api} from 'api';
import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderSearchSelect } from "Utils/renderField/renderField";

let referents = [];

const getReferents = (search) => {
    return api.get("referents", {search}).catch((error) => {})
    .then((data) => {
        if (data){
            referents = [];
            data.results.forEach(x=>{
                referents.push({value: x.id, label: x.name})
            })
            return referents;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

let interpreters = [];
const getInterpreters = (search) => {
    return api.get("referents/getInterpreters", {search}).catch((error) => {})
    .then((data) => {
        if (data){
            interpreters = [];
            data.results.forEach(x=>{
                interpreters.push({value: x.id, label: x.name})
            })
            return interpreters;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

let ExamOrderForm = props => {
    const { handleSubmit, setFiles, foto } = props;
    return (
        <form
            onSubmit={handleSubmit}
            className="pb-3 pt-5 d-flex justify-content-around"
        >
            <div className="w-75">
                <Field name='id' component='input' type='hidden'/>

                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="patient_name" className="">
                                Nombre del paciente
                            </label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="nombre_completo" component={renderField} type="text" className="form-control" disabled={true}/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referent" className="">
                                Referente
                            </label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="referent" valueKey="value" labelKey="label" label="name"
                                component={renderSearchSelect} loadOptions={getReferents} className="no-border" disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">
                                Fecha
                            </label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            {/*<Field name="date" component={renderDatePicker} type="text" className="form-control" disabled={true}/>*/}
                            <Field name="date" component='input' type="text" className="form-control" disabled={true}/>
                        </div>
                    </div>

                    <div className="m-2 invisible">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referent" className="">
                                Comodín
                            </label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodin" component={renderField} type="text" className="form-control"  disabled={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

ExamOrderForm = reduxForm({
    form: "showexamorder",
})(ExamOrderForm);

export default ExamOrderForm;
