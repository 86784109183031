import moment from 'moment';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {RenderCurrency} from "../../Utils/renderField/renderReadField";
import NumberFormat from 'react-number-format';
class ProcedimientosPromedio extends Component {

    componentWillMount() {
        const { listar } = this.props;
        listar();
    }
    render() {
        const {
            listar,
            initial_date,
            final_date,
            setInitialDate,
            setFinalDate,
            cargado,
            data
        } = this.props;
        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-1 text-uppercase text-titulo">
                                    <strong>TIEMPOS POR PROCEDIMIENTOS</strong> &nbsp; &nbsp;
                                </span>
                            </div>
                            <div className="d-flex flex-row justify-content-end align-items-center w-50">
                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20"
                                    value={initial_date}
                                    onValueChange={(values) => {
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            setInitialDate(values.formattedValue);
                                        }
                                    }}
                                    autoFocus
                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}
                                />

                                <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                    className="form-control w-20 ml-2 mr-2"
                                    value={final_date}
                                    onValueChange={(values) => {
                                        const dia = values.formattedValue.indexOf("D")
                                        const mes = values.formattedValue.indexOf("M")
                                        const anio = values.formattedValue.indexOf("Y")

                                        if((dia + mes + anio) < 0){
                                            setFinalDate(values.formattedValue);
                                        }
                                    }}

                                    onFocus={(e) => {
                                        e.target.setSelectionRange(0,10);
                                    }}
                                />
                            <button
                                className=" btn-primary btn-sm"
                                onClick={()=>{
                                listar();
                            }}>
                                <i className="fa fa-search"></i>
                            </button>
                            </div>
                        </div>
                        <LoadMask loading={cargado} blur={true}>
                        <div className="table-responsive">
                            <h3 className="ml-1 ml-md-3">Mujeres</h3>
                            <table className="table">
                                <thead>
                                <tr style={{background: 'gray'}}>
                                    <th className='text-center'>Paso</th>
                                    <th className='text-center'>0 - 1â</th>
                                    <th className='text-center'>1â - 10â</th>
                                    <th className='text-center'>11â - 20â</th>
                                    <th className='text-center'>21â - 30â</th>
                                    <th className='text-center'>31â - 40â</th>
                                    <th className='text-center'>41â - 50â</th>
                                    <th className='text-center'>51â - 60â</th>
                                    <th className='text-center'>61â - 70â</th>
                                    <th className='text-center'>71â - </th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(data).map((llave, index) => (
                                    <tr key={`h${index}`}>
                                        <td className=''>{llave}</td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.anio ? data[llave].mujeres.anio / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.diez ? data[llave].mujeres.diez / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.veinte ? data[llave].mujeres.veinte / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.treinta ? data[llave].mujeres.treinta / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.cuarenta ? data[llave].mujeres.cuarenta / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.cincuenta ? data[llave].mujeres.cincuenta / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.sesenta ? data[llave].mujeres.sesenta / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.setenta ? data[llave].mujeres.setenta / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].mujeres.ochenta ? data[llave].mujeres.ochenta / 60 : 0} thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <h3 className="ml-1 ml-md-3">Hombres</h3>
                            <table className="table">
                                <thead>
                                <tr style={{background: 'gray'}}>
                                    <th className='text-center'>Paso</th>
                                    <th className='text-center'>0 - 1â</th>
                                    <th className='text-center'>1â - 10â</th>
                                    <th className='text-center'>11â - 20â</th>
                                    <th className='text-center'>21â - 30â</th>
                                    <th className='text-center'>31â - 40â</th>
                                    <th className='text-center'>41â - 50â</th>
                                    <th className='text-center'>51â - 60â</th>
                                    <th className='text-center'>61â - 70â</th>
                                    <th className='text-center'>71â - </th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(data).map((llave, index) => (
                                    <tr key={`h${index}`}>
                                        <td className=''>{llave}</td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.anio ? data[llave].hombres.anio / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.diez ? data[llave].hombres.diez / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.veinte ? data[llave].hombres.veinte / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.treinta ? data[llave].hombres.treinta / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.cuarenta ? data[llave].hombres.cuarenta / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.cincuenta ? data[llave].hombres.cincuenta / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.sesenta ? data[llave].hombres.sesenta / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.setenta ? data[llave].hombres.setenta / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                        <td className='text-right'>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true}
                                                          value={data[llave].hombres.ochenta ? data[llave].hombres.ochenta / 60 : 0 } thousandSeparator={true} suffix={'m'}
                                                          displayType="text"
                                            />

                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProcedimientosPromedio;
