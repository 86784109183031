import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom }  from 'Utils/renderField/renderField';
import { connect } from 'react-redux'

let subsidiary = [];
const getSubsidiary = (search)  =>{
    return api.get("subsidiaries",{search}).catch((error) => {})
        .then((data) => {

            if (data){
                subsidiary = [];
                data.results.forEach(x=>{
                    subsidiary.push({value: x.id, label: x.name})
                })
                return subsidiary;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let WhearhouseForm = props => {
    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="subsidiary" className="m-0">Sucursal</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="subsidiary"
                        component={renderSearchSelect}
                        valueKey="value"
                        labelKey="label"
                        label="subsidiary"
                        loadOptions={getSubsidiary}
                        className="no-border"
                        disabled={view}
                        />
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/warehouse">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

WhearhouseForm = reduxForm({
   form: 'bodegas',
   validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'subsidiary': validators.exists()('Campo requerido.'),
       })
   }
})(WhearhouseForm);

export default WhearhouseForm
