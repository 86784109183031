import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// maquetado base
import NavbarPro from './common/components/Navbar/SideBarProContainer';

// funciona para determinar si puede acceder a la vista
function isAuthenticated() {
    return localStorage.getItem('token');
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (isAuthenticated() ? (
            <NavbarPro history={props.history} location={props.location}>
                <Component {...props} />
            </NavbarPro>
        ) : (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}
            />
        ))
        }
    />
);

export default ProtectedRoute;
