import React, {
    Component
} from 'react';
import RecordForm from '../RecordForm';

class RecordEdit extends Component {
    componentWillMount() {
        if(this.props.match.params && this.props.match.params.id) {
            const { getRecordDetail } = this.props;
            getRecordDetail(this.props.match.params.id);
        }
    }
    enviarData = (values) => {
        if(values) {
            const { record_data, putData } = this.props;
            values.record_type = record_data.record_type;
            values.id = record_data.id;
            putData(values);
        }
    }
    render() {
        const { record_data } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <div className="top-title">
                            Editar registro
                        </div>
                        <RecordForm
                            urlId={record_data.record_type}
                            onSubmit={this.enviarData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default RecordEdit;
