import React, {Component} from 'react';
import {Link} from "react-router-dom";
import moment from 'moment'


class DetalleBitacora extends Component {

    componentWillMount = () => {
        const { detail, match: { params: { id } } } = this.props;
        detail(id);
    };


    render() {

        const {
            cargando,
            updateData,
        } = this.props;

        const detalle = JSON.parse(updateData.detail);
        let detalle_anterior = false;
        if (updateData.prev_detail)
            detalle_anterior = JSON.parse(updateData.prev_detail);


        return (
            <div className="content">

                <div className={"m-auto row-fluid w-100 pt-5"}>
                    <div className="" />
                    <div className="table-decoration">
                        <div className="top-title">
                            Bitácora {updateData.action.toUpperCase()}
                        </div>
                        <div className="d-block d-md-flex px-1 py-2 px-md-4">
                            <div style={{flex: "1"}}>
                                <b>DETALLE</b>
                                {Object.keys(detalle).map(item => {
                                    const dato = (item == 'hecho' || item == 'made_fel') && detalle[item]
                                                ? moment.utc(detalle[item], "DD/MM/YYYY hh:mm").local().format("DD/MM/YYYY hh:mm A")
                                                : item == 'fechaCertificacion' && detalle[item]
                                                ? moment.utc(detalle[item]).local().format("DD/MM/YYYY hh:mm A")
                                                : detalle[item]
                                    return(
                                        <div className="d-block d-md-flex border border-dark" key={item}>
                                            <div style={{flex: "1"}} className="text-right"><b>{item}:</b></div>
                                            <div style={{flex: "1"}} className="ml-3">{dato ? dato : "---"}</div>
                                        </div>)
                                })}
                            </div>
                            {detalle_anterior ? (
                                <div style={{flex: "1"}}>
                                <b>DETALLE ANTERIOR</b>
                                {Object.keys(detalle_anterior).map(item => {
                                    return(
                                        <div className="d-block d-md-flex border border-dark" key={item}>
                                            <div style={{flex: "1"}} className="text-right"><b>{item}:</b></div>
                                            <div style={{flex: "1"}} className="ml-3">{detalle[item] ? detalle[item]: "---"}</div>
                                        </div>)
                                })}
                            </div>
                            ) : (<div style={{flex: "1"}} />)}
                        </div>
                        <Link className="btn btn-secondary m-4" to="/bitacora">Regresar</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetalleBitacora;
