import Doctor from './MedicalHistory';
import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/medical_history';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.medical_history,
        me: state.login.me,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Doctor);
