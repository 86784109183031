import React, {
    Component
} from 'react';
import RecordForm from '../RecordForm';
import LoadMask from 'Utils/LoadMask/LoadMask';

class RecordCreate extends Component {
    componentDidMount() {
        if(this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id
            this.props.setUrl(id);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params && prevProps.match.params.id) {
            if(this.props.match.params.id != prevProps.match.params.id) {
                let id = this.props.match.params.id
                this.props.setUrl(id);
            }
        }
    }
    enviarData = (values) => {
        if(values) {
            const { id, create } = this.props;
            values.record_type = id;
            create(values);
        }
    }
    render() {
        const {
            titulares, id,
            cargando
        } = this.props;
        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            <div className="top-title">
                                { titulares.crear }
                            </div>
                            <RecordForm
                                urlId={id}
                                onSubmit={this.enviarData}
                            />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecordCreate;
