import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from 'redux-form';
import { api, updateExpiracion } from "api";
import Swal from 'sweetalert2';
import moment, { lang } from "moment";
import {
    VISUAL_ACUITY,
    TONOMETRY,
    REFRACTOR,
    MUSCLES,
    LENSOMETRY,
    KERATOMERY,
    INDIRECT_EYE,
    AUTOREFRACTOR,
    LAMP,
    RECORD,
    RECONSULT,
    DIAGNOSTIC_PLAN,
    EDUCATIONAL_PLAN,
    OPTICS_ORDER,
} from "../../utility/variables";

const LECTURA_INEXACTA = 1;
const MAL_ESTADO = 2;

// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'CONSULT_LOADER';
const DATA = 'CONSULT_DATA';
const ITEM = 'CONSULT_ITEM';
const PAGE = 'CONSULT_PAGE';
const ORDERING = 'CONSULT_ORDERING';
const SEARCH = 'CONSULT_SEARCH';
const SET_PLANES = 'SET_PLANES';
const SET_OPEN_MODAL_STEP = 'SET_OPEN_MODAL_STEP';
const SET_LAST_CONSULT = 'SET_LAST_CONSULT';
const SET_LAST_RECORD = 'SET_LAST_RECORD';
const SET_RECETA = 'SET_RECETA';
const COMPLETE_STEPS = 'COMPLETE_STEPS';
const SET_ORDEN_OPTICA = 'SET_ORDEN_OPTICA';

const MD = 1;
const QX = 2;
const ALG = 3;
const TRX = 4;
const FAM = 5;

export const constants = {
};

const endpoint = "appointments";
const editarForm = "EditarCitaForm";
// -----------------------------------
// Pure Actions
// -----------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

export const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

export const setSearch = search => ({
    type: SEARCH,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------

export const listar = (show_list) => (dispatch, getStore) => {
    const { consult } = getStore();
    const current_user = getStore().login.me;
    const subsidiary = current_user.person_user.subsidiary;

    const params = {show_list, initial_date: moment().format('YYYY-MM-DD'), final_date: moment().format('YYYY-MM-DD'), exclude_locks:1, subsidiary };

    params.ordering = consult.ordering;
    params.search = consult.search;
    dispatch(setLoader(true));

    api.get("appointments/all/", params).then((response) => {
        console.log("Response: ", response);
        dispatch(setData({results: response, count: response.length}));
    }).catch(() => {

    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        api.get(`consult/getLastConsult`, {patient_id: response.patient.id}).then((data) => {
            console.log("Last consult: ", data);
            dispatch({type: SET_LAST_CONSULT, last_consult: data});

        }).catch((error)=>{
            console.log("Error: ", error);
        });

        dispatch(setItem(response));
        const namePatient = response.patient.first_name + ' ' + response.patient.last_name;
        //dispatch(change('OpticsOrder', 'name', namePatient));
        //dispatch(change('OpticsOrder', 'date', Date.now()));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        dispatch(push("/consult"));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        dispatch(push("/consult"));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(listar());
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const sortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().consult.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const setStep = (step, appointment_id, current_step=0, redirect=false) => (dispatch, getStore) => {
    const params = {
        step,
        appointment_id,
    }

    api.post("appointments/setStep", params).then((response) => {
        Swal('Éxito',response.detail, 'success')
        .then(() => {
            let return_path = '';

            if(!redirect){
                if(current_step === VISUAL_ACUITY){
                    return_path = '/visual_acuity';
                }else if(current_step === TONOMETRY){
                    return_path = '/tonometry';
                }else if(current_step === REFRACTOR){
                    return_path = '/refraction';
                }else if(current_step === MUSCLES){
                    return_path = '/muscle';
                }else if(current_step === LENSOMETRY){
                    return_path = '/lensometry';
                }else if(current_step === KERATOMERY){
                    return_path = '/keratometry';
                }else if(current_step === INDIRECT_EYE){
                    return_path = '/indirect_eye';
                }else if(current_step === AUTOREFRACTOR){
                    return_path = '/autorefractor';
                }else if(current_step === LAMP){
                    return_path = '/lamp';
                }else if(current_step === DIAGNOSTIC_PLAN){
                    return_path = '/diagnostic-plan'
                }else if(current_step === 0 && step === RECORD){
                    return_path = `/record/${appointment_id}`;
                }else if(current_step === 0 && step === RECONSULT){
                    return_path = `/reconsult/${appointment_id}`;
                }else if(current_step === RECORD){
                    return_path = '/record';
                }else{
                    return_path = '/query-queue';
                }
            }else{
                if(step === VISUAL_ACUITY){
                    return_path = `/visual_acuity/${appointment_id}`;
                }else if(step === TONOMETRY){
                    return_path = `/tonometry/${appointment_id}`;
                }else if(step === REFRACTOR){
                    return_path = `/refraction/${appointment_id}`;
                }else if(step === MUSCLES){
                    return_path = `/muscle/${appointment_id}`;
                }else if(step === LENSOMETRY){
                    return_path = `/lensometry/${appointment_id}`;
                }else if(step === KERATOMERY){
                    return_path = `/keratometry/${appointment_id}`;
                }else if(step === INDIRECT_EYE){
                    return_path = `/indirect_eye/${appointment_id}`;
                }else if(step === AUTOREFRACTOR){
                    return_path = `/autorefractor/${appointment_id}`;
                }else if(step === LAMP){
                    return_path = `/lamp/${appointment_id}`;
                }else if(current_step === DIAGNOSTIC_PLAN){
                    return_path = '/diagnostic-plan'
                }else if(step === 0 && step === RECORD){
                    return_path = `/record/${appointment_id}`;
                }else if(step === 0 && step === RECONSULT){
                    return_path = `/reconsult/${appointment_id}`;
                }else if(step === RECORD){
                    return_path = `/record/${appointment_id}`;
                }else{
                    return_path = '/query-queue';
                }
            }
            dispatch(push(return_path));
        })
    }).catch((error) => {
        console.log("Error: ", error);
    }).finally(() => {

    });
}

export const topBarSetStep = (step, appointment_id, current_step=0, redirect=false) => (dispatch, getStore) => {
    const params = {
        step,
        appointment_id,
    }

    api.post("appointments/setStep", params).then((response) => {
        Swal.fire({
            type: "success",
            title: response.detail,
            showConfirmButton: false,
            position: "bottom-end",
            timer: 800,
        }).then(() => {
            let return_path = '';

            if(!redirect){
                if(current_step === VISUAL_ACUITY){
                    return_path = '/visual_acuity';
                }else if(current_step === TONOMETRY){
                    return_path = '/tonometry';
                }else if(current_step === REFRACTOR){
                    return_path = '/refraction';
                }else if(current_step === MUSCLES){
                    return_path = '/muscle';
                }else if(current_step === LENSOMETRY){
                    return_path = '/lensometry';
                }else if(current_step === KERATOMERY){
                    return_path = '/keratometry';
                }else if(current_step === INDIRECT_EYE){
                    return_path = '/indirect_eye';
                }else if(current_step === AUTOREFRACTOR){
                    return_path = '/autorefractor';
                }else if(current_step === LAMP){
                    return_path = '/lamp';
                }else if(current_step === DIAGNOSTIC_PLAN){
                    return_path = '/diagnostic-plan'
                }else if(current_step === 0 && step === RECORD){
                    return_path = `/record/${appointment_id}`;
                }else if(current_step === 0 && step === RECONSULT){
                    return_path = `/reconsult/${appointment_id}`;
                }else if(current_step === RECORD){
                    return_path = '/record';
                }else{
                    return_path = '/query-queue';
                }
            }else{
                if(step === VISUAL_ACUITY){
                    return_path = `/visual_acuity/${appointment_id}`;
                }else if(step === TONOMETRY){
                    return_path = `/tonometry/${appointment_id}`;
                }else if(step === REFRACTOR){
                    return_path = `/refraction/${appointment_id}`;
                }else if(step === MUSCLES){
                    return_path = `/muscle/${appointment_id}`;
                }else if(step === LENSOMETRY){
                    return_path = `/lensometry/${appointment_id}`;
                }else if(step === KERATOMERY){
                    return_path = `/keratometry/${appointment_id}`;
                }else if(step === INDIRECT_EYE){
                    return_path = `/indirect_eye/${appointment_id}`;
                }else if(step === AUTOREFRACTOR){
                    return_path = `/autorefractor/${appointment_id}`;
                }else if(step === LAMP){
                    return_path = `/lamp/${appointment_id}`;
                }else if(step === DIAGNOSTIC_PLAN){
                    return_path = `/educational-plan/${appointment_id}`;                    
                }else if(step === EDUCATIONAL_PLAN){
                    return_path = `/diagnostic-plan/${appointment_id}`;
                }else if(step === OPTICS_ORDER){
                    return_path = `/optics-order/${appointment_id}`;                    
                }else if(step === 0 && step === RECORD){
                    return_path = `/record/${appointment_id}`;
                }else if(step === 0 && step === RECONSULT){
                    return_path = `/reconsult/${appointment_id}`;
                }else if(step === RECORD){
                    return_path = `/record/${appointment_id}`;
                }else{
                    return_path = '/query-queue';
                }
            }
            dispatch(push(return_path));
        })

        api.get('consult/getCompleteSteps', { appointment_id }).then(complete_steps => {
            dispatch({ type: COMPLETE_STEPS, complete_steps });            
        }).catch((error) => {
        });
    }).catch((error) => {
        console.log("Error: ", error);
    }).finally(() => {

    });
}

export const simularDetail = () => (dispatch) => {
    const od_dibujo = '{"lines":[{"points":[{"x":64.48155576501638,"y":61.90763907888523},{"x":64.48155576501638,"y":61.90763907888523},{"x":63.69873755571873,"y":62.77403425577424},{"x":62.1852704379352,"y":65.40063782530264},{"x":60.74944200943463,"y":69.19424178365314},{"x":59.37147413527404,"y":75.07901054738704},{"x":58.945588349886016,"y":80.0111222166751},{"x":58.784367338083044,"y":85.00156428803072},{"x":58.71979285478718,"y":91.00017380978002},{"x":58.70133999127964,"y":95.0000319243963},{"x":59.200945588247414,"y":98.0397187963972},{"x":59.32167492797043,"y":99.02238820248935},{"x":59.71320427353891,"y":100.16261602221348},{"x":59.70243658728471,"y":97.99996281616293},{"x":58.64255800574662,"y":94.84391705323121},{"x":57.63928731290866,"y":91.84501334109106},{"x":55.87544638691077,"y":87.75477465173209},{"x":54.753635271192316,"y":84.80416753841843},{"x":52.92001787864107,"y":80.73512333886977},{"x":51.51940586407532,"y":75.8823484579969},{"x":51.10759202920866,"y":72.97044149698245},{"x":51.00315270038216,"y":71.98334767882348},{"x":50.87714443033367,"y":69.99399983133657},{"x":50.829786691768426,"y":68.99662384982594},{"x":51.058004823905705,"y":67.93321254314685},{"x":51.23061721905689,"y":67.62249739038792},{"x":51.636435856734856,"y":67.18932315572918},{"x":52.25008060721332,"y":66.74899591299334},{"x":53.89647254355647,"y":66.10007533259244}],"brushColor":"#444","brushRadius":3}],"width":125,"height":125}';
    dispatch(initializeForm('IndirectEye', {od_dibujo}));
}

export const getRecordDetail = (appointment_id) => (dispatch) =>{
    api.get(`record/getRecordData`, {appointment_id}).then((response) => {
        console.log("record detail: ", response);

        response.reason = response.consultation_reason;
        response.history = response.disease_history;
        response.note = response.fam_note;

        let md = []; let qx = []; let alg = []; let trx = []; let fam = [];

        //response.record_record_appointment.forEach(item => {
        response.rar_ra.forEach(item => {
            if(item.record.record_type === MD){
                md.push({
                    diagnostico: {value: item.record.name, label: item.record.name },
                    tratamiento: {value: item.record.md_treatment, label: item.record.md_treatment}
                });
            }else if(item.record.record_type === QX){
                qx.push({
                    cirugia: {value: item.record.name, label: item.record.name}
                });
            }else if(item.record.record_type === ALG){
                alg.push({
                    alergia: {values: item.record.name, label: item.record.name}
                });
            }else if(item.record.record_type === TRX){
                trx.push({
                    trauma: {values: item.record.name, label: item.record.name}
                });
            }else if(item.record.record_type === FAM){
                fam.push({
                    diagnostico: {value: item.record.name, label: item.record.name},
                    tratamiento: {value: item.record.fam_family, label: item.record.fam_family}
                });
            }

        });

        response.md = md;
        response.qx = qx;
        response.alg = alg;
        response.trx = trx;
        response.fam = fam;

        dispatch(initializeForm('Record', response));
    }).catch((error)=>{
        //console.log("Error: ", error);
        api.get(`record/getLastRecordData`, {appointment_id}).then((response) => {
            console.log("record detail: ", response);

            response.reason = response.consultation_reason;
            response.history = response.disease_history;
            response.note = response.fam_note;

            let md = []; let qx = []; let alg = []; let trx = []; let fam = [];

            //response.record_record_appointment.forEach(item => {
            response.rar_ra.forEach(item => {
                if(item.record.record_type === MD){
                    md.push({
                        diagnostico: {value: item.record.name, label: item.record.name },
                        tratamiento: {value: item.record.md_treatment, label: item.record.md_treatment}
                    });
                }else if(item.record.record_type === QX){
                    qx.push({
                        cirugia: {value: item.record.name, label: item.record.name}
                    });
                }else if(item.record.record_type === ALG){
                    alg.push({
                        alergia: {values: item.record.name, label: item.record.name}
                    });
                }else if(item.record.record_type === TRX){
                    trx.push({
                        trauma: {values: item.record.name, label: item.record.name}
                    });
                }else if(item.record.record_type === FAM){
                    fam.push({
                        diagnostico: {value: item.record.name, label: item.record.name},
                        tratamiento: {value: item.record.fam_family, label: item.record.fam_family}
                    });
                }

            });

            response.md = md;
            response.qx = qx;
            response.alg = alg;
            response.trx = trx;
            response.fam = fam;

            dispatch(initializeForm('Record', response));
        }).catch((error)=>{
            //console.log("Error: ", error);

        });

    });
}

export const saveRecord = (appointment_id, current_step) => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.Record.values);

    console.log("Guardando Historial Clínica");
    const params = {
        appointment_id,
        current_step,
        formData,
    }

    api.post("record", params).then((response) => {
        console.log("Response: ", response);

        Swal('Éxito', 'Datos guardados correctamente.', 'success')
        .then(() => {
            let return_path = '';
            console.log("current_step luego de ok: ", current_step);

            if(current_step === VISUAL_ACUITY){
                return_path = '/visual_acuity';
            }else if(current_step === TONOMETRY){
                return_path = '/tonometry';
            }else if(current_step === REFRACTOR){
                return_path = '/refraction';
            }else if(current_step === MUSCLES){
                return_path = '/muscle';
            }else if(current_step === LENSOMETRY){
                return_path = '/lensometry';
            }else if(current_step === KERATOMERY){
                return_path = '/keratometry';
            }else if(current_step === INDIRECT_EYE){
                return_path = '/indirect_eye';
            }else if(current_step === AUTOREFRACTOR){
                return_path = '/autorefractor';
            }else if(current_step === LAMP){
                return_path = '/lamp';
            }else if(current_step === RECORD){
                dispatch(getRecordDetail(appointment_id));
            }else{
                return_path = '/query-queue';
            }

            // Consulta para verificar en que pasos ya se han registrado datos
            api.get('consult/getCompleteSteps', { appointment_id }).then(complete_steps => {
                dispatch({ type: COMPLETE_STEPS, complete_steps });
                dispatch({ type: SET_OPEN_MODAL_STEP, open_modal_step: true });
            }).catch((error) => {
            });
        });
    }).catch((error) => {
        console.log("Error: ", error);
        Swal(
            'Error',
            'Por favor ingrese los datos en los campos correspondientes',
            'error'
        );
    }).finally(() => {

    });


}

export const saveConsultStep = (appointment_id, current_step, printSave=false, timerSwal=false) => (dispatch, getStore) => {
    let formData = {};

    let url_step = '';
    if(current_step === LENSOMETRY){
        url_step = 'consult/saveLensometry';
        formData = _.cloneDeep(getStore().form.Lensometry.values);
    }else if(current_step === VISUAL_ACUITY){
        url_step = 'consult/saveVisualAcuity';
        formData = _.cloneDeep(getStore().form.VisualAcuity.values);
    }else if(current_step === AUTOREFRACTOR){
        url_step = 'consult/saveAutorefractor';
        formData = _.cloneDeep(getStore().form.Autorefractor.values);
    }else if(current_step === KERATOMERY){
        url_step = 'consult/saveKeratometry';
        formData = _.cloneDeep(getStore().form.Keratometry.values);
    }else if(current_step === REFRACTOR){
        url_step = 'consult/saveRefractor';
        formData = _.cloneDeep(getStore().form.Refraction.values);
    }else if(current_step === MUSCLES){
        url_step = 'consult/saveMuscle';
        formData = _.cloneDeep(getStore().form.Muscle.values);
    }else if(current_step === TONOMETRY){
        url_step = 'consult/saveTonometry';
        formData = _.cloneDeep(getStore().form.Tonometry.values);
    }else if(current_step === LAMP){
        url_step = 'consult/saveLamp';
        formData = _.cloneDeep(getStore().form.Lamp.values);

        console.log("FORM DATA EN SAVE LAMP: ", formData);
        if(formData){
            if(formData.od_via_lagrimal === 'None' ||formData.od_via_lagrimal === undefined){ formData.od_via_lagrimal = null};
            if(formData.os_via_lagrimal === 'None'){ formData.os_via_lagrimal = null};
            if(formData.od_parpado === 'None'){ formData.od_parpado = null};
            if(formData.os_parpado === 'None'){ formData.os_parpado = null};
            if(formData.od_conjuntiva === 'None'){ formData.od_conjuntiva = null};
            if(formData.os_conjuntiva === 'None'){ formData.os_conjuntiva = null};
            if(formData.od_esclera === 'None'){ formData.od_esclera = null};
            if(formData.os_esclera === 'None'){ formData.os_esclera = null};
            if(formData.od_cornea === 'None'){ formData.od_cornea = null};
            if(formData.os_cornea === 'None'){ formData.os_cornea = null};
            if(formData.od_camara_anterior === 'None'){ formData.od_camara_anterior = null};
            if(formData.os_camara_anterior === 'None'){ formData.os_camara_anterior = null};
            if(formData.od_iris === 'None'){ formData.od_iris = null};
            if(formData.os_iris === 'None'){ formData.os_iris = null};
            if(formData.od_pupila === 'None'){ formData.od_pupila = null};
            if(formData.os_pupila === 'None'){ formData.os_pupila = null};
            if(formData.od_cristalino === 'None'){ formData.od_cristalino = null};
            if(formData.os_cristalino === 'None'){ formData.os_cristalino = null};

            if(formData.od_vitreo === 'None'){ formData.od_vitreo = null};
            if(formData.os_vitreo === 'None'){ formData.os_vitreo = null};

            if(formData.od_nervio_optico === 'None'){ formData.od_nervio_optico = null};
            if(formData.os_nervio_optico === 'None'){ formData.os_nervio_optico = null};

            if(formData.od_macula === 'None'){ formData.od_macula = null};
            if(formData.os_macula === 'None'){ formData.os_macula = null};

            if(formData.od_retina_periferica === 'None'){ formData.od_retina_periferica = null};
            if(formData.os_retina_periferica === 'None'){ formData.os_retina_periferica = null};
        }
    }else if(current_step === INDIRECT_EYE){
        url_step = 'consult/saveIndirectEye';
        formData = _.cloneDeep(getStore().form.IndirectEye.values);

        if(formData.od_vitreo === 'None'){ formData.od_vitreo = null};
        if(formData.os_vitreo === 'None'){ formData.os_vitreo = null};

        if(formData.od_nervio_optico === 'None'){ formData.od_nervio_optico = null};
        if(formData.os_nervio_optico === 'None'){ formData.os_nervio_optico = null};

        if(formData.od_mascula === 'None'){ formData.od_mascula = null};
        if(formData.os_mascula === 'None'){ formData.os_mascula = null};

        if(formData.od_retina_periferica_select === 'None'){ formData.od_retina_periferica_select = null};
        if(formData.os_retina_periferica_select === 'None'){ formData.os_retina_periferica_select = null};

    }else if(current_step === DIAGNOSTIC_PLAN){
        url_step = 'consult/saveDiagnosticPlan';
        formData = _.cloneDeep(getStore().form.DiagnosticPlan.values);
        console.log("Diagnostic plan: ", formData);
    }else if(current_step === EDUCATIONAL_PLAN){
        url_step = 'consult/saveEducationalPlan';
        formData = _.cloneDeep(getStore().form.EducationalPlan.values);
        console.log("FORM DATA ANTES DE SER ENVIADO: ", formData);
        /*
        delete formData.refraction_od_esf;
        delete formData.refraction_os_esf;
        delete formData.refraction_od_cl;
        delete formData.refraction_os_cl;
        delete formData.refraction_od_eje;
        delete formData.refraction_os_eje;
        */
    }else if(current_step === OPTICS_ORDER){
        url_step = 'consult/saveOpticsOrder';
        formData = _.cloneDeep(getStore().form.OpticsOrder.values);
    }

    const params = {
        appointment_id,
        current_step,
        formData,
    }

    console.log("PARAMS: ", params);
    dispatch(setLoader(true))
    api.post(url_step, params).then((response) => {
        if(!printSave) {                        
            Swal.fire({
                type: "success",
                title: "Datos guardados correctamente",
                showConfirmButton: false,
                position: "bottom-end",
                timer: 800,
            }).then(()=>{
                let return_path = '';
                console.log("current_step luego de ok: ", current_step);
    
                if(current_step === VISUAL_ACUITY){
                    dispatch(getVisualAcuityDetail(appointment_id));
                }else if(current_step === TONOMETRY){
                    dispatch(getTonometryDetail(appointment_id));
                }else if(current_step === REFRACTOR){
                    dispatch(getRefractorDetail(appointment_id));
                }else if(current_step === MUSCLES){
                    dispatch(getMuscleDetail(appointment_id));
                }else if(current_step === LENSOMETRY){
                    dispatch(getLensometryDetail(appointment_id));
                }else if(current_step === KERATOMERY){
                    dispatch(getKeratometryDetail(appointment_id));
                }else if(current_step === INDIRECT_EYE){
                    dispatch(getIndirectEyeDetail(appointment_id));
                }else if(current_step === AUTOREFRACTOR){
                    dispatch(getAutorefractorDetail(appointment_id));
                }else if(current_step === LAMP){
                    dispatch(getLampDetail(appointment_id));
                }else if(current_step === RECORD){
                    dispatch(getRecordDetail(appointment_id));
                }else if(current_step === DIAGNOSTIC_PLAN){
                    dispatch(getDiagnosticPlan(appointment_id));
                }else if(current_step === EDUCATIONAL_PLAN){
                    dispatch(getEducationalPlan(appointment_id));
                }else if(current_step === OPTICS_ORDER){
                    dispatch(getOpticsOrderDetail(appointment_id));
                    dispatch(getRefractorDetail(appointment_id));
                    dispatch(getAutorefractorDetail(appointment_id));
                }else{
                    return_path = '/query-queue';
                }

                api.get('consult/getCompleteSteps', { appointment_id }).then(complete_steps => {
                    dispatch({ type: COMPLETE_STEPS, complete_steps });
                    dispatch({ type: SET_OPEN_MODAL_STEP, open_modal_step: true });
                }).catch((error) => {
                });                
            });
            
            
            /*
            Swal('Éxito', 'Datos guardados correctamente.', 'success')
            .then(() => {
                let return_path = '';
                console.log("current_step luego de ok: ", current_step);
    
                if(current_step === VISUAL_ACUITY){
                    dispatch(getVisualAcuityDetail(appointment_id));
                }else if(current_step === TONOMETRY){
                    dispatch(getTonometryDetail(appointment_id));
                }else if(current_step === REFRACTOR){
                    dispatch(getRefractorDetail(appointment_id));
                }else if(current_step === MUSCLES){
                    dispatch(getMuscleDetail(appointment_id));
                }else if(current_step === LENSOMETRY){
                    dispatch(getLensometryDetail(appointment_id));
                }else if(current_step === KERATOMERY){
                    dispatch(getKeratometryDetail(appointment_id));
                }else if(current_step === INDIRECT_EYE){
                    dispatch(getIndirectEyeDetail(appointment_id));
                }else if(current_step === AUTOREFRACTOR){
                    dispatch(getAutorefractorDetail(appointment_id));
                }else if(current_step === LAMP){
                    dispatch(getLampDetail(appointment_id));
                }else if(current_step === RECORD){
                    dispatch(getRecordDetail(appointment_id));
                }else if(current_step === DIAGNOSTIC_PLAN){
                    dispatch(getDiagnosticPlan(appointment_id));
                }else if(current_step === EDUCATIONAL_PLAN){
                    dispatch(getEducationalPlan(appointment_id));
                }else if(current_step === OPTICS_ORDER){
                    dispatch(getOpticsOrderDetail(appointment_id));
                    dispatch(getRefractorDetail(appointment_id));
                    dispatch(getAutorefractorDetail(appointment_id));
                }else{
                    return_path = '/query-queue';
                }
    
                // Consulta para verificar que pasos ya se han registrado datos
                api.get('consult/getCompleteSteps', { appointment_id }).then(complete_steps => {
                    dispatch({ type: COMPLETE_STEPS, complete_steps });
                    dispatch({ type: SET_OPEN_MODAL_STEP, open_modal_step: true });
                }).catch((error) => {
                });
            })      
            */     
        } else if(current_step === OPTICS_ORDER && printSave){
            dispatch(getOpticsOrderDetail(appointment_id));
            dispatch(getRefractorDetail(appointment_id));
            dispatch(getAutorefractorDetail(appointment_id));
        }
    }).catch((error) => {
        console.log("Error: ", error);
    }).finally(() => {
        dispatch(setLoader(false))
    });

};

export const getLensometryDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getLensometryData`, {appointment_id}).then((response) => {
        console.log("lensometry detail: ", response);
        // ESTADOS
        if(parseInt(response.od_estado) === LECTURA_INEXACTA){
            response.od_estado = {value: LECTURA_INEXACTA, label: 'Lectura inexacta'}
        }else if(parseInt(response.od_estado) === MAL_ESTADO){
            response.od_estado = {value: MAL_ESTADO, label: 'Mal estado'}
        }

        if(parseInt(response.os_estado) === LECTURA_INEXACTA){
            response.os_estado = {value: LECTURA_INEXACTA, label: 'Lectura inexacta'}
        }else if(parseInt(response.os_estado) === MAL_ESTADO){
            response.os_estado = {value: MAL_ESTADO, label: 'Mal estado'}
        }

        if(response.types){
            response.tipo = {value: response.types.texto, label: response.types.texto};
        }

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }

        dispatch(initializeForm('Lensometry', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getVisualAcuityDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getVisualAcuityData`, {appointment_id}).then((response) => {
        console.log("visual acuity detail: ", response);
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('VisualAcuity', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getAutorefractorDetail = (appointment_id) => (dispatch) => {
    api.get(`consult/getAutorefractorData`, {appointment_id}).then((response) => {
        console.log("autorefractor detail: ", response);
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }

        dispatch(initializeForm('Autorefractor', response));
        dispatch(change('OpticsOrder', 'dip', response.dip));
    }).catch((error)=>{
        console.log("Error11: ", error);
    });
}

export const getKeratometryDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getKeratometryData`, {appointment_id}).then((response) => {
        console.log("keratometry detail: ", response);
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Keratometry', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getRefractorDetail = (appointment_id) => (dispatch, getStore) =>{
    api.get(`consult/getRefractorData`, {appointment_id}).then((response) => {
        console.log("refractor detail: ", response);
        console.log("appointment_id:", appointment_id);
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Refraction', response));
        dispatch(change('OpticsOrder', 'od_final_esfera', response.od_esf_final));
        dispatch(change('OpticsOrder', 'os_final_esfera', response.os_esf_final));
        dispatch(change('OpticsOrder', 'od_final_cilindro', response.od_cil_final));
        dispatch(change('OpticsOrder', 'os_final_cilindro', response.os_cil_final));
        dispatch(change('OpticsOrder', 'od_final_eje', response.od_eje_final));
        dispatch(change('OpticsOrder', 'os_final_eje', response.os_eje_final));
        dispatch(change('OpticsOrder', 'od_adicion_esfera', response.od_add_final));
        dispatch(change('OpticsOrder', 'os_adicion_esfera', response.os_add_final));
        const dataEducation = {
            refraction_od_esf: response.od_esf_final,
            refraction_os_esf: response.os_esf_final,
            refraction_od_cl: response.od_cil_final,
            refraction_os_cl: response.os_cil_final,
            refraction_od_eje: response.od_eje_final,
            refraction_os_eje: response.os_eje_final,
            refraction_os: response.os_avl_final ? response.os_avl_final.toString().toUpperCase() : '',
            refraction_od: response.od_avl_final ? response.od_avl_final.toString().toUpperCase() : '',
        };
        let formEducacional = getStore().form['EducationalPlan'];
        try {
            formEducacional = formEducacional.values;
        } catch (e) {
            formEducacional = {}
        }
        dispatch(initializeForm('EducationalPlan', {...formEducacional,...dataEducation}));
    }).catch((error)=>{
        api.get(`consult/getAutorefractorData`, {appointment_id}).then((response) => {
            const od_esf_objetiva = response.od_esf;
            const os_esf_objetiva = response.os_esf;
            const od_cil_objetiva = response.od_cil;
            const os_cil_objetiva = response.os_cil;
            const od_eje_objetiva = response.od_eje;
            const os_eje_objetiva = response.os_eje;

            const formData = {
                od_esf_objetiva,
                os_esf_objetiva,
                od_cil_objetiva,
                os_cil_objetiva,
                od_eje_objetiva,
                os_eje_objetiva
            }

            dispatch(initializeForm('Refraction', formData));
        }).catch((error)=>{
            console.log("Error: ", error);
        });
    });
}

const capitalize = (cadena) => {
    return cadena.charAt(0).toUpperCase() + cadena.slice(1)
}

export const getMuscleDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getMuscleData`, {appointment_id}).then((response) => {
        if(response.od_state !== null){
            response.od_state = capitalize(response.od_state.toString());
        }

        if(response.os_state !== null){
            console.log("response.os_state: ", response.os_state)
            response.os_state = capitalize(response.os_state.toString());
        }

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }

        console.log("Detalle de musculo extraocular: ", response);

        dispatch(initializeForm('Muscle', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getTonometryDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getTonometryData`, {appointment_id}).then((response) => {
        console.log("detalle de tonometría: ", response);
        if(response.od_tono_digital !== null){
            response.od_tono_digital = response.od_tono_digital.toString();
        }

        if(response.os_tono_digital !== null){
            response.os_tono_digital = response.os_tono_digital.toString();
        }

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Tonometry', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getLampDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getLampData`, {appointment_id}).then((response) => {
        response.od_via_lagrimal = response.od_via_lagrimal === null ? response.od_via_lagrimal_otro === null ? undefined : 'None' : capitalize(response.od_via_lagrimal.toString());
        response.os_via_lagrimal = response.os_via_lagrimal === null ? response.os_via_lagrimal_otro === null ? undefined : 'None' : capitalize(response.os_via_lagrimal.toString());
        response.od_parpado = response.od_parpado === null ? response.od_parpado_otro === null ? undefined : 'None' : capitalize(response.od_parpado.toString());
        response.os_parpado = response.os_parpado === null ? response.os_parpado_otro === null ? undefined : 'None' : capitalize(response.os_parpado.toString());
        response.od_conjuntiva = response.od_conjuntiva === null ? response.od_conjuntiva_otro === null ? undefined : 'None' : capitalize(response.od_conjuntiva.toString());
        response.os_conjuntiva = response.os_conjuntiva === null ? response.os_conjuntiva_otro === null ? undefined : 'None' : capitalize(response.os_conjuntiva.toString());
        response.od_esclera = response.od_esclera === null ? response.od_esclera_otro === null ? undefined : 'None' : capitalize(response.od_esclera.toString());
        response.os_esclera = response.os_esclera === null ? response.os_esclera_otro === null ? undefined : 'None' : capitalize(response.os_esclera.toString());
        response.od_cornea = response.od_cornea === null ? response.od_cornea_otro === null ? undefined : 'None' : capitalize(response.od_cornea.toString());
        response.os_cornea = response.os_cornea === null ? response.os_cornea_otro === null ? undefined : 'None' : capitalize(response.os_cornea.toString());
        response.od_camara_anterior = response.od_camara_anterior === null ? response.od_camara_anterior_otro === null ? undefined : 'None' : capitalize(response.od_camara_anterior.toString());
        response.os_camara_anterior = response.os_camara_anterior === null ? response.os_camara_anterior_otro === null ? undefined : 'None' : capitalize(response.os_camara_anterior.toString());
        response.od_iris = response.od_iris === null ? response.od_iris_otro === null ? undefined : 'None' : capitalize(response.od_iris.toString());
        response.os_iris = response.os_iris === null ? response.os_iris_otro === null ? undefined : 'None' : capitalize(response.os_iris.toString());
        response.od_pupila = response.od_pupila === null ? response.od_pupila_otro === null ? undefined : 'None' : capitalize(response.od_pupila.toString());
        response.os_pupila = response.os_pupila === null ? response.os_pupila_otro === null ? undefined : 'None' : capitalize(response.os_pupila.toString());
        response.od_cristalino = response.od_cristalino === null ? response.od_cristalino_otro === null ? undefined : 'None' : capitalize(response.od_cristalino.toString());
        response.os_cristalino = response.os_cristalino === null ? response.os_cristalino_otro === null ? undefined : 'None' : capitalize(response.os_cristalino.toString());

        {/*
        response.od_vitreo = (response.od_vitreo_otro === null || response.od_vitreo_otro === '') ? null : capitalize(response.od_vitreo.toString());
        response.os_vitreo = (response.os_vitreo_otro === null || response.os_vitreo_otro === '') ? null : capitalize(response.os_vitreo.toString());
        response.od_nervio_optico = (response.od_nervio_optico_otro === null || response.od_nervio_optico_otro === '') ? null : capitalize(response.od_nervio_optico.toString());
        response.os_nervio_optico = (response.os_nervio_optico_otro === null || response.os_nervio_optico_otro === '') ? null : capitalize(response.os_nervio_optico.toString());
        response.od_macula = (response.od_macula_otro === null || response.od_macula_otro === '') ? capitalize(response.od_mascula.toString()) : null;
        response.os_macula = (response.os_macula_otro === null || response.os_macula_otro === '') ? capitalize(response.os_mascula.toString()) : null;
        */}

        response.od_macula_otro = response.od_mascula_otro;
        response.os_macula_otro = response.os_mascula_otro;

        response.od_vitreo = response.od_vitreo === null ? response.od_vitreo_otro === null ? undefined : 'None' : capitalize(response.od_vitreo.toString());
        response.os_vitreo = response.os_vitreo === null ? response.os_vitreo_otro === null ? undefined : 'None' : capitalize(response.os_vitreo.toString());

        response.od_nervio_optico = response.od_nervio_optico === null ? response.od_nervio_optico_otro === null ? undefined : 'None' : capitalize(response.od_nervio_optico.toString());
        response.os_nervio_optico = response.os_nervio_optico === null ? response.os_nervio_optico_otro === null ? undefined : 'None' : capitalize(response.os_nervio_optico.toString());

        response.od_macula = response.od_mascula === null ? response.od_mascula_otro === null ? undefined : 'None' : capitalize(response.od_mascula.toString());
        response.os_macula = response.os_mascula === null ? response.os_mascula_otro === null ? undefined : 'None' : capitalize(response.os_mascula.toString());

        response.od_retina_periferica = response.od_retina_periferica === null ? response.od_retina_periferica_otro === null ? undefined : 'None' : capitalize(response.od_retina_periferica.toString());
        response.os_retina_periferica = response.os_retina_periferica === null ? response.os_retina_periferica_otro === null ? undefined : 'None' : capitalize(response.os_retina_periferica.toString());

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Lamp', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getIndirectEyeDetail = (appointment_id) => (dispatch) =>{
    api.get(`consult/getIndirectEyeData`, {appointment_id}).then((response) => {
        console.log("indirect eye data: ", response);

        //const od_vitreo = (!response.od_vitreo &&  (response.od_vitreo_otro === null || response.od_vitreo_otro === '')) ? undefined : capitalize(response.od_vitreo.toString());
        const od_vitreo = response.od_vitreo === null ? response.od_vitreo_otro === null ? undefined : 'None' : capitalize(response.od_vitreo.toString());
        response.od_vitreo = od_vitreo;

        //const os_vitreo = (!response.os_vitreo &&  (response.os_vitreo_otro === null || response.os_vitreo_otro === '')) ? undefined : capitalize(response.os_vitreo.toString());
        const os_vitreo = response.os_vitreo === null ? response.os_vitreo_otro === null ? undefined : 'None' : capitalize(response.os_vitreo.toString());
        response.os_vitreo = os_vitreo;

        //const od_nervio_optico = (!response.od_nervio_optico &&  (response.od_nervio_optico_otro === null || response.od_nervio_optico_otro === '')) ? undefined : capitalize(response.od_nervio_optico.toString());
        const od_nervio_optico = response.od_nervio_optico === null ? response.od_nervio_optico_otro === null ? undefined : 'None' : capitalize(response.od_nervio_optico.toString());
        response.od_nervio_optico = od_nervio_optico;

        //const os_nervio_optico = (!response.os_nervio_optico &&  (response.os_nervio_optico_otro === null || response.os_nervio_optico_otro === '')) ? undefined : capitalize(response.os_nervio_optico.toString());
        const os_nervio_optico = response.os_nervio_optico === null ? response.os_nervio_optico_otro === null ? undefined : 'None' : capitalize(response.os_nervio_optico.toString());
        response.os_nervio_optico = os_nervio_optico;

        //const od_mascula = (!response.od_mascula &&  (response.od_mascula_otro === null || response.od_mascula_otro === '')) ? undefined : capitalize(response.od_mascula.toString());
        const od_mascula = response.od_mascula === null ? response.od_mascula_otro === null ? undefined : 'None' : capitalize(response.od_mascula.toString());
        response.od_mascula = od_mascula;

        //const os_mascula = (!response.os_mascula &&  (response.os_mascula_otro === null || response.os_mascula_otro === '')) ? undefined : capitalize(response.os_mascula.toString());
        const os_mascula = response.os_mascula === null ? response.os_mascula_otro === null ? undefined : 'None' : capitalize(response.os_mascula.toString());
        response.os_mascula = os_mascula;

        const od_retina_periferica_select = response.od_retina_periferica_select === null ? response.od_retina_periferica_otro_select === null ? undefined : 'None' : capitalize(response.od_retina_periferica_select.toString());
        response.od_retina_periferica_select = od_retina_periferica_select;

        const os_retina_periferica_select = response.os_retina_periferica_select === null ? response.os_retina_periferica_otro_select === null ? undefined : 'None' : capitalize(response.os_retina_periferica_select.toString());
        response.os_retina_periferica_select = os_retina_periferica_select;

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }

        dispatch(initializeForm('IndirectEye', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getDiagnosticPlan = (appointment_id) => (dispatch) =>{
    api.get(`consult/getDiagnosticPlan`, {appointment_id, marcar_inicio: true}).then((response) => {
        response.dp_dpa.forEach(plan => {
            const nuevo_nombre = {value: plan.nombre, label: plan.nombre};
            plan.nombre = nuevo_nombre;
            let tratamientos = [];
            plan.td_dp.filter(td => {
                const new_commercial_name = td.treatment.commercial_name;

                const treatment_data = {
                    commercial_name: new_commercial_name,
                    quantity: td.quantity,
                    active_principle: td.treatment.active_principle,
                    application: td.application,
                    dose: td.dose,
                    frecuency: td.frecuency,
                    presentation: td.presentation,
                    time_duration: td.time_duration,
                }
                tratamientos.push(treatment_data);
            });

            plan.td_dp = tratamientos;
        });

        dispatch({type: SET_RECETA, receta: response});
        dispatch(initializeForm('DiagnosticPlan', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const resolveDiagnosticPlan = (diagnostic_plan_id, resolve=false, appointment_id) => (dispatch) => {
    api.post(`consult/resolveDiagnosticPlan`, {diagnostic_plan_id, resolve}).then((response) => {
        Swal('Éxito', 'Se ha actualizado el estado del diagnóstico', 'success')
        .then(() => {
            console.log("APPOINTMENT ID: ", appointment_id);
            dispatch(getReconsultDetail(appointment_id));
        });
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getEducationalPlan = (appointment_id) => (dispatch, getStore) => {
    api.get(`consult/getEducationalPlan`, {appointment_id}).then((response) => {
        let data = [];
        if(response.eps_ep){

            response.eps_ep.forEach(plan => {
                if(plan.plan){
                    plan.id = plan.plan.id;
                    plan.nombre = plan.plan.nombre;
                    plan.incluye = plan.plan.incluye;
                }
                data.push(plan)
            });
            delete response.eps_ep
            response.planes = data;
            response.laboratorio = {value: response.laboratorios, label: response.laboratorios}
        }else{
            const planes = response;
            response = {planes};
        }

        console.log("Planes: ", response);
        let formEducacional = getStore().form['EducationalPlan'];
        try {
            formEducacional = formEducacional.values;
        } catch (e) {
            formEducacional = {}
        }
        dispatch(initializeForm('EducationalPlan', {...response, ...formEducacional}));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getOpticsOrderDetail = (appointment_id) => (dispatch, getStore) => {
    api.get(`consult/getOpticsOrder`, {appointment_id}).then((response) => {
        console.log("Orden de óptica: ", response);
        dispatch({type: SET_ORDEN_OPTICA, orden_optica: response});
        dispatch(initializeForm('OpticsOrder', response));
    }).catch((error)=>{
        api.get(`consult/getRefractorData`, {appointment_id}).then((response) => {
            console.log("refractor detail: ", response);
            let formData = {
                od_final_esfera: response.od_esf_final,
                os_final_esfera: response.os_esf_final,
                od_final_cilindro: response.od_cil_final,
                os_final_cilindro: response.os_cil_final,
                od_final_eje: response.od_eje_final,
                os_final_eje: response.os_eje_final,
                od_adicion_esfera: response.od_add_final,
                os_adicion_esfera: response.os_add_final,
                //date: moment().date(1).format("DD/MM/YYYY"),
                date: moment(new Date()).format("YYYY-MM-DD"),
            }
            api.get(`consult/getAutorefractorData`, {appointment_id}).then((response) => {
                formData.dip = response.dip;
            }).catch((error)=>{
                console.log("Error11: ", error);
            }).finally(()=>{
                dispatch(initializeForm('OpticsOrder', formData));
            });
        }).catch((error)=>{
            console.log("Error: ", error);
        });
    });
}

export const getReconsultDetail = (appointment_id) => (dispatch, getStore) => {
    console.log("Mostrando detalle de consulta");
    api.get(`consult/getDiagnosticPlan`, {appointment_id}).then((response) => {
        response.dp_dpa.forEach(plan => {
            console.log("PLAN ---> ", plan);
            const nuevo_nombre = {value: plan.nombre, label: plan.nombre};
            plan.nombre = nuevo_nombre;
            let tratamientos = [];
            plan.td_dp.filter(td => {
                const new_commercial_name = td.treatment.commercial_name;

                const treatment_data = {
                    commercial_name: new_commercial_name,
                    active_principle: td.treatment.active_principle,
                    application: td.application,
                    dose: td.dose,
                    frecuency: td.frecuency,
                    presentation: td.presentation,
                    time_duration: td.time_duration,
                }
                tratamientos.push(treatment_data);
            });

            plan.td_dp = tratamientos;
        });

        //dispatch(initializeForm('ReconsultPlan', response));
        dispatch(initializeForm('Reconsult', response));
    }).catch((error)=>{
            console.log("Error eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee ", error);

            api.get(`consult/getLastDiagnosticPlan`, {appointment_id}).then((response) => {
                response.dp_dpa.forEach(plan => {
                    const nuevo_nombre = {value: plan.nombre, label: plan.nombre};
                    plan.nombre = nuevo_nombre;
                    let tratamientos = [];
                    plan.td_dp.filter(td => {
                        const new_commercial_name = td.treatment.commercial_name;

                        const treatment_data = {
                            commercial_name: new_commercial_name,
                            active_principle: td.treatment.active_principle,
                            application: td.application,
                            dose: td.dose,
                            frecuency: td.frecuency,
                            presentation: td.presentation,
                            time_duration: td.time_duration,
                        }
                        tratamientos.push(treatment_data);
                    });

                    plan.td_dp = tratamientos;
                });

                console.log("NUEVA DATA PARA REINICIALIZAR EL FORM: ", response);
                dispatch(initializeForm('Reconsult', response));
            }).catch((error)=>{
                console.log("Error rrrrrrrrrrrrrrrrrr ", error);

            });
    });
}

export const setOpenModalStep = (open_modal_step) => (dispatch, getStore) => {
    console.log("ABRIR PREGUNTA DE MODAL: ", open_modal_step);
    dispatch({type: SET_OPEN_MODAL_STEP, open_modal_step});
}

export const getLastConsult = (patient_id) => (dispatch, getStore) => {
    api.get(`consult/getLastConsult`, {patient_id}).then((response) => {
        console.log("Last consult: ", response);
        dispatch({type: SET_LAST_CONSULT, last_consult: response});
        //dispatch(initializeForm('EducationalPlan', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getLastRecordPlan = (appointment_id) => (dispatch, getStore) => {
    api.get(`record/getLastRecordData`, {appointment_id}).then((response) => {
        response.reason = response.consultation_reason;
        response.history = response.disease_history;
        response.note = response.fam_note;

        let md = []; let qx = []; let alg = []; let trx = []; let fam = [];

        response.rar_ra.forEach(item => {
            if(item.record.record_type === MD){
                md.push({
                    diagnostico: {value: item.record.name, label: item.record.name },
                    tratamiento: {value: item.record.md_treatment, label: item.record.md_treatment}
                });
            }else if(item.record.record_type === QX){
                qx.push({
                    cirugia: {value: item.record.name, label: item.record.name}
                });
            }else if(item.record.record_type === ALG){
                alg.push({
                    alergia: {values: item.record.name, label: item.record.name}
                });
            }else if(item.record.record_type === TRX){
                trx.push({
                    trauma: {values: item.record.name, label: item.record.name}
                });
            }else if(item.record.record_type === FAM){
                fam.push({
                    diagnostico: {value: item.record.name, label: item.record.name},
                    tratamiento: {value: item.record.fam_family, label: item.record.fam_family}
                });
            }

        });

        response.md = md;
        response.qx = qx;
        response.alg = alg;
        response.trx = trx;
        response.fam = fam;

        dispatch({type: SET_LAST_RECORD, last_record: response});
    }).catch((error)=>{
        console.log("Error: ", error);
        dispatch({type: SET_LAST_RECORD, last_record: null});
    });
}

const changeKProm = (fieldName, value )=>(dispatch)=>{
    dispatch(change('Keratometry', fieldName, value));
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    sortChange,
    setStep,
    topBarSetStep,
    simularDetail,

    saveRecord,
    getRecordDetail,
    saveConsultStep,
    getLensometryDetail,
    getVisualAcuityDetail,
    getAutorefractorDetail,
    getKeratometryDetail,
    getRefractorDetail,
    getMuscleDetail,
    getTonometryDetail,
    getLampDetail,
    getIndirectEyeDetail,
    getReconsultDetail,
    getDiagnosticPlan,
    resolveDiagnosticPlan,
    getEducationalPlan,
    setOpenModalStep,
    getOpticsOrderDetail,
    getLastConsult,
    changeKProm,
    getLastRecordPlan,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SET_PLANES]: (state, { planes }) => {
        return {
            ...state,
            planes,
        };
    },
    [SET_OPEN_MODAL_STEP]: (state, { open_modal_step }) => {
        console.log("Setear el modal en: ", open_modal_step);
        return {
            ...state,
            open_modal_step,
        };
    },
    [SET_LAST_CONSULT]: (state, { last_consult }) => {
        return {
            ...state,
            last_consult,
        };
    },
    [SET_LAST_RECORD]: (state, { last_record }) => {
        return {
            ...state,
            last_record,
        };
    },
    [SET_RECETA]: (state, { receta }) => {
        return {
            ...state,
            receta,
        };
    },
    [COMPLETE_STEPS]: (state, { complete_steps }) => {
        return {
            ...state,
            complete_steps,
        };
    },
    [SET_ORDEN_OPTICA]: (state, { orden_optica }) => {
        return {
            ...state,
            orden_optica,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {patient:{}},
    page: 1,
    ordering: '',
    search: '',
    complete_steps: [],
    planes: [],
    open_modal_step: false,
    last_consult: null,
    last_record: null,

    receta: null,
    orden_optica: null,
};

export default handleActions(reducers, initialState);
