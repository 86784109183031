import Table from "../../Utils/Grid";

import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import moment from "moment";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import NumberFormat from "react-number-format";

class ReporteProductosConsignacion extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        this.props.listar();
    }

    render() {
        const {
            listar,
            setInitialDate,
            setFinalDate,
            filtro,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
        } = this.props;

        const filter_options = [
            {value: 1 , label: 'Vendidos'},
            {value: 2 , label: 'Por vender'}
        ];

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                    <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">

                    <div className="top-title-toolbar mt-3 d-flex">
                        <span className="ml-2 text-uppercase text-titulo"><strong>REPORTE CONSIGNACIÓN</strong></span>
                    </div>

                    <div className="d-flex flex-row justify-content-end align-items-center flex-wrap w-50">
                        {/*
                        <div style={{width: 150}} className='mr-2'>
                        <Select
                            className={"w-100"}
                            onChange={(e) => {
                                filtro(e.value);
                            }}
                            classNamePrefix="react-select"
                            defaultOptions
                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: '1em',
                                    background: 'white',
                                    colors: {
                                        ...theme.colors,
                                        text: '#415362',
                                        primary25: '#ebebeb',
                                        primary: '#0a5995',
                                    },
                                }
                            }}

                            searchPromptText="Escriba para buscar" valueKey={'id'} labelKey={'name'}
                            options={filter_options}
                        />
                        </div>
                        */}
                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20"
                            value={initial_date}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    setInitialDate(values.formattedValue);
                                }                                                                                
                            }}
                            autoFocus
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />

                        <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                            className="form-control w-20 ml-2 mr-2"
                            value={final_date}
                            onValueChange={(values) => {                                        
                                const dia = values.formattedValue.indexOf("D")
                                const mes = values.formattedValue.indexOf("M")
                                const anio = values.formattedValue.indexOf("Y")

                                if((dia + mes + anio) < 0){
                                    setFinalDate(values.formattedValue);
                                }                                                                                
                            }}
                            
                            onFocus={(e) => {
                                e.target.setSelectionRange(0,10);
                            }}                                    
                        />
                    <div className="top-title-toolbar mt-3">
                        <CustomToolbar
                            buscar={this.props.search}
                            buscador={this.props.buscador}                            
                        />
                    </div>
                    </div>
                    </div>

                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="doctor"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    if(row.lote){
                                        return row.lote.lote;
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                >Lote</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort
                                dataFormat={(cell, row) => {
                                    if(row.service){
                                        return row.service.name;
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                >Producto</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField='patient'
                                dataFormat={(cell, row)=>{
                                    return row.lote.creado;
                                }}
                                dataSort>Fecha</TableHeaderColumn>

                            {/*
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField='entry_movement'
                                dataFormat={(cell, row)=>{
                                    if(row.entry_movement){
                                        return (
                                            <a
                                                href={`/#/reporte-entrada-producto/${row.entry_movement.id}`}
                                                target='_blank'
                                            >
                                                <img
                                                    src={require('../../../../../assets/img/icons/view-icon.png')}
                                                    className='action-img watch'
                                                    title='ver cuenta'
                                                />
                                            </a>
                                        )
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                dataSort>Ingreso de producto</TableHeaderColumn>
                                */}

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell, row)=>{
                                    if(row.entry_movement){
                                        return (
                                            <a
                                                href={`/#/reporte-entrada-producto/${row.entry_movement.id}`}
                                                target='_blank'
                                            >
                                                <img
                                                    src={require('../../../../../assets/img/icons/view-icon.png')}
                                                    className='action-img watch'
                                                    title='ver cuenta'
                                                />
                                            </a>
                                        )
                                    }else{
                                        return 'Sin asignar';
                                    }
                                }}
                                >Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReporteProductosConsignacion;
