import React, { Component } from 'react';
import { Field, reduxForm,  formValueSelector } from 'redux-form';
import { renderField, renderDatePicker, renderCurrency, dateInput } from 'Utils/renderField/renderField';

export const renderDepositRow =({ fields, meta: {error, submitFailed}, edit, view}) => (

    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} >
                    <td className="d-none">
                    <Field
                        component="input"
                        name={`${field}.id`}
                        type="hidden"
                    />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.amount`}
                            component={renderCurrency}
                            type="text"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.document_number`}
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.date`}
                            component={dateInput}
                            className="w-50"
                        />
                    </td>
                    <td className="text-center">
                        <img className="action-img delete-item-package"
                            src={require("../../../../../assets/img/icons/delete-icon.png")}
                            onClick={() => {fields.remove(index)}}
                            alt="Eliminar" />
                    </td>
                </tr>
                )
            )
        }
                <tr id="add-row">
                    <td colSpan={3}></td>
                    <td className="text-center">
                            <img onClick={() => fields.push()} className="action-img add" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </td>
                </tr>
        {/* row for the add button */}
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);
