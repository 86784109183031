import React, {Component} from 'react';
import './audio_style';

class AudioReproductor extends Component {
    render(){
        console.log("AUDIO PROPS: ", this.props);

        return(
            <React.Fragment>
            <div className="d-flex flex-row align-items-center">
                <div className='d-flex flex-row align-items-center audio-content'>
                    <div className='d-flex flex-column w-75'>
                        <span className='audio-interpreter-label'>Interpretación 1</span>
                        <span className='audio-fecha'>30/09/2019</span>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-end text-right w-25">
                        <img
                            className="action-img edit"
                            title="Ver"
                            src={require("../../../../../../assets/img/icons/start-icon-white.png")}
                            alt="Ver"
                        />
                    </div>
                </div>


                <div className='d-flex flex-row align-items-center reproductor-content ml-5'>
                    <div className='d-flex flex-row w-25'>
                        <span className='reproductor-interpreter-label'>00:01:25</span>
                    </div>
                    <div className='d-flex flex-row w-25'>
                        <span className='reproductor-interpreter-label'>Reproduciendo</span>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-end text-right w-50">
                        <img
                            className="action-img add"
                            title="Ver"
                            src={require("../../../../../../assets/img/icons/stop-icon-blue.png")}
                            alt="Ver"
                        />

                        <img
                            className="action-img add"
                            title="Ver"
                            src={require("../../../../../../assets/img/icons/pausa-icon-blue.png")}
                            alt="Ver"
                        />
                    </div>
                </div>

            </div>
            </React.Fragment>
        );
    }
}

export default AudioReproductor;
