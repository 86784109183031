import React from "react";

const Table = ({
    data_pdf = {},
    total = 0,
    observaciones = "Sin observaciones",
}) => {
    return (
        <div
            style={{
                position: "relative",
                color: "black",
                left: "0",
                top: "0",
                borderRadius: "30",
                minHeight: "100",
                fontSize: "11px",
                fontWeight: "600",
            }}
        >
            <div
                style={{
                    backgroundColor: "grey",
                    minHeight: "5",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    fontSize: "8",
                }}
            >
                {/* Titles */}
                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>CODIGO</span>
                </div>
                <div
                    style={{
                        flex: 4,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>PRODUCTO</span>
                </div>

                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>INCLUYE</span>
                </div>

                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>MEDIDA</span>
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>UTILIZO</span>
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>EXTRA</span>
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>TOTAL</span>
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>PRECIO</span>
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignItems: "right",
                        color: "white",

                        fontWeight: "bold",
                    }}
                >
                    <span>TOTAL EXTRA</span>
                </div>
            </div>
            {data_pdf.map((data, index) => (
                <div
                    style={{
                        minHeight: "0",
                        borderBottom: "0.5px solid #ABAFB2",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "8",
                        fontWeight: "400",
                    }}
                    key={index}
                >
                    {/* CODIGO */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>{data.service.barcode}</span>
                    </div>

                    {/* PRODUCTO */}
                    <div
                        style={{
                            flex: 4,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>{data.service.name}</span>
                    </div>

                    {/* INCLUYE */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>
                            {data.initial ? data.requested_quantity : 0}
                        </span>
                    </div>

                    {/* MEDIDA */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>Unidad</span>
                    </div>

                    {/* UTILIZO */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>{data.used_quantity}</span>
                    </div>

                    {/* EXTRA */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>
                            {data.initial
                                ? data.used_quantity -
                                      data.requested_quantity <=
                                  0
                                    ? 0
                                    : data.used_quantity -
                                      data.requested_quantity
                                : data.used_quantity}
                        </span>
                    </div>

                    {/* TOTAL */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>{data.used_quantity}</span>
                    </div>

                    {/* PRECIO */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>{data.price}</span>
                    </div>

                    {/* TOTAL EXTRA */}
                    <div
                        style={{
                            flex: 1,
                            padding: "2",
                            display: "flex",
                        }}
                    >
                        <span>
                            {(data.initial
                                ? data.used_quantity -
                                      data.requested_quantity <=
                                  0
                                    ? 0
                                    : data.used_quantity -
                                      data.requested_quantity
                                : data.used_quantity) * data.price}
                        </span>
                    </div>
                </div>
            ))}

            <div
                style={{
                    borderBottom: "0.5px solid #ABAFB2",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: "8",
                }}
            >
                <div
                    style={{
                        flex: 10,
                        padding: "2",
                        display: "flex",
                    }}
                ></div>

                <div
                    style={{
                        flex: 1,
                        padding: "2",
                        display: "flex",
                        alignContent: "flex-end",
                    }}
                >
                    <span style={{ fontWeight: "bold" }}>{total}</span>
                </div>
            </div>
            <span
                style={{
                    fontSize: "10",
                    fontStyle: "italic",
                    fontWeight: "bold",
                }}
            >
                Observaciones:
            </span>
            <span
                style={{
                    fontSize: "10",
                    fontWeight: "400",
                }}
            >
                {observaciones ? observaciones : "Sin observaciones"}
            </span>
        </div>
    );
};

export default Table;
