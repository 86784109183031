import React, { Component } from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {
    renderField,
    renderSelectCustom,
    renderSearchSelect,
    renderCurrency,
    renderSwitch
} from 'Utils/renderField/renderField';
import { connect } from 'react-redux'
import trim from 'lodash/trim';
import validarNit from 'validar-nit-gt';
import {renderFieldDateFormat} from "../Utils/renderField/renderField";

//import {conexion} from './FormConfig';

export const isEmpty = value => value === undefined || value === null || value === '';
export function NIT(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase())
        return validarNit(value) ? null : value === 'CF' ? null : 'Escriba un nit válido o CF'
    }
    return null
}

let spendings = []
const getSpendingTypes = (search)  =>{
    return api.get("spending_type",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                spendings = [];
                data.results.forEach(x=>{
                    spendings.push({value: x.id, label: x.name})
                })
                return spendings;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let small_boxes = []
const getSmallBoxes = (search)  =>{
    return api.get("small_box",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                small_boxes = [];
                data.results.forEach(x=>{
                    small_boxes.push({value: x.id, label: x.name, nit: x.nit, limit: x.limit});
                })
                return small_boxes;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let providers=[];
const getProviders = (search)  => {
    return api.get("providers/", {search}).catch((error) => {})
        .then((data) => {
            if(data){
                providers = [];
                data.results.forEach(x=>{
                    providers.push({
                        value: x.id, 
                        label: x.name, 
                        retention: x.retention,
                        retention_iva: x.retention_iva,
                    })
                })
                return providers;
            }
            else{
                return [];
            }
        })
        .catch(e => {
            return [];
        })
}

let initialValues=  {};

let mostrar = true;
const setMostar = (value)=> { 
    mostrar = value.value === 2 ? true : false;
}

const Form = props => {
    const {
        handleSubmit,
        use_small_box,
        smallbox_limit,
        to_pay,
        provider,
        amount,
    } = props;

    initialValues.smallbox_limit = smallbox_limit;

    const view = window.location.href.includes("view");
    const edit = window.location.href.includes("edit");

    //console.log("USE_SMALL_BOX: ", use_small_box);

    if(use_small_box !== undefined){
        if(!use_small_box){
            props.change('payment_type',{value:2, label:'Transferencia'});
            props.change('small_box', null);
        }
    }

    // PARA ISR
    let retention_amount = 0;
    if((amount >= 2800) && !!provider && (provider.retention)){
        retention_amount = ((amount / 1.12) * 0.05).toFixed(2);
        props.change('retention_amount', retention_amount);
    }else{
        props.change('retention_amount', 0);
    }
    // PARA IVA
    let iva_retention_amount = 0;
    if((amount >= 2500) && !!provider && (provider.retention_iva)){
        iva_retention_amount = (((amount / 1.12) * 0.12) * 0.15).toFixed(2);
        props.change('retention_amount_iva', iva_retention_amount);
    }else{
        props.change('retention_amount_iva', 0);
    }

    console.log("iva_retention_amount: ", iva_retention_amount);
    console.log("retention_amount: ", retention_amount, props);

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <Field name="smallbox_limit" component="input" type="hidden"/>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="to_pay" className="m-0">¿Registrar como cuenta por pagar?</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="to_pay" disabled={view} component={renderSwitch} type="text" className="form-control"/>
                    </div>
                </div>
                {!!to_pay && to_pay === true ?
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="bill_number" className="">No. Factura</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="bill_number" disabled={view} component={renderField} />
                        </div>
                    </div>
                :
                    <div className="m-2 invisible">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin" className="">Comodín</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodin" disabled={view} component={renderField} />
                        </div>
                    </div>
                }
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="spending_type" className="m-0">Proveedor</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="provider"
                            valueKey="value"
                            labelKey="label"
                            label="provider"
                            component={renderSearchSelect}
                            loadOptions={getProviders}
                            className="no-border"
                            autofocus
                            disabled={view}
                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="detail" className="m-0">detalle</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="detail" disabled={view} component={renderField} />
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="spending_type" className="m-0">Tipo de Gasto</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="spending_type"
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getSpendingTypes}
                            className="no-border"
                            disabled={ view || edit }

                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="amount" className="m-0">Monto</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="amount" disabled={view} component={renderCurrency} />
                    </div>
                </div>
            </div>

            {(to_pay === undefined || to_pay === null || to_pay === false) &&
            <React.Fragment>
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="payment_type" className="">Modo de pago</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="payment_type"
                                disabled={view}
                                component={renderSelectCustom}
                                onChange={ (value)=>{ setMostar(value) } }
                                options={[
                                    {value:1, label:'Caja Chica'},
                                    {value:2, label:'Transferencia'},
                                ]}
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="document_number_payment" className="">NO. FACTURA</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="document_number_payment" disabled={view} component={renderField} />
                        </div>
                    </div>
                </div>

                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="document_number_entry" className="">NO. TRANSFERENCIA</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field 
                                name="document_number_entry" 
                                disabled={ view || !mostrar } 
                                component={renderField} />
                        </div>
                    </div>
                    <div className="m-2 invisible">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin" className="">Comodín</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodin" disabled={view} component={renderField} />
                        </div>
                    </div>
                </div>

                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="document_date" className="">FECHA DE PAGO</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="document_date" disabled={view} component={renderFieldDateFormat} />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="document_date_payment" className="">FECHA DE FACTURA</label>
                        </div>
                        <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="document_date_payment" disabled={view} component={renderFieldDateFormat} />
                        </div>
                    </div>
                </div>
                {(use_small_box === true || view) &&
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="small_box" className="m-0">Nit de caja Chica:</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="small_box"
                                valueKey="value"
                                labelKey="nit"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getSmallBoxes}
                                className="no-border"
                                disabled={ view || edit }
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="small_box" className="m-0">Caja Chica</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="small_box"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getSmallBoxes}
                                className="no-border"
                                disabled={ view || edit }
                            />
                        </div>
                    </div>
                </div>
                }
                {(use_small_box === false || view) &&
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="account_number" className="m-0">No. Cuenta</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="bank_account"
                                valueKey="value"
                                labelKey="number"
                                label="cuenta"
                                component={renderSearchSelect}
                                loadOptions={getBankAccounts}
                                className="no-border"
                                addClass="no-border"
                                disabled={ view || edit }
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="account_number" className="m-0">Usuario Cuenta</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="bank_account"
                                valueKey="value"
                                labelKey="label"
                                label="referent"
                                component={renderSearchSelect}
                                loadOptions={getBankAccounts}
                                className="no-border"
                                addClass="no-border"
                                disabled={ view || edit }
                            />
                        </div>
                    </div>
                </div>
                }
                </React.Fragment>
            }

            {((!!retention_amount && retention_amount > 0) || (!!iva_retention_amount && iva_retention_amount > 0)) &&
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    {/* RETENCION DEL ISR */}
                    {(!!retention_amount && retention_amount > 0) && (
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="retention_amount" className="m-0">Monto de retención ISR</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="retention_amount" disabled={true} component={renderCurrency} type="text" className="form-control"/>
                            </div>
                        </div>
                    )
                    }
                    {/* RETENCION DEL IVA */}
                    {(!!iva_retention_amount && iva_retention_amount > 0) && (
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="retention_amount_iva" className="m-0">Monto de retención IVA</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="retention_amount_iva" disabled={true} component={renderCurrency} type="text" className="form-control"/>
                            </div>
                        </div>
                    )
                    }
                    {/* COMODIN */}
                    {(!(!!retention_amount && retention_amount > 0) || !(!!iva_retention_amount && iva_retention_amount > 0)) &&
                        <div className="m-2 invisible">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="comodin" className="">Comodín</label>
                            </div>
                            <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="comodin" disabled={view} component={renderField} />
                            </div>
                        </div>
                    }
                </div>
            }

            {!!to_pay && to_pay === true ?
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="fecha_fac" className="m-0">FECHA FACTURA</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="fecha_fac"
                                label="fecha_fac"
                                component={renderFieldDateFormat}
                                className="no-border"
                                disabled={view}
                            />
                        </div>
                    </div>
                    <div className="m-2 invisible">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin" className="">Comodín</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodin" disabled={view} component={renderField} />
                        </div>
                    </div>
                </div>
            :
                <div/>
            }

            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/income-expense">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {(to_pay === undefined || to_pay === null || to_pay === false) ?
                        <React.Fragment>
                            {!view && (<button type="submit" className="btn btn-primary m-3">Registrar Gasto</button>)}
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {!view && (<button type="submit" className="btn btn-success m-3">Registrar Cuenta por pagar</button>)}
                        </React.Fragment>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-center">*LA CAJA CHICA DEBE TENER FONDOS SUFICIENTES PARA REALIZAR OPERACIONES, DE LO CONTRARIO NO PODRÁ SELECCIONARLA.</div>
        </form>
    )
};

let SmallBoxForm = reduxForm({
    form: 'expenseForm',
    initialValues,
    enableReinitialize: true,
    validate: data => {
        return validate(data, {
           'spending_type': validators.exists()('Campo requerido'),
           'payment_type': validators.exists()('Campo requerido.'),
           'amount': validators.exists()('Campo requerido.'),
           'document_number_payment': validators.exists()('Campo requerido.'),
           'small_box': validators.exists()('Campo requerido.'),
           'bank_account': validators.exists()('Campo requerido.'),
           'document_date_payment': validators.exists()('Campo requerido'),
           'document_date':validators.exists()('Campo requerido')
        })
    }
})(Form);

const selector = formValueSelector('expenseForm');

SmallBoxForm = connect(state => {
    const payment_type = selector(state, "payment_type.value");
    //let smallbox_limit = selector(state, "smallbox_limit");
    let small_box = selector(state, 'small_box');
    let amount = selector(state, "amount");
    amount = amount ? amount : 0;


    const smallbox_limit = small_box ? small_box.limit : 0;

    //const excede_caja_chica = (small_box !== undefined && parseFloat(smallbox_limit) >= parseFloat(amount)) ? true : false;
    //const use_small_box = (payment_type === 1 && excede_caja_chica) ? true : false;
    let use_small_box = (payment_type === 1) ? true : false;


    if(small_box && amount && parseFloat(small_box.limit) < parseFloat(amount)){
        use_small_box = false;
    }

    const to_pay = selector(state, 'to_pay')
    const provider = selector(state, 'provider')

    return {
        use_small_box,
        to_pay,
        provider,
        amount,
    };

})(SmallBoxForm);

export default SmallBoxForm;
