import Form from './Form';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {EXAMEN, PROCEDIMIENTO, PAQUETE} from '../../../../utility/variables';

export default class PlanCrear extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    state = {
        editar: false,
        mensaje: 'Agregar Plan',
        password: false,
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                    editar: true,
                    mensaje: 'Editar Plan'
                });
        }
    }
    update = (tipo_plan) => (data) => {
        const {update} = this.props;
        update({...data, tipo_plan})
    }
    create = (tipo_plan) => (data) => {
        const {create} = this.props;
        create({...data, tipo_plan})
    }

    render(){

        const {
            create,
            update
        } = this.props;

        const {
            updateData,
            cargando
        } = this.props;

        let tipo_plan = 0; let titulo='';
        if(window.location.href.includes("examen")){
            tipo_plan = EXAMEN; titulo='EXAMEN';
        }else if(window.location.href.includes("procedimiento")){
            tipo_plan = PROCEDIMIENTO; titulo='PROCEDIMIENTO';
        }else if(window.location.href.includes("paquete")){
            tipo_plan = PAQUETE; titulo='PAQUETE';
        }

        return (
            <div className="content">
                <div className="m-auto row-fluid big-card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div className="fields">
                                        <div className="top-title">
                                            {titulo}
                                        </div>
                                        <Form
                                            onSubmit={this.update(tipo_plan)}
                                            updateData={updateData}
                                            editando={true}
                                            initialValues={{
                                                tipo_plan: tipo_plan,
                                            }}
                                        />
                                    </div>
                            :
                                <div className="fields">
                                    <div className="top-title">
                                        CREAR {titulo}
                                    </div>
                                    <Form
                                        onSubmit={this.create(tipo_plan)}
                                        initialValues={{
                                            tipo_plan: tipo_plan,
                                        }}
                                    />
                                </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
