import React, {Component} from 'react';
import './style';

class EntryMovementReport extends Component{
    componentWillMount = () => {
        const {entryMovementReport, match} = this.props;
        entryMovementReport(match.params.id);

        this.props.match.params.id
    }

    render(){
        const {entry_movement} = this.props;

        return(
            <div className="content">
                <div className="table-decoration mt-3 p-4">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <h4>Orden de compra</h4><br />
                    {entry_movement &&
                        <table className='table table-header-report w-50 table-bordered'>
                            <tbody>
                                <tr>
                                    <th>Proveedor</th>
                                    <td>{entry_movement.purchase.provider.name}</td>
                                </tr>
                                <tr>
                                    <th>Comentario</th>
                                    <td>{entry_movement.purchase.comment}</td>
                                </tr>
                                <tr>
                                    <th>Fecha de Orden</th>
                                    <td>{entry_movement.purchase.order_date}</td>
                                </tr>
                            </tbody>
                        </table>
                    }

                    <br />
                    <h4>Detalle Ingreso</h4>
                    <table className='table table-header-report w-50'>
                        <thead>
                            <tr>
                                <th>Cantidad</th>
                                <th>Producto</th>
                                <th>Lote</th>
                            </tr>
                        </thead>
                        <tbody>
                        {entry_movement && entry_movement.details.map((detail, i)=>(
                            <tr key={i}>
                                <td>{detail.entry_quantity}</td>
                                <td>{detail.service.name}</td>
                                <td>{detail.lote.lote}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        );
    }
}

export default EntryMovementReport;
