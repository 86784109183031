const validate = values => {
    const errors = {};
    if (!values.referent) {
        errors.referent = 'Campo requerido';
    }

    if (!values.patient) {
        errors.patient = 'Campo requerido';
    }

    return errors;
};

export default validate;
